/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect } from "react";
import useAvosApi from "../../../../../utils/useAvosApiHook";
import ManualImages from "../ManualImages";
// Use for create purchase order reports
// Where we can have multiple check in one view
export default function CheckImages({ test_id, required_images }) {
    const [callCheckImages, images, isLoading] = useAvosApi(`/images/check/${test_id}/`);


    useEffect(() => {
        if (test_id > 0) {
            callCheckImages();
        }
    }, [test_id]);

    const addCheckImage = (type, base64, focusMode) => callCheckImages({
        test_id, type, base64, focusMode,
    }, { endpoint: `/images/check/${test_id}/add_image/`, method: "POST" });

    const deleteCheckImage = (image) => callCheckImages(image, { endpoint: `/images/check/${test_id}/delete_image/`, method: "DELETE" });

    return <div>

        {required_images && <div>
            <ManualImages
                images={images || []}
                required_images={required_images}
                saveImage={addCheckImage}
                deleteImage={deleteCheckImage}
                isLoading={isLoading}
            />
        </div>}

    </div>;
}

CheckImages.propTypes = {
    test_id: PropTypes.number,
    required_images: PropTypes.array
};
