/** @jsxImportSource @emotion/react */
import { useDispatch, useSelector } from "react-redux";
import { Badge, Col, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { updateTenantState } from "../../../actions/Tenants/actions";

interface ListTenantConfigProps {
    title: string;
}

function ListTenantInformation({ title }: ListTenantConfigProps) {
    const current = useSelector<any, any>((state) => state.tenants.current);
    const {
        tenant_name,
        tenant_id,
        tenant_country,
        tenant_group,
        powerbi_workspace,
        powerbi_report,
    } = current;
    const dispatch = useDispatch();

    const onChange = (e) => {
        dispatch(updateTenantState({ ...current, [e.target.name]: e.target.value }));
    };

    const stringify = (value) => (value === null ? "" : value);

    return (
        <div id={title}>
            <Row className="justify-content-between">
                <Col md="12">
                    <FormGroup className="pb-2">
                        <Label for="tenant_name">Tenant name</Label>
                        <Input type="text" name="tenant_name" id="tenant_name" value={tenant_name} onChange={onChange} />
                        <FormText color="muted">Give this tenant a recognizable name</FormText>
                    </FormGroup>
                </Col>
                <Col md="12">
                    <FormGroup className="pb-2">
                        <Label for="tenant_country">Tenant Country</Label>
                        <Input type="text" name="tenant_country" id="tenant_country" value={stringify(tenant_country)} onChange={onChange} />
                        <FormText color="muted">The primary Country of this tenant. Has no side effects.</FormText>
                    </FormGroup>
                </Col>
                <Col md="12">
                    <FormGroup className="pb-2">
                        <Label for="tenant_id">Tenant ID</Label>
                        <Input type="text" name="tenant_id" id="tenant_id" disabled value={tenant_id} onChange={onChange} />
                        <FormText color="muted">Unique identifier for this tenant, go to <a href="https://www.whatismytenantid.com" rel="noopener noreferrer" target="_blank">https://www.whatismytenantid.com</a> <Badge color="danger">Testable: No</Badge></FormText>
                    </FormGroup>
                </Col>
                <Col md="12">
                    <FormGroup className="pb-2">
                        <Label for="tenant_group">Tenant Group</Label>
                        <Input type="text" name="tenant_group" id="tenant_group" value={stringify(tenant_group)} onChange={onChange} />
                        <FormText color="muted">Give multiple tenants the same tenant group to let them view eachothers data  <Badge color="success">Testable: Yes</Badge></FormText>
                    </FormGroup>
                </Col>
                <Col md="12">
                    <FormGroup className="pb-2">
                        <Label for="powerbi_workspace">PowerBI Workspace ID</Label>
                        <Input type="text" name="powerbi_workspace" id="powerbi_workspace" value={powerbi_workspace} onChange={onChange} />
                        <FormText color="muted">Fill with PowerBI workspace id created for this tenant <Badge color="success">Testable: Yes</Badge></FormText>
                    </FormGroup>
                </Col>
                <Col md="12">
                    <FormGroup className="pb-2">
                        <Label for="powerbi_report">PowerBI Report ID</Label>
                        <Input type="text" name="powerbi_report" id="powerbi_report" value={powerbi_report} onChange={onChange} />
                        <FormText color="muted">Fill with PowerBI report id created for this tenant <Badge color="success">Testable: Yes</Badge></FormText>
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}

export default ListTenantInformation;
