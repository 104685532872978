/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { DeviceListModal } from "./DeviceListModal";


export default function DeviceToggle() {
    const devices = useSelector((state) => state.devices);
    const [isDeviceListModalOpen, toggleDeviceListModal] = useState(false);
    const toggleDeviceModal = () => {
        toggleDeviceListModal((state) => !state);
    };

    return (
        <>
            <Button color="transparent" className="my-1 mx-2" css={css`&:hover{color:inherit};&:focus{color:inherit};`} onClick={() => toggleDeviceModal()}>
                {devices.selected_mini !== null ? devices.selected_mini.name : "Select a device."}
            </Button>
            <DeviceListModal isOpen={isDeviceListModalOpen} toggleModal={toggleDeviceListModal} locationSelected={devices.selected_mini} />
        </>
    );
}
