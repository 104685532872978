import { faCaretDown, faCaretUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FILTER_METHOD_TYPES, FILTER_METHODS, listLayers } from "../../actions/Layers/actions";
import { applyDisplayFilterLayerFields } from "../../actions/Tenants/config/applyDisplayFilter";
import useConfig from "../../actions/Tenants/config/configHook";
import { getFilledArrayOrDefault, isFilledArray } from "../../utils";
import useClickHandler from "../../utils/useClickHandler";
import { useFruitTypes } from "../../utils/useUserPreferences";
import MetaForm from "../Forms/MetaForm";
import { removeDuplicates, useManagedOptionsHook } from "../Forms/useManagedOptionsHook";
import { TableHeaderCell } from "../Helper/Table";
import Field from "../scenes/Layers/fields/Field"; // Assuming that Field exports a type for its props
import { useSwitchTabContext } from "../scenes/Layers/fields/useSwitchTab";
import { attributeIsSet, flagOptions } from "../scenes/Tenants/Forms/FlagConfigForm";

export const SortDirection = {
    ASC: "ASC",
    DESC: "DESC",
    NONE: null
} as const;

export const IS_SET_FILTER_OPTIONS = [
    {
        label: "Only empty",
        value: "only-empty"
    },
    {
        label: "Only filled",
        value: "only-filled"
    },
    {
        label: "All",
        value: "all"
    }
];

interface SortableHeaderProps {
    field: Field; // Assuming FieldType is the correct type for `Field`
}
const LayerSortableHeader: React.FC<SortableHeaderProps> = ({ field }: SortableHeaderProps) => {
    const dispatch = useDispatch();
    const filter = useSelector((state: any) => state?.layers?.filter);
    const params = useParams();
    const navigate = useNavigate();


    const order_field_name = field.get_backend_fieldname()[0];


    const isSortable = field.is_sortable();
    const [current_order_field_name, current_direction] = filter.ordering?.split(",") || [null, null];
    const currentFieldOrdered = current_order_field_name === order_field_name;

    const updateOrdering = (direction: string) => {
        dispatch(
            listLayers({ offset: 0, ordering: `${order_field_name},${direction}` }) as any
        );
    };

    const handleClick = () => {
        if (!isSortable) {
            return false;
        }
        // If another field was ordered, default to ASC
        if (!currentFieldOrdered) {
            updateOrdering(SortDirection.ASC);
        } else {
            const direction = current_direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
            updateOrdering(direction);
        }

        return true;
    };

    // * handler for single click and double click (you cannot use onClick and onDoubleClick together)
    const doubleClickHandler = useClickHandler(handleClick, () => navigate(`/layer/tab/${params.tab_category}/${params.active_tab}/edit-filter/${order_field_name}`));

    return (
        <TableHeaderCell>
            <div className={`d-flex align-items-center ${(isSortable ? "cursor-pointer" : "")}`} onClick={field.is_filterable() ? doubleClickHandler : handleClick} >

                <div>{field.label}</div>
                {isSortable && currentFieldOrdered && (
                    <div>
                        {current_direction === SortDirection.ASC && (
                            <span className="p-2">
                                <FontAwesomeIcon icon={faCaretUp} />
                            </span>
                        )}
                        {current_direction === SortDirection.DESC && (
                            <span className="p-2">
                                <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                        )}
                    </div>
                )}
            </div>
            <LayerFieldFilterModal field={field} />
        </TableHeaderCell>
    );
};

export default LayerSortableHeader;


interface LayerFieldFilterModalProps {
    field: Field;
}

const LayerFieldFilterModal = ({ field }: LayerFieldFilterModalProps) => {
    const config = useConfig();
    const filter = useSelector((state: any) => state?.layers?.filter);
    const selectedFruitTypes = useFruitTypes();
    const order_field_name = field.get_backend_fieldname()[0];
    const { submitTabQuery, normalizeUrl } = useSwitchTabContext();

    const params = useParams();

    const { filter_method_type, min, max, formatter } = field.filter_config;

    // * Make sure the color code field is prefixed with the backend table
    const color_code_field = field.color_code_field ? `${field.backend_table}.${field.color_code_field}` : null;

    // ! unfortunatly this cannot work, because "green badges" are most of the cases unset meta fields (default value = green)
    // ! We have to do this with a NOT IN qeuey, or we should consider setting the default value in layer meta
    //  If field is LayerMetaColorBadgeField, then the color code field is the fieldname_getter
    // if (filter_method_type === FILTER_METHOD_TYPES.COLOR_CODE) {
    //     color_code_field = order_field_name;
    // }

    const [query, setQuery] = useState({
        ordering: filter.ordering,
        [order_field_name]: filter[order_field_name],
    });

    const options = useManagedOptionsHook({
        object: {
            fruit_type: getFilledArrayOrDefault(selectedFruitTypes).length === 1 ? selectedFruitTypes[0] : null, // * only enable fruit type filter on dropdown options when exactly one fruit type is selected
            layer_type: filter.layer_type
        },
        name: field.filter_config.options_name || field.options_name || field.fieldname_getter });

    const isOpen = params.edit_field_filter === order_field_name;


    // update ordering from outside this component
    useEffect(() => {
        setQuery((prev) => ({
            ...prev,
            ordering: filter.ordering,
        }));
    }, [filter.ordering]);

    const methods = [
        order_field_name,
        `${order_field_name}_min`,
        `${order_field_name}_max`,
        `${order_field_name}_date_from`,
        `${order_field_name}_date_to`,
        `${order_field_name}_not_in`,
        `${order_field_name}_is_set`
    ];
    const values = methods.map((i) => filter?.meta_filter[i]?.value);

    useEffect(() => {
        if (params.edit_field_filter === order_field_name) {
            setQuery((prev) => ({
                ...prev,
                [order_field_name]: filter?.meta_filter[order_field_name]?.value,
                [`${order_field_name}_min`]: attributeIsSet(filter?.meta_filter[`${order_field_name}_min`]?.value) ? Number(filter?.meta_filter[`${order_field_name}_min`]?.value) : null,
                [`${order_field_name}_max`]: attributeIsSet(filter?.meta_filter[`${order_field_name}_max`]?.value) ? Number(filter?.meta_filter[`${order_field_name}_max`]?.value) : null,
                [`${order_field_name}_date_from`]: filter?.meta_filter[`${order_field_name}_date_from`]?.value,
                [`${order_field_name}_date_to`]: filter?.meta_filter[`${order_field_name}_date_to`]?.value,
                [`${order_field_name}_not_in`]: filter?.meta_filter[`${order_field_name}_not_in`]?.value,
                [`${order_field_name}_is_set`]: filter?.meta_filter[`${order_field_name}_is_set`]?.value,
            }));
        }
    }, [order_field_name, params.edit_field_filter, ...values]);


    // update color code field from outside this component
    useEffect(() => {
        if (color_code_field) {
            setQuery((prev) => ({
                ...prev,
                [color_code_field as string]: filter?.meta_filter[color_code_field as string]?.value,
            }));
        }
    }, [color_code_field]);

    // * make sure max value is always higher than min value, if any value is set
    useEffect(() => {
        setQuery((prev) => ({
            ...prev,
            [`${order_field_name}_max`]: attributeIsSet(prev[`${order_field_name}_max`]) && attributeIsSet(prev[`${order_field_name}_min`]) ? Math.max(query[`${order_field_name}_min`], prev[`${order_field_name}_max`]) : prev[`${order_field_name}_max`],
        }));
    }, [query[`${order_field_name}_min`]]);

    // * make sure min value is always lower than max value, if any value is set
    useEffect(() => {
        setQuery((prev) => ({
            ...prev,
            [`${order_field_name}_min`]: attributeIsSet(prev[`${order_field_name}_max`]) && attributeIsSet(prev[`${order_field_name}_min`]) ? Math.min(prev[`${order_field_name}_min`], query[`${order_field_name}_max`]) : prev[`${order_field_name}_min`],
        }));
    }, [query[`${order_field_name}_max`]]);


    const formFields = [
        field.is_sortable() && {
            label: "Sort",
            type: "radio",
            name: "ordering",
            options: [
                {
                    label: "Descending",
                    value: `${order_field_name},DESC`
                },
                {
                    label: "Ascending",
                    value: `${order_field_name},ASC`
                },
            ],
        },
        filter_method_type === FILTER_METHOD_TYPES.NUMBER_RANGE && {
            label: "Max value",
            type: "range",
            name: `${order_field_name}_max`,
            description: attributeIsSet(query[`${order_field_name}_max`]) ? `${field.label} ≤ ${formatter(query[`${order_field_name}_max`])}` : "Slide to set upper bound",
            min,
            max,
        },
        filter_method_type === FILTER_METHOD_TYPES.NUMBER_RANGE && {
            label: "Min value",
            type: "range",
            name: `${order_field_name}_min`,
            description: attributeIsSet(query[`${order_field_name}_min`]) ? `${field.label} ≥ ${formatter(query[`${order_field_name}_min`])}` : "Slide to set lower bound",
            min,
            max
        },
        filter_method_type === FILTER_METHOD_TYPES.DATE_RANGE && {
            label: "From date",
            type: "date-picker",
            name: `${order_field_name}_date_from`,
            description: attributeIsSet(query[`${order_field_name}_date_from`]) ? `${field.label} > ${formatter(query[`${order_field_name}_date_from`])}` : "Select from date",
        },
        filter_method_type === FILTER_METHOD_TYPES.DATE_RANGE && {
            label: "To date",
            type: "date-picker",
            name: `${order_field_name}_date_to`,
            description: attributeIsSet(query[`${order_field_name}_date_to`]) ? `${field.label} ≤ ${formatter(query[`${order_field_name}_date_to`])}` : "Select to date",
        },

        filter_method_type === FILTER_METHOD_TYPES.TEXT && options.length > 0 && {
            label: "Filter",
            type: "single-select",
            name: order_field_name,
            multi: true,
            return_array: true,
            options: removeDuplicates(options)
        },
        filter_method_type === FILTER_METHOD_TYPES.TEXT && options.length === 0 && {
            label: "Filter",
            type: "text",
            name: order_field_name
        },
        color_code_field && {
            label: "Flag",
            type: "single-select",
            multi: true,
            return_array: true,
            name: color_code_field,
            options: flagOptions
        },
        filter_method_type === FILTER_METHOD_TYPES.COLOR_CODE && {
            label: "Exclude flags",
            type: "single-select",
            multi: true,
            return_array: true,
            name: `${order_field_name}_not_in`,
            options: flagOptions
        },
        {
            label: "Show empty values",
            type: "radio",
            name: `${order_field_name}_is_set`,
            options: IS_SET_FILTER_OPTIONS
        }


    ].filter((i) => i);

    const onSubmit = () => {
        let new_meta_filter = { ...filter.meta_filter };
        new_meta_filter[`${order_field_name}_min`] = attributeIsSet(query[`${order_field_name}_min`]) ? {
            method: FILTER_METHODS.NUMBER_RANGE_MIN,
            value: query[`${order_field_name}_min`],
            label: `${field.label} ≥ ${formatter(query[`${order_field_name}_min`])}`,
            field: order_field_name,
        } : null;

        new_meta_filter[`${order_field_name}_max`] = attributeIsSet(query[`${order_field_name}_max`]) ? {
            method: FILTER_METHODS.NUMBER_RANGE_MAX,
            value: query[`${order_field_name}_max`],
            label: `${field.label} ≤ ${formatter(query[`${order_field_name}_max`])}`,
            field: order_field_name,
        } : null;

        new_meta_filter[`${order_field_name}_date_from`] = attributeIsSet(query[`${order_field_name}_date_from`]) ? {
            method: FILTER_METHODS.DATE_FROM,
            value: query[`${order_field_name}_date_from`],
            label: `${field.label} > ${formatter(query[`${order_field_name}_date_from`])}`,
            field: order_field_name,
        } : null;

        new_meta_filter[`${order_field_name}_date_to`] = attributeIsSet(query[`${order_field_name}_date_to`]) ? {
            method: FILTER_METHODS.DATE_TO,
            value: query[`${order_field_name}_date_to`],
            label: `${field.label} ≤ ${formatter(query[`${order_field_name}_date_to`])}`,
            field: order_field_name,
        } : null;

        new_meta_filter[order_field_name] = attributeIsSet(query[order_field_name]) ? {
            method: options.length > 0 ? FILTER_METHODS.IN : FILTER_METHODS.STARTS_WITH,
            value: query[order_field_name],
            label: isFilledArray(query[order_field_name]) ? `${field.label} ${(query[order_field_name].length === 1 ? "=" : "in")} ${query[order_field_name].join(", ")}` : `${field.label} starts with ${query[order_field_name]}`,
            field: order_field_name,
        } : null;

        if (color_code_field) {
            new_meta_filter[color_code_field] = color_code_field && attributeIsSet(query[color_code_field]) ? {
                method: FILTER_METHODS.IN,
                value: query[color_code_field],
                label: `Color ${field.label} ${(query[color_code_field].length === 1 ? "=" : "in")} ${query[color_code_field].join(", ")}`,
                field: color_code_field,
            } : null;
        }

        new_meta_filter[`${order_field_name}_not_in`] = attributeIsSet(query[`${order_field_name}_not_in`]) ? {
            method: FILTER_METHODS.NOT_IN,
            value: query[`${order_field_name}_not_in`],
            label: `${field.label} not in ${query[`${order_field_name}_not_in`].join(", ")}`,
            field: order_field_name,
        } : null;

        new_meta_filter[`${order_field_name}_is_set`] = query[`${order_field_name}_is_set`] ? {
            method: FILTER_METHODS.IS_SET,
            value: query[`${order_field_name}_is_set`],
            // eslint-disable-next-line no-nested-ternary
            label: query[`${order_field_name}_is_set`] === "only-empty" ? `${field.label} is empty` : (query[`${order_field_name}_is_set`] === "filled" ? `${field.label} is filled` : `${field.label} is empty or filled`),
            field: order_field_name,
        } : null;

        new_meta_filter = Object.keys(new_meta_filter).reduce((acc, i) => {
            if (new_meta_filter[i]) {
                acc[i] = {
                    ...new_meta_filter[i],
                    optional: true // Filter can be removed by user
                };
            }
            return acc;
        }, {});

        submitTabQuery({ ordering: query.ordering, ...new_meta_filter });
    };

    const onReset = () => submitTabQuery({});
    const setValue = (field: string, value: any) => setQuery((prev) => ({ ...prev, [field]: value }));

    return <Modal isOpen={isOpen} toggle={() => normalizeUrl()} >
        <ModalHeader toggle={() => normalizeUrl()}>Filter by {field.label}</ModalHeader>
        <ModalBody>
            <MetaForm config={config} meta={formFields} object={query} setValue={setValue} />
        </ModalBody>
        <ModalFooter>
            <Button onClick={onSubmit} color="primary">Filter</Button>
            <Button onClick={onReset} color="warning">Reset</Button>
            <Button onClick={() => normalizeUrl()} color="light">Close</Button>
        </ModalFooter>
    </Modal>;
};

// * This component shows a warning modal when a filter is configured from the tab config, without also configuring a column in table.
// * This is an issue, because we need the column config to know how to filter the data.
export const HandleUnknownLayerFieldFilter = () => {
    const { fields, tabs } = useSwitchTabContext();
    const availableFields = fields.map((i) => i.get_backend_fieldname()[0]);
    const params = useParams();

    const order_field_name = params.edit_field_filter || "" as string;

    // * No field filter is set, so no need to show the modal
    if (!order_field_name) return null;

    // * The field filter is set, but it is not available in the fields list
    const fieldIsNotSet = !availableFields.includes(order_field_name as string);

    if (fieldIsNotSet) {
        // * Maybe the field is set on a different tab, so we need to check if the field is available on another tab
        const field = tabs.flatMap((t) => applyDisplayFilterLayerFields(getFilledArrayOrDefault(t.layer_overview_fields)))
            .find((item) => item.get_backend_fieldname()[0] === params.edit_field_filter);

        if (field) {
            // * Use the field from another tab
            return <LayerFieldFilterModal field={field} />;
        }
    }

    // * The field is not available on any tab, so show the warning modal
    return <UnknownLayerFieldFilterModal />;


};
export const UnknownLayerFieldFilterModal = () => {
    const { fields, normalizeUrl, submitTabQuery } = useSwitchTabContext();
    const availableFields = fields.map((i) => i.get_backend_fieldname()[0]);
    const params = useParams();
    const isOpen = (params.edit_field_filter && !availableFields.includes(params.edit_field_filter as string));

    const order_field_name = params.edit_field_filter || "" as string;

    const config = useConfig();
    const filter = useSelector((state: any) => state?.layers?.filter);
    const selectedFruitTypes = useFruitTypes();

    const formatter = (value) => value;
    const min = 0;
    const max = 100;
    const field_name = order_field_name.split(".")[1];
    // ! unfortunatly this cannot work, because "green badges" are most of the cases unset meta fields (default value = green)
    // ! We have to do this with a NOT IN qeuey, or we should consider setting the default value in layer meta
    //  If field is LayerMetaColorBadgeField, then the color code field is the fieldname_getter
    // if (filter_method_type === FILTER_METHOD_TYPES.COLOR_CODE) {
    //     color_code_field = order_field_name;
    // }

    const [query, setQuery] = useState({
        ordering: filter.ordering,
        [order_field_name]: filter[order_field_name],
    });

    const options = useManagedOptionsHook({
        object: {
            fruit_type: getFilledArrayOrDefault(selectedFruitTypes).length === 1 ? selectedFruitTypes[0] : null, // * only enable fruit type filter on dropdown options when exactly one fruit type is selected
            layer_type: filter.layer_type
        },
        name: field_name });


    // update ordering from outside this component
    useEffect(() => {
        setQuery((prev) => ({
            ...prev,
            ordering: filter.ordering,
        }));
    }, [filter.ordering]);

    const methods = [
        order_field_name,
        `${order_field_name}_min`,
        `${order_field_name}_max`,
        // `${order_field_name}_date_from`,
        // `${order_field_name}_date_to`,
        `${order_field_name}_not_in`,
        `${order_field_name}_is_set`
    ];
    const values = methods.map((i) => filter?.meta_filter[i]?.value);

    useEffect(() => {
        if (params.edit_field_filter === order_field_name) {
            setQuery((prev) => ({
                ...prev,
                [order_field_name]: filter?.meta_filter[order_field_name]?.value,
                [`${order_field_name}_min`]: attributeIsSet(filter?.meta_filter[`${order_field_name}_min`]?.value) ? Number(filter?.meta_filter[`${order_field_name}_min`]?.value) : null,
                [`${order_field_name}_max`]: attributeIsSet(filter?.meta_filter[`${order_field_name}_max`]?.value) ? Number(filter?.meta_filter[`${order_field_name}_max`]?.value) : null,
                // [`${order_field_name}_date_from`]: filter?.meta_filter[`${order_field_name}_date_from`]?.value,
                // [`${order_field_name}_date_to`]: filter?.meta_filter[`${order_field_name}_date_to`]?.value,
                [`${order_field_name}_not_in`]: filter?.meta_filter[`${order_field_name}_not_in`]?.value,
                [`${order_field_name}_is_set`]: filter?.meta_filter[`${order_field_name}_is_set`]?.value,
            }));
        }
    }, [order_field_name, params.edit_field_filter, ...values]);

    // * make sure max value is always higher than min value, if any value is set
    useEffect(() => {
        setQuery((prev) => ({
            ...prev,
            [`${order_field_name}_max`]: attributeIsSet(prev[`${order_field_name}_max`]) && attributeIsSet(prev[`${order_field_name}_min`]) ? Math.max(query[`${order_field_name}_min`], prev[`${order_field_name}_max`]) : prev[`${order_field_name}_max`],
        }));
    }, [query[`${order_field_name}_min`]]);

    // * make sure min value is always lower than max value, if any value is set
    useEffect(() => {
        setQuery((prev) => ({
            ...prev,
            [`${order_field_name}_min`]: attributeIsSet(prev[`${order_field_name}_max`]) && attributeIsSet(prev[`${order_field_name}_min`]) ? Math.min(prev[`${order_field_name}_min`], query[`${order_field_name}_max`]) : prev[`${order_field_name}_min`],
        }));
    }, [query[`${order_field_name}_max`]]);


    const formFields = [
        {
            label: "Sort",
            type: "radio",
            name: "ordering",
            options: [
                {
                    label: "Descending",
                    value: `${order_field_name},DESC`
                },
                {
                    label: "Ascending",
                    value: `${order_field_name},ASC`
                },
            ],
        },
        {
            label: "Max value",
            type: "range",
            name: `${order_field_name}_max`,
            description: attributeIsSet(query[`${order_field_name}_max`]) ? `value ≤ ${formatter(query[`${order_field_name}_max`])}` : "Slide to set upper bound",
            min,
            max,
        },
        {
            label: "Min value",
            type: "range",
            name: `${order_field_name}_min`,
            description: attributeIsSet(query[`${order_field_name}_min`]) ? `value ≥ ${formatter(query[`${order_field_name}_min`])}` : "Slide to set lower bound",
            min,
            max
        },
        {
            label: "From date",
            type: "date-picker",
            name: `${order_field_name}_date_from`,
            description: attributeIsSet(query[`${order_field_name}_date_from`]) ? `value > ${formatter(query[`${order_field_name}_date_from`])}` : "Select from date",
        },
        {
            label: "To date",
            type: "date-picker",
            name: `${order_field_name}_date_to`,
            description: attributeIsSet(query[`${order_field_name}_date_to`]) ? `value ≤ ${formatter(query[`${order_field_name}_date_to`])}` : "Select to date",
        },

        options.length > 0 && {
            label: "Filter",
            type: "single-select",
            name: order_field_name,
            multi: true,
            return_array: true,
            options: removeDuplicates(options)
        },
        options.length === 0 && {
            label: "Filter",
            type: "text",
            name: order_field_name
        },
        {
            label: "Show empty values",
            type: "radio",
            name: `${order_field_name}_is_set`,
            options: IS_SET_FILTER_OPTIONS
        }


    ].filter((i) => i);

    const onSubmit = () => {
        let new_meta_filter = { ...filter.meta_filter };
        new_meta_filter[`${order_field_name}_min`] = attributeIsSet(query[`${order_field_name}_min`]) ? {
            method: FILTER_METHODS.NUMBER_RANGE_MIN,
            value: query[`${order_field_name}_min`],
            label: `${field_name} ≥ ${formatter(query[`${order_field_name}_min`])}`,
            field: order_field_name,
        } : null;

        new_meta_filter[`${order_field_name}_max`] = attributeIsSet(query[`${order_field_name}_max`]) ? {
            method: FILTER_METHODS.NUMBER_RANGE_MAX,
            value: query[`${order_field_name}_max`],
            label: `${field_name} ≤ ${formatter(query[`${order_field_name}_max`])}`,
            field: order_field_name,
        } : null;

        new_meta_filter[`${order_field_name}_date_from`] = attributeIsSet(query[`${order_field_name}_date_from`]) ? {
            method: FILTER_METHODS.DATE_FROM,
            value: query[`${order_field_name}_date_from`],
            label: `${field_name} > ${formatter(query[`${order_field_name}_date_from`])}`,
            field: order_field_name,
        } : null;

        new_meta_filter[`${order_field_name}_date_to`] = attributeIsSet(query[`${order_field_name}_date_to`]) ? {
            method: FILTER_METHODS.DATE_TO,
            value: query[`${order_field_name}_date_to`],
            label: `${field_name} ≤ ${formatter(query[`${order_field_name}_date_to`])}`,
            field: order_field_name,
        } : null;

        new_meta_filter[order_field_name] = attributeIsSet(query[order_field_name]) ? {
            method: options.length > 0 ? FILTER_METHODS.IN : FILTER_METHODS.STARTS_WITH,
            value: query[order_field_name],
            label: isFilledArray(query[order_field_name]) ? `${field_name} ${(query[order_field_name].length === 1 ? "=" : "in")} ${query[order_field_name].join(", ")}` : `${field_name} starts with ${query[order_field_name]}`,
            field: order_field_name,
        } : null;

        new_meta_filter[`${order_field_name}_not_in`] = attributeIsSet(query[`${order_field_name}_not_in`]) ? {
            method: FILTER_METHODS.NOT_IN,
            value: query[`${order_field_name}_not_in`],
            label: `${field_name} not in ${query[`${order_field_name}_not_in`].join(", ")}`,
            field: order_field_name,
        } : null;

        new_meta_filter[`${order_field_name}_is_set`] = query[`${order_field_name}_is_set`] ? {
            method: FILTER_METHODS.IS_SET,
            value: query[`${order_field_name}_is_set`],
            label: query[`${order_field_name}_is_set`] === "only-empty" ? `${field_name} is empty` : `${field_name} is filled`,
            field: order_field_name,
        } : null;

        new_meta_filter = Object.keys(new_meta_filter).reduce((acc, i) => {
            if (new_meta_filter[i]) {
                acc[i] = {
                    ...new_meta_filter[i],
                    optional: true // Filter can be removed by user
                };
            }
            return acc;
        }, {});

        submitTabQuery({ ordering: query.ordering, ...new_meta_filter });
    };

    const onReset = () => submitTabQuery({});
    const setValue = (field: string, value: any) => setQuery((prev) => ({ ...prev, [field]: value }));

    return <Modal isOpen={isOpen as boolean } toggle={() => normalizeUrl()} >
        <ModalHeader toggle={() => normalizeUrl()}>Unknown Column/Field</ModalHeader>
        <ModalBody>
            <Alert color="warning"><b>{order_field_name}</b> is not configured as column in the table. Please configure the column in the table to enable customized filtering.</Alert>
        </ModalBody>
        <ModalBody>
            <MetaForm config={config} meta={formFields} object={query} setValue={setValue} />
        </ModalBody>
        <ModalFooter>
            <Button onClick={onSubmit} color="primary">Filter</Button>
            <Button onClick={onReset} color="warning">Reset</Button>
            <Button onClick={() => normalizeUrl()} color="light">Close</Button>
        </ModalFooter>
    </Modal>;

};
