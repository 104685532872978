import { toast } from "../../utils/toast";
import { formatFruit2Backend, formatFruit2Frontend } from "../Avocados/utils";
import {
    CREATE,
    DELETE,
    GET,
    LIST,
    PATCH,
    RESET,
    UPDATE,
} from "./actionsTypes";
import { defaultAvocado } from "./constants";

const avocados = {
    items: false,
    isLoading: false,
    isUpdating: false,
    current: defaultAvocado,
    filter: { search: "", limit: 50, ordering: "" },
};
export default (state = avocados, action) => {
    switch (action.type) {
    case LIST.FILTER:
        return { ...state, filter: action.payload };
    case LIST.LOADING:
    case GET.LOADING:
        return { ...state, isLoading: true };

    case CREATE.LOADING:
        // toast.info('Saving fruit...', {
        //     toastId: 'create-avocado',
        //     autoClose: 1500,
        // });
        return { ...state, isUpdating: true };

    case UPDATE.LOADING:
        // toast.info('Updating fruit...', {
        //     toastId: 'update-avocado-' + action.payload.request.data.avocado_id,
        //     autoClose: 1500,
        // });
        return { ...state, isUpdating: true };

    case PATCH.STATE:
        return { ...state,
            current: {
                ...state.current,
                [action.payload.field]: action.payload.value,
            } };

    case PATCH.LOADING:
        return { ...state,
            current: {
                ...state.current,
                [action.payload.request.data.field]: action.payload.request.data.state_value,
            },
            isUpdating: true };

    case PATCH.SUCCESS:
        return { ...state, isUpdating: false };
    case PATCH.ERROR:
        return { ...state, isUpdating: false };

    case DELETE.LOADING:
        toast.warning("Deleting fruit...", {
            toastId: "delete-avocados",
            autoClose: 1500,
        });
        return {
            ...state,
            isUpdating: true,
            items: {
                results: state.items.results.filter((x) => x.avocado_id !== action.payload.request.avocado_id),
            },
        };

    case LIST.ERROR:
    case GET.ERROR:
    case CREATE.ERROR:
    case UPDATE.ERROR:
    case DELETE.ERROR:
        return { ...state, isLoading: false, isUpdating: false };

    case LIST.SUCCESS:
        return { ...state, isLoading: false, items: action.payload.data.map(formatFruit2Frontend) };

    case RESET.SUCCESS:
    case GET.SUCCESS:
        return { ...state, isLoading: false, current: formatFruit2Frontend(action.payload.data) };

    case CREATE.SUCCESS:
        // toast.update('create-avocado', { render: "Fruit saved", type: toast.TYPE.SUCCESS, autoClose: 3500 });
        return {
            ...state, isLoading: false, isUpdating: false, current: formatFruit2Frontend(action.payload.data),
        };

    case UPDATE.SUCCESS:
        // toast.update('update-avocado-' + action.payload.data.avocado_id, { render: "Fruit updated!", type: toast.TYPE.SUCCESS, autoClose: 3500 });
        return { ...state, isUpdating: false };
    case DELETE.SUCCESS:
        toast.update("delete-avocados", { render: "Avocado deleted!", type: "warning", autoClose: 3500 });
        return {
            ...state, isLoading: false, isUpdating: false, current: formatFruit2Backend(action.payload.data),
        };

    default:
        return state;
    }
};
