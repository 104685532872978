/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button
} from "reactstrap";
import useConfig from "../../../../actions/Tenants/config/configHook";
import MetaForm from "../../../Forms/MetaForm";

export default function SubconfigForm({ name, title, description, getForm, subconfig, setSubconfig, defaultSubconfig }) {
    const [display, setDisplay] = useState("preview");
    const config = useConfig();

    const enable = () => {
        setSubconfig(defaultSubconfig);
        setDisplay("edit");
    };
    const disable = () => {
        setSubconfig(false);
        setDisplay("preview");
    };

    return (<div className="border p-3 mb-3" css={css`background: #fcfcfc;`}>

        <div className="d-flex align-items-center">
            <div className="mb-0 me-auto">
                <div className="mb-0 fw-bold">{title} [{name}] </div>
                <div className="text-muted">{description}</div>
            </div>
            <div className="ms-auto"></div>
            { !subconfig && <div className="ms-2"><Button size="sm" onClick={() => enable()}>Enable</Button></div>}
            { subconfig && <div className="ms-2"><Button size="sm" outline color="danger" onClick={() => disable()}>Disable</Button></div>}
            { display === "edit" && subconfig && <div className="ms-2"><Button size="sm" onClick={() => setDisplay("preview")}><FontAwesomeIcon icon={faMinus}/></Button></div>}
            { display === "preview" && subconfig && <div className="ms-2"><Button size="sm" onClick={() => setDisplay("edit")}><FontAwesomeIcon icon={faPlus}/></Button></div>}
        </div>
        { display === "edit"
        && <div>
            <MetaForm
                meta={getForm(subconfig)}
                setValue={(field, value) => setSubconfig({ ...subconfig, [field]: value }) }
                object={subconfig}
                config={config}
            />
        </div>}

    </div>
    );
}
