/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faFileArrowDown, faFileArrowUp, faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
    Badge,
    Button,
    Col, Container, Row,
    Table
} from "reactstrap";
import { listPackingList, updateFilter } from "../../../actions/PackingList/actions";
import { Loading } from "../../Helper/Loading";
import { PaginationRow } from "../../Helper/PaginationRow";
import { TableCell, TableHeaderCell } from "../../Helper/Table";
import PackingListReviewModal from "./PackingListReviewModal";
import PackingListUploadModal from "./PackingListUploadModal";

function StatusBadge({ item }) {
    let color = "light";
    if (item.status === "SUCCESS") {
        color = "success";
    } else if (item.status === "FAILED") {
        color = "danger";
    } else if (item.status === "CREATED") {
        color = "warning";
    }
    return <Badge size="sm" color={color}>{item.status}</Badge>;
}

// * Use this component to display packing list in standalone page
export default function PackingListPage() {
    const navigate = useNavigate();
    const { addButton, body, isUpating, isLoading } = packingListComponents();
    return <Container className="py-5">
        <Row className="justify-content-center">
            <Col lg="12">
                <div className="d-flex justify-content-between">
                    <div>
                        <h2 className="">Packing list import</h2>
                    </div>
                    <div>
                        {addButton}
                        <Button className="btn-close my-1" disabled={isUpating || isLoading} size="lg" onClick={() => navigate(`/`)} ></Button>
                    </div>
                </div>
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col lg="12">
                {body}
            </Col>
        </Row>
    </Container>;

}

// * Use this component to display packing list in tab
export function PackingListTab() {
    const { addButton, body } = packingListComponents();
    return <div>
        <div className="d-flex align-items-center">
            {addButton}
        </div>
        <div className="pt-4">
            {body}
        </div>
    </div>;

}


export function packingListComponents() {
    const dispatch = useDispatch();
    const items = useSelector((state: any) => state.packingLists.items);
    const isLoading = useSelector((state: any) => state.packingLists.isLoading);
    const isUpating = useSelector((state: any) => state.packingLists.isUpdating);
    const [importModal, setImportModal] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const filter = useSelector((state: any) => state.packingLists.filter);

    useEffect(() => {
        if (!isLoading) {
            dispatch(listPackingList(filter) as any);
        }
    }, [filter]);

    const updateLimit = (limit) => {
        dispatch(updateFilter({ ...filter, limit }));
    };

    const download = (item) => {
        window.location.href = item.url;
    };

    const onPageChanged = (data) => {
        const { currentPage, pageLimit } = data;
        const offset = Math.max((currentPage - 1) * pageLimit, 0);
        if (filter.offset !== offset) {
            dispatch(updateFilter({ ...filter, offset }));
        }
    };

    const layerLink = (item) => {
        if (!item.layer_id) {
            return <span>-</span>;
        }
        return <Link to={`/layer/${item.layer_id}`}>{item.label}</Link>;
    };

    const noWrap = css`
        white-space: nowrap; /* Prevents wrapping */
        overflow: hidden; /* Keeps content from spilling out */
        text-overflow: ellipsis; /* Adds ellipsis if content is too long */
        max-width: 150px; /* Adjust as needed */
    `;


    const results = items.results || [];
    const addButton = <Button className="text-nowrap me-3" color="primary" onClick={() => setImportModal(true)}><FontAwesomeIcon icon={faFileArrowUp} /> Import packing list</Button>;
    const body = (<div>
        <div css={css`min-height: 70vh;`}>
            {isLoading && <Loading />}
            {!isLoading && <Table size="sm" responsive={true} borderless={true}>
                <thead>
                    <tr>
                        <TableHeaderCell>Template</TableHeaderCell>
                        <TableHeaderCell>File name</TableHeaderCell>
                        <TableHeaderCell>Created</TableHeaderCell>
                        <TableHeaderCell>Container</TableHeaderCell>
                        <TableHeaderCell styles={noWrap}>Purchase order</TableHeaderCell>
                        <TableHeaderCell>Country</TableHeaderCell>
                        <TableHeaderCell>Supplier</TableHeaderCell>
                        <TableHeaderCell>Fruit</TableHeaderCell>
                        <TableHeaderCell >Status</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </tr>
                </thead>
                <tbody>
                    {results.map((item) => <tr key={item.id}>
                        <TableCell>{item.template}</TableCell>
                        <TableCell>{item.file_name}</TableCell>
                        <TableCell styles={noWrap}>{dayjs(item.created).format("HH:mm DD/MM/YYYY")}</TableCell>
                        <TableCell>{item.container_no || "-"}</TableCell>
                        <TableCell>
                            {layerLink(item)}
                        </TableCell>
                        <TableCell>{item.country}</TableCell>
                        <TableCell styles={noWrap}>{item.supplier}</TableCell>
                        <TableCell>{item.fruit_type}</TableCell>
                        <TableCell ><StatusBadge item={item}></StatusBadge></TableCell>
                        <TableCell className="text-end d-flex">
                            <Button styles={noWrap} size="sm" className="mx-1" color="light" onClick={() => setPreviewModal(item.id)}><FontAwesomeIcon icon={faMagnifyingGlass} /> View</Button>
                            <Button styles={noWrap} size="sm" color="light" onClick={() => download(item)}><FontAwesomeIcon icon={faFileArrowDown} /> Download</Button>
                        </TableCell>
                    </tr>) }
                </tbody>
            </Table>}
        </div>
        <PaginationRow results={results} totalCount={items.count} filter={filter} onPageChanged={onPageChanged} updateLimit={updateLimit} />
        {items?.count === 0 && <div className="text-center py-5">
            <div className="text-center d-flex align-items-center justify-content-center" css={css`min-height: 50vh;`}>
                <div>
                    <h6 className="text-muted">Upload a packing list to get started</h6>
                    <Button color="primary" onClick={() => setImportModal(true)}><FontAwesomeIcon icon={faFileArrowUp} /> Import packing list</Button>
                </div>
            </div>
        </div>}
        <PackingListUploadModal isOpen={importModal} toggle={() => setImportModal(!importModal)} />
        <PackingListReviewModal list_id={previewModal} isOpen={previewModal !== false} toggle={() => setPreviewModal(false)} />
    </div>);
    return { addButton, body, isUpating, isLoading };
}
