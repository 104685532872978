/** @jsxImportSource @emotion/react */
// TODO: consider replacing this functionality with a color coding scheme in internal data processing
import FilterableConfigList from "./FilterableConfigList";
import FlagConfigForm, { flagOptions } from "./FlagConfigForm";

const MetricCardOptions = [
    {
        value: "delay_on_arrival",
        label: "Delay on arrival",
        default_flag: "success",
        flags: [
            {
                gte: null,
                lt: null,
                gt: null,
                lte: 2,
                flag: "warning", // less then or equal to 2 days delayed
            },
            {
                gte: null,
                lt: null,
                gt: 2,
                lte: null,
                flag: "warning", // more then 2 days delayed
            },
        ],
    },
    {
        value: "unique_ggn",
        label: "Unique GGNs",
        default_flag: "success",
        flags: [
            {
                gte: null,
                lt: null,
                gt: 4,
                lte: null,
                flag: "danger", // GGN count > 4
            },
        ],
    },
    {
        value: "fruit_age",
        label: "Fruit ages",
        default_flag: "success",
        flags: [
            {
                gte: null,
                lt: null,
                gt: 36,
                lte: null,
                flag: "danger",
            },
            {
                gte: null,
                lt: null,
                gt: 21,
                lte: null,
                flag: "warning",
            },
        ],
    },
    {
        value: "average_dry_matter",
        label: "AVG DM",
        default_flag: "light",
        flags: [
            {
                gte: null,
                lt: 21,
                gt: null,
                lte: null,
                flag: "danger",
            },
            {
                gte: 21,
                lt: null,
                gt: null,
                lte: 32,
                flag: "success",
            },
            {
                gte: null,
                lt: null,
                gt: 32,
                lte: null,
                flag: "warning",
            },
        ],
    },
    {
        value: "transit_time",
        label: "Transit time",
        default_flag: "success",
        flags: [
            {
                gte: null,
                lt: null,
                gt: 12,
                lte: null,
                flag: "warning",
            },
        ],
    },
    {
        value: "spread_harvest",
        label: "Spread Harvest",
        default_flag: "success",
        flags: [
            {
                gte: null,
                lt: null,
                gt: 2,
                lte: null,
                flag: "danger",
            },
            {
                gte: null,
                lt: null,
                gt: 1,
                lte: null,
                flag: "warning",
            },
        ],
    },
    {
        value: "spread_harvest_packing",
        label: "Spread Harvest Packing",
        default_flag: "success",
        flags: [
            {
                gte: null,
                lt: null,
                gt: 2,
                lte: null,
                flag: "danger",
            },
            {
                gte: null,
                lt: null,
                gt: 1,
                lte: null,
                flag: "warning",
            },
        ],
    },
];

const defaultField = {
    field: {
        key: "delay_on_arrival",
        label: "Delay on arrival",
        default_flag: "light",
        flags: [],
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
    // exclude_layer_types: [],
    }
};

export default function MetricCardsConfigForm({ name, title, fields, description, setArray }) {
    const getFieldTitle = (object) => <span>{object.label} [{object.key}]</span>;
    const getFieldForm = (object, setObject) => [
        {
            label: "Key",
            type: "single-select",
            name: "key",
            options: MetricCardOptions,
            onchange_callback: ({ field, value }) => {
                const option = MetricCardOptions.find((i) => i.value === value);
                if (option) {
                    setObject({ ...object, [field]: value, label: option.label, default_flag: option.default_flag, flags: option.flags });
                    return;
                }
                setObject(defaultField);
            },
        },
        {
            label: "Label",
            type: "text",
            name: "label"
        },
        {
            label: "Default flag",
            type: "single-select",
            name: "default_flag",
            options: flagOptions

        },
        {
            type: "element",
            el: <FlagConfigForm
                fields={object.flags || []}
                description="Possible flags for this field"
                name="flags"
                title="Flags"
                setArray={(flags) => setObject({ ...object, flags })} />
        }

    ];
    return <FilterableConfigList
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        useDisplayFilter ={ ["fruit_types", "exclude_fruit_types"]}
        getFieldTitle={getFieldTitle} />;

}


