import { Document, Font, Page, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { PDFFooter } from "./PDFFooter";
import { PDFHeader } from "./PDFHeader";

// Stylesheet for PDFDocument
const documentStyles = StyleSheet.create({
    container: {
        fontFamily: "Poppins",
        padding: 5,
        marginBottom: 5
    },
});

// Stylesheet for PDFPage
const pageStyles = StyleSheet.create({
    page: {
        padding: "20px 30px",
        color: "black"
    },
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
        height: "90vh"
    },
    cell1: {
        width: "40%", // Adjust based on your layout
        paddingTop: "15px"
    },
    cell2: {
        width: "56%", // Adjust based on your layout
        padding: "30px 0px"
    },
});

// Register fonts (assuming these are declared globally)
Font.register({ family: "Poppins", src: `/font/Poppins-Regular.ttf` });
Font.register({ family: "Poppins-Medium", src: `/font/Poppins-Medium.ttf` });
Font.register({ family: "Poppins-Bold", src: `/font/Poppins-Bold.ttf` });
Font.registerHyphenationCallback((word) => [word]);

// Interface for PDFDocument props
interface PDFDocumentProps {
    children?: React.ReactNode;
    onRender?: () => void;
    title?: string;
}

// PDFDocument component
export const PDFDocument: React.FC<PDFDocumentProps> = ({ children, onRender, title }) => {
    return (
        <Document style={documentStyles.container} onRender={onRender} title={title}>
            {children}
        </Document>
    );
};

// Interface for PDFPage props
interface PDFPageProps {
    children?: React.ReactNode;
    logo?: string;
    footer?: boolean;
    header_title?: string;
}

// PDFPage component
export const PDFPage: React.FC<PDFPageProps> = ({ children, logo, footer, header_title }) => {
    return (
        <Page size="A4" style={pageStyles.page} orientation="landscape">
            {header_title && <PDFHeader logo_name={logo} title={header_title} />}
            {children}
            {footer && <PDFFooter />}
        </Page>
    );
};

