/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Table } from "reactstrap";
import { listFacilityLocations } from "../../../actions/FacilityLocations/actions";
import { applyDisplayFilterLayerFields } from "../../../actions/Tenants/config/applyDisplayFilter";
import useConfig from "../../../actions/Tenants/config/configHook";
import { TableCell, TableHeaderCell } from "../../Helper/Table";
import { LOCATION_TYPES } from "../Tenants/TenantEditFacilityLocationsForm";
import RipeWiseBreadcrumbs from "./RipeWiseBreadcrumbs";
import StyledBox from "./StyledBox";

const RipeningCellsTableWrapped = () => {

    return (
        <Container className="pt-3">
            <h2>Ripecells</h2>
            <RipeWiseBreadcrumbs currentLocation={null} />
            <StyledBox >
                <RipeningCellsTable />
            </StyledBox>
        </Container>
    );
};

export default RipeningCellsTableWrapped;


// DataTable component for displaying cells
export function RipeningCellsTable() {

    const dispatch = useDispatch();
    const filter = useSelector((state: any) => state.facilityLocations.filter);
    const ripening_cells = useSelector((state: any) => state.facilityLocations.locations);

    useEffect(() => {
        dispatch(listFacilityLocations({ type: LOCATION_TYPES.RIPENING_CELL, ...filter }));
    }, []);

    const config = useConfig();
    const navigate = useNavigate();

    const fields = applyDisplayFilterLayerFields(config?.root_config?.ripening_cell_overview_fields, {
        // fruit_type: "avocado"  # TODO: discuss fruit_type fillters
    });

    if (fields.length === 0) {
        return null;
    }
    const values = (p) => fields.map((i, index) => <TableCell key={index}>{i.set_value({
        config,
        ripeningCell: p,
    }).display_component()}</TableCell>);

    const renderHeader = () => {
        return fields.map((field, index) => (
            <TableHeaderCell key={index} >{field.label}</TableHeaderCell>
        ));
    };

    return (
        <div className="pt-4 d-flex flex-column" css={css`min-height: 50vh;`} >
            <div >
                {<Table size="sm" responsive={true} borderless={true}>
                    <thead>
                        <tr>{renderHeader()}</tr>
                    </thead>
                    <tbody>
                        {ripening_cells.map((i, index) => <tr key={index} className="clickable" title={i.location_id} onClick={() => navigate(`/ripening-cell/${i.location_id}`)}>{values(i)}</tr>)}
                    </tbody>
                </Table >}
            </div>
        </div>);
}
