import { getStore } from "../../store";
import { DELETE, LIST, POST } from "./actionsTypes";


//
// PLATFORM WASTE
//
export function listWaste(filter = {}) {
    const state = getStore().getState();
    filter = { ...state.waste.filter, ...filter };
    return {
        types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
        payload: {
            request: {
                filter_object: filter,
                params: filter_to_params(filter),
                method: "GET",
                url: "waste/",
            },
        },
    };
}
function filter_to_params(filter) {
    return {
        ...filter,
        // country: filter.country.map((i) => i.value).join(","),
    };
}

export function createWaste(data) {
    return {
        types: [POST.LOADING, POST.SUCCESS, POST.ERROR],
        payload: {
            request: {
                method: "POST",
                url: "waste/",
                data
            },
        },
    };
}

export function deleteWaste(waste_id) {
    return {
        types: [DELETE.LOADING, DELETE.SUCCESS, DELETE.ERROR],
        payload: {
            request: {
                method: "DELETE",
                url: `waste/${waste_id}`,
            },
        },
    };

}
