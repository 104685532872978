/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faBracketsCurly, faExchange, faHistory, faSave } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert, Badge, Button, Col, Container, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { createTenantConfig, getTenant, getTenantConfig, getTenantConfigRevisions, patchTenantConfig, updateTenantConfigState } from "../../../actions/Tenants/actions";
import useConfig from "../../../actions/Tenants/config/configHook";
import { CONFIG_TYPES } from "../../../actions/Tenants/config/constants";
import { toast } from "../../../utils/toast";
import MetaForm from "../../Forms/MetaForm";
import DebugModal from "../../Helper/DebugModal";
import { Loading } from "../../Helper/Loading";
import CheckLocationConfigForm from "./Forms/CheckLocationConfigForm";
import ColorCodeConfigForm from "./Forms/ColorCodeConfigForm";
import FruitTypeConfigForm from "./Forms/FruitTypeConfigForm";
import LayerTypeConfigForm from "./Forms/LayerTypeConfigForm";
import OverviewTabConfigForm from "./Forms/OverviewTabConfigForm";
import PDFReportConfigForm from "./Forms/PDFReportConfigForm";
import RootConfigForm from "./Forms/RootConfigForm";
import { deepEqual } from "./Forms/util";
import TenantPageWrappedInConfigProvider from "./TenantPageWrappedInConfigProvider";

type ImportDataType = {
    config_data: object,
    tenant: object
}


type SelectedFileType ={
    name: string,
    size: string,
    type: string,
}

// * Wrap this page in the config of the tenant we are editing
function WrappedInConfigProviderTenantEditForm() {
    return <TenantPageWrappedInConfigProvider>
        <DndProvider backend={HTML5Backend}>
            <TenantEditConfig/>
        </DndProvider>
    </TenantPageWrappedInConfigProvider>;
}


export default WrappedInConfigProviderTenantEditForm;

function TenantEditConfig() {
    const tenant = useSelector((state: any) => state?.tenants?.current);
    const isLoading = useSelector((state: any) => state?.tenants?.isLoading);
    const isUpating = useSelector((state: any) => state?.tenants?.isUpating);
    const currentConfig = useSelector((state: any) => state?.tenants?.currentConfig);
    const revisions = useSelector((state: any) => state?.tenants?.configRevisions);
    const [debugModal, setDebugModal] = useState(false);
    const [saveModal, setSaveModal] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const [revisionModal, setRevisionModal] = useState(false);
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [importData, setImportData] = useState<boolean|ImportDataType>(false);
    const [selectedFile, setSelectedFile] = useState<boolean|SelectedFileType>(false);
    const config = useConfig();

    const { tenant_name } = tenant;
    const { tenant_id, config_id } = params;
    // * set the config data and also reset note as soon as something changes
    const setConfigData = (config) => dispatch(updateTenantConfigState({ ...currentConfig, note: "", value: config.value, config }) as any);


    // * refresh the tenant object if needed
    useEffect(() => {
        if (tenant_id !== tenant?.tenant_id) {
            dispatch(getTenant(tenant_id) as any);
        }
    }, [tenant_id]);

    // * get the config by id
    // * config_data is the config json
    // * currentConfig is config record from the db
    const refreshRevisions = () => dispatch(getTenantConfigRevisions(tenant_id, config_id) as any);
    useEffect(() => {

        if (tenant_id && String(config_id)?.length > 30) {
            refreshRevisions();
            dispatch(getTenantConfig(tenant_id, config_id) as any);
        }
    }, [config_id, tenant_id]);


    const fileChangeHandler = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const jsonData = JSON.parse(event?.target?.result as string);
                    setImportData(jsonData);
                    setSelectedFile(file);
                } catch (error) {
                    toast.error(`Error parsing JSON file: ${error}`, {
                        autoClose: 1500,
                    });
                }
            };

            reader.readAsText(file);
        }
    };

    const handleImport = () => {
        setConfigData(importData && importData !== true ? importData.config_data : {});
        toast.success("Updated the form with the imported data");
        setImportModal(false);
    };

    const handleImportFromClipboard = (data) => {
        setConfigData(data && data !== true ? data : {});
        toast.success("Updated the form with the imported data");
        setImportModal(false);
    };


    const toggleActive = (config_id, active) => dispatch(patchTenantConfig(tenant_id, config_id, "active", active) as any).then(() => {
        refreshRevisions();
    });
    const save = () => dispatch(createTenantConfig(tenant_id, currentConfig) as any).then((response) => {
        if (!response.error) {
            setSaveModal(false);
            navigate(`/tenants/${tenant_id}/config/${response.payload.data.id}`);
        }
    });

    const selectRevison = (config_id) => {
        navigate(`/tenants/${tenant_id}/config/${config_id}`);
        setRevisionModal(false);
    };

    const exportData = (data, fileName, type) => {
    // Create a link and download the file
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const exportOptions = () => {

        const data = {
            tenant,
            config_data
        };

        const jsonData = JSON.stringify(data, null, 2);

        exportData(jsonData, `export-${tenant.tenant_name}-${dayjs().format("DD-MMM-YYYY-HHmm")}-${currentConfig.type}-${currentConfig.value}.json`, "application/json;charset=utf-8;");
    };
    // console.log("currentConfig", currentConfig); // eslint-ignore
    // console.log("isLoading", isLoading);
    if (!currentConfig || isLoading) return <Loading></Loading>;
    const config_data = currentConfig.config;

    const earlierRevision = revisions.find((i) => i.id === config_id);
    const configIsDirty = earlierRevision && !deepEqual(config_data, earlierRevision.config);
    const isLatestRevision = revisions[revisions.length - 1]?.id === config_id;

    const isSaveDisabled = isLoading || isUpating || !config_data || !configIsDirty;

    return (
        <Container className="py-5">
            <Row className="justify-content-center">
                <Col lg="12">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2 className="">Config {currentConfig.type === "stage" ? "tab" : currentConfig.type}</h2>
                            <p className="">Tenant <b>{tenant_name}</b> {currentConfig.type} for <b>{currentConfig.value}</b> created on <b>{dayjs(currentConfig.created).format("HH:mm DD MMM YYYY")}</b> by <b>{currentConfig.original_author}</b></p>
                        </div>
                        <div>
                            <Button className="text-nowrap me-2" color="light" onClick={() => setDebugModal(true)}><FontAwesomeIcon icon={faBracketsCurly} /></Button>
                            <Button className="text-nowrap me-2" color="light" onClick={() => setImportModal(true)}><FontAwesomeIcon icon={faExchange} /> Import</Button>
                            <Button className="text-nowrap me-2" color="light" onClick={() => setRevisionModal(true)}><FontAwesomeIcon icon={faHistory} /> Revisions</Button>
                            <Button className="text-nowrap me-2" color="primary" onClick={() => setSaveModal(true)} disabled={isSaveDisabled}><FontAwesomeIcon icon={faSave} /></Button>
                            {!currentConfig.active && <Button className="text-nowrap me-2" color="primary" disabled={configIsDirty} onClick={() => toggleActive(config_id, true)}>Activate</Button>}
                            {currentConfig.active && <Button className="text-nowrap me-2" color="danger" onClick={() => toggleActive(config_id, false)}>Deactivate</Button>}
                            <Button className="btn-close my-1" disabled={isUpating || isLoading} size="lg" onClick={() => navigate(`/tenants/${tenant_id}/tab/${currentConfig.type}`)} ></Button>
                        </div>
                    </div>
                    {!isLatestRevision && <Alert color="danger" ><b>You are not editing the latest revision.</b> Are you sure you want to make changes from this point in time?</Alert>}
                    {isLatestRevision && !currentConfig.active && <Alert color="info" >Do not forget to <b>activate</b> your config.</Alert>}
                </Col>
            </Row>
            <Row className="justify-content-center py-5">
                <Col lg="12">
                    {currentConfig?.type === CONFIG_TYPES.ROOT && <RootConfigForm config_data={config_data} setConfigData={setConfigData} />}
                    {currentConfig?.type === CONFIG_TYPES.LAYER_TYPE && <LayerTypeConfigForm config_data={config_data} setConfigData={setConfigData} /> }
                    {currentConfig?.type === CONFIG_TYPES.CHECK_LOCATION && <CheckLocationConfigForm config_data={config_data} setConfigData={setConfigData} />}
                    {currentConfig?.type === CONFIG_TYPES.STAGE && <OverviewTabConfigForm config_data={config_data} setConfigData={setConfigData} />}
                    {currentConfig?.type === CONFIG_TYPES.FRUIT_TYPE && <FruitTypeConfigForm config_data={config_data} setConfigData={setConfigData} />}
                    {currentConfig?.type === CONFIG_TYPES.PDF_REPORT && <PDFReportConfigForm config_data={config_data} setConfigData={setConfigData} />}
                    {currentConfig?.type === CONFIG_TYPES.COLOR_CODE && <ColorCodeConfigForm config_data={config_data} setConfigData={setConfigData} />}
                </Col>
            </Row>
            <DebugModal close={() => setDebugModal(false)} isOpen={debugModal} docProps={{ data: config_data }} enableClipboardImport={true} onChange={handleImportFromClipboard} title="JSON Editor" />
            <Modal size="lg" isOpen={saveModal} toggle={() => setSaveModal(false)} >
                <ModalHeader toggle={() => setSaveModal(false)} >Add note and save</ModalHeader>
                <ModalBody>
                    <MetaForm meta={[
                        {
                            label: "Note",
                            description: "Optional note. Describe what you have changed.",
                            type: "textarea",
                            name: "note",
                        }

                    ]} config={config} object={currentConfig} setValue={(field, value) => dispatch(updateTenantConfigState({ ...currentConfig, [field]: value })) } />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => save()} color="primary"><FontAwesomeIcon icon={faSave} /> Save</Button>
                    <Button color="light" onClick={() => setSaveModal(false)}>Cancel</Button>
                </ModalFooter>
            </Modal >

            <Modal size="xl" isOpen={revisionModal} toggle={() => setRevisionModal(false)} >
                <ModalHeader toggle={() => setRevisionModal(false)} >History</ModalHeader>
                <ModalBody>
                    <div css={css`max-height: 70vh;overflow-y:scroll;`}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Original author</th>
                                    <th>Type</th>
                                    <th>Value</th>
                                    <th>Created</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>

                                {revisions && revisions.map((i) => <tr key={i.id} >
                                    <td className="text-align-middle">
                                        <Link to={`/tenants/${tenant_id}/config/${i.id}`}>{i.id.split("-")[0]}</Link>
                                        {i.active && <Badge size="sm" className="ms-2" color="primary">Active</Badge>}
                                    </td>
                                    <td className="text-align-middle">
                                        <div>{i.original_author}</div>
                                        <em>{i.note}</em>
                                    </td>
                                    <td className="text-align-middle">{i.type}</td>
                                    <td className="text-align-middle">{i.value}</td>
                                    <td className="text-align-middle">{dayjs(i.created).format("HH:mm DD MMM YYYY")}</td>
                                    <td className="text-end text-align-middle">
                                        <Button color="primary" size="sm" outline={i.id !== config_id} onClick={() => selectRevison(i.id)}>View</Button>
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => setRevisionModal(false)}>Cancel</Button>
                </ModalFooter>
            </Modal >

            <Modal size="lg" isOpen={importModal} toggle={() => setImportModal(false)} >
                <ModalHeader toggle={() => setImportModal(false)} >Import / Delete all</ModalHeader>
                <ModalBody>
                    <p>This will overwrite the current state of your config form, but nothing will be stored in the database jet. Please adjust to your likings before saving.</p>
                    <FormGroup>
                        <Label for="exampleFile">JSON</Label>
                        <Input
                            id="exampleFile"
                            name="file"
                            type="file"
                            onChange={fileChangeHandler}
                        />

                        {(selectedFile && selectedFile !== true) ? (
                            <div className="py-3">
                                <FormText >
                                    <div>Filename: {selectedFile?.name }</div>
                                    <div>Filetype: {selectedFile?.type}</div>
                                    <div>Size in bytes: {selectedFile?.size}</div>
                                </FormText>
                            </div>
                        ) : (<FormText>Upload json file here.</FormText>)}
                    </FormGroup>
                    {importData && <div css={css`max-height: 70vh;overflow-y:scroll;`}>
                        <pre>
                            {JSON.stringify(importData, null, 2)}
                        </pre>
                    </div>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => setImportModal(false)}>Cancel</Button>
                    <Button color="light" className="me-1 mb-1" onClick={() => exportOptions()}>Export</Button>
                    <Button color="primary" disabled={isLoading || isUpating || !importData} className="me-1 mb-1" onClick={() => handleImport()}>Import</Button>
                </ModalFooter>
            </Modal >
        </Container>
    );
}
