/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useConfig from "../../../../../actions/Tenants/config/configHook";
import { isFilledArray } from "../../../../../utils";
import useAvosApi from "../../../../../utils/useAvosApiHook";
import ManualImages from "../ManualImages";

// Use for create purchase order reports
// Where we can have multiple check in one view
export default function FruitImages({ avocado_id }) {
    const config = useConfig();
    const check = useSelector((state) => state.checks.current);
    const layer = useSelector((state) => state.layers.current);
    const location = config.get_location(layer, check);
    const endpoint = `/images/fruit/${avocado_id}/images`;
    const [callFruitImages, images, isLoading] = useAvosApi(endpoint);

    useEffect(() => {
        callFruitImages();
    }, [endpoint]);

    const addFruitImage = (type, base64, focusMode) => callFruitImages({
        avocado_id, type, base64, focusMode,
    }, { endpoint: `/images/fruit/${avocado_id}/image/`, method: "POST" });

    const deleteFruitImage = (image) => callFruitImages(image, { endpoint: `/images/fruit/${avocado_id}/image/${image.image_id}`, method: "DELETE" });

    if (!isFilledArray(location?.required_fruit_images)) {
        return null;
    }

    return <div>
        <ManualImages
            images={images || []}
            required_images={location?.required_fruit_images}
            saveImage={addFruitImage}
            deleteImage={deleteFruitImage}
            isLoading={isLoading}
        />
    </div>;
}

FruitImages.propTypes = {
    avocado_id: PropTypes.number,
};
