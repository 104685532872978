import { defaultFilter, EmailRecipients, Entity, Filter } from "../../../actions/Admin/reducer";

export type EmailRecipientsTextList = { to: string[], cc: string[], bcc: string[] };

export const convertEmailRecipientTextListIntoObject = (value?: EmailRecipientsTextList): EmailRecipients => {
    const populate = (list: string[] = []) => list.map((email) => ({ address: email, displayName: email }));
    return {
        to: populate(value?.to),
        cc: populate(value?.cc),
        bcc: populate(value?.bcc),
    };
};

export const convertEmailRecipientsObjectIntoTextList = (value?: EmailRecipients): EmailRecipientsTextList => {
    const populate = (list: Array<{ address: string, displayName: string }> = []) => list.map((email) => email.address);
    return {
        to: populate(value?.to),
        cc: populate(value?.cc),
        bcc: populate(value?.bcc),
    };
};

export const filterResults = <T extends Entity>(results: T[] = [], filter:Filter) => {
    const offset = filter.offset || defaultFilter.offset as number;
    const limit = filter.limit || defaultFilter.limit as number;
    // check if offset and limit are within the bounds of the results
    if (offset < 0 || offset > results.length || limit <= 0) {
        // eslint-disable-next-line no-console
        console.error(`Invalid offset or limit: ${offset}, ${limit}`);
        return [];
    }
    return results.slice(filter.offset, offset + limit);
};
