/** @jsxImportSource @emotion/react */

import { headerLinks } from "../../../../actions/Tenants/config/constants";
import { UserRole } from "../../../../actions/Tenants/config/constantsTyped";
import FilterableConfigList from "./FilterableConfigList";


export const links = [
    { path: headerLinks.home, label: "Overview" },
    { path: headerLinks.settings, label: "Settings" },
    { path: headerLinks.logout, label: "Logout" },
    { path: headerLinks.insights, label: "Insights" },
    { path: headerLinks.labelling, label: "Labelling" },
    { path: headerLinks.packinglist, label: "Packing list" },
    { path: headerLinks.deviceManagement, label: "Device Management" },
    { path: headerLinks.tenants, label: "Tenants" },
    { path: headerLinks.ripening, label: "Ripening" },
    { path: headerLinks.waste, label: "Waste" },
    { path: headerLinks.mailbox, label: "Mailbox" },
    { path: headerLinks.ripeWise, label: "RipeWise" },
    { path: headerLinks.admin, label: "Admin", description: "Admin UI for clients" },
    { path: headerLinks.tabCategoryStock, label: "Stock", description: "Tab category Stock" },
    { path: headerLinks.tabCategoryQCPlanning, label: "QC", description: "Tab category QC Planning" },
    { path: headerLinks.tabCategoryQCReport, label: "Reports", description: "Tab category QC Reports" },
    { path: headerLinks.tabCategoryOperation, label: "Operation", description: "Tab category Operation" },
    { path: headerLinks.tabCategoryTransit, label: "Transit", description: "Tab category Transit" },
];

const defaultField = {
    field: {
        path: headerLinks.home,
        label: "Overview",
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
    // exclude_layer_types: [],
    }
};
export default function NavigationConfigForm({ name, title, fields, description, setArray }) {
    const getFieldTitle = (object) => <span>{object.label} [{object.path}]</span>;
    const getFieldForm = (_, setObject) => [
        {
            label: "Path",
            type: "single-select",
            name: "path",
            options: links.map((i) => ({ value: i.path, label: i.description || i.label })),
            onchange_callback: ({ value }) => {
                const urlOption = links.find((i) => i.path === value);
                if (urlOption) {
                    const { path, label } = urlOption;
                    setObject({ path, label });
                } else {
                    setObject({ path: null, label: "" });
                }
            }
        },
        {
            label: "Label",
            type: "text",
            name: "label",
        },
        {
            label: "Roles to display",
            description: "Roles that can see this link",
            type: "single-select",
            name: "roles",
            multi: true,
            return_array: true,
            options: [
                { value: UserRole.TENANT_ADMIN, label: "TenantAdmin" },
            ]
        }

    ];
    return <FilterableConfigList
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle}
        useDisplayFilter={false}
    />;

}


