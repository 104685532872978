import {
    baseConfig,
    ripening_stages_firmness,
} from "../constants";
import { RootConfigType } from "../constantsTyped";


const required_fruit_images = [
    {
        type: "fruit_overview",
        label: "Fruit",
    },
];

export const ColruytConfig: RootConfigType = {
    ...baseConfig,
    indicate_quality_at_defects: false,
    strict_check_size: false,
    show_atron_pressure_range_kg: true,
    indicate_soft_avocado_at_internal: false,
    suggest_color_stage: true,
    ripening_stages_firmness,
    required_fruit_images,

};
