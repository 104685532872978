/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { css } from "@emotion/react";
import { Button, FormGroup, FormText, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "../../utils/toast";
import { MimeType } from "../../constants/misc";

export interface FileImportModalProps<T> {
    open: boolean;
    onClose: () => void;
    onChange: (data: T) => void;
    visual: {
        title: string;
        description: string | JSX.Element;
    };
    accept: MimeType[];
    parseFile: (fileType:MimeType, fileContent: string) => {data?: T, message?: string | JSX.Element};
}

type SelectedFileType ={
    name: string,
    size: string,
    type: string,
}

export function FileImportModal<T>({ open, onClose, visual: { title, description }, accept, parseFile, onChange }: FileImportModalProps<T>) {
    const [dataToImport, setDataToImport] = useState<T>();
    const [selectedFile, setSelectedFile] = useState<SelectedFileType>();
    const [warningMessage, setWarningMessage] = useState<string | JSX.Element>();
    const [fileSelectorValue, setFileSelectorValue] = useState<string>("");

    const isValidFileType = (fileType: MimeType, accept: MimeType[]) => {
        return accept.includes(fileType);
    };

    const getFileFormatsLabel = () => {
        const humanReadableFileFormatList = accept.map((x) => {
            const parts = x.split("/");
            return parts[1] || parts[0];
        });
        const toUpperCase = (s: string) => s.toUpperCase();
        return humanReadableFileFormatList.map(toUpperCase).join(" or ");
    };

    const fileChangeHandler = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (isValidFileType(file.type as MimeType, accept)) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    try {
                        const { data, message } = parseFile(file.type as MimeType, event?.target?.result as string);
                        setDataToImport(data);
                        setSelectedFile(file);
                        setWarningMessage(message);
                    } catch (error:any) {
                        toast.error(error.message, {
                            autoClose: 3000,
                        });
                    }
                };
                setFileSelectorValue(e.target.value);
                reader.readAsText(file);
            } else {
                toast.error(`Invalid file type. Please upload a file of type ${getFileFormatsLabel()}`, {
                    autoClose: 3000,
                });
            }
        }
    };
    const clearFileSelection = () => {
        setSelectedFile(undefined);
        setDataToImport(undefined);
        setWarningMessage("");
        setFileSelectorValue("");
    };
    const onSelectedFileRemove = () => {
        clearFileSelection();
    };
    const onImportClick = () => {
        onChange(dataToImport!);
        onClose();
        clearFileSelection();
    };

    const _onClose = () => {
        clearFileSelection();
        onClose();
    };

    return <Modal size="lg" isOpen={open} toggle={_onClose} >
        <ModalHeader toggle={_onClose} >{title}</ModalHeader>
        <ModalBody>
            {description}
            <FormGroup>
                <Label for="exampleFile">{getFileFormatsLabel()}</Label>
                <InputGroup className="mt-3">
                    {/* disable all files except for the accepted file types */}
                    <Input
                        id="exampleFile"
                        name="file"
                        type="file"
                        accept={accept.join(",")}
                        onChange={fileChangeHandler}
                        value={fileSelectorValue}
                    />
                    {/* Button to remove the selected file */}
                    <Button color="danger" className="ms-2 my-1" outline size="sm" onClick={onSelectedFileRemove}>Remove</Button>
                </InputGroup>

                {(selectedFile) ? (
                    <div className="py-3">
                        <FormText >
                            <div>Filename: {selectedFile?.name }</div>
                            <div>Filetype: {selectedFile?.type}</div>
                            <div>Size in bytes: {selectedFile?.size}</div>
                        </FormText>
                    </div>
                ) : (<FormText>Upload the file here.</FormText>)}
            </FormGroup>
            {dataToImport && <div css={css`max-height: 70vh;overflow-y:scroll;`}>
                <pre>
                    {JSON.stringify(dataToImport, null, 2)}
                </pre>
            </div>
            }
            <div className="text-danger">{warningMessage}</div>
        </ModalBody>
        <ModalFooter>
            <Button color="light" onClick={_onClose}>Cancel</Button>
            <Button disabled={!dataToImport} color="primary" className="me-1 mb-1" onClick={() => onImportClick()}>Import</Button>
        </ModalFooter>
    </Modal >;

}
