import {
    CREATE_MASTER_DEFECT,
    DELETE_MASTER_DEFECT,
    LIST_ALL_MASTER_DEFECTS,
    LIST_MASTER_DEFECTS,
    UPDATE_MASTER_DEFECT,
} from "./actionsTypes";

const endpoint = "tenants/";

// * Tenant defects (master defects)
export function getTenantDefects(tenant_id, fruit_type) {
    return {
        types: [
            LIST_MASTER_DEFECTS.LOADING,
            LIST_MASTER_DEFECTS.SUCCESS,
            LIST_MASTER_DEFECTS.ERROR,
        ],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint}${tenant_id}/defects/${fruit_type}/`,
            }
        }
    };
}

export function getAllTenantDefects(tenant_id) {
    return {
        types: [
            LIST_ALL_MASTER_DEFECTS.LOADING,
            LIST_ALL_MASTER_DEFECTS.SUCCESS,
            LIST_ALL_MASTER_DEFECTS.ERROR,
        ],
        payload: {
            request: {
                method: "GET",
                tenant_id,
                url: `${endpoint}${tenant_id}/defects/all`,
            }
        }
    };
}

export function createTenantDefect(tenant_id, fruit_type, data) {
    return {
        types: [
            CREATE_MASTER_DEFECT.LOADING,
            CREATE_MASTER_DEFECT.SUCCESS,
            CREATE_MASTER_DEFECT.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/defects/${fruit_type}/`,
                data,
            }
        }
    };
}

export function deleteTenantDefect(tenant_id, fruit_type, id) {
    return {
        types: [
            DELETE_MASTER_DEFECT.LOADING,
            DELETE_MASTER_DEFECT.SUCCESS,
            DELETE_MASTER_DEFECT.ERROR,
        ],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint}${tenant_id}/defects/${fruit_type}/${id}`,
            }
        }
    };
}

export function deleteAllTenantDefect(tenant_id, fruit_type) {
    return {
        types: [
            DELETE_MASTER_DEFECT.LOADING,
            DELETE_MASTER_DEFECT.SUCCESS,
            DELETE_MASTER_DEFECT.ERROR,
        ],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint}${tenant_id}/defects/${fruit_type}/all`,
            }
        }
    };
}

export function updateTenantDefect(tenant_id, fruit_type, data) {
    return {
        types: [
            UPDATE_MASTER_DEFECT.LOADING,
            UPDATE_MASTER_DEFECT.SUCCESS,
            UPDATE_MASTER_DEFECT.ERROR,
        ],
        payload: {
            request: {
                method: "PUT",
                url: `${endpoint}${tenant_id}/defects/${fruit_type}/${data.id}`,
                data,
            }
        }
    };
}

export function importTenantDefect(tenant_id, fruit_type, data) {
    return {
        types: [
            UPDATE_MASTER_DEFECT.LOADING,
            UPDATE_MASTER_DEFECT.SUCCESS,
            UPDATE_MASTER_DEFECT.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/defects/${fruit_type}/import`,
                data,
            }
        }
    };
}

export function resetAllTenantDefect(tenant_id, data) {
    return {
        types: [
            UPDATE_MASTER_DEFECT.LOADING,
            LIST_ALL_MASTER_DEFECTS.SUCCESS,
            UPDATE_MASTER_DEFECT.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/defects/reset-all`,
                data,
            }
        }
    };
}
