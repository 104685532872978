/** @jsxImportSource @emotion/react */
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Button, Container
} from "reactstrap";
import { triggerCheckBusinessRules } from "../../../../actions/Checks/actions";
import useConfig from "../../../../actions/Tenants/config/configHook";
import LayerBreadcrumbs from "../../Layers/Layer/LayerBreadcrumbs";
import { LAYER_TAB_CATEGORIES } from "../../../../actions/Layers/constants";


export interface BoxProps {
    buttons?: any;
    hideButtons?: boolean;
    canSubmit?: boolean;
    canGoBack?: boolean;
    submit?: () => void;
    extra_buttons?: any[];
    children?: any;
    devicesModalButton?: any;
}

export default function Box({
    children, buttons, hideButtons = false, canSubmit = true, canGoBack = true, submit, extra_buttons = [], devicesModalButton
}: BoxProps) {
    const buttonsComp = <Container>
        {!hideButtons && buttons && <div>{buttons}</div>}
        {!hideButtons && !buttons && <BottomNavButtons canSubmit={canSubmit} canGoBack={canGoBack} submit={submit} extra_buttons={extra_buttons} />}
    </Container>;
    return (
        <div>
            <div>
                <div className="px-3 py-4">
                    <Container>
                        <Header devicesModalButton={devicesModalButton} />
                    </Container>
                </div>
                {buttonsComp}
            </div>
            <Container >
                <div className="bg-body rounded shadow">
                    <div className="p-lg-5 p-2" >
                        {children}
                    </div>
                </div>
            </Container>
            {buttonsComp}
        </div>
    );
}

export interface BottomNavButtonsProps {
    canSubmit?: boolean;
    canGoBack?: boolean;
    submit?: () => void;
    extra_buttons?: any[];
}

export function BottomNavButtons({ submit, canSubmit = true, canGoBack = true, extra_buttons = [] }: BottomNavButtonsProps) {
    const check = useSelector<any, any>((state) => state.checks.current);
    const layer = useSelector<any, any>((state) => state.layers.current);
    const navigate = useNavigate();
    const isUpdating = useSelector<any, any>((state) => state.avocados.isUpdating);
    const config = useConfig();
    if (!check.location || !layer.type) {
        return null;
    }
    const _submit = () => {
        if (submit) {
            submit();
        } else {
            navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${next.path}`);
        }
    };
    const next = config.next(layer, check);
    const prev = config.prev(layer, check);

    return (
        <div >
            <div className="py-4 d-md-flex justify-content-end align-items-center">
                {prev
                    && <div className="pb-2 pb-sm-0 me-2 d-inline-flex d-md-block">
                        <Button color="secondary" outline={true} disabled={isUpdating || !canGoBack} onClick={() => navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${prev.path}`)}>Back</Button>
                    </div>
                }
                {extra_buttons.map((i, index) => <div key={index}>{i}</div>)}
                {(next || submit)
                    && <div className="pb-2 pb-sm-0 me-2 d-inline-flex d-md-block">
                        <Button color="primary" disabled={isUpdating || !canSubmit} onClick={() => _submit()}>{next ? "Next" : "Finish"}</Button>
                    </div>
                }

            </div>
        </div>
    );
}

export const Header = (props) => {
    const check = useSelector<any, any>((state) => state.checks.current);
    const layer = useSelector<any, any>((state) => state.layers.current);
    const browse_history = useSelector<any, any>((state) => state.navigation.browse_history);
    const { devicesModalButton } = props;
    const config = useConfig();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location_config = config.get_location(layer, check);
    const human_check = check.location ? location_config.text : false;
    const layer_config = config.get_layer_config(layer);

    const onClose = () => {
        const lastBrowse = browse_history[browse_history.length - 1];
        const previousPath = lastBrowse?.pathname || `/layer/tab/${LAYER_TAB_CATEGORIES.OPERATION}/first`;
        // trigger the business rules
        dispatch(triggerCheckBusinessRules(check.test_id) as any).then(() => {
            navigate(previousPath);
        });
    };

    return (
        <div>
            <div className="pt-3 d-flex justify-content-end align-items-center">
                <div className="pb-2 pb-sm-0 me-2">
                    {check.group_label && <h4 className="mb-0" onClick={() => navigate(`/ripening-cell/${check.location_id}/from/${dayjs(check.created).subtract(5, "day").format("DD-MM-YYYY")}/to/${(dayjs(check.created).format("DD-MM-YYYY"))}`)}>
                        <small className="text-muted">{check.group_label}</small>
                    </h4>}
                    {!check?.group_label && <h4 className="mb-0" onClick={() => navigate(`/layer/${check.layer_id}`)}>
                        <small className="text-muted">{`${layer_config?.text} ${layer.label}${human_check ? ` ${human_check}` : ""}`}</small>
                    </h4>}
                    <h3>
                        {props.name || config.title()}
                    </h3>
                </div>
                <div className="pb-2 pb-sm-0 ms-auto">
                    {devicesModalButton}
                    <Button className="btn-close my-1" size="lg" onClick={() => onClose()} ></Button>
                </div>
            </div>
            <div>
                <LayerBreadcrumbs location_config={location_config} check={check} />
            </div>
        </div>
    );
};

Header.defaultProps = {
    name: "",
    devicesModalButton: undefined,
};
Header.propTypes = {
    name: PropTypes.string,
    devicesModalButton: PropTypes.object,
};


export const AdvanceRipeningLayout = (props) => {
    const { children, ripeningDays, buttons, hideButtons } = props;
    const buttonsComp = <Container>
        {!hideButtons && buttons && <div>{buttons}</div>}
    </Container>;
    return <div>
        <div>
            <div className="px-3 py-4">
                <Container>
                    <Header />
                </Container>
            </div>
            {ripeningDays}
            {buttonsComp}
        </div>
        <Container >
            <div className="bg-body rounded shadow">
                <div className="p-lg-5 p-2" >
                    {children}
                </div>
            </div>
        </Container>
        {buttonsComp}
    </div>;

};


AdvanceRipeningLayout.propTypes = {
    ripeningDays: PropTypes.object,
    children: PropTypes.object,
    buttons: PropTypes.object,
    hideButtons: PropTypes.bool,
};
