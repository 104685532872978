import dayjs from "dayjs";
import { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
    Button, Col, Container, FormGroup, Input, Label, Row
} from "reactstrap";
import { createRelabellingSession, getDefectsStats } from "../../../actions/Labelling/actions";
import { listTenants } from "../../../actions/Tenants/actions";
import avocadoPng from "../../../img/avocado.png";
import { Loading } from "../../Helper/Loading";

function reducer(state, { field, value }) {
    return {
        ...state,
        [field]: value,
    };
}

export default function NewLabelling() {
    const date = new Date();
    const today = date.toISOString().substr(0, 10);
    date.setDate(date.getDate() - 7);
    const last_week = date.toISOString().substr(0, 10);

    const initialState = {
        tenants_list: [],
        tenant_id: "",
        defect_type: "",
        start_date: last_week,
        end_date: today,
    };

    const user = useSelector((state) => state.auth.user);
    const tenants = useSelector((state) => state.tenants);
    const { defects_stats, isLoading, created } = useSelector((state) => state.labeling);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [state, dispatchLocal] = useReducer(reducer, initialState);
    const {
        tenants_list, tenant_id, defect_type, start_date, end_date,
    } = state;

    useEffect(() => {
        dispatch(listTenants());
    }, [dispatch]);

    useEffect(() => {
        dispatchLocal({ field: "tenant_id", value: user.tenant.tenant_id });
    }, [user]);

    useEffect(() => {
        if (created) navigate(`/labelling/${created.relabel_session_id}`);
    }, [created]);

    useEffect(() => {
        if (tenant_id == null
            || !dayjs(start_date, "YYYY-MM-DD", true).isValid()
            || !dayjs(end_date, "YYYY-MM-DD", true).isValid()
        ) {
            return;
        }

        dispatch(getDefectsStats({
            tenant_id,
            from_date: start_date,
            to_date: end_date,
        }));
    }, [tenant_id, start_date, end_date]);

    useEffect(() => {
        if (tenants.items && tenants.items.length > 0) {
            dispatchLocal(
                {
                    field: "tenants_list",
                    value: tenants.items.map((i) => ({ label: i.tenant_name, value: i.tenant_id })),
                },
            );
        }
    }, [tenants]);

    const onChange = (e) => {
        console.log(e); // eslint-disable-line no-console
        dispatchLocal({ field: e.target.name, value: e.target.value });
    };

    const setTenant = (value) => {
        dispatchLocal({ field: "tenant_id", value });
    };

    const setDefectType = (value) => {
        dispatchLocal({ field: "defect_type", value });
    };

    const submitRelabelingSession = () => {
        dispatch(createRelabellingSession({
            from_date: start_date, to_date: end_date, tenant: tenant_id, defect_type: defect_type.value,
        }));
    };

    const getListOfDefects = () => {
        if (defects_stats.length > 0) {
            return defects_stats.map((i) => ({ label: `${i.slug} [Todo: ${i.todo}/ Total: ${i.total}]`, value: i.slug }));
        }

        return [];
    };

    return (
        <Container className="py-5">
            <Row className="justify-content-center py-3">
                <Col lg="9" >
                    <div>
                        <h2 className="">Relabelling Fruits</h2>
                        <Row className="justify-content-center pb-3">
                            <Col>
                                <span className="text-muted">You can specify the photos that you want to check and start the process</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="4" >
                                {user.tenant.tenant_id === "a6aff297-ac1b-4e81-88a6-420591d31f31"
                                    && <FormGroup>
                                        <Label for="tenant_id">Tenant</Label>
                                        {tenants_list.length > 0
                                            && <Select name="tenant_id" id="tenant_id" defaultValue={tenants_list.find((element) => element.value === tenant_id)} options={tenants_list} is_multi={false} onChange={(selected_option) => setTenant(selected_option.value)}></Select>}
                                        {isLoading && <Loading></Loading>}
                                    </FormGroup>
                                }
                                <FormGroup>
                                    <Label for="start_date">Start Date</Label>
                                    <Input type="date" name="start_date" id="start_date" defaultValue={last_week} onChange={onChange} />
                                    {!dayjs(start_date, "YYYY-MM-DD", true).isValid() && <span className="text-danger">Please enter a valid date!</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="tenant_id">End Date</Label>
                                    <Input type="date" name="end_date" id="end_date" defaultValue={today} onChange={onChange} />
                                    {!dayjs(end_date, "YYYY-MM-DD", true).isValid() && <span className="text-danger">Please enter a valid date!</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="defect_type">Defect Type</Label>
                                    <Select name="defect_type" id="defect_type" isLoading={isLoading} options={getListOfDefects()} onChange={(selected_option) => setDefectType(selected_option)}></Select>

                                </FormGroup>
                                <FormGroup>
                                    <Button onClick={submitRelabelingSession}>Submit</Button>
                                </FormGroup>
                            </Col>
                            <Col xs="5">
                                <img src={avocadoPng} alt="Relabeling" className="float-right" />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>);
}
