/** @jsxImportSource @emotion/react */

import { TriggerMetaForm } from "./ColorCodeTriggerForm";
import FilterableConfigList from "./FilterableConfigList";


const defaultField = {
    field: {
        gte: false,
        lt: 16,
        label: "Overripe",
        color: "#FFA500"
    }
};
export default function CategoryConfigForm({ name, title, fields, description, setArray, useDisplayFilter = false }) {
    const getFieldTitle = (object) => <span>{object.label}</span>;
    const getFieldForm = (object, setObject) => [
        {
            label: "Greater then or equal to",
            name: "gte",
            type: "number",
        },
        {
            label: "Less then",
            name: "lt",
            type: "number",
        },
        {
            label: "Greater then",
            name: "gt",
            type: "number",
        },
        {
            label: "Less then or equal to",
            name: "lte",
            type: "number",
        },
        {
            label: "Label",
            name: "label",
            type: "text",
        },
        {
            label: "Color",
            name: "color",
            type: "text",
            description: "Color for the category in hex format. For example #FFA500. Used in some components."
        },
        {
            type: "header",
            label: "Triggers conditions",
        },
        {
            type: "element",
            name: "trigger_meta",
            el: <TriggerMetaForm name="tigger_meta" title="Trigger meta" description="Define meta data to trigger on" fields={object?.trigger_meta || []} setArray={(trigger_meta) => setObject({ ...object, trigger_meta })} />
        }
    ];
    return <FilterableConfigList
        useDisplayFilter={useDisplayFilter as false | string[]}
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle} />;

}
