/** @jsxImportSource @emotion/react */
import { useParams } from "react-router-dom";


import { ConfigProvider } from "../../../actions/Tenants/config/configHook";

export default function TenantEditFormWrappedInProved({ children }: {children: JSX.Element}) {
    const params = useParams();
    const { tenant_id } = params;
    return <ConfigProvider tenant_id={tenant_id} refreshInterval={false}>
        {children}
    </ConfigProvider>;
}
