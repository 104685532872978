
/** @jsxImportSource @emotion/react */
import useConfig from "../../../../actions/Tenants/config/configHook";
import MetaForm from "../../../Forms/MetaForm";

export const default_display_config = {
    fruit_types: [],
    // exclude_fruit_types: [],
    layer_types: [],
    only_show_for_check_type: undefined

};


export function DisplayFilterForm({ object, setValue, attributes = ["fruit_types", "exclude_fruit_types", "layer_types", "manual_or_with_mini"] }) {
    const config = useConfig();
    const fruitOptions = config.fruit_types.map((i) => ({ label: i.text, value: i.value }));
    const layerTypeOptions = config.layer_types.map((i) => ({ label: i.text, value: i.value }));
    const form = [
        {
            type: "header",
            label: "Display Filter",
        },
        {
            label: "Show for Fruit Types",
            name: "fruit_types",
            description: "Only show this item for specific fruit types. Leave empty to ignore.",
            type: "single-select",
            return_array: true,
            options: fruitOptions,
            multi: true,
        },
        {
            label: "Hide for Fruit Types",
            name: "exclude_fruit_types",
            description: "Do not show this item for specific fruit types. Leave empty to ignore.",
            type: "single-select",
            return_array: true,
            options: fruitOptions,
            multi: true,
        },
        {
            label: "Show for Layer Types",
            name: "layer_types",
            description: "Show this item for specific layer types. Leave empty to ignore.",
            type: "single-select",
            options: layerTypeOptions,
            return_array: true,
            multi: true,
        },
        {
            label: "Only show for specific check",
            name: "manual_or_with_mini",
            description: "Only show for specific checks. Leave empty to ignore.",
            options: [
                {
                    value: "only_on_mini_check",
                    label: "Only on mini checks"
                },
                {
                    value: "only_on_manual_check",
                    label: "Only on manual checks"
                },
            ],
            type: "single-select",
        }
    ];

    const filteredForm = form.filter((i) => i.type === "header" || attributes.includes(i.name as any));

    return <div>
        <MetaForm
            meta={filteredForm}
            setValue={setValue}
            object={object}
            config={config}
        />
    </div>;
}
