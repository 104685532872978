/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import { showIndividual } from "./utils";

const mailRecipientsStyle = css`
    &:hover .email-list {
        display: block;
    }
`;

const emailListStyle = css`
    display: none;
    position: absolute;
    background-color: black;
    padding: 10px;
    z-index: 1000;
    color: white;
    border-radius: 8px;
`;

export const MailRecipientGroup = ({ group, title }) => (
    <div>
        <strong>{title}:</strong>
        {group.map((recipient, i) => showIndividual(recipient) && (
            <span key={i}>
                {recipient?.displayName} &lt;{recipient?.address}&gt;
            </span>
        ))}
    </div>
);

export const MailRecipients = ({ recipients = {} as any }) => {
    const { to = [], cc = [], bcc = [] } = recipients;
    const emails = [...to, ...cc, ...bcc];
    const [hovered, setHovered] = useState(false);
    const emailToString = (email) => email?.address || "";

    return (
        <div css={mailRecipientsStyle}>
            <div>
                {emailToString(emails[0])}
                {emails.length > 1 && <span
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >, ...</span>}
            </div>
            {hovered && (
                <div css={emailListStyle} className="email-list">
                    {[
                        to.length > 0 && <div key={"to"}>to: {to.map((email) => emailToString(email)).join(", ")}</div>,
                        cc.length > 0 && <div key={"cc"}>cc: {cc.map((email) => emailToString(email)).join(", ")}</div>,
                        bcc.length > 0 && <div key={"bcc"}>bcc: {bcc.map((email) => emailToString(email)).join(", ")}</div>,
                    ]}
                </div>
            )}
        </div>
    );
};
