import { FormCheckboxManagedField, FormFruitImagesField, FormImageRadioButtonsField, FormNumberField, FormRadioField } from "./constantsTyped";
// TODO: Fix this import typescript error
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import fruitColorsImage from "../../../img/mango-internal-color.png";
import { DEFECT_POSITION, DEFECT_SEVERITY } from "../../Checks/constants";

export const pressure: FormNumberField = {
    label: "Pressure",
    name: "pressure",
    type: "number",
    mandatory: false,
    min: 0,
    max: 100,
    unity: "LBS",
    lg: 3
};


export const weight: FormNumberField = {
    label: "Weight",
    name: "weight",
    type: "number",
    mandatory: false,
    min: 0,
    max: 9999,
    unity: "GR",
    lg: 3
};

export const taste: FormRadioField = {
    label: "Taste",
    name: "manual_taste",
    type: "radio",
    options: [
        { text: "Balanced", value: "balanced" },
        { text: "Sweet", value: "sweet" },
    ],
};


export const internal_color: FormRadioField = {
    label: "Internal Color",
    name: "manual_internal_color_stage",
    type: "radio",
    options: [
        { text: "1", value: 1, color: "#f8d37a", className: "me-3 text-white" },
        { text: "2", value: 2, color: "#f0d35b", className: "me-3 text-white" },
        { text: "3", value: 3, color: "#e7bf43", className: "me-3 text-white" },
        { text: "4", value: 4, color: "#f6b041", className: "me-3 text-white" },
        { text: "5", value: 5, color: "#f19940", className: "me-3 text-white" },
    ],
    mandatory: true
};

const mango_internal_defects_image_positions = {
    kent: {
        1: [-121, -3],
        2: [-250, -3],
        3: [-385, -3],
        4: [-515, -3],
        5: [-639, -3],
    },
    keitt: {
        1: [-121, -155],
        2: [-250, -155],
        3: [-385, -155],
        4: [-515, -155],
        5: [-639, -155],
    }
};
// this type is poorly supported due to wizard
const create_mango_internal_image_check_field_option = (optionValue: number) => ({
    text: `${optionValue}`,
    value: optionValue,
    className: "me-3 text-white"
});

// !warning: This is actually the only field that needs to be defined at runtime (not configuring time), because there are some nested functions and complex objects you can not define using the wizard
export const mango_internal_image_check_field: FormImageRadioButtonsField = {
    label: "Internal Color",
    name: "manual_internal_color_stage",
    type: "image-radiobutton",
    image: {
        path: fruitColorsImage,
        nativeSize: [764, 300],
        optionDisplaySize: [50, 56],
        scale: 0.4
    },
    // TODO: implement something that requires less math and no functions
    get_image_position: (value, { fruit_variety }) => {
        if (!Object.keys(mango_internal_defects_image_positions).includes(fruit_variety)) {
            fruit_variety = "kent";
            // eslint-disable-next-line no-console
            console.error(`Fruit variety ${fruit_variety} is not defined in mango_internal_defects_image_positions, using kent as default`);
        }
        return mango_internal_defects_image_positions[fruit_variety][value];
    },
    options: [1, 2, 3, 4, 5].map(create_mango_internal_image_check_field_option),
    mandatory: true
};


export const defects: FormCheckboxManagedField = {
    label: "Defects",
    name: "defects",
    type: "checkbox-managed",
    defect_position: [DEFECT_POSITION.INTERNAL, DEFECT_POSITION.EXTERNAL],
    defect_severity: [DEFECT_SEVERITY.MINOR, DEFECT_SEVERITY.MAJOR, DEFECT_SEVERITY.UNACCEPTABLE]

};
const internal_defects: FormCheckboxManagedField = {
    ...defects,
    label: "Internal Defects",
    defect_position: [DEFECT_POSITION.INTERNAL],
    defect_severity: [DEFECT_SEVERITY.MINOR, DEFECT_SEVERITY.MAJOR, DEFECT_SEVERITY.UNACCEPTABLE]

};

export const external_defects: FormCheckboxManagedField = {
    ...defects,
    label: "External Defects",
    defect_position: [DEFECT_POSITION.EXTERNAL],
    defect_severity: [DEFECT_SEVERITY.MINOR, DEFECT_SEVERITY.MAJOR, DEFECT_SEVERITY.UNACCEPTABLE]

};

// // at some point we can remove
// export const mango_internal_quality_check_field: FormCheckboxManagedField = { ...internal_defects, deprecated: true };

// export const avocado_internal_quality_check_field: FormCheckboxManagedField = { ...internal_defects, deprecated: true };

// export const avocado_external_quality_check_field: FormCheckboxManagedField = { ...external_defects, deprecated: true };

// export const mango_external_quality_check_field: FormCheckboxManagedField = { ...external_defects, deprecated: true };

export const maturity: FormRadioField = {
    label: "Maturity",
    name: "manual_maturity",
    type: "radio",
    options: [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "2.5", value: "2.5" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
    ],
    mandatory: true
};

export const brix: FormNumberField = {
    label: "Brix",
    name: "manual_brix",
    type: "number",
    min: 0,
    max: 100,
    unity: "%",
    lg: 3
};

// export const avocado_taste: FormRadioManagedField = {
//     label: "Taste score",
//     name: "manual_taste",
//     type: "radio-managed",
//     options_name: "manual_taste_avocado",
//     mandatory: true,
//     deprecated: true
// };

// export const avocado_mouth_ripeness_field: FormRadioManagedField = {
//     label: "Mouth ripeness",
//     name: "mouth_ripeness",
//     type: "radio-managed",
//     options_name: "mouth_ripeness_avocado",
//     mandatory: true,
//     deprecated: true
// };

// export const avocado_flavour_nuttiness_field: FormRadioManagedField = {
//     label: "Flavour nuttiness",
//     name: "flavour_nuttiness",
//     type: "radio-managed",
//     options_name: "flavour_nuttiness_avocado",
//     mandatory: true,
//     deprecated: true
// };

// export const avocado_flavour_creamy_field: FormRadioManagedField = {
//     label: "Flavour creamy",
//     name: "flavour_creamy",
//     type: "radio-managed",
//     options_name: "flavour_creamy_avocado",
//     mandatory: true,
//     deprecated: true
// };

export const temperature: FormNumberField = {
    label: "Temperature",
    name: "manual_temperature",
    type: "number",
    mandatory: false,
    min: -100,
    max: 100,
    unity: "°C",
    lg: 3
};

export const fruit_images: FormFruitImagesField = {
    label: "Fruit Images",
    name: "fruit_images",
    type: "fruit-images",
};

export const fruit_diameter: FormNumberField = {
    label: "Diameter",
    name: "fruit_diameter",
    type: "number",
    mandatory: false,
    min: 0,
    unity: "mm",
    lg: 3
};

const fruitFormField = {
    defects,
    internal_defects,
    external_defects,
    // avocado_internal_quality_check_field,
    // avocado_external_quality_check_field,
    // avocado_mouth_ripeness_field,
    // avocado_taste,
    // avocado_flavour_nuttiness_field,
    // avocado_flavour_creamy_field,
    // mango_internal_quality_check_field,
    // mango_external_quality_check_field,
    mango_internal_image_check_field,
    maturity,
    pressure,
    taste,
    internal_color,
    brix,
    weight,
    temperature,
    fruit_images,
    fruit_diameter
};

Object.keys(fruitFormField).forEach((key) => {
    fruitFormField[key].form_field_id = key;
});
export { fruitFormField };

