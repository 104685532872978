/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Badge, Button, Table } from "reactstrap";

import { Parser } from "@json2csv/plainjs";
import { flatten } from "@json2csv/transforms";
import { useEffect, useState } from "react";
import { formatCheck2Frontend } from "../../../../actions/Checks/util";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { countCategory } from "../../../../actions/Tenants/config/utils";
import mq from "../../../../constants/mediaqueries";
import { callAvosApi } from "../../../../utils/useAvosApiHook";
import CategoryDistribution from "./CategoryDistribution";


export function RipeningStageFirmnessHistoryTable({ subconfig }) {
    const checks = useSelector((state) => state.layers.checks);
    const layer = useSelector((state) => state.layers.current);
    const config = useConfig();
    const { filter_field, filter_location } = subconfig;

    const filtered_checks = checks
        .filter((i) => (filter_field && layer.id !== i.layer_id ? i[filter_field] === layer[filter_field] : true))
        .filter((i) => (filter_location && layer.id !== i.layer_id ? i.location === filter_location : true))
        .filter((i) => (i.fruits_checked > 0));

    if (!layer || !checks) {
        return null;
    }

    const title = "Progress External Firmness";
    const doctitle = "export-firmness-ripening-class";

    return <HistoryTable
        title={title}
        doctitle={doctitle}
        checks={filtered_checks}
        field={"atron_pressures_median"}
        categories={config.ripening_stages_firmness}
        show_pie={false} />;

}


RipeningStageFirmnessHistoryTable.propTypes = {
    subconfig: PropTypes.shape({
        view: PropTypes.string,
        filter_field: PropTypes.string,
        filter_location: PropTypes.string,

    }),
};

export function RipeningStagePressureHistoryTable() {
    const checks = useSelector((state) => state.layers.checks);
    const layer = useSelector((state) => state.layers.current);
    const config = useConfig();


    const filtered_checks = checks.map((i) => ({ ...i, config: config.get_location(layer, i) })).filter((i) => i.config.show_pressure_prediction).filter((i) => (i.fruits_checked > 0));

    if (!layer || !checks) {
        return null;
    }

    const title = "Progress Internal Pressure";
    const doctitle = "export-pressure-ripening-class";

    return <HistoryTable
        title={title}
        doctitle={doctitle}
        checks={filtered_checks}
        field={"atron_pressure_kg"}
        categories={config.ripening_stages}
        show_pie={true} />;

}


function HistoryTable({ title, doctitle, checks, field, categories, show_pie }) {
    const layer = useSelector((state) => state.layers.current);
    const [ordered, setOrdered] = useState([]);
    useEffect(() => {
        const fetch_intake_checks = checks.map((check) => callAvosApi(`/tests/${check.test_id}/`));
        Promise.all(fetch_intake_checks).then((full_checks) => {
            const new_ordered = full_checks
                .map((i) => formatCheck2Frontend(i.data))
                .map((i) => ({
                    ...i,
                    counted_categories: categories.map((cat) => countCategory(field, cat, i.avocados)[0])
                })).sort((a, b) => a.test_id - b.test_id);
            setOrdered(new_ordered);

        });
    }, [checks.length, layer.id]);

    const _downloadCSV = () => {
        const data = ordered.map((i) => {
            const row = {
                location: i.location,
                date: dayjs(i.created).format("HH:m DD/MM/YYYY"),
                count: i.avocados.length,
            };
            categories.forEach((x, index) => {
                row[x.label] = i.counted_categories[index];
            });
            return row;
        });
        downloadCSV(doctitle, data);
    };

    const latest_check = ordered[ordered.length - 1];

    return <div className="w-100 pb-5">
        <div css={css`border-radius:8px;`} className="bg-body shadow p-5 " >
            <div className="d-flex">
                <h3 className=" mb-0">{title}</h3>
                <div className="ms-auto">
                    <Button color="secondary" outline size="xs" onClick={() => _downloadCSV()} >
                        <FontAwesomeIcon icon={faDownload} />
                    </Button>
                </div>

            </div>
            <div className="pt-3" >
                <div className="d-flex flex-wrap">
                    <div css={mq({ width: ["100%", "100%", "100%", "65%"] })} >
                        <Table size="sm" responsive css={css`width: auto;`}>
                            <thead>
                                <tr>
                                    <th className="pe-5">Date</th>
                                    <th className="pe-5">Location</th>
                                    {categories.map((i, index) => <th key={index} className="pe-5">{i.label}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ordered.map((c) => <tr
                                        key={c.test_id}
                                        title={`Check ${c.test_id}`}
                                    >
                                        <td className="p-0">{dayjs(c.created).format("DD MMM")}</td>
                                        <td className="text-muted font-size-sm p-0 "><Badge color="light">{c.location}</Badge></td>
                                        {categories.map((i, index) => <td key={index}>
                                            {parseFloat(100 * (c.counted_categories[index] / c.avocados.length)).toFixed(1)}%
                                        </td>)}
                                    </tr>)
                                }
                            </tbody>
                        </Table>
                    </div>
                    {latest_check && show_pie && <div className="text-center pt-3 pt-lg-0" css={mq({ width: ["100%", "100%", "100%", "35%"] })}>
                        <CategoryDistribution catField={field} categories={categories} avocados={latest_check.avocados} style={({ width: "300px", height: "140px" })} />
                    </div>
                    }
                </div>

            </div>
        </div>
    </div >;
}

HistoryTable.propTypes = {
    title: PropTypes.string,
    checks: PropTypes.array,
    field: PropTypes.string,
    categories: PropTypes.array,
    show_pie: PropTypes.bool,
    doctitle: PropTypes.string
};

const downloadCSV = (doctitle, data) => {
    try {
        const opts = {
            delimiter: ";",
            transforms: [
                flatten(),
            ]

        };
        const parser = new Parser(opts);
        const csv = parser.parse(data);

        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        const datetime = dayjs().format("Hm-DDMMYYYY");
        link.download = `${doctitle}-${datetime}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (err) {
        console.error(err); // eslint-disable-line no-console
    }
};
