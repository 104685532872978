import PropTypes from "prop-types";

import CreateChildAndStartCheck from "./CreateChildAndStartCheck";
// import LinkParentAndStartCheck from "./LinkParentAndStartCheck";

export const SPECIAL_ACTIONS = {
    CREATE_CHILD_AND_START_CHECK: "create-child-and-start-check",
    // LINK_PARENT_AND_START_CHECK: "link-parent-and-start-check",

};

export const SpecialActionComponents = {
    [SPECIAL_ACTIONS.CREATE_CHILD_AND_START_CHECK]: (props) => <CreateChildAndStartCheck {...props} />,
    // [SPECIAL_ACTIONS.LINK_PARENT_AND_START_CHECK]: (props) => <LinkParentAndStartCheck {...props} />
};
export default function SpecialActions({ special_actions }) {
    return special_actions.map((i, index) => <div key={index} className="me-2 d-inline-flex d-lg-block">
        {SpecialActionComponents[i.type](i)}
    </div>);

}

SpecialActions.propTypes = {
    special_actions: PropTypes.array,
};
