import React from "react";

interface PdfReaderProps {
    pdfUrl: string;
}

const PdfReader: React.FC<PdfReaderProps> = ({ pdfUrl }) => {

    return <div>
        <strong>DRAFT</strong>
        <iframe
            src={pdfUrl}
            width="100%"
            height="1200px"
            style={{ border: "none", minHeight: "90vh" }}>
        </iframe>
    </div>;

};

export default PdfReader;
