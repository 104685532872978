/** @jsxImportSource @emotion/react */
import React from "react";
import { useSelector } from "react-redux";
import { Badge, Col, FormGroup, FormText, Row } from "reactstrap";
import EmailRecipientsForm from "./Forms/EmailRecipientsForm";


interface ListTenantEmailRecipientsProps {
    // type: string;
    title: string;
}

function ListTenantEmailRecipients({ title }: ListTenantEmailRecipientsProps) {
    const current = useSelector<any, any>((state) => state.tenants.current);
    const {
        tenant_id,
    } = current;

    return (
        <div id={title}>
            <Row>
                <Col md="12">
                    <FormGroup className="pb-2">
                        <div className="p-2">
                            <EmailRecipientsForm tenant_id={tenant_id}/>
                        </div>
                        <FormText color="muted">Email recipients for the send email button. e.g. <Badge color="success">Testable: Yes</Badge></FormText>
                    </FormGroup>
                </Col>

            </Row>
        </div>
    );
}

export default ListTenantEmailRecipients;
