/** @jsxImportSource @emotion/react */
import { attributeIsSet } from "../../Tenants/Forms/FlagConfigForm";
import Field, { DataSource } from "./Field";

class LayerLatestLocationField extends Field {
    field_type = "layer_latest_location_field";

    backend_table = "latest_location";

    is_filterable(): boolean {
        return true;
    }

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        let value = "-";

        if (datasource.layer && attributeIsSet(datasource.layer.latest_location?.[this.fieldname_getter])) {
            value = datasource.layer.latest_location?.[this.fieldname_getter];
        }
        this.value = this.value_filter(value, datasource);
        return this;

    }

}

export default LayerLatestLocationField;
