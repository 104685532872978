

import {
    baseConfig,
    ripening_colors,
    ripening_stages_firmness,
} from "../constants";
import { RootConfigType } from "../constantsTyped";

const ripening_stages = [
    {
        gt: 12,
        lte: 30,
        label: "Hard",
        color: ripening_colors.Hard_Mission,
    },

    {
        gt: 7,
        lte: 12,
        label: "Nearly ripe",
        color: ripening_colors["Nearly ripe"],
    },

    {
        gt: 1,
        lte: 7,
        label: "Ripe",
        color: ripening_colors.Ripe,
    },
    {
        gte: false,
        lte: 1,
        label: "Soft",
        color: ripening_colors.Soft,
    },
];

export const DeLaatConfig: RootConfigType = {
    ...baseConfig,
    use_barcode_scanner: false,
    ripening_stages,
    ripening_stages_firmness,
    indicate_quality_at_defects: false,
    lang: {
        pressure: "Pressure"
    },
};
