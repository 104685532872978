// Extract filename from url
const getFilename = (str) => {
    const regex = /\/([^/?]+)\?/;
    const match = str.match(regex);
    if (match && match.length >= 2) {
        return match[1];
    }
    return null;
};

export const AttachmentLink = ({ url, label = getFilename(url), id = label }) => <a id={id} href={url} target="_blank" rel="noreferrer">{label}</a>;

export default AttachmentLink;
