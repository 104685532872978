/** @jsxImportSource @emotion/react */
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import useConfig from "../../../actions/Tenants/config/configHook";


interface ListTenantFormOptionsProps {
    // type: string;
    title: string;
}

const addManagedItems = (current_list, items_to_add) => (items_to_add && items_to_add.length > 0 ? [...current_list, ...items_to_add.filter((meta) => meta?.type === "select-managed" || meta?.type === "radio-managed")] : current_list);

function collectAllManagedForms(config) {
    const overview_filter_form = config?.overview_filter_form || [];
    const layerTypeForms = (config?.layer_types || []).flatMap((i) => (i?.meta_form || []).map((i) => i.field));
    const CheckLocationForms = (config?.check_locations || []).flatMap(({
        pre_check_form,
        meta_form_layer,
        meta_form_summary,
        visual_check_form,
        meta_form_advance_ripening
    }) => [pre_check_form, meta_form_layer, meta_form_summary, visual_check_form, meta_form_advance_ripening].map((i) => i || []))
        .flatMap((i) => i).map((i) => i.field);

    return [...layerTypeForms, ...CheckLocationForms, ...overview_filter_form];
}

function removeDuplicates(arrOfObjects) {
    const uniqueLabels: string[] = [];
    return arrOfObjects.filter((obj) => {
        const key = obj.label + (obj.options_name || obj.name);
        const isDuplicate = uniqueLabels.includes(key);
        if (!isDuplicate) {
            uniqueLabels.push(key);
            return true;
        }
        return false;
    });
}

function ListTenantFormOptions({ title }: ListTenantFormOptionsProps) {
    const current = useSelector<any, any>((state) => state.tenants.current);
    const config = useConfig();
    const navigate = useNavigate();
    const {
        tenant_id,
    } = current;

    // Add some default managed forms
    let meta_list = [
        {
            label: "Template",
            name: "packing_list_template",
            type: "select-managed",
        },
        {
            label: "Teams",
            name: "teams",
            type: "select-managed",
        },
        {
            label: "Fruit types",
            name: "fruit_type",
            type: "select-managed",
        },
        {
            label: "Stages",
            name: "stage",
            type: "select-managed",
        },
        {
            label: "Check type",
            name: "check_type",
            type: "select-managed",
        }
    ];

    meta_list = addManagedItems(meta_list, collectAllManagedForms(config));
    meta_list = removeDuplicates(meta_list);

    return (
        <div id={title}>
            <Row>
                <Col md="12">
                    <FormGroup className="pb-2">
                        <Label >Form options</Label>
                        {meta_list && <div className="pb-2">{
                            meta_list.filter((meta) => meta.name !== "supplier" && meta.name !== "grower")
                                .map((meta: { label: string; name: string; type: string; options_name?: string; }, index: number) => (
                                    <Button key={`meta-${index}`} className="me-2 mb-2" size="sm" color="light" onClick={() => navigate(`/tenants/${tenant_id}/form-field-options/${meta.options_name || meta.name}`)}>Edit {meta.label} options</Button>
                                ))
                        }</div>}

                    </FormGroup>

                </Col>

            </Row>
        </div>
    );
}

export default ListTenantFormOptions;
