// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import PropTypes from "prop-types";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faBars, faCheck, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircleNotch,
    faExclamationTriangle, faEye, faFilter, faInfoCircle, faLemon, faPencil, faPlus, faRedo,
    faThumbsDown, faThumbsUp, faTimes, faTrash, faVideo,
} from "@fortawesome/pro-regular-svg-icons";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainContent from "./MainContent";
import IsOfflineBar from "./scenes/Layout/IsOfflineBar";

library.add(
    faChevronRight,
    faPlus,
    faInfoCircle,
    faCheck,
    faTimes,
    faChevronUp,
    faChevronDown,
    faChevronLeft,
    faPencil,
    faFilter,
    faEye,
    faTrash,
    faBars,
    faCircleNotch,
    faRedo,
    faVideo,
    faLemon,
    faThumbsDown,
    faThumbsUp,
    faExclamationTriangle,
);

export default function App({ pca }) {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app

    return (
        <MsalProvider instance={pca}>
            <div className="wrapper">
                <ToastContainer limit={4}/>
                <IsOfflineBar/>
                <MainContent/>
            </div>
        </MsalProvider>
    );
}

App.propTypes = {
    pca: PropTypes.object,
};
