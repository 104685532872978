/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { patchCheck } from "../../../../../actions/Checks/actions";
import { optionsFlagged } from "../../../../../actions/Layers/constants";
import useConfig from "../../../../../actions/Tenants/config/configHook";
import MetaForm from "../../../../Forms/MetaForm";
import { Loading } from "../../../../Helper/Loading";


const defaultFormFields = [
    {
        label: "Notes",
        name: "feedback",
        type: "textarea",
        lg: 12,
    },
    {
        label: "",
        name: "flag",
        type: "radio",
        options: optionsFlagged,
        lg: 12
    }
];

export default function CheckSummaryForm({ layer, check }) {
    const config = useConfig();
    const location = config.get_location(layer, check);
    const dispatch = useDispatch();

    // when you navigate from a parent layer to a child layer check summary...
    // we need to give it some time to reinstate the config (e.g. when the parent doesn't have a location)
    if (!layer || !check || !location || !location.summary_fruit_fields) {
        return <Loading />;
    }


    return (
        <div className="d-print-none pt-3">

            {location.meta_form_summary && <MetaForm
                grid={true}
                meta={[...location.meta_form_summary, ...defaultFormFields]}
                onDebounce={(field, value) => dispatch(patchCheck(check.test_id, field, value, true))}
                setValue={(field, value) => dispatch(patchCheck(check.test_id, field, value, false))}
                object={check}
                config={config} />}
        </div>
    );
}


CheckSummaryForm.propTypes = {
    check: PropTypes.object,
    layer: PropTypes.object,
};
