export const blobToBase64 = async (blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
    });
};

export const fetchBlob = async (report_url: string): Promise<Blob> => {
    try {
        const response = await fetch(report_url);

        if (!response.ok) {
            throw new Error(
                `Failed to fetch blob. Status: ${response.status}, Message: ${response.statusText}`
            );
        }
        return await response.blob();
    } catch (error) {
        console.error("Error fetching blob:", error); // eslint-disable-line no-console
        throw error;
    }
};
