export function format2Backend(data) {
    const copyData = { ...data };

    const {
        id, user_id, tenant_id, updated, created, type, label, latest_check, latest_location, calibrated_stages, children, parents, parent_meta, images,
        ...meta
    } = copyData;

    // random comment
    const meta_data = Object.keys(meta).map((key) => ({
        key,
        value: meta[key]
    }));

    const parent_meta_data = parent_meta ? Object.keys(parent_meta).map((key) => ({
        key,
        value: meta[key]
    })) : {};
    return {
        id,
        user_id,
        tenant_id,
        updated,
        created,
        type,
        label,
        latest_check, // * undefined now
        latest_location,
        calibrated_stages,
        children,
        parents,
        images,
        parent_meta: parent_meta_data,
        meta: meta_data
    };
}

export function format2Frontend(data) {
    const { meta, parent_meta, ...layer } = data;
    meta.forEach((i) => {
        layer[i.key] = i.value;
    });

    const parent_meta_obj = parent_meta ? parent_meta.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {}) : {};

    return { ...layer, parent_meta: parent_meta_obj };
}

export const extractMetaProperties = (array) => {
    return array.map((obj) => {
        const { meta, ...rest } = obj;
        const newObj = { ...rest };
        if (meta && meta.length > 0) {
            meta.forEach(({ key, value }) => {
                newObj[key] = value;
            });
        }
        return newObj;
    });
};


