/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Col, Container, Row } from "reactstrap";
import { ROOT_URL } from "../../../constants/urls";
import mrAvos from "../../../img/avos-eye.jpg";

export default function Logout() {
    return (
        <Container >
            <Row className="align-items-center justify-content-center text-center" css={css`min-height: 82vh`} >
                <Col xs="12" md="6" lg="4" >
                    <div className="pb-2 text-center">
                        <img src={mrAvos} alt="Meneer AVOS" css={css`max-width: 11rem;width: 50%;`} />
                    </div>
                    <h4 className="py-5 mb-0 text-center"><b>You are Logged Out</b><br></br>See you next time!</h4>
                    <a className="btn btn-primary w-100 btn-lg" href={`${ROOT_URL}oauth2/login`}>Login with Microsoft</a>
                </Col>
            </Row>
        </Container>
    );
}
