/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button, Table } from "reactstrap";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { Loading } from "../../../Helper/Loading";
import { AvocadoIndex } from "./Images";
import Box from "./Layout";

export default function MaturityInput() {
    const config = useConfig();

    const check = useSelector((state) => state.checks.current);
    const isLoading = useSelector((state) => state.checks.isLoading);
    const layer = useSelector((state) => state.layers.current);

    return (
        <Box headerTitle={config.title()} hideButtons={isLoading}>
            {isLoading && <Loading></Loading>}
            {!isLoading
                && <div css={check.location === "validation" ? css`max-width: 64rem;` : css`max-width: 40rem;`}>
                    <Table size="sm" responsive={true} >
                        <thead>
                            <tr className="align-middle">
                                <th className="text-muted text-uppercase text-start ">{layer.fruit_type}</th>
                                <th className="text-muted text-uppercase text-start ">Maturity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {check.avocados.map((i, index) => (
                                <tr key={i.avocado_id} className="align-items-center pb-2">
                                    <td className="text-start text-uppercase align-middle" ><AvocadoIndex avocado={i} key={i.avocado_id} index={index + 1}></AvocadoIndex></td>
                                    <td className="text-start align-middle" >
                                        <MaturityInputField avocado={i} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            }
        </Box>

    );
}
export function MaturityInputField({ avocado }) {
    const patchAvocado = usePatchAvocado();
    return <div>
        {[1, 2, 2.3, 2.5, 3, 4, 5].map((option) => <Button
            key={option}
            className="me-3"
            disabled={avocado.isLoading === option}
            outline={avocado.manual_maturity !== option}
            onClick={() => patchAvocado(avocado, "manual_maturity", option)}
        >{option}
        </Button>)}
    </div>;
}

MaturityInputField.PropTypes = {
    avocado: PropTypes.def,
};

MaturityInputField.propTypes = {
    avocado: PropTypes.object,
};
