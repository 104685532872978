/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
    UncontrolledTooltip,
} from "reactstrap";
import { getDeviceStatusList, resetDeviceStatusList } from "../../../../actions/Devices/actions";
import { Loading } from "../../../Helper/Loading";
import { PaginationRow } from "../../../Helper/PaginationRow";

export function DeviceStatusTable() {
    const [ShowAllColumns, setShowAllColumns] = useState(false);
    const toggle = () => { setShowAllColumns(!ShowAllColumns); };
    const [limit, setLimit] = useState(25);
    const isLoading = useSelector((state) => state.devices.isLoading);
    const statusHistory = useSelector((state) => state.devices.statusHistory);
    const current = useSelector((state) => state.devices.current);
    const dispatch = useDispatch();

    // The name of the device is the hostname of the  operating system posting the status update
    const hostname = current?.name;

    useEffect(() => {
        if (hostname) {
            const offset = 0;
            dispatch(resetDeviceStatusList());
            dispatch(getDeviceStatusList({ hostname, limit, offset }));
        }
    }, [hostname, limit]);

    const onPageChanged = (data) => {
        const { currentPage, pageLimit } = data;
        const offset = Math.max((currentPage - 1) * pageLimit, 0);
        dispatch(getDeviceStatusList({ hostname, limit, offset }));
    };

    if (!statusHistory) {
        return <Loading/>;
    }

    return (
        <div>
            <Row>
                <Col>
                    <FormGroup switch>
                        <Input type="switch" onClick={toggle} />
                        <Label check>Show details</Label>
                    </FormGroup>
                    {isLoading && <Loading />}
                    { (!isLoading && statusHistory)
                        && <div css={css`min-height: 30vh;`}>
                            <Table hover responsive striped>
                                <thead>
                                    <tr css={css`font-size:14px;`}>
                                        {ShowAllColumns && <th><span id="hostname">Hostname</span> <UncontrolledTooltip placement="top" target="hostname">Hostname that is unique to a mini.</UncontrolledTooltip ></th>}
                                        {ShowAllColumns && <th><span id="cpu_variant">CPU</span> <UncontrolledTooltip placement="top" target="cpu_variant">Here you can find the CPU specifications.</UncontrolledTooltip ></th>}
                                        {ShowAllColumns && <th className="text-center"><span id="cpu_load">CPU load</span> <UncontrolledTooltip placement="top" target="cpu_load">Here you can find the CPU load of the device.</UncontrolledTooltip ></th>}
                                        {ShowAllColumns && <th><span id="memory">Memory</span> <UncontrolledTooltip placement="top" target="memory">Here you can find the device memory usage and capacity.</UncontrolledTooltip ></th>}
                                        {ShowAllColumns && <th><span id="disk">Disk</span> <UncontrolledTooltip placement="top" target="disk">Here you can find the device disk free space and capacity.</UncontrolledTooltip ></th>}
                                        <th><span id="Uptime">Uptime</span> <UncontrolledTooltip placement="top" target="Uptime">Here you can find the uptime regarding the device.</UncontrolledTooltip ></th>
                                        <th css={css`min-width:140px;`}><span id="wired_ip">IP address</span> <UncontrolledTooltip placement="top" target="wired_ip">Here you can find the wired and wirelesss ip address.</UncontrolledTooltip ></th>
                                        <th css={css`min-width:180px;`}><span id="mac_eth0_ip">MAC</span> <UncontrolledTooltip placement="top" target="mac_eth0_ip">Here you can find the wired and wirelesss MAC address.</UncontrolledTooltip ></th>
                                        <th><span id="status_avos">AVOS</span> <UncontrolledTooltip placement="top" target="status_avos">Here you can find the status regarding AVOS platform.</UncontrolledTooltip ></th>
                                        <th><span id="status_ovpn">OVPN</span> <UncontrolledTooltip placement="top" target="status_ovpn">Here you can find the OVPN from the device.</UncontrolledTooltip ></th>
                                        <th><span id="tun0_ip">Tunnel</span> <UncontrolledTooltip placement="top" target="tun0_ip">Here you can find the tunnel ip from the device.</UncontrolledTooltip ></th>
                                        {ShowAllColumns && <th><span id="wired_status">Wired status</span> <UncontrolledTooltip placement="top" target="wired_status">Here you can find the wired status regarding the device.</UncontrolledTooltip ></th>}
                                        {ShowAllColumns && <th><span id="wlan_status">WLAN status</span> <UncontrolledTooltip placement="top" target="wlan_status">Here you can find the WLAN status regarding the device.</UncontrolledTooltip ></th>}
                                        <th><span id="created_at">Last Activity</span> <UncontrolledTooltip placement="top" target="created_at">Here you can find the date on which this log has been created.</UncontrolledTooltip ></th>
                                    </tr>
                                </thead>
                                <tbody css={css`font-size:14px;`}>
                                    {statusHistory.results.map((item, index) => {
                                        return <tr key={index}>
                                            {ShowAllColumns && <td className="text-capitalize"><p>{item.hostname}</p></td>}
                                            {ShowAllColumns && <td><p>{item.cpu_variant}</p></td>}
                                            {ShowAllColumns && <td className="text-center"><p>{item.cpu_load}</p></td>}
                                            {ShowAllColumns && <td><p>{item.memory}</p></td>}
                                            {ShowAllColumns && <td>
                                                {item.disk2 === " " && <div className="fw-bold pt-2">Disk </div>} {item.disk}
                                                {item.disk2 !== " " && <div className="fw-bold pt-2">Disk 2</div>} {item.disk2}
                                            </td>}
                                            <td><p>{item.uptime}</p></td>
                                            <td>
                                                {item.wired_ip !== " " && <div className="fw-bold">LAN</div>} {item.wired_ip}
                                                {item.wlan_ip !== " " && <div className="fw-bold pt-2">WLAN</div>} {item.wlan_ip}
                                            </td>
                                            <td>
                                                {item.wired_mac !== " " && <div className="fw-bold">LAN</div>} {item.wired_mac}
                                                {item.wlan_mac !== " " && <div className="fw-bold pt-2">WLAN</div>} {item.wlan_mac}
                                            </td>
                                            <td><p>{item.avos_status}</p></td>
                                            <td><p>{item.openvpn_status}</p></td>
                                            <td><p>{item.openvpn_ip}</p></td>
                                            {ShowAllColumns && <td><p>{item.wired_status}</p></td>}
                                            {ShowAllColumns && <td><p>{item.wlan_status}</p></td>}
                                            <td><p>{dayjs(item.created_at).format("DD-MMM-YY hh:mm:ss")}</p></td>
                                        </tr>;
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    }
                </Col>
            </Row>
            <PaginationRow results={statusHistory?.results || []} totalCount={statusHistory?.count || 0} onPageChanged={onPageChanged} filter={{ limit }} updateLimit={setLimit}/>
        </div>
    );
}
