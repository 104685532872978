import "react-app-polyfill/ie11";
import "./styles/style.scss";

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

import apiClient from "./constants/apiClient";
import { msalInstance } from "./msal-instance";
import { initStore } from "./store";

import App from "./components/App";

import { StoreProvider } from "./components/Helper/StoreProvider";
import * as serviceWorker from "./serviceWorker";

// Sentry.init({
//     dsn: "https://a3dda5aeaa77469fb5052a26bd93acc0@o1199567.ingest.sentry.io/6323348",
//     integrations: [new BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     enabled: process.env.NODE_ENV !== "development",
//     tracesSampleRate: 1.0,
// });

const container = document.getElementById("root") || document.createElement("div");
const root = createRoot(container);
initStore(apiClient);
root.render(<React.StrictMode>
    <StoreProvider>
        <Router>
            <App pca={msalInstance} />
        </Router>
    </StoreProvider>
</React.StrictMode >);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
