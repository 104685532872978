/** @jsxImportSource @emotion/react */

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import { patchAvocado } from "../../../../actions/Avocados/actions";

export function InvalidFruitModal(props) {
    const {
        avocado, toggleModal, isOpen, index,
    } = props;
    const dispatch = useDispatch();

    const markInvalid = () => {
        dispatch(patchAvocado(avocado.avocado_id, "is_invalid", true)).then((response) => {
            if (!response.error) {
                toggleModal(false);
            }
        });
    };
    return (
        <Modal isOpen={isOpen} className="modal-lg" toggle={() => toggleModal(!isOpen)}>
            <ModalHeader toggle={() => toggleModal(!isOpen)} >Fruit {index} <span className="text-muted">(ID:{avocado.avocado_id})</span></ModalHeader>
            <ModalBody>
                Please mark the fruit as invalid if you think something went wrong with this measurement. We will keep it out of your reports. Please try to rescan the fruit after you marked it as invalid.
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => toggleModal(!isOpen)}>Cancel</Button>
                <Button color="danger" onClick={() => markInvalid()}>Mark Invalid</Button>
            </ModalFooter>
        </Modal>
    );
}

InvalidFruitModal.propTypes = {
    avocado: PropTypes.object,
    toggleModal: PropTypes.func,
    isOpen: PropTypes.bool,
    index: PropTypes.number,
};
