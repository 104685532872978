import { AVOCADO_FRUIT } from "../fruitNames";
import { avocado_min_weight } from "../../../Layers/constants";
import {
    baseConfig,
    headerLinks,
    pressure_classes,
    ripening_colors,
    ripening_stages_firmness
} from "../constants";
import { RootConfigType } from "../constantsTyped";


const ripening_stages = [
    {
        gt: 12,
        lte: false,
        label: "Hard",
        color: ripening_colors.Hard_Mission,
    },

    {
        gt: 7,
        lte: 12,
        label: "Nearly ripe",
        color: ripening_colors["Nearly ripe"],
    },

    {
        gt: 1,
        lte: 7,
        label: "Ripe",
        color: ripening_colors.Ripe,
    },
    {
        gte: false,
        lte: 1,
        label: "Soft",
        color: ripening_colors.Soft,
    },
];


const required_fruit_images = [
    {
        type: "fruit_overview",
        label: "Fruit",
    },
];

export const MissionBredaConfig: RootConfigType = {
    ...baseConfig,
    default_fruit_type: AVOCADO_FRUIT,
    required_fruit_images,
    ripening_stages,
    underweight: avocado_min_weight,
    ripening_stages_firmness,
    indicate_quality_at_defects: false,
    ripening_stages_pressure: pressure_classes,
    lang: {
        pressure: "Pressure"
    },
    // On take numbers from the string
    barcode_filter: (scanned) => scanned.replace(/\D/g, ""),
    headerMainLinks: [headerLinks.home, headerLinks.packinglist, headerLinks.mailbox],
    headerSecondaryLinks: [headerLinks.packinglist, headerLinks.settings, headerLinks.logout],
};
