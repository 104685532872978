/** @jsxImportSource @emotion/react */
import Field, { DataSource } from "./Field";


class ListChildrenMetaField extends Field {
    field_type = "list_children_meta_field";


    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        const { children } = datasource;
        if (!children || children.results.length === 0) {
            this.value = "-";
            return this;
        }
        this.value = Array.from(new Set(children.results.map((i) => i[this.fieldname_getter]))).filter((i) => i).join(", ");
        return this;
    }


}

export default ListChildrenMetaField;
