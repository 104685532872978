import {
    CREATE_FACILITY_LOCATION,
    DELETE_FACILITY_LOCATION,
    LIST_FACILITY_LOCATIONS,
    UPDATE_FACILITY_LOCATION
} from "./actionsTypes";

const endpoint = "tenants/";

// * Tenant facility-locations
export function listTenantFacilityLocations(tenant_id) {
    return {
        types: [
            LIST_FACILITY_LOCATIONS.LOADING,
            LIST_FACILITY_LOCATIONS.SUCCESS,
            LIST_FACILITY_LOCATIONS.ERROR,
        ],
        payload: {
            request: {
                method: "GET",
                tenant_id,
                url: `${endpoint}${tenant_id}/facility-locations`,
            }
        }
    };
}

export function createTenantFacilityLocation(tenant_id, data) {
    return {
        types: [
            CREATE_FACILITY_LOCATION.LOADING,
            CREATE_FACILITY_LOCATION.SUCCESS,
            CREATE_FACILITY_LOCATION.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/facility-locations`,
                data,
            }
        }
    };
}

export function deleteTenantFacilityLocation(tenant_id, id) {
    return {
        types: [
            DELETE_FACILITY_LOCATION.LOADING,
            DELETE_FACILITY_LOCATION.SUCCESS,
            DELETE_FACILITY_LOCATION.ERROR,
        ],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint}${tenant_id}/facility-locations/${id}`,
            }
        }
    };
}

export function deleteAllTenantFacilityLocations(tenant_id) {
    return {
        types: [
            DELETE_FACILITY_LOCATION.LOADING,
            DELETE_FACILITY_LOCATION.SUCCESS,
            DELETE_FACILITY_LOCATION.ERROR,
        ],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint}${tenant_id}/facility-locations/all`,
            }
        }
    };
}

export function updateTenantFacilityLocation(tenant_id, data) {
    return {
        types: [
            UPDATE_FACILITY_LOCATION.LOADING,
            UPDATE_FACILITY_LOCATION.SUCCESS,
            UPDATE_FACILITY_LOCATION.ERROR,
        ],
        payload: {
            request: {
                method: "PUT",
                url: `${endpoint}${tenant_id}/facility-locations/${data.location_id}`,
                data,
            }
        }
    };
}

export function importTenantFacilityLocation(tenant_id, data) {
    return {
        types: [
            UPDATE_FACILITY_LOCATION.LOADING,
            UPDATE_FACILITY_LOCATION.SUCCESS,
            UPDATE_FACILITY_LOCATION.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/facility-locations/import`,
                data,
            }
        }
    };
}

export function resetAllTenantFacilityLocation(tenant_id, data) {
    return {
        types: [
            UPDATE_FACILITY_LOCATION.LOADING,
            LIST_FACILITY_LOCATIONS.SUCCESS,
            UPDATE_FACILITY_LOCATION.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/facility-locations/reset-all`,
                data,
            }
        }
    };
}
