/** @jsxImportSource @emotion/react */

import Field, { DataSource } from "../../Layers/fields/Field";


class RipeningCellMetaFields extends Field {
    field_type = "ripening_cell_meta_field";

    backend_table = "ripening_cell_meta";

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        this.value = null;
        if (datasource.ripeningCell?.[this.fieldname_getter]) {
            this.value = datasource.ripeningCell[this.fieldname_getter];
        }
        return this;

    }
}

export default RipeningCellMetaFields;


