/** @jsxImportSource @emotion/react */

import { Link } from "react-router-dom";
import Field, { DataSource } from "./Field";


class LinkLayerMetaField extends Field {
    field_type = "layer_meta_field";

    backend_table = "layer_meta";

    layer_id: string | null = null;

    is_filterable(): boolean {
        // * fruit_type has its own way of doing things for now
        if (this.fieldname_getter === "fruit_type") {
            return false;
        }
        return true;
    }

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        this.value = null;
        if (datasource.layer[this.fieldname_getter]) {
            this.value = datasource.layer[this.fieldname_getter];
        } else if (datasource.layer.parent_meta && datasource.layer.parent_meta[this.fieldname_getter]) {
            this.value = datasource.layer.parent_meta[this.fieldname_getter];
        }

        this.value = this.get_form_option_label(datasource.form_options, this.value_filter(this.value, datasource));
        this.layer_id = datasource.layer.id ?? null;
        this.set_color_code(datasource.layer);
        return this;

    }

    display_component(): JSX.Element {
        if (this.layer_id && this.value) {
            return <Link to={`/layer/${this.layer_id}`}>{this.value}</Link>;
        }
        return <span>-</span>;
    }
}

export default LinkLayerMetaField;


