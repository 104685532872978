import { defineAction } from "redux-define";
import {
    ERROR,
    FILTER,
    LOADING,
    RESET,
    SUCCESS,
    TheApp,
} from "../../constants/stateConstants";

const STEP = "STEP";
const IMPORT_DATA = "IMPORT_DATA";
export const packingLists = defineAction("packing-list", [LOADING, ERROR, SUCCESS], TheApp);
export const LIST = defineAction("LIST", [FILTER, LOADING, ERROR, SUCCESS], packingLists);
export const GET = defineAction("GET", [LOADING, ERROR, SUCCESS], packingLists);
export const CREATE = defineAction("CREATE", [LOADING, ERROR, SUCCESS, RESET], packingLists);
export const UPDATE = defineAction("UPDATE", [LOADING, ERROR, SUCCESS, STEP, IMPORT_DATA], packingLists);
// export const DELETE = defineAction("DELETE", [LOADING, ERROR, SUCCESS], packingLists);
