/* eslint-disable no-case-declarations */
import { DELETE, LIST, UPDATE, CREATE } from "./actionTypes";
import { createEntityReducer } from "../entityReducer";
import { EntityState } from "../reducer";
import { SupplierInfo } from "../supplier/reducer";


export type InternalEmailsState = EntityState<SupplierInfo>;

export default createEntityReducer<InternalEmailsState>({ LIST, CREATE, UPDATE, DELETE });
