/** @jsxImportSource @emotion/react */
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { updateTenantState } from "../../../actions/Tenants/actions";
import useConfig from "../../../actions/Tenants/config/configHook";
import MetaForm from "../../Forms/MetaForm";


interface ListTenantLegacyFeaturesProps {
    title: string;
}
const true_or_false = [{ value: true, text: "True" }, { value: false, text: "False" }];
const features = [
    {
        name: "active",
        title: "Active",
        description: "Is this tenant able to login?",
        type: "radio",
        options: true_or_false

    },
    {
        name: "uses_kg",
        title: "Uses kg",
        description: "Do these tenant members measure pressure in KG or LBS.",
        type: "radio",
        options: [{ value: true, text: "KG" }, { value: false, text: "LBS" }]
    },
    {
        name: "quality_check_size",
        title: "Check size",
        description: "How many avocados are there in one check? This is relevant for the bulk edit and pressure calibration.",
        type: "number"
    },
    {
        name: "has_two_fta",
        title: "Has multiple FTA",
        description: "Uses pressure_1 and pressure_2",
        type: "radio",
        options: true_or_false
    },
    {
        name: "show_powerbi_link",
        title: "Show PowerBI link",
        description: "Show PowerBI link on the header for this tenant",
        type: "radio",
        options: true_or_false
    },
];

function ListTenantLegacyFeatures({ title }: ListTenantLegacyFeaturesProps) {
    const current = useSelector<any, any>((state) => state.tenants.current);
    const config = useConfig();


    const dispatch = useDispatch();


    return (
        <div id={title}>
            <Row>
                <Col>
                    <MetaForm config={config} meta={features} object={current} setValue={(field, value) => dispatch(updateTenantState({ ...current, [field]: value }))} />
                </Col>
            </Row>
        </div>
    );
}

export default ListTenantLegacyFeatures;
