/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Select from "react-select";
import { FormGroup, FormText, Label } from "reactstrap";
import { RadioButtons } from "./RadioButton";
import { getMetaValues, useManagedOptionsHook } from "./useManagedOptionsHook";

// * use return_objects if you want to return the array with objects
// * use return array if you want to return the array with values
// * by default will return a comma seperated string, to be stored as meta value as string
export function SelectManaged({
    setValue, object, post_value, name, label, disabled, multi, options_name, hide_when_no_options, return_objects, return_array, description, children_meta, extra_context
}) {

    const options = children_meta ? getMetaValues({ name: options_name || name })
        : useManagedOptionsHook({ object: { ...object, ...extra_context }, name: options_name || name });

    let selectedValue = ""; // if null it will be saved in the db as None
    const _onChange = (option) => {
        if (option) {
            if (return_objects) {
                setValue(name, option);
            } else if (return_array) {
                setValue(name, option.map((i) => i.value));
            } else {
                setValue(name, multi ? option.map((i) => i.value).join(",") : option.value);
            }
        } else {
            setValue(name, multi ? [] : null);
        }
    };

    if (return_objects && post_value) {
        selectedValue = post_value;
    } else if (multi) {
        let selectedOptions = [];
        if (return_array && post_value) {
            selectedOptions = post_value;
        } else {
            selectedOptions = post_value && post_value.length > 0 ? String(post_value).split(",") : [];
        }
        selectedValue = options.filter((i) => selectedOptions.includes(i.value));

    } else {
        selectedValue = options.find((i) => String(i.value) === String(post_value)) || {};
    }

    if (hide_when_no_options && options.length === 0) {
        return null;
    }

    return (
        <FormGroup>
            <Label>{label}</Label>
            <Select id={name} options={options} isMulti={multi} hideSelectedOptions={false} isClearable={true} value={selectedValue} onChange={_onChange} isDisabled={disabled} ></Select>
            {description && <FormText className="d-block">{description}</FormText>}
        </FormGroup>
    );
}

SelectManaged.defaultProps = {
    options_name: false,
    hide_when_no_options: false,
    description: "",
    extra_context: {}
};

SelectManaged.propTypes = {
    setValue: PropTypes.func,
    object: PropTypes.object,
    post_value: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    options_name: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    extra_context: PropTypes.object,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    multi: PropTypes.bool,
    hide_when_no_options: PropTypes.bool,
    children_meta: PropTypes.bool,
    return_objects: PropTypes.bool,
    return_array: PropTypes.bool,
};

export function RadioButtonManaged({
    setValue, object, post_value, name, label, disabled, options_name, description, default_value, extra_context
}) {
    const options = useManagedOptionsHook({ object: { ...object, ...extra_context }, name: options_name || name });
    let selectedValue = null;

    selectedValue = options.find((i) => String(i.value) === String(post_value || default_value)) || {};
    options.sort((a, b) => a.label - b.label);

    return (
        <FormGroup>
            <Label>{label}</Label>
            <div>
                {options.length === 0 && <div className="text-warning">No options configured for {options_name || name}</div>}
                <RadioButtons
                    color="secondary"
                    options={options}
                    name={name}
                    current={selectedValue.value}
                    onChange={setValue}
                    disabled={disabled}
                    className={"me-1 mb-1"}
                />
            </div>
            {description && <FormText className="d-block">{description}</FormText>}
        </FormGroup>
    );
}

RadioButtonManaged.defaultProps = {
    options_name: false,
    description: "",
    extra_context: {}
};
RadioButtonManaged.propTypes = {
    setValue: PropTypes.func,
    object: PropTypes.object,
    post_value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    extra_context: PropTypes.object,
    name: PropTypes.string,
    description: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    default_value: PropTypes.string,
    options_name: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
};
