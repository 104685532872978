import { defineAction } from "redux-define";
import {
    ERROR,
    FILTER,
    LOADING,
    RESET,
    SUCCESS,
    TheApp
} from "../../constants/stateConstants";

export const deviceManagment = defineAction("devices", [LOADING, ERROR, SUCCESS], TheApp);

export const LIST_DEVICE = defineAction("LIST", [FILTER, LOADING, ERROR, SUCCESS], deviceManagment);
export const GET_DEVICE = defineAction("GET", [LOADING, ERROR, SUCCESS], deviceManagment);
export const SELECT_DEVICE = defineAction("SELECT", [LOADING, ERROR, SUCCESS, "AFTER_LOGIN"], deviceManagment);
export const CREATE_DEVICE = defineAction("CREATE", [LOADING, ERROR, SUCCESS], deviceManagment);
export const UPDATE_DEVICE = defineAction("UPDATE", [LOADING, ERROR, SUCCESS], deviceManagment);
export const DELETE_DEVICE = defineAction("DELETE", [LOADING, ERROR, SUCCESS], deviceManagment);

export const POWER_OPTION = defineAction("POWER_OPTION", [LOADING, ERROR, SUCCESS], deviceManagment);
export const LIST_EVENT = defineAction("GET_EVENT", [LOADING, ERROR, SUCCESS], deviceManagment);
export const CREATE_EVENT = defineAction("CREATE_EVENT", [LOADING, ERROR, SUCCESS], deviceManagment);
export const GET_EVENT = defineAction("GET_EVENT", [LOADING, ERROR, SUCCESS], deviceManagment);
export const POLL_CHECK_EVENT = defineAction("POLL_CHECK_EVENT", [LOADING, ERROR, SUCCESS], deviceManagment);
export const ADMIN_POLL_EVENT = defineAction("ADMIN_POLL_EVENT", [LOADING, ERROR, SUCCESS], deviceManagment);
export const LIST_EVENT_QUEUE = defineAction("LIST_EVENT_QUEUE", [LOADING, ERROR, SUCCESS], deviceManagment);
export const LIST_STATUS = defineAction("LIST_STATUS", [LOADING, ERROR, SUCCESS, RESET], deviceManagment);

export const SOFTWARE_STATUS = defineAction("SOFTWARE_STATUS", [LOADING, ERROR, SUCCESS, RESET], deviceManagment);

