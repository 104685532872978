
/** @jsxImportSource @emotion/react */
import { LAYER_TYPE, Parent, Related } from "../../../../actions/Layers/constants";
import Field, { DataSource } from "./Field";


class ParentLabelField extends Field {
    field_type = "parent_label_field";

    backend_table = "parents";

    parent: Parent | null = null;

    parent_layer_type: string;

    constructor(key: string, label: string, layer_type: LAYER_TYPE, options = {}) {
        super(key, label, options);
        this.parent_layer_type = layer_type;
    }

    get_backend_fieldname(): string[] {
        return [`${this.backend_table}.label`, `${this.backend_table}.id`];
    }

    is_sortable(): boolean {
        return true;
    }

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        const related = datasource.layer?.parents;
        if (!related) {
            this.parent = null;
            this.value = null;
            return this;
        }
        const item = related.find((i: Related) => i.type === this.parent_layer_type);
        if (item) {
            this.parent = item;
            this.value = item.label;
            return this;
        }
        this.value = null;
        this.parent = null;
        return this;

    }

    display_component(): JSX.Element {
        if (this.parent) {
            return <span>{this.parent.label}</span>;
        }
        return <span>-</span>;
    }

}

export default ParentLabelField;
