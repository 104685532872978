/** @jsxImportSource @emotion/react */
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import {
    Alert
} from "reactstrap";


function dnsIsAvailable(setStatus) {
    axios.get("https://dns.google/resolve", {
        params: {
            name: "google.com",
            type: "A",
        },
        timeout: 7000,
    })
        .then(() => {
            setStatus(true);
        })
        .catch(() => {
            setStatus(false);
        });
}

export default function IsOfflinebar() {
    const [isOnline, setStatus] = useState(window.navigator.onLine);
    const ref = useRef();
    useEffect(() => {
        ref.current = setInterval(() => {
            dnsIsAvailable(setStatus);
        }, 7000);
        return () => clearInterval(ref.current);
    }, []);

    if (!isOnline) {
        return <Alert color="danger" className="text-center py-1 fs-7 mb-0 rounded-0">Your internet connection is <strong>disrupted</strong>.</Alert>;
    }

    return null;

}
