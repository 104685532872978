import { StyleSheet, View, Text } from "@react-pdf/renderer";
import { MinMaxAvg } from "../../../../../actions/Tenants/config/constantsTyped";

const styles = StyleSheet.create({
    container: {
        paddingTop: "6px",
        borderRadius: "5px",
        marginLeft: "5px",
        marginRight: "5px",
    },
    table: {
        borderWidth: "1px",
        borderColor: "#939496",
        borderRadius: "5px",
        width: "100%",
    },
    tableRow: {
        flexDirection: "row",
    },
    tableCell: {
        flex: 1,
        padding: "4px",
        borderRightWidth: 1,
        borderColor: "#939496",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontSize: "14px",
        color: "black",
        fontFamily: "Poppins-Medium",
    },
    lastCell: {
        borderRightWidth: 0,
    },
});

interface PDFDryMatterMinMaxAvgOverviewProps {
    dryMatterMinMaxAvg?: MinMaxAvg;
}


const PDFDryMatterMinMaxAvgOverview: React.FC<PDFDryMatterMinMaxAvgOverviewProps> = ({ dryMatterMinMaxAvg }) => {

    if (!dryMatterMinMaxAvg) {
        return null;
    }

    return (
        <View style={styles.container}>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                        <Text>Dry matter:</Text>
                    </View>
                    <View style={styles.tableCell}>
                        <Text>Min: {dryMatterMinMaxAvg?.min || "-"}</Text>
                    </View>
                    <View style={styles.tableCell}>
                        <Text>Max: {dryMatterMinMaxAvg?.max || "-"}</Text>
                    </View>
                    <View style={[styles.tableCell, styles.lastCell]}>
                        <Text>Avg: {dryMatterMinMaxAvg?.avg || "-"}</Text>
                    </View>
                </View>
            </View>
        </View>
    );
};

export default PDFDryMatterMinMaxAvgOverview;
