/** @jsxImportSource @emotion/react */

import {
    path_ask_quality,
    path_brix_input,
    path_color_check,
    path_dry_matter,
    path_durometer,
    path_external_quality_check,
    path_fruit_images,
    path_fta_input,
    path_has_internal_quality_check,
    path_internal_color_check,
    path_internal_quality_check,
    path_maturity,
    path_penetrometer,
    path_pit_size,
    path_plu,
    path_pressure_kg,
    path_pressure_lbs,
    path_taste,
    path_temperature,
    path_weight_input,
    path_weight_input_kg
} from "../../../../actions/Tenants/config/constants";
import FilterableConfigList from "./FilterableConfigList";

const inlineFormFields = [
    {
        value: path_external_quality_check,
        label: "External Defects",
    },
    {
        value: path_internal_quality_check,
        label: "Internal Defects",
    },
    {
        value: path_color_check,
        label: "Color",
    },
    {
        value: path_internal_color_check,
        label: "Internal Color",
    },
    {
        value: path_fta_input,
        label: "Pressure",
    },
    {
        value: path_taste,
        label: "Taste",
    },
    {
        value: path_weight_input,
        label: "Weight",
    },
    {
        value: path_weight_input_kg,
        label: "Weight",
    },
    {
        value: path_pressure_lbs,
        label: "Pressure",
    },
    {
        value: path_pressure_kg,
        label: "Pressure",
    },
    {
        value: path_brix_input,
        label: "Brix",
    },
    {
        value: path_dry_matter,
        label: "Dry Matter",
    },
    {
        value: path_temperature,
        label: "Temperature",
    },
    {
        value: path_penetrometer,
        label: "Penetrometer",
    },
    {
        value: path_durometer,
        label: "Durometer",
    },
    {
        value: path_pit_size,
        label: "Pit",
    },
    {
        value: path_has_internal_quality_check,
        label: "Internal Defects",
    },
    {
        value: path_ask_quality,
        label: "Quality",
    },
    {
        value: path_fruit_images,
        label: "Fruit Images",
    },
    {
        value: path_plu,
        label: "PLU",
    },
    {
        value: path_maturity,
        label: "Maturity",
    }
];


const defaultField = {
    field: {
        field_type: path_fta_input,
        label: "Pressure"
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
    // exclude_layer_types: [],
    }
};
export default function LegacyInlineFormConfigForm({ name, title, fields, description, setArray }) {
    const getFieldTitle = (object) => <span> <span className="fw-bold">{object.label}</span> [{object.field_type}]</span>;
    const getFieldForm = (object, setObject) => [
        {
            label: "Field type",
            type: "single-select",
            name: "field_type",
            options: inlineFormFields,
            onchange_callback: ({ field, value, setValue }) => {
                const selectedOption = inlineFormFields.find((i) => i.value === value);
                if (selectedOption) {
                    setObject({ ...object, [field]: value, label: selectedOption.label });
                } else {
                    setValue(field, value);
                }
            }
        },
        {
            label: "Label",
            type: "text",
            name: "label"
        }
    ];
    return <FilterableConfigList name={name} title={title} description={description} fields={fields} setArray={setArray} defaultField={defaultField} getFieldForm={getFieldForm} getFieldTitle={getFieldTitle} />;

}


