import { MANGO_FRUIT } from "../fruitNames";
import {
    baseConfig, default_location_config,
    headerLinks
} from "../constants";
import { RootConfigType } from "../constantsTyped";


const required_fruit_images = [
    {
        type: "fruit_overview",
        label: "Fruit",
    },
];

export const SHPTropicalConfig: RootConfigType = {
    ...baseConfig,
    required_fruit_images,
    default_fruit_type: MANGO_FRUIT,
    can_do_manual_check: true,
    default_location_config: { ...default_location_config, device_result_action: false },
    default_manual: true,
    strict_check_size: false,
    show_atron_pressure_range_kg: true,
    indicate_soft_avocado_at_internal: false,
    suggest_color_stage: true,

    default_location: "ripening",

    research_mode: false,
    invalidate_20x_firmness: false,
    headerMainLinks: [headerLinks.home, headerLinks.packinglist],
    headerSecondaryLinks: [headerLinks.packinglist, headerLinks.settings, headerLinks.logout],
};
