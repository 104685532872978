import { useDispatch, useSelector } from "react-redux";
import useConfig from "../actions/Tenants/config/configHook";
import { updatePreferences, User, UserPreferences } from "../actions/Users/actions";
import { getFilledArrayOrDefault, isFilledArray } from "./getFilledArrayOrDefault";

export const useUserPreferences = (): [UserPreferences, (data: UserPreferences) => void] => {
    const user = useSelector<any>((state) => state.auth.user) as User;
    const dispatch = useDispatch();

    const preferences = user.preferences || {};
    const tenant_id = user.tenant?.tenant_id;

    // Set default preferences for tenant if not available
    const tenantPreferences = (preferences?.[tenant_id] || {}) as UserPreferences;

    const updateAllPreferences = (data: UserPreferences) => {
        if (tenant_id) {
            dispatch(updatePreferences({
                ...preferences,
                [tenant_id]: {
                    ...tenantPreferences,
                    ...data
                }
            }));
        }
    };

    return [tenantPreferences, updateAllPreferences];
};

export const useFruitTypes = () => {
    const config = useConfig();
    const [userPreferences] = useUserPreferences();
    const allOptions = useSelector((state: any) => state?.tenants?.options_by_field);
    const fruitTypeOptions = allOptions?.[config.tenant_id]?.fruit_type || [];

    // * The preferred fruit types should be available at this point (logged in user)
    // * the fruit type Options come later, we first assume the user list is correct with valid fruit,
    // * then when the fruit options are loaded, we can filter the list to only include valid fruit types
    if (isFilledArray(fruitTypeOptions)) {
        return getFilledArrayOrDefault(userPreferences?.fruit_types).filter((fruitType) => fruitTypeOptions.find((option) => option.value === fruitType));
    }

    return getFilledArrayOrDefault(userPreferences?.fruit_types);

};
