import { useDispatch } from "react-redux";
import { patchLayer } from "../../../../actions/Layers/actions";
import { blobToBase64 } from "../../../../utils/base64";
import { QC_STATUS } from "./Report/QCStatusButton";
import { AttachedUrls, SendReportModal } from "./SendReportToSupplierModal";


export interface SendReportRequestBody {
    layer_id: string;
    report_base64: string;
    report_type: string;
    report_file_type: string;
    attached_reports_type?: string;
    attached_urls?: AttachedUrls;
}

export interface SendReportButtonProps {
    blob: Blob | undefined;
    layerId: string;
    reportType: string;
    attachedReportsType?: string;
    onSendReport: (RequestBody: SendReportRequestBody) => void;
    statusField?: string;
}

export const SendReportButton = ({ layerId, blob, reportType, attachedReportsType, onSendReport, statusField }: SendReportButtonProps) => {
    const dispatch = useDispatch();
    const onSend = async (attached_urls) => {
        // * Update QC status if field is set
        if (statusField) {
            dispatch(patchLayer(layerId, statusField, QC_STATUS.SENT, true) as any);
        }
        const base64Data = await blobToBase64(blob);
        await onSendReport({
            layer_id: layerId,
            report_base64: base64Data,
            report_type: reportType,
            report_file_type: "pdf",
            attached_reports_type: attachedReportsType,
            attached_urls,
        });
    };

    // if (!blob) return <></>;
    const pdfSizeInMB = blob ? blob.size / 1024 / 1024 : 0;
    return <SendReportModal onSend={onSend} reportType={reportType} attachedReportsType={attachedReportsType} pdfSizeInMB={pdfSizeInMB} />;
};
