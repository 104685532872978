/**
 * Converts a value or an array of values to a comma-separated string.
 *
 * @param {string|Array} value - The input value or array of values to be converted.
 * @returns {string} - A comma-separated string representation of the input values.
 * @example
 * const stringValue = "John Doe";
 * const arrayValue = [
 *   { value: 'Developer', label: 'Software Developer' },
 *   { value: 'Manager', label: 'Project Manager' }
 * ];
 *
 * const resultString = convert_to_comma_separated_list(stringValue);
 * console.log(resultString);
 * // Output: 'John Doe'
 *
 * const resultArray = convert_to_comma_separated_list(arrayValue);
 * console.log(resultArray);
 * // Output: 'Developer,Manager'
 */
export function convert_to_comma_seperated_list(value) {

    // * sometimes in the eventloop the value will evaluate to undefined
    // * if that happens we will return null so that a proper request to backend is send
    if (typeof value === "undefined") {
        return null;
    }
    if (!value) {
        return value;
    }
    if (typeof value === "string") {
        return value;
    }
    // i.value is for react-select, i is for any arbitrary array
    if (value.length > 0) {
        return value.map((i) => i.value || i).join(",");
    }
    return value;
}

/**
 * Converts an object with a "meta" property into a flat object.
 *
 * @param {Object} obj - The input object with a "meta" property.
 * @returns {Object} - A flat object with properties from the "meta" array.
 * @example
 * const inputObjectWithMeta = {
 *   name: 'John Doe',
 *   age: 30,
 *   meta: [
 *     { key: 'occupation', value: 'Developer' },
 *     { key: 'status', value: 'Active' }
 *   ],
 *   location: 'Cityville'
 * };
 *
 * const result = createFlatObjectFromLayerWithMetaObject(inputObjectWithMeta);
 * console.log(result);
 * // Output: { name: 'John Doe', age: 30, occupation: 'Developer', status: 'Active', location: 'Cityville' }
 */
export const createFlatObjectFromLayerWithMetaObject = (obj) => {
    const { meta, ...rest } = obj;
    if (Array.isArray(meta)) {
        return meta.reduce((acc, { key, value }) => ({
            ...acc,
            [key]: value
        }), rest);
    }
    return rest;
};

/**
 * Converts a flat object into an object with a "meta" property containing specified fields.
 *
 * @param {Object} obj - The input flat object.
 * @param {Array.<string>} fields - An array of fields to be excluded in the "meta" property.
 * @returns {Object} - An object with specified fields moved to the "meta" property.
 * @example
 * const inputFlatObjectWithFields = {
 *   name: 'John Doe',
 *   age: 30,
 *   occupation: 'Developer',
 *   status: 'Active',
 *   location: 'Cityville'
 * };
 *
 * const fieldsToExclude = ['name', 'location'];
 *
 * const result = createLayerWithMetaObjectFromFlatObject(inputFlatObjectWithFields, fieldsToExclude);
 * console.log(result);
 * Output: { meta: [{ key: 'age', value: 30 }, { key: 'occupation', value: 'Developer' }, { key: 'status', value: 'Active' }] }
 */
export const createLayerWithMetaObjectFromFlatObject = (obj, fields) => {
    const newObj = { ...obj };
    const metaFields = Object.keys(newObj).filter((key) => !fields.includes(key));
    newObj.meta = metaFields.reduce((acc, curr) => {
        acc.push({ key: curr, value: newObj[curr] });
        delete newObj[curr];
        return acc;
    }, newObj.meta || []);
    return newObj;
};
