/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, } from "react-redux";
import Select from "react-select";
import {
    Button,
    Col,
    FormGroup,
    FormText,
    Input,
    Label,
    Row,
} from "reactstrap";
import { createDeviceEvent } from "../../../../actions/Devices/actions";
import { DeviceEventType, eventIsFinished } from "../../../../actions/Devices/constants";
import { callAvosApi } from "../../../../utils/useAvosApiHook";
import DeviceEventDump from "../../../Devices/DeviceEventDump";


/** @jsxImportSource @emotion/react */

export const DeviceAdminCommandSections = {
    POWER: "POWER",
    TEST: "TEST"
};
export function DeviceAdminCommand({ type }) {
    const device = useSelector((state) => state.devices.current);
    const [event_id, setLastEventId] = useState(null);
    const [event, setEvent] = useState({});
    const dispatch = useDispatch();
    // We check if we have a valid token before triggering mini, we need a valid token to submit the fruit to the backend
    const dispatchCreateDeviceEvent = (data) => {
        dispatch(createDeviceEvent({
            device_id: device.UID,
            ...data
        })).then((response) => {
            if (!response.error) {
                setLastEventId(response.payload.data.id);
                setEvent(response.payload.data);
            }
        });
    };

    // * This will update `processed` in the device state
    // * will contain succelfull triggers from last 3 minutes
    const pollEventStatus = useCallback(() => {
        if (!event_id) {
            return;
        }

        // Nothing is going to change on this event
        if (eventIsFinished(event.status)) {
            return;
        }

        callAvosApi(`/device/events/user/${event_id}`).then((response) => {
            setEvent(response.data);
        });
    }, [event_id, event.status]);

    useEffect(() => {
        pollEventStatus();
        const interval = setInterval(pollEventStatus, 700);
        return () => clearInterval(interval);
    }, [pollEventStatus, device.UID]);

    const buttons = [
        DeviceEventType.TEST_CAMERA,
        DeviceEventType.TEST_PRESSURE,
        DeviceEventType.TEST_GRAPH_PRESSURE,
        DeviceEventType.TEST_TAR_WEIGHTSCALE,
        DeviceEventType.TEST_WEIGHT_WEIGHTSCALE,
        DeviceEventType.TEST_SCALE_WEIGHTSCALE,
        DeviceEventType.UPDATE_MCU,
        DeviceEventType.UPDATE_AI_MODEL,
        DeviceEventType.VERSION_MCU].map((i) => <AdminDeviceButton key={i} command={i} createEvent={dispatchCreateDeviceEvent} >{i}</AdminDeviceButton>);


    return (
        <div className="pt-3">
            {type === DeviceAdminCommandSections.TEST && <div>
                <h2>Quick buttons</h2>
                <div className="d-flex flex-wrap pt-2">
                    {buttons}
                </div>
            </div>
            }
            {type === DeviceAdminCommandSections.POWER && <div>
                <h2>Power options</h2>
                <PowerOptionsForm dispatchCreateDeviceEvent={dispatchCreateDeviceEvent}/>

            </div>}
            <div className="py-5">
                {event.id && <DeviceEventDump event={event} /> }
            </div>
        </div>);
}

DeviceAdminCommand.propTypes = {
    type: PropTypes.string.isRequired,
};


export function AdminDeviceButton({ command, children, createEvent }) {
    const queue = useSelector((state) => state.devices.queue);
    const device = useSelector((state) => state.devices.current);

    // Blocked when same type is already queued
    const busy = queue.some((i) => i.command === command);


    return (
        <div className="pe-2 pb-2">
            <Button disabled={busy || !device.UID} className="text-nowrap" size="md" color="success" onClick={() => createEvent({ command })} >{children}</Button>
        </div>
    );
}


AdminDeviceButton.propTypes = {
    command: PropTypes.string,
    children: PropTypes.any.isRequired,
    createEvent: PropTypes.func
};


function PowerOptionsForm({ dispatchCreateDeviceEvent }) {
    const initialState = {
        command: "",
        comment: "",
    };
    const [form, updateForm] = useState(initialState);
    const onChange = (e) => {
        updateForm((state) => ({ ...state, comment: e.target.value }));
    };
    const power_options = [
        { value: DeviceEventType.REBOOT, label: "Reboot" },
    ];

    const changePowerOption = () => {
        dispatchCreateDeviceEvent({
            command: form.command,
            data: { comment: form.comment }
        });
    };

    return (
        <div>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Action</Label>
                        <Select
                            options={power_options}
                            name="action"
                            value={power_options.find((x) => x.value === form.command)}
                            onChange={(power_options_item) => updateForm((state) => ({ ...state, command: power_options_item.value }))} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Comment</Label>
                        <Input type="text" name="comment" value={form.comment} onChange={onChange} />
                        <FormText color="muted">The reason for performing this action</FormText>
                    </FormGroup>
                    <button type="button" className="btn btn-primary" onClick={() => changePowerOption()} >Submit</button>
                </Col>
            </Row>
        </div>
    );
}

PowerOptionsForm.propTypes = {
    dispatchCreateDeviceEvent: PropTypes.func.isRequired,
};
