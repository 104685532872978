/** @jsxImportSource @emotion/react */

import { CHECK_CARD_TYPE, LAYER_STAGE, VIEW } from "../../../../actions/Layers/constants";
import FieldConfigForm, { SingleFieldForm } from "./FieldConfigForm";
import SubConfigForm from "./SubConfigForm";

const defaultConfig = {
    title: "",
    field: {
        field_id: null,
        fieldname_getter: "",
        label: "",
    },
    view: VIEW.SELF,
    filter_field: "",
    filter_location: undefined,
    card_type: CHECK_CARD_TYPE.DATE,
};

export default function LayerComponentConfigForm({ title, description, subconfig, setSubconfig, name, attributes }) {


    const filteredDefaultConfig = attributes.reduce((config, attr) => {
        if (Object.prototype.hasOwnProperty.call(defaultConfig, attr)) {
            config[attr] = defaultConfig[attr];
        }
        return config;
    }, {});

    const getForm = (object) => {
        if (!object) {
            return [];
        }
        const form = [
            {
                label: "Title",
                description: "Title of the component, leave blank for default title.",
                type: "text",
                name: "title",
            },
            {
                label: "Field",
                type: "element",
                name: "field",
                el: <SingleFieldForm
                    title="Field"
                    setField={(field) => setSubconfig({ ...object, field })}
                    field={subconfig.field} />,
            },
            {
                type: "element",
                name: "fields",
                el: <FieldConfigForm
                    fields={subconfig.fields || []}
                    description="What fields to display"
                    useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                    name="fields"
                    title="Fields"
                    setArray={(fields) => setSubconfig({ ...object, fields })} />
            },
            {
                label: "View",
                description: "Which data will be used? SELF is only data on this layer, EXTRAPOLATE is data from parent layers, CHILDREN is data from child layers",
                type: "single-select",
                name: "view",
                options: Object.keys(VIEW).map((key) => ({ label: key, value: VIEW[key] })),
                onchange_callback: ({ setValue, field, value }) => {
                    if (value !== VIEW.EXTRAPOLATE) {
                        setSubconfig({ ...object, [field]: value, filter_field: undefined, filter_location: undefined });
                    } else {
                        setValue(field, value);
                    }
                }
            },
            {
                label: "Fiter field",
                description: "When you extrapolate data from sibling/parent layers, you can filter the data by this field. For example, if you want to display only data from the same size, you can set this field to 'size' and only data of the same size will be shown.",
                type: "text",
                name: "filter_field",
            },
            {
                label: "Filter location",
                type: "single-select",
                name: "filter_location",
                description: "When you extrapolate data from sibling/parent layers, you can filter the data by this location. For example, if you want to extrapolate only data from intake,  then you can set this field to 'intake'.",
                options: Object.keys(LAYER_STAGE).map((key) => ({ label: key, value: LAYER_STAGE[key] }))
            },
            {
                label: "Card type",
                type: "single-select",
                name: "card_type",
                description: "Which card type to show in the calender overview",
                options: Object.keys(CHECK_CARD_TYPE).map((key) => ({ label: key, value: CHECK_CARD_TYPE[key] }))
            },
        ];
        return form.filter((i) => attributes.includes(i.name));
    };


    return <SubConfigForm
        name={name}
        title={title}
        description={description}
        subconfig={subconfig}
        setSubconfig={setSubconfig}
        defaultSubconfig={filteredDefaultConfig}
        getForm={getForm}
    />;
}


