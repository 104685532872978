import { Text, View } from "@react-pdf/renderer";
import { TenantDefect } from "../../../../Forms/useManagedOptionsHook";
import { toPercentage } from "../../fields/formatters";
import { styles } from "./PDFCheckFruitTables";
import { truncateText } from "./utils";

interface Defect {
    defect_id: string;
    frequency: number;
}
interface Avocado {
    defects: Defect[];
}
interface Check {
    defect_list: Defect[];
    avocados: Avocado[];
    sample_size: number;
}
interface DefectGroup {
    defect_position: string[];
    total_defects_title: string;
    decimals?: number;
}

interface PDFContainerDefectsProps {
    defect_group: DefectGroup;
    all_defects: TenantDefect[];
    printChecks: Check[];
}

const PDFContainerDefects: React.FC<PDFContainerDefectsProps> = ({ defect_group, all_defects, printChecks }) => {
    if (!all_defects) return null;

    // calculate total defects and return an array of defect
    const calculateDefects = (checks) => {
        const fruit_defects = checks.reduce((acc, check) => {
            const defects = [
                ...check.defect_list,
                ...check.avocados.flatMap((avocado) => avocado.defects)
            ];

            defects.forEach(({ defect_id, frequency }) => {
                acc[defect_id] = (acc[defect_id] || 0) + frequency;
            });

            return acc;
        }, {});

        return Object.keys(fruit_defects).map((defect_id) => {
            const defect = all_defects.find((defect) => defect.defect_id === defect_id);
            if (defect) {
                const { position, label } = defect;
                return { defect_id, value: fruit_defects[defect_id], position, label };
            }

            return { defect_id, value: fruit_defects[defect_id], position: null, label: null };
        });
    };

    const decimals = defect_group.decimals || 1;

    const sum_defects = calculateDefects(printChecks);

    const getSum = (checks, key) => checks.reduce((acc, check) => acc + Number(key(check)), 0);
    const sample_size_sum = getSum(printChecks, (check) => check.sample_size);

    const defect_percentage = (value) => toPercentage(value, sample_size_sum, decimals);

    const total_defects = sum_defects
        .filter((defect) => defect.position === defect_group.defect_position[0])
        .reduce((acc, defect) => acc + defect.value, 0);

    return (
        <View style={[styles.metaContainer, styles.horizontalMargins]}>
            <View>
                <View style={styles.metaItems}>
                    <View>
                        <Text style={{ fontSize: "9px" }}>{defect_group.total_defects_title}: </Text>
                    </View>
                    <View style={styles.metaTextValueIntakeReport}>
                        <Text>{defect_percentage(total_defects)}</Text>
                    </View>
                </View>
                <View style={styles.divider}></View>
                {sum_defects
                    .filter((defect) => defect.position === defect_group.defect_position[0])
                    .map((defect, index) => (
                        <View key={index} style={styles.metaItems}>
                            <View>
                                <Text style={{ fontSize: "9px" }}>{truncateText(defect.label, 28, 26)}:</Text>
                            </View>
                            <View>
                                <Text>{defect_percentage(defect.value)}</Text>
                            </View>
                        </View>
                    ))}
            </View>
        </View>
    );
};

export default PDFContainerDefects;
