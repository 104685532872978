/* eslint-disable no-case-declarations */
import { createEntityReducer } from "../entityReducer";
import { Entity, EntityState } from "../reducer";
import { CREATE, DELETE, LIST, UPDATE } from "./actionTypes";

export interface User extends Entity{
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    roles: string[];
    teams: string[];
    status: string;
    new_status?: string;
    created: Date
}

export type UserState = EntityState<User>;

export default createEntityReducer<UserState>({ LIST, CREATE, UPDATE, DELETE });
