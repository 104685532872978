/* eslint-disable function-paren-newline */
import { useRef, useCallback } from "react";
import apiClient from "../../constants/apiClient";
import { ROOT_URL } from "../../constants/urls";
import { LAYER_TYPE } from "../../actions/Layers/constants";

/**
 * search for layers
 */
export const searchLayers = (value, type) => {
    return apiClient.get(`${ROOT_URL}api/layers/search/`, {
        params: {
            text: value,
            layer_type: type
        }
    })
        .then((response) => {
            return response.data.map((i) => ({
                ...i, value: i.label, label: i.label, type: i.type
            }));
        })
        .catch((error) => {
            throw new Error(`Could not fetch layers ${error}`);
        });
};


/**
 * Custom hook for debouncing a value change handler.
 *
 * @param setValue - The state change function to set the value immediately.
 * @param onDebounce - The callback function to be called after debounce time.
 * @param debounceTime - The debounce time in milliseconds (default is 1000).
 * @returns The debounced value change handler.
 */
export function useDebounceForObject(
    setValue: (field: string, value: any) => void,
    onDebounce: (field: string, value: any) => void,
    debounceTime = 1000
): (field: string, value: any) => void {
    const debounceTimer = useRef<{ [key: string]: number | undefined }>({});

    const handleValueChange = useCallback((field: string, value: any) => {
        // Immediately dispatch the state change
        setValue(field, value);

        // Clear any previous timers for this field
        if (debounceTimer.current[field]) {
            clearTimeout(debounceTimer.current[field]);
        }

        // Set a new timer for this field
        debounceTimer.current[field] = setTimeout(() => {
            onDebounce(field, value);
        }, debounceTime) as unknown as number;
    }, [onDebounce, setValue, debounceTime]);

    return handleValueChange;
}

export type UseDebouncedReturnType<T extends (...args: any[]) => void> = [(...args: Parameters<T>) => void, (...args: Parameters<T>) => void];

/**
 * Custom hook to debounce a function call.
 *
 * @template T - Type of the value being debounced.
 * @param {Function} setValue - Function to set the value immediately.
 * @param {Function} onDebounce - Function to call after debounce time.
 * @param {Object} options - Options for debounce.
 * @param {number} [options.debounceTime=1000] - Time in milliseconds to debounce.
 * @param {boolean} [options.immediate=false] - Whether to call the function immediately on the leading edge.
 * @returns {Function} - Debounced function.
 */
// eslint-disable-next-line space-before-function-paren, function-paren-newline
export function useDebounce<T extends (...args: any[]) => void>(
    setValue: T,
    onDebounce: T,
    { debounceTime = 1000, immediate = false }: { debounceTime?: number, immediate?: boolean }
): UseDebouncedReturnType<T> {
    const debounceTimer = useRef<number | undefined>();
    const immediateCalled = useRef<boolean>(false);

    const callDebounced = useCallback((...args: Parameters<T>) => {
        // Immediately dispatch the state change
        setValue(...args);

        // Clear any previous timers
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }

        if (immediate && !immediateCalled.current) {
            // If immediate is true and the function hasn't been called immediately yet
            onDebounce(...args);
            immediateCalled.current = true;
        } else {
            // Set a new timer for the trailing edge
            debounceTimer.current = setTimeout(() => {
                onDebounce(...args);
                immediateCalled.current = false; // Reset for the next call
            }, debounceTime) as unknown as number;
        }
    }, [onDebounce, setValue, debounceTime, immediate]);
    const callAsIfWaited = useCallback((...args: Parameters<T>) => {
        // Immediately dispatch the state change
        setValue(...args);

        // Clear any previous timers
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }

        if (!immediateCalled.current) {
            // If immediate is true and the function hasn't been called immediately yet
            onDebounce(...args);
        }
    }, [onDebounce, setValue]);
    return [callDebounced, callAsIfWaited];
}

export type UseDebouncedAsyncReturnType<T extends (...args: any[]) => Promise<any>> = [(...args: Parameters<T>) => Promise<any>, (...args: Parameters<T>) => Promise<any>];

export function useDebounceFunc<T extends(...args: any[]) => any>(
    onDebounce: T,
    { debounceTime = 1000, immediate = false }: { debounceTime?: number, immediate?: boolean }
): UseDebouncedAsyncReturnType<T> {
    const debounceTimer = useRef<number | undefined>();
    const immediateCalled = useRef<boolean>(false);

    const callDebounced = useCallback((...args: Parameters<T>) => {
        return new Promise((resolve) => {
            // Clear any previous timers
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }

            if (immediate && !immediateCalled.current) {
                // If immediate is true and the function hasn't been called immediately yet
                const result = onDebounce(...args);
                immediateCalled.current = true;
                resolve(result);
            } else {
                // Set a new timer for the trailing edge
                debounceTimer.current = setTimeout(() => {
                    const result = onDebounce(...args);
                    resolve(result);
                }, debounceTime) as unknown as number;
            }
        });
    }, [onDebounce, debounceTime, immediate]);
    const callAsIfWaited = useCallback((...args: Parameters<T>) => {
        return new Promise((resolve) => {
            // Clear any previous timers
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }
            const result = onDebounce(...args);
            resolve(result);
        });
    }, [onDebounce]);
    return [callDebounced, callAsIfWaited];
}

export const useSearchLayersDebounced = () => useDebounceFunc((value: string, type?: LAYER_TYPE | null) => searchLayers(value, type), { debounceTime: 500 });
