import AvosModal, { ModalResult } from "./Modal";

export interface ConfirmationModalProps {
    size?: string;
    children: any;
    onClose: (result: ModalResult) => void;
    isOpen: boolean;
}

export function ConfirmationModal({ size, children, isOpen, onClose }:ConfirmationModalProps) {
    return (
        <AvosModal
            size={size}
            header="Confirmation"
            isOpen={isOpen}
            onClose={onClose}
            showCancelButton={true}
            showSkipButton={false}
            showSaveButton={true}
            saveButtonText="Confirm"
        >
            {children}
        </AvosModal>
    );
}
