import { defineAction } from "redux-define";
import { CREATE, ERROR, FILTER, LOADING, SELECT, SUCCESS, TheApp, UNSELECT } from "../../constants/stateConstants";

export const PALLETS = defineAction("PALLETS", [LOADING, ERROR, SUCCESS, SELECT, UNSELECT, CREATE], TheApp); // WHY IS THIS CALLED PALLET???
export const LIST = defineAction("LIST", [FILTER, LOADING, ERROR, SUCCESS], PALLETS);
export const GET = defineAction("GET", [LOADING, ERROR, SUCCESS], PALLETS);
export const UPDATE = defineAction("UPDATE", [LOADING, ERROR, SUCCESS], PALLETS);

export const WASTE = defineAction("WASTE", [LOADING, ERROR, SUCCESS, SELECT, UNSELECT, CREATE], TheApp);
export const POST = defineAction("POST", [LOADING, ERROR, SUCCESS], WASTE);
export const DELETE = defineAction("DELETE", [LOADING, ERROR, SUCCESS], WASTE);

export const ALL_PALLET_NUMBERS = defineAction("ALL_PALLET_NUMBERS", [LOADING, ERROR, SUCCESS], PALLETS);
export const LIST_ALL_PALLET_NUMBERS = defineAction("LIST", [LOADING, ERROR, SUCCESS], ALL_PALLET_NUMBERS);
