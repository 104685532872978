/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faCamera } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import {
    Button, Col,
    Label,
    Modal, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import { toast } from "../../../../utils/toast";
import { Loading } from "../../../Helper/Loading";
import { UploadImageFiles } from "./UploadImageFiles";
import { MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH, checkImageSizeAndShrinkIfNeeded } from "../../image-utils";

// import UploadFiles from "./UploadFiles";

export interface ManualImageProps {
    image: any;
}

export function ManualImage({ image }: ManualImageProps) {
    const placeholder = !image.url_webp;
    const timer = useRef<any>();
    const [isLoaded, setLoaded] = useState(false);
    const [dimensions, setDimensions] = useState([MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT]);
    const [the_time_out, set_time_out] = useState(250);
    const loadImage = () => {
        const _image = new Image();
        _image.crossOrigin = "";
        _image.src = image.url_webp;

        _image.onload = () => {
            setDimensions([_image.width, _image.height]);
            setLoaded(true);
        };
        _image.onerror = () => {
            timer.current = setTimeout(() => loadImage(), get_time_out());
        };
    };


    const get_time_out = () => {
        const new_time_out = the_time_out + 250;
        set_time_out(new_time_out);
        return new_time_out;
    };

    // clear on component unmount
    useEffect(() => () => clearTimeout(timer.current), []);

    //! warning: canvas must be rendered for this to work, hence the ugly .d-none hack
    useEffect(() => {
        if (placeholder) {
            return;
        }
        loadImage();
    }, [placeholder]);
    const padding = (dimensions[1] / dimensions[0]) * 100;

    // !warning: please note that everything is absolute positioned inside the relative div. So we do not have ugly jumps between avocado loads.
    return <div css={css`max-width: 70rem;`} className="w-100">
        <div className="w-100 mx-auto" css={css`background: #f1f1f1; position: relative; padding-bottom: ${padding}%;`} >
            <div css={css`position:absolute; top: 50%; left: 50%; transform: translate(-50%,-50%)`}>
                {!placeholder && !isLoaded && <Loading />}
                {placeholder && <div >
                    <div className="text-center" css={css`opacity: 0.4; font-size: 150%;`}><FontAwesomeIcon icon={faCamera} /></div>
                    <div className="lead text-center text-uppercase d-none d-md-block" css={css`opacity: 0.6; font-size: 0.8rem; font-weight: bold;`}><small>Click to add Image</small></div>
                </div>}
            </div>
            {isLoaded && <img css={css`position:absolute; top: 0; bottom: 0; left: 0; right: 0; `} src={image.url_webp} alt={image.blob_name} className="w-100"></img>}
        </div>
    </div>;
}

export interface ManualImagesProps {
    images: any[];
    required_images: any[];
    saveImage: (arg0: any, arg1: any, arg2: any) => Promise<any>;
    deleteImage: (arg0: any) => void;
    isLoading: boolean;
}

export default function ManualImages({ required_images, saveImage, images, deleteImage, isLoading }: ManualImagesProps) {
    const [modal_image, toggleModal] = useState<any>();
    const [base64, setBase64] = useState<string>();
    const _saveImage = (base64, focusMode) => saveImage(modal_image!.type, base64, focusMode).then((response) => {
        if (!response?.error) {
            setBase64(undefined);
        }
    });
    const imageWrapperStyle = css`cursor:pointer; width: 30%; min-width: 6rem; max-width: 10rem;`;
    return (
        <div>
            <div>{required_images.map((req_image, index) => <Row className={`${index > 0 ? "mb-3" : "mb-3"}`} key={req_image.type} >
                <Col>
                    <Label>{String(req_image.label)}</Label>
                    <div className="d-flex flex-wrap">
                        {images.filter((x) => req_image.type === x.type).map((x, index) => <div
                            key={index}
                            css={imageWrapperStyle}
                            onClick={() => toggleModal(x)}
                            className="pt-2 me-3">
                            <ManualImage image={x} />
                        </div>)}
                        <div css={imageWrapperStyle} onClick={() => toggleModal(req_image)} >
                            <ManualImage image={({})} />
                        </div>
                    </div>
                </Col>
            </Row>)}
            {modal_image && <ManualImageModal
                images={images}
                saveImage={_saveImage}
                deleteImage={deleteImage}
                modal_image={modal_image}
                required_image={required_images.find((x) => x.type === modal_image.type)}
                toggleModal={toggleModal}
                setBase64={setBase64}
                isLoading={isLoading}
                base64={base64} />}
            </div>

        </div>
    );
}

export interface ManualImageModalProps {
    images: any[];
    modal_image: any;
    required_image: any;
    toggleModal: (arg0: any) => void;
    saveImage: (base64: string | undefined, focusMode: any) => Promise<any>;
    deleteImage: (arg0: any) => void;
    setBase64: (arg0: string | undefined) => void;
    base64: string | undefined;
    isLoading: boolean;
}

export function ManualImageModal({
    toggleModal, required_image, modal_image, saveImage, deleteImage, setBase64, base64, images, isLoading
}: ManualImageModalProps) {
    const webcamRef = React.useRef<Webcam | null>(null);
    const [focusMode, setFocusMode] = useState({
        browser: [], capabilities: [], constraint: "", user_agent: "",
    });
    const [videoConstraints, setVideoConstraints] = useState({
        facingMode: "environment",
        focusMode: "continuous",
        width: MAX_IMAGE_WIDTH,
        height: MAX_IMAGE_HEIGHT,
    });
    const capture = async () => {
        const { video } = webcamRef.current!;
        if (video) {
            const base64 = webcamRef.current!.getScreenshot()!;
            const resizedBase64 = await checkImageSizeAndShrinkIfNeeded(base64, video.videoWidth, video.videoHeight);
            setBase64(resizedBase64);
        }
    };
    const captureAndContinue = async () => {
        const { video } = webcamRef.current!;
        if (video) {
            const base64 = webcamRef.current!.getScreenshot()!;
            const resizedBase64 = await checkImageSizeAndShrinkIfNeeded(base64, video.videoWidth, video.videoHeight);
            setBase64(resizedBase64);
            saveImage(resizedBase64, focusMode).then(() => {
                // Toast to alert the user that image has been saved
                toast.success(`Photo successfully saved.`, {
                    toastId: "upload-image",
                    autoClose: 3500,
                });

            }).catch(() => {
                // Toast to alert the user that image has been saved
                toast.error(`Failed to save Image`, {
                    toastId: "upload-image",
                    autoClose: 3500,
                });
            });
        }
    };
    const [actionType, setActionType] = useState("camera");

    const onCameraError = (error) => {
        // console.error("onCameraError", error);
        toast.error(`Failed to start camera: (${error.name}:${error.message}). Please stop any application that is using your camera.`, {
            toastId: "create-location",
            autoClose: 3500,
        });
    };

    const logCapabilities = (media) => {
        const SupportedConstraints = navigator.mediaDevices.getSupportedConstraints();
        // console.log("getSupportedConstraints by browser", SupportedConstraints)
        const focusModeStatus = {
            browser: (SupportedConstraints as any).focusMode, capabilities: [], constraint: "", user_agent: navigator.userAgent,
        };
        if (media.getVideoTracks) {
            const tracks = media.getVideoTracks();
            // console.log("tracks", tracks);
            if (tracks && tracks.length > 0) {
                const track = tracks[0];
                if (track.getCapabilities) {
                    const capabilities = track.getCapabilities();
                    // console.log("capabilties", capabilities)
                    focusModeStatus.capabilities = capabilities.focusMode;
                }
                if (track.getSettings) {
                    const settings = track.getSettings();
                    // console.log("settings", settings)
                    focusModeStatus.constraint = settings.focusMode || "";
                }
                setFocusMode(focusModeStatus);
                // console.log(focusModeStatus)
            }
        }
    };

    const _deleteImage = () => {
        deleteImage(modal_image);
        setBase64(undefined);
        setActionType("managing-files");
    };

    // activate camera if there is no existing image object in our DB and there is no preview base64 available to be uploaded
    const activate_camera = !modal_image.url_webp && !base64;

    let img;
    if (modal_image.url_webp) {
        img = <ManualImage image={modal_image} />;
    } else {
        img = <img onClick={() => setBase64(undefined)} src={base64} className="h-100" alt={`Preview ${required_image.label}`} />;
    }
    const onUploadImage = async (base64, focusMode) => {
        // resize the uploaded image
        if (base64) {
            // get ratio from base64
            const img = new Image();
            img.src = base64;
            await new Promise((resolve) => { img.onload = resolve; });
            base64 = await checkImageSizeAndShrinkIfNeeded(base64, img.width, img.height);
        }
        saveImage(base64, focusMode);
    };
    return (
        <>
            <Modal isOpen={modal_image.type !== false} className="modal-xl" fullscreen={true} >
                <ModalHeader toggle={() => toggleModal(false)} >{required_image.label}</ModalHeader>
                <ModalBody>
                    {actionType === "camera" && <div className="d-flex align-items-middle justify-content-center h-100">
                        {!activate_camera && img}
                        {activate_camera
                            && <Webcam
                                onClick={() => capture()}
                                onUserMediaError={onCameraError}
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/webp"
                                forceScreenshotSourceSize={true}
                                onUserMedia={logCapabilities}
                                imageSmoothing={false}
                                videoConstraints={videoConstraints}
                                className="h-100"
                            />
                        }
                    </div>
                    }
                    {actionType === "managing-files" && <UploadImageFiles setActionType={setActionType} isLoading={isLoading} saveImage={onUploadImage} images={images} deleteImage={deleteImage} />}
                </ModalBody>

                {actionType === "camera" && <ModalFooter>
                    <Button color="secondary" onClick={() => setActionType("managing-files")}>Go to files</Button>
                    {modal_image.url_webp && <Button color="danger" onClick={() => _deleteImage()}>Delete</Button>}
                    {activate_camera
                        && <Button color="primary" onClick={() => captureAndContinue()}>Capture</Button>
                    }
                    {activate_camera
                        && <Button color="secondary" onClick={() => setVideoConstraints({ ...videoConstraints, facingMode: videoConstraints.facingMode === "environment" ? "user" : "environment" })} >
                            <FontAwesomeIcon icon={["far", "redo"]} /> <FontAwesomeIcon className="" icon={["far", "video"]} />
                        </Button>
                    }
                </ModalFooter>}
            </Modal>
        </>
    );
}
