import { AVOCADO_FRUIT } from "../fruitNames";
import { avocado_min_weight } from "../../../Layers/constants";
import {
    baseConfig,
    default_location_config,
    headerLinks
} from "../constants";
import { RootConfigType } from "../constantsTyped";
import { createBarcodeFilter } from "../utils";


const required_fruit_images = [
    {
        type: "fruit_overview",
        label: "Fruit",
    },
];


export const MehadrinConfig: RootConfigType = {
    ...baseConfig,

    required_fruit_images,
    summary_collapsed: false,
    default_fruit_type: AVOCADO_FRUIT,
    can_do_manual_check: true,
    default_location_config: { ...default_location_config, device_result_action: false },
    default_manual: true,
    strict_check_size: false,
    show_atron_pressure_range_kg: true,
    indicate_soft_avocado_at_internal: false,
    suggest_color_stage: true,
    underweight: avocado_min_weight,
    default_location: "ripening",

    research_mode: false,
    invalidate_20x_firmness: false,

    headerMainLinks: [headerLinks.home, headerLinks.packinglist],
    headerSecondaryLinks: [headerLinks.packinglist, headerLinks.settings, headerLinks.logout],
    barcode_filter: createBarcodeFilter([
        // Regular expression to match a string consisting of exactly 20 digits
        // Get the 20 digits as a group
        /^(\d{20})$/,
        // Regular expression to match a string starting with 'P' followed by exactly 7 digits
        // Get the 7 digits as a group
        /^P(\d{7})$/,
        // -----------------------------Yellow Barcodes---------------------------------------
        // Regular expression to match a string starting with 'D' followed by exactly 10 digits
        // Get the whole string including the 'D' and the 10 digits as a group
        /^D(\d{10})$/,
        // Regular expression to match a string consists of exactly 18 digits
        // Get the 18 digits as a group
        /^(\d{18})$/,
        // Regular expression to match a string consists of exactly 14 characters including digits
        // Get the 14 characters as a group
        /^(\w{14})$/,
        // Regular expression to match a string consists of exactly 8 digits
        // Get the 8 digits as a group
        /^(\d{8})$/,
    ]),
};
