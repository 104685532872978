/* eslint-disable no-case-declarations */
import { DELETE, LIST, UPDATE, CREATE } from "./actionTypes";
import { createEntityReducer } from "../entityReducer";
import { Entity, EntityState } from "../reducer";

export interface Grower extends Entity{
    label: string;
    name: string;
    country: string;
    fruit_types: string[];
    ggn: string;
}

export type GrowerState = EntityState<Grower>;

export default createEntityReducer<GrowerState>({ LIST, CREATE, UPDATE, DELETE });
