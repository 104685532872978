/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { Badge } from "reactstrap";
import { DeviceEventStatusColor } from "../../actions/Devices/constants";

export default function DeviceEventStatusBadge({ event }) {
    return <Badge color={DeviceEventStatusColor[event.status]} size="sm">{event.status}</Badge>;
}


DeviceEventStatusBadge.propTypes = {
    event: PropTypes.object,

};
