import { defineAction } from "redux-define";
import {
    ERROR, FILTER, LOADING, RESET,
    STATE,
    SUCCESS,
    TheApp
} from "../../constants/stateConstants";

export const checks = defineAction("checks", [LOADING, ERROR, SUCCESS], TheApp);
export const LIST = defineAction("LIST", [FILTER, LOADING, ERROR, SUCCESS, RESET], checks);
export const GET = defineAction("GET", [LOADING, ERROR, SUCCESS], checks);
export const CREATE = defineAction("CREATE", [LOADING, ERROR, SUCCESS], checks);
export const UPDATE = defineAction("UPDATE", [LOADING, ERROR, SUCCESS], checks);
export const PATCH = defineAction("PATCH", [LOADING, ERROR, SUCCESS, STATE], checks);
export const DELETE = defineAction("DELETE", [LOADING, ERROR, SUCCESS], checks);
