/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { Layer } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { isFilledArray, matchesPattern } from "../../../../utils";
import LayerAutoComplete from "../../../Forms/LayerAutoComplete";
// import QRCodeScanner from "../../../Forms/QRCodeScanner";
import apiClient from "../../../../constants/apiClient";
import { ROOT_URL } from "../../../../constants/urls";
import { toast } from "../../../../utils/toast";
import QRCodeScan from "../../../Forms/QRCodeScan";
import { RadioButtons } from "../../../Forms/RadioButton";
import { Loading } from "../../../Helper/Loading";

interface CreateLayerModalProps {
    onClose: () => void;
    onSubmit: () => void;
    isOpen: { withScanner: boolean} | false;
    disableSaveButton: boolean;
    setValue: (field: string, value: any) => void;
    layer: Layer;
    setLayerSelect: (layer: Layer | Layer[]) => void;
  }

export function CreateLayerModal({
    onClose, onSubmit, isOpen, disableSaveButton, setValue, layer, setLayerSelect
}: CreateLayerModalProps) {
    const config = useConfig();

    const layer_config = config.get_layer_config(layer);
    const { label_format, invalid_hint } = layer_config;
    const [showFormNotScanner, setShowFormNotScanner] = useState(false);
    const [searchingForScannedLabel, setSearchingForScannedLabel] = useState(false);
    const navigate = useNavigate();
    // When creating layers...check if the label matches the format
    const labelFormatInvalid = label_format && !matchesPattern(layer?.label, label_format);

    const handleScan = (result) => {
        goToScannedLayer(result);
        setValue("label", result);
    };
    useEffect(() => {
        // * Reset the label field only when closed
        // * Dont remove this, as isOpen changes when you use the dropdown box
        if (isOpen) {
            return;
        }

        setValue("label", "");
        setSearchingForScannedLabel(false);
        setShowFormNotScanner(false);
    }, [isOpen]);

    const goToScannedLayer = (label) => {
        setSearchingForScannedLabel(true);
        apiClient
            .get(`${ROOT_URL}api/layers/search-label/`, {
                params: {
                    label,
                }
            }).then((response) => {
                if (response.data.layer_id) {
                    navigate(`/layer/${response.data.layer_id}`);
                }
                setSearchingForScannedLabel(false);
            }).catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
                toast.error(`Could not search for ${label}`);
                setSearchingForScannedLabel(false);
            });
    };


    const creatableLayerTypes = config.layer_types && config.layer_types.filter((i) => i.is_creatable !== false);

    const scannerIsOpen = config.use_barcode_scanner && isOpen && isOpen.withScanner && !showFormNotScanner && !layer.label && !searchingForScannedLabel;

    const retryScanner = () => {
        setShowFormNotScanner(false);
        setValue("label", "");
    };

    return (
        <Modal isOpen={!!isOpen} size="md">
            <ModalHeader toggle={() => () => onClose()} onClick={() => onClose()}>Scan label</ModalHeader>
            <ModalBody>
                {searchingForScannedLabel && <Loading />}
                {scannerIsOpen && (
                    <div className="pb-2">
                        <QRCodeScan
                            onScan={handleScan}
                            barcode_filter={config.barcode_filter}
                        />
                    </div>
                )}
                { !scannerIsOpen && isFilledArray(creatableLayerTypes) && <div className="pb-2">
                    <FormGroup>
                        <Label >Type</Label>
                        <div>
                            <RadioButtons color="secondary" name="type" current={layer.type!} options={creatableLayerTypes} onChange={setValue}></RadioButtons>
                        </div>
                    </FormGroup>
                </div>
                }
                { !scannerIsOpen && <div className="pb-2">
                    <LayerAutoComplete
                        multi={false}
                        type={layer.type}
                        layer={layer}
                        setLayer={setLayerSelect}
                        label_format={label_format}
                        invalid_hint={invalid_hint} />
                </div> }
            </ModalBody>
            <ModalFooter>
                {!scannerIsOpen && <Button color="light" className="me-auto" onClick={() => retryScanner()}>Retry Scan</Button>}
                {scannerIsOpen && <Button color="light" className="me-auto" onClick={() => setShowFormNotScanner(true)}>Enter Manually</Button>}
                <Button color="light" onClick={() => onClose()}>Cancel</Button>
                {!scannerIsOpen && <Button disabled={disableSaveButton || labelFormatInvalid} color="success" onClick={() => onSubmit()}>Save</Button> }
            </ModalFooter>
        </Modal>

    );
}


