/** @jsxImportSource @emotion/react */
import { Badge } from "reactstrap";
import { LAYER_STAGE } from "../../../../actions/Layers/constants";
import { Stage } from "../../../../actions/Tenants/config/constantsTyped";
import Field, { DataSource } from "./Field";

/*
    This field renders a badge for each stage of a layer.
*/
class LayerStagesColorBadgeField extends Field {
    field_type = "layer_stages_color_badge_field";

    badges: Stage[] = [];

    backend_table = "layer_meta";

    get_backend_fieldname(): string[] {
        return Object.keys(LAYER_STAGE).map((i) => i.toLowerCase()).map((i) => `${this.backend_table}.${i}_status`);

    }


    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        const { stages } = datasource.config;
        this.badges = stages.filter((i: Stage) => i.layer_type && !i.hide_color_code).map((i) => ({ ...i, status_color: datasource.layer[`${i.value}_status`] }));
        this.value = stages.map((i) => `${i.text}: ${i.status_color}`).join(", ");
        return this;
    }

    display_component(): JSX.Element {
        return <span>{this.badges.map((i) => <Badge key={i.value} size="sm" className="me-1" color={i.status_color}>{i.status_letter}</Badge >)}</span>;
    }

}

export default LayerStagesColorBadgeField;


