import { AnyAction } from "redux";
import { ROOT_URL } from "../../constants/urls";
import { LIST } from "./actionsTypes";

export const getNotifications = (filter: { offset: number, limit: number, layer_id?: string | null }): AnyAction => {
    const layerIdFilter = filter.layer_id ? `&layer_id=${filter.layer_id}` : "";
    return {
        types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${ROOT_URL}api/notification/?limit=${filter.limit}&offset=${filter.offset}${layerIdFilter}`,
            },
        },
    } as unknown as AnyAction;
};

export const updateFilter = (filter) => ({
    type: LIST.FILTER,
    payload: filter,
});
