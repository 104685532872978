import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const isOffline = (device) => {
    const device_time_utc_ms = dayjs(device.last_seen).utcOffset(0, true);
    const current_time_utc_ms = dayjs();
    const delay = current_time_utc_ms - device_time_utc_ms;
    return !device.last_seen || delay >= (5 * 60 * 1000);
};


export const isMoreThanTwoWeeks = (item) => {
    const itemTimeStamp = item.last_seen;
    const current_time = new Date();
    const item_time = new Date(itemTimeStamp);
    const time_difference = current_time - item_time;
    return time_difference >= (14 * 24 * 3600 * 1000);
};
