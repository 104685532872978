/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
    Col, Label, Row
} from "reactstrap";
import useConfig from "../../../../actions/Tenants/config/configHook";
import NumericInputField from "../../../Forms/NumericInputField";
import { Loading } from "../../../Helper/Loading";

import {
    path_ask_quality,
    path_brix_input, path_color_check, path_dry_matter,
    path_durometer,
    path_external_quality_check,
    path_fruit_images,
    path_fta_input,
    path_has_internal_quality_check,
    path_internal_color_check, path_internal_quality_check,
    path_maturity,
    path_penetrometer,
    path_pit_size,
    path_plu,
    path_pressure_kg,
    path_pressure_lbs,
    path_taste,
    path_temperature, path_weight_input, path_weight_input_kg
} from "../../../../actions/Tenants/config/constants";
// import { useEffectOnce } from "../../../../utils/useEffectOnce";
import { RadioButtonManaged } from "../../../Forms/SelectManaged";
import usePatchAvocado from "../usePatchAvocado";
import { RequiredIcon } from "./BulkFTAInput";
// import { InValidModal } from "./InvalidModal";
import { AskQualityCheck, ColorCheckInputField, QualityCheckButtons } from "./QualityCheck";
import FruitImages from "./Summary/FruitImages";

/**
 * @deprecated
 * Use ManualForm from frontend/src/components/scenes/AddItem/components/ManualFruitForm.tsx instead
 * This form is build based on `path_ something something`, instead use the new component that uses MetaForm
 */
export function DeprecatedManualForm({ avocado }) {
    const config = useConfig();
    const check = useSelector((state) => state.checks.current);
    const layer = useSelector((state) => state.layers.current);
    const location = config.get_location(layer, check);
    const user = useSelector((state) => state.auth.user);
    const patchAvocado = usePatchAvocado();

    const inputFields = {
        [path_external_quality_check]: {
            label: "External Defects",
            input: (key) => <QualityCheckButtons key={key + avocado.avocado_id} internal={false} avocado={avocado} />,
        },
        [path_internal_quality_check]: {
            label: "Internal Defects",
            input: (key) => <QualityCheckButtons key={key + avocado.avocado_id} internal={true} avocado={avocado} />,
        },
        [path_color_check]: {
            label: "Color",
            input: (key) => <ColorCheckInputField
                key={key + avocado.avocado_id}
                avocado={avocado}
                field="manual_color_stage"
                variety_colors_key="external_colors" />,
        },
        [path_internal_color_check]: {
            label: "Internal Color",
            input: (key) => <ColorCheckInputField
                key={key + avocado.avocado_id}
                avocado={avocado}
                field="manual_internal_color_stage"
                variety_colors_key="internal_colors" />,
        },
        [path_fta_input]: {
            label: "Pressure",
            input: (key) => (
                <Row>
                    {!user.tenant.has_two_fta && <Col lg="3">
                        <NumericInputField
                            key={key + avocado.avocado_id}
                            field="pressure"
                            db_value={avocado.pressure}
                            min={0}
                            max={100}
                            unity={user.tenant.uses_kg ? "KG" : "LBS"}
                            setValue={(_, value) => patchAvocado(avocado, "pressure", value)}
                        />
                    </Col>}
                    {user.tenant.has_two_fta && <Col lg="3">
                        <NumericInputField
                            key={key + avocado.avocado_id}
                            field="pressure_1"
                            db_value={avocado.pressure_1}
                            min={0}
                            max={100}
                            unity={user.tenant.uses_kg ? "KG" : "LBS"}
                            setValue={(_, value) => patchAvocado(avocado, "pressure_1", value)}
                        />
                    </Col>}
                    {user.tenant.has_two_fta && <Col lg="3">
                        <NumericInputField
                            key={key + avocado.avocado_id}
                            field="pressure_2"
                            db_value={avocado.pressure_2}
                            min={0}
                            max={100}
                            unity={user.tenant.uses_kg ? "KG" : "LBS"}
                            setValue={(_, value) => patchAvocado(avocado, "pressure_2", value)}
                        />
                    </Col>}
                </Row>
            ),
        },
        [path_taste]: {
            input: (key) => <RadioButtonManaged key={key + avocado.avocado_id} avocado={avocado}
                setValue={(_, value) => patchAvocado(avocado, "manual_taste", value)}
                object={avocado}
                post_value={avocado.manual_taste}
                options_name={`manual_taste_${layer.fruit_type}`}
                extra_context={{ fruit_type: layer.fruit_type }}
                name="manual_taste"
                label="Taste"
                tenant_id={user.tenant.tenant_id} />,
        },
        [path_weight_input]: {
            label: "Weight",
            input: (key) => <Row>
                <Col lg={3}>
                    <NumericInputField
                        key={key + avocado.avocado_id}
                        field="weight"
                        db_value={avocado.weight}
                        setValue={(_, value) => patchAvocado(avocado, "weight", value)}
                        min={0}
                        max={9999}
                        unity="GR" />
                </Col>
            </Row>,
        },
        [path_weight_input_kg]: {
            label: "Weight",
            input: (key) => <Row>
                <Col lg={3}>
                    <NumericInputField
                        key={key + avocado.avocado_id}
                        field="weight"
                        db_value={avocado.weight}
                        setValue={(_, value) => patchAvocado(avocado, "weight", value)}
                        min={0}
                        max={9999}
                        unity="KG" />
                </Col>
            </Row>,
        },
        [path_pressure_lbs]: {
            label: "Pressure",
            input: (key) => <Row>
                <Col lg={3}>
                    <NumericInputField
                        key={key + avocado.avocado_id}
                        field="pressure"
                        db_value={avocado.pressure}
                        setValue={(_, value) => patchAvocado(avocado, "pressure", value)}
                        min={0}
                        unity="LBS" />
                </Col>
            </Row>,
        },
        [path_pressure_kg]: {
            label: "Pressure",
            input: (key) => <Row>
                <Col lg={3}>
                    <NumericInputField
                        key={key + avocado.avocado_id}
                        field="pressure"
                        db_value={avocado.pressure}
                        setValue={(_, value) => patchAvocado(avocado, "pressure", value)}
                        min={0}
                        max={100}
                        unity="KG" />
                </Col>
            </Row>,
        },
        [path_brix_input]: {
            label: "Brix",
            input: (key) => <Row>
                <Col lg={3}>
                    <NumericInputField
                        key={key + avocado.avocado_id}
                        field="manual_brix"
                        db_value={avocado.manual_brix}
                        setValue={(_, value) => patchAvocado(avocado, "manual_brix", value)}
                        unity="%"
                        min={0}
                        max={100}
                    />
                </Col>
            </Row>,
        },
        [path_dry_matter]: {
            label: "Dry Matter",
            input: (key) => <Row>
                <Col lg={3}>
                    <NumericInputField
                        key={key + avocado.avocado_id}
                        field="manual_dry_matter"
                        db_value={avocado.manual_dry_matter}
                        setValue={(_, value) => patchAvocado(avocado, "manual_dry_matter", value)}
                        min={0}
                        max={100}
                        unity="%" />
                </Col>
            </Row>,
        },
        [path_temperature]: {
            label: "Temperature",
            input: (key) => <Row>
                <Col lg={3}>
                    <NumericInputField
                        key={key + avocado.avocado_id}
                        field="manual_temperature"
                        db_value={avocado.manual_temperature}
                        setValue={(_, value) => patchAvocado(avocado, "manual_temperature", value)}
                        min={-100}
                        max={100}
                        unity="°C" />
                </Col>
            </Row>,
        },
        [path_penetrometer]: {
            label: "Penetrometer",
            input: (key) => (
                <Row>
                    <Col lg="3">
                        <NumericInputField
                            key={key + avocado.avocado_id}
                            field="pressure_1"
                            db_value={avocado.pressure_1}
                            min={0}
                            max={100}
                            unity="KG"
                            setValue={(_, value) => patchAvocado(avocado, "pressure_1", value)}
                        />
                    </Col>
                    <Col lg="3">
                        <NumericInputField
                            key={key + avocado.avocado_id}
                            field="pressure_2"
                            db_value={avocado.pressure_2}
                            min={0}
                            max={100}
                            unity="KG"
                            setValue={(_, value) => patchAvocado(avocado, "pressure_2", value)}
                        />
                    </Col>
                    <Col css={css`padding-top: 7px;`}>
                        <RequiredIcon status={avocado.pressure_1 > 0 && avocado.pressure_2 > 0} />
                    </Col>
                </Row>
            ),
        },
        [path_durometer]: {
            label: "Durometer",
            input: (key) => (
                <Row>
                    <Col lg="3">
                        <NumericInputField
                            key={key + avocado.avocado_id}
                            field="durometer_pressure_1"
                            db_value={avocado.durometer_pressure_1}
                            min={0}
                            max={100}
                            unity="KG"
                            setValue={(_, value) => patchAvocado(avocado, "durometer_pressure_1", value)}
                        />
                    </Col>
                    <Col lg="3">
                        <NumericInputField
                            key={key + avocado.avocado_id}
                            field="durometer_pressure_2"
                            db_value={avocado.durometer_pressure_2}
                            min={0}
                            max={100}
                            unity="KG"
                            setValue={(_, value) => patchAvocado(avocado, "durometer_pressure_2", value)}
                        />
                    </Col>
                    <Col css={css`padding-top: 7px;`}>
                        <RequiredIcon status={avocado.durometer_pressure_1 > 0 && avocado.durometer_pressure_2 > 0} />
                    </Col>
                </Row>
            ),
        },
        [path_pit_size]: {
            label: "Pit",
            input: (key) => (
                <Row>
                    <Col lg="3">
                        <NumericInputField
                            key={key + avocado.avocado_id}
                            field="pit_a"
                            db_value={avocado.pit_a}
                            min={0}
                            max={9999}
                            unity="MM"
                            setValue={(_, value) => patchAvocado(avocado, "pit_a", value)}
                        />
                    </Col>
                    <Col lg="3">
                        <NumericInputField
                            key={key + avocado.avocado_id}
                            field="pit_b"
                            db_value={avocado.pit_b}
                            min={0}
                            max={9999}
                            unity="MM"
                            setValue={(_, value) => patchAvocado(avocado, "pit_b", value)}
                        />
                    </Col>
                    <Col lg="3">
                        <NumericInputField
                            key={key + avocado.avocado_id}
                            field="pit_c"
                            db_value={avocado.pit_c}
                            min={0}
                            max={9999}
                            unity="MM"
                            setValue={(_, value) => patchAvocado(avocado, "pit_c", value)}
                        />
                    </Col>

                </Row>
            ),
        },
        [path_has_internal_quality_check]: {
            label: "Internal Defects",
            input: (key) => <QualityCheckButtons key={key + avocado.avocado_id} internal={true} avocado={avocado} />,
        },
        [path_ask_quality]: {
            input: (key) => <AskQualityCheck key={key + avocado.avocado_id} avocado={avocado} />,
        },
        [path_fruit_images]: {
            input: (key) => <FruitImages key={key + avocado.avocado_id} avocado_id={avocado.avocado_id} />,
        },
        [path_plu]: {
            input: (key) => <RadioButtonManaged key={key + avocado.avocado_id} avocado={avocado}
                setValue={(_, value) => patchAvocado(avocado, "plu", value)}
                object={avocado}
                post_value={avocado.plu}
                options_name={`plu_${layer.fruit_type}`}
                extra_context={{ fruit_type: layer.fruit_type }}
                name="plu"
                label="PLU"
                tenant_id={user.tenant.tenant_id} />,
        },
        [path_maturity]: {
            input: (key) => <RadioButtonManaged key={key + avocado.avocado_id} avocado={avocado}
                label="Maturity"
                setValue={(_, value) => patchAvocado(avocado, "manual_maturity", value)}
                object={avocado}
                post_value={avocado.manual_maturity}
                name="manual_maturity"
                extra_context={{ fruit_type: layer.fruit_type }}
                options_name={`manual_maturity_${layer.fruit_type}`}
                tenant_id={user.tenant.tenant_id} />,
        },
    };
    const fields = location.inline_form || location.flow;
    if (!fields) {
        return <Loading />;
    }
    return (
        <>
            {avocado.avocado_id > 0 && fields.map((i) => (i in inputFields ? <div key={i} className="pb-3">
                <Label>{inputFields[i].label}</Label>
                {inputFields[i].input(i)}
            </div> : null))}
        </>
    );
}

DeprecatedManualForm.propTypes = {
    avocado: PropTypes.object,
};
