import { defineAction } from "redux-define";
import {
    ERROR,
    FILTER,
    LOADING,
    SUCCESS,
    TheApp
} from "../../constants/stateConstants";


export const facilityLocation = defineAction("facilityLocation", [LOADING, ERROR, SUCCESS], TheApp);
export const LIST = defineAction("LIST", [LOADING, ERROR, SUCCESS, FILTER], facilityLocation);
export const CREATE = defineAction("CREATE", [LOADING, ERROR, SUCCESS], facilityLocation);
export const GET = defineAction("GET", [LOADING, ERROR, SUCCESS], facilityLocation);
export const GET_DATE_CONDITION = defineAction("GET_DATE_CONDITION", [LOADING, ERROR, SUCCESS], facilityLocation);
export const GET_LAYERS = defineAction("GET_LAYERS", [LOADING, ERROR, SUCCESS], facilityLocation);
export const GET_CHECKS = defineAction("GET_CHECKS", [LOADING, ERROR, SUCCESS], facilityLocation);
export const GET_CONDITIONS = defineAction("GET_CONDITIONS", [LOADING, ERROR, SUCCESS], facilityLocation);
