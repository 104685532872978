import { defineAction } from "redux-define";
import {
    LOADING, ERROR, SUCCESS, FILTER, TheApp,
} from "../../constants/stateConstants";

export const labeling = defineAction("labeling", [LOADING, ERROR, SUCCESS], TheApp);
export const LIST = defineAction("LIST", [FILTER, LOADING, ERROR, SUCCESS], labeling);
export const CREATE_SESSION = defineAction("CREATE_SESSION", [LOADING, ERROR, SUCCESS], labeling);
export const GET_RELABELING_SESSIONS = defineAction("GET_RELABELING_SESSIONS", [LOADING, ERROR, SUCCESS], labeling);
export const GET_RELABELING_SESSION_DETAIL = defineAction("GET_RELABELING_SESSION_DETAIL", [LOADING, ERROR, SUCCESS], labeling);
export const DEFECTS_STAT = defineAction("DEFECTS_STAT", [LOADING, ERROR, SUCCESS], labeling);
export const DEFECT_UPDATE = defineAction("DEFECT_UPDATE", [LOADING, ERROR, SUCCESS], labeling);
export const GET_NEW_BATCH = defineAction("GET_NEW_BATCH", [LOADING, ERROR, SUCCESS], labeling);
export const RESET_RELABELING = defineAction("RESET_RELABELING", [SUCCESS], labeling);
