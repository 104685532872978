/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "reactstrap";
import { patchAvocado, postDefect } from "../../../../actions/Avocados/actions";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { useManagedOptionsHook } from "../../../Forms/useManagedOptionsHook";
import { Loading } from "../../../Helper/Loading";
import usePatchAvocado from "../usePatchAvocado";
import { AvocadoIndex } from "./Images";
import Box from "./Layout";


/**
 * @deprecated
 * This is still being used by the legacy fruit form. Instead urge the business consultant to use the fruit_form component
 * This form is build based on `path_ something something`, instead use the new component that uses MetaForm
 */
export function QualityCheckButtons(props) {
    const dispatch = useDispatch();
    const { avocado } = props;
    const config = useConfig();
    const layer = useSelector((state) => state.layers.current);
    // const currentTenant = useSelector((state) => state.auth.user.tenant);
    // const { tenant_id } = currentTenant;
    const isLoading = useSelector((state) => state.tenants.isLoading);

    const options = useManagedOptionsHook({ object: { ...layer, defect_position: [props.internal ? "internal" : "external"] }, name: "defects" });

    const postFruitDefect = (dispatch, avocado, defect) => {
        dispatch(postDefect({ fruit_id: avocado.avocado_id, test_id: avocado.test_id, ...defect }));
    };

    const _toggleDefect = (avocado, defect) => {
        // update the avocado state so we do not have to wait for the backend to update
        const defects = avocado.defects.some((x) => x.defect_id === defect.defect_id) ? avocado.defects.filter((x) => x.defect_id !== defect.defect_id) : [...avocado.defects, { defect_id: defect.defect_id }];
        const frequency = defects.length > avocado.defects.length ? 1 : 0;
        // make the request
        dispatch(patchAvocado(avocado.avocado_id, "defects", defects));
        postFruitDefect(dispatch, avocado, { defect_id: defect.defect_id, frequency });
    };
    const use_median = props.internal && config.indicate_soft_avocado_at_internal;


    if (isLoading) return <Loading></Loading>;

    // Encountering a false negative here, early in the rendering process.
    // try switching fruit at manaul form, you will see below message flickering, I can not solve it for now
    // a cookie for the one who does
    if (options.length === 0) return <div className="text-warning">No defects configured for {layer.fruit_type}</div>;

    return <div className="d-flex flex-wrap align-items-center" >
        {
            options.map((defect) => (
                <div key={defect.value} className="p-1">
                    <Button
                        size="sm"
                        className="w-100"
                        color={avocado.defects.some((x) => x.defect_id === defect.value) ? "warning" : "secondary"}
                        disabled={(avocado.isLoading === defect.value) || (use_median && avocado.atron_pressures_median >= config.indicate_soft_avocado_at_internal)}
                        outline={!avocado.defects.some((x) => x.defect_id === defect.value)}
                        onClick={() => _toggleDefect(avocado, defect)} >
                        {defect.label}
                    </Button>
                </div>))
        }
    </div>;
}

QualityCheckButtons.propTypes = {
    avocado: PropTypes.object,
    internal: PropTypes.bool,
};

export function ExternalColorCheck() {
    const isLoading = useSelector((state) => state.checks.isLoading);
    const config = useConfig();
    const check = useSelector((state) => state.checks.current);
    const canSubmit = check.avocados.filter((i) => i.manual_color_stage > 0).length === check.avocados.length;
    const patchAvocado = usePatchAvocado();
    useEffect(() => {
        if (config.suggest_color_stage) {
            check.avocados.forEach((i) => {
                if (!i.manual_color_stage) {
                    patchAvocado(i, "manual_color_stage", config.translate_atron_color_to_manual_color_stage(i.atron_color_stage));
                }
            });
        }
    }, [config.suggest_color_stage]);
    return (
        <Box headerTitle={config.title()} canSubmit={canSubmit} >
            {isLoading && <Loading></Loading>}
            {!isLoading && <ExternalColorCheckButtons />}
        </Box>
    );
}

function ExternalColorCheckButtons() {
    const config = useConfig();
    const check = useSelector((state) => state.checks.current);
    const layer = useSelector((state) => state.layers.current);

    return <Table size="sm" responsive={true} borderless={false}>
        <thead>
            <tr className="align-middle">
                <th className="text-muted text-uppercase text-start">{layer.fruit_type}</th>
                {config.suggest_color_stage && <th className="text-muted text-uppercase text-start " css={css`width: 13rem;`}>Color Stage</th>}
                <th className="text-muted text-uppercase text-start">Color stage</th>
            </tr>
        </thead>
        <tbody>
            {check.avocados.map((a, index) => <tr key={index}>
                <td className="text-start text-uppercase align-middle" ><AvocadoIndex avocado={a} key={a.avocado_id} index={index + 1}></AvocadoIndex></td>
                {config.suggest_color_stage && config.research_mode && <td className="text-start text-uppercase align-middle" ><b>{a.atron_color_stage}</b></td>}
                <td className="align-middle" >
                    <ColorCheckInputField
                        key={a.avocado_id}
                        avocado={a}
                        field="manual_color_stage"
                        variety_colors_key="external_colors" />
                </td>

            </tr>)}
        </tbody>
    </Table>;
}

export function InternalColorCheck() {
    const isLoading = useSelector((state) => state.checks.isLoading);
    const config = useConfig();
    const check = useSelector((state) => state.checks.current);
    const canSubmit = check.avocados.filter((i) => i.manual_internal_color_stage > 0).length === check.avocados.length;

    return (
        <Box headerTitle={config.title()} canSubmit={canSubmit} >
            {isLoading && <Loading></Loading>}
            {!isLoading && <InternalColorCheckButtons />}
        </Box>
    );
}

function InternalColorCheckButtons() {
    const check = useSelector((state) => state.checks.current);
    const layer = useSelector((state) => state.layers.current);
    return <Table size="sm" responsive={true} borderless={false}>
        <thead>
            <tr className="align-middle">
                <th className="text-muted text-uppercase text-start">{layer.fruit_type}</th>
                <th className="text-muted text-uppercase text-start">Color stage</th>
            </tr>
        </thead>
        <tbody>
            {check.avocados.map((a, index) => <tr key={index}>
                <td className="text-start text-uppercase align-middle" ><AvocadoIndex avocado={a} key={a.avocado_id} index={index + 1}></AvocadoIndex></td>
                <td className="align-middle" >
                    <ColorCheckInputField
                        key={a.avocado_id}
                        avocado={a}
                        field="manual_internal_color_stage"
                        variety_colors_key="internal_colors" />
                </td>
            </tr>)}
        </tbody>
    </Table>;
}

export function ColorCheckInputField({ avocado, field, variety_colors_key }) {
    const config = useConfig();
    const layer = useSelector((state) => state.layers.current);
    const patchAvocado = usePatchAvocado();
    let colors = {};
    if (variety_colors_key === "internal_colors") {
        colors = config.get_fruit_type_internal_colors(layer);
    } else {
        colors = config.get_fruit_type_external_colors(layer);
    }
    const _setColor = (avocado, color) => patchAvocado(avocado, field, color);

    return <div className="d-flex flex-wrap align-items-center" >
        {colors && colors.filter((i) => i.text).map((i) => <Button
            key={i.value}
            disabled={avocado.isLoading === i.value}
            className="me-3 text-white"
            css={css`border-color: ${i.color}; background-color: ${i.color}; opacity: ${avocado[field] !== i.value ? 0.5 : 1};&:hover, &:active, &:focus{background-color: ${i.color}; } `}
            outline={avocado[field] !== i.value}
            onClick={() => _setColor(avocado, i.value)}
        >{i.text} </Button>)}
    </div>;
}

ColorCheckInputField.propTypes = {
    avocado: PropTypes.object,
    field: PropTypes.string,
    variety_colors_key: PropTypes.string,
};

export function AskQualityCheck(props) {
    const patchAvocado = usePatchAvocado();
    const { avocado } = props;

    return (<div>
        <div>
            <h4 className="py-3 mb-0">Do you think this fruit is A quality?</h4>
            <div className="">
                <Button
                    size="sm"
                    outline={avocado.manual_quality !== "a"}
                    color={avocado.manual_quality === "a" ? "success" : "secondary"}
                    className="me-2"
                    onClick={() => patchAvocado(avocado, "manual_quality", "a")}
                >Yes</Button>
                <Button
                    size="sm"
                    outline={avocado.manual_quality !== "b"}
                    color={avocado.manual_quality === "b" || avocado.manual_quality === "" ? "danger" : "secondary"}
                    className=""
                    onClick={() => patchAvocado(avocado, "manual_quality", "b")}
                >No</Button>
            </div>
        </div>
    </div >);
}
AskQualityCheck.propTypes = {
    avocado: PropTypes.object,
};
