import React, { useState } from "react";
import {
    Button, FormGroup, FormText, Label, Input,
} from "reactstrap";
import apiClient from "../../../constants/apiClient";
import { ROOT_URL } from "../../../constants/urls";
import { toast } from "../../../utils/toast";

export default function TemporaryTEFileUpload() {
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const handleSubmission = () => {
        const formData = new FormData();
        formData.append("file", selectedFile);

        apiClient.post(`${ROOT_URL}api/ripening/upload_excel_file`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(() => {
            toast.success("File uploaded!");
        }).catch((error) => toast.error(`Could not handle file ${error}`));
    };

    return (

        <div>
            <FormGroup>
                <Label for="exampleFile">
                    Excelsheet
                </Label>
                <Input
                    id="exampleFile"
                    name="file"
                    type="file"
                    onChange={changeHandler}
                />

                {isFilePicked ? (
                    <FormText>
                        <p>Filename: {selectedFile.name}</p>
                        <p>Filetype: {selectedFile.type}</p>
                        <p>Size in bytes: {selectedFile.size}</p>
                    </FormText>

                ) : (<FormText>Download excel file from onedrive and upload here.</FormText>)}
            </FormGroup>

            <div>
                <Button disabled={!isFilePicked} onClick={handleSubmission}>Submit</Button>

            </div>

        </div>

    );
}
