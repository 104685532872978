/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { Progress } from "reactstrap";
import { Loading } from "./Loading";

export default function LoadingProgressBar({ text, startIndex = 0 }: { text: { text: string; seconds: number }[], startIndex?: number }) {
    const [index, setIndex] = useState(startIndex);
    const setTimeLeft = useState(text[startIndex].seconds)[1];
    const [totalSeconds, setTotalSeconds] = useState(
        text.slice(0, startIndex).reduce((acc, curr) => acc + curr.seconds, 0)
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev === 0.25) {
                    if (index < text.length - 1) {
                        setIndex((prevIndex) => prevIndex + 1);
                        return text[index + 1].seconds;
                    }
                    // * Nothing else to show
                    return 0.25;

                }

                return prev - (1 / 4);

            });
            setTotalSeconds((prev) => prev + 1);
        }, 250);

        return () => clearInterval(interval);
    }, [index, text]);

    const currentText = text[index];

    return (
        <div className="d-flex align-items-center" css={css`min-height: 70vh;`}>
            <div className="text-center w-100">
                <Loading />
                <Progress animated color="success" value={totalSeconds % 100} />
                <h6 className="text-muted pt-5">{currentText.text}</h6>
            </div>
        </div>
    );
}
