/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Badge } from "reactstrap";
import useConfig from "../../actions/Tenants/config/configHook";
// DEPRECATED, please use the color scales from the tenant config opbject
export const _5_colors = ["#037631", "#206025", "#32521d", "#474215", "#632d09"];
export const _3_colors = [_5_colors[0], _5_colors[2], _5_colors[4]];

export const humanRipeningStage = {
    hard: "Hard",
    triggered: "Triggered",
    ready_to_eat: "Ready to Eat",
};

export const ripeningStageColors = {
    hard: _3_colors[0],
    triggered: _3_colors[1],
    ready_to_eat: _3_colors[2],
};
//  fancy histogram thing?
// export function ColorScale({ colors, values, total }) {
//     if (!(total > 0)) {
//         return null;
//     }
//     const items = () => colors.map((color, index) => {
//         var relative = (values[index] / total) * 60
//         return (<div className="align-self-end text-white text-center" css={{ 'height': relative + 'px', 'width': '20px', 'background': color }} >{relative > 5 && values[index] > 0 && values[index]}</div>)
//     })

//     return (
//         <div className="d-flex align-items-end">
//             {items()}
//         </div>
//     )
// }

export function CountedBadges({ list, item }) {
    return list.map((i) => <CountedBadge key={i.key} {...i} value={item[i.key]} />);
}
export function CountedBadge({ color, name, value }) {
    return <Badge css={css({ backgroundColor: `${color}!important`, opacity: value === 0 ? 0.6 : 1 })} className="me-1 rounded-pill text-white px-2" pill> {name && <span className="opacity-80 pe-1">{name}</span>}  <Badge css={css`background-color: transparent!important`} >{value}</Badge></Badge>;
}
CountedBadge.propTypes = {
    color: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
};
export function CountedBadgeQuality({ color, name, value }) {
    if (!value) {
        return null;
    }
    return <Badge color={color} className="me-1 rounded-pill text-white px-2" pill> {name && <span className="opacity-80 pe-1">{name}</span>}  <Badge color={color} >{value}</Badge></Badge>;
}
CountedBadgeQuality.propTypes = {
    color: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
};

// ! warning Deprecated: but still used in inbox, please use ColorStageBadgeConfiruable
export function ColorStageBadge({ color_stage }) {
    const index = color_stage - 1;
    if (!_5_colors[index]) {
        return color_stage;
    }
    const color = _5_colors[index];

    return <Badge css={css({ backgroundColor: `${color}!important` })} className="me-1 rounded-pill text-white px-2" pill> {color_stage} </Badge>;
}
ColorStageBadge.propTypes = {
    color_stage: PropTypes.string,
};

export function ColorStageBadgeConfigurable({ color_stage, colors }) {
    const color = (colors || []).find((i) => String(i.value) === String(color_stage));
    if (!color) {
        return color_stage;
    }
    return <Badge css={css`background-color: ${color.color}!important`} className="me-1 rounded-pill text-white px-2" pill> {color.text || color.label} </Badge>;
}
ColorStageBadgeConfigurable.propTypes = {
    color_stage: PropTypes.number,
    colors: PropTypes.array,
};

export function RipeningStageBadge({ ripening_stage }) {
    if (!Object.prototype.hasOwnProperty.call(humanRipeningStage, "ripening_stage")) {
        return ripening_stage;
    }
    const color = ripeningStageColors[ripening_stage];

    return <Badge css={css`background-color: ${color}!important`} className="me-1 rounded-pill text-white px-2" pill> {humanRipeningStage[ripening_stage]} </Badge>;
}
RipeningStageBadge.propTypes = {
    ripening_stage: PropTypes.string,
};

export function QualityBadge({ quality }) {
    if (quality !== "a" && quality !== "b") {
        return null;
    }

    const color = quality === "a" ? "success" : "danger";

    return <Badge color={color} className="rounded-pill text-white px-2 text-uppercase" pill> {quality} </Badge>;
}
QualityBadge.propTypes = {
    quality: PropTypes.string,
};

// TODO: refactor when we finally make a project of the fruit table refactoring. Now you can only choose to either show internal or external defects, but nothing with severerity
export function DefectBadges({ defects }) {
    if (defects.length === 0) {
        return (<span>-</span>);
    }

    return defects.map((defect) => <Badge key={defect.defect_id} color="secondary" className="me-1 rounded-pill" pill>{defect.label}</Badge>);

}

DefectBadges.propTypes = {
    defects: PropTypes.array,
};

export function CountedDefectBadges({ defects }) {
    const check = useSelector((state) => state.checks.current);
    const config = useConfig();
    const _defects = config.defects[check.pallet.fruit_type];
    return defects.map((i) => {
        const key = i[0];
        const index = _defects.findIndex((e) => e.id === key);
        if (index === -1) {
            return null;
        }
        return (<Badge key={key} color="light" className="me-1 rounded-pill" pill>{_defects[index].name} <Badge color="secondary">{i[1]}</Badge></Badge>);
    });
}

CountedDefectBadges.propTypes = {
    defects: PropTypes.array,
};
