import { FormTextListField } from "../../actions/Tenants/config/constantsTyped";
import MetaForm from "./MetaForm";
import useConfig from "../../actions/Tenants/config/configHook";

export interface TextListProps {
    value: string[],
    onChange: (value: string[]) => void,
    field: FormTextListField["field"],
    disabled?: boolean,
}

export default function TextList({ value = [], onChange, field, disabled = false }: TextListProps) {
    const config = useConfig();
    const handleChange = (index: number, newValue: string) => {
        const newValueList = [...value];
        newValueList[index] = newValue;
        onChange(newValueList.filter((v) => v));
    };

    // Add an empty string to the end of the list for adding new items
    const items = [...value, ""];
    return (
        <div>
            {items.map((item, index) => (
                <MetaForm
                    key={index}
                    meta={[field]}
                    setValue={(_: string, value: unknown) => handleChange(index, value as string)}
                    config={config}
                    object={{ [field.name]: item }}
                    debounce_time={500}
                    disabled={disabled}
                />
            ))}
        </div>
    );
}
