/** @jsxImportSource @emotion/react */
import { Badge } from "reactstrap";
import Field, { DataSource } from "./Field";

class LayerStageField extends Field {
    field_type = "layer_stage_field";

    backend_table = "latest_location";

    is_filterable(): boolean {
        return true;
    }

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        let value = "-";
        if (datasource.layer && datasource.layer.latest_location?.stage) {
            value = datasource.layer.latest_location?.stage;
        }
        this.value = this.value_filter(value, datasource);
        return this;

    }

    display_component(): JSX.Element {
        if (this.value === "-") {
            return <span>-</span>;
        }
        return <span >
            <Badge color="light" className="text-black">{this.value}</Badge>
        </span>;
    }

}

export default LayerStageField;
