/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Col,
    Container,
    Row,
    Table
} from "reactstrap";
import { createManualAvocado, resetAvocadoState } from "../../../../actions/Avocados/actions";
import { createCheck, patchCheck } from "../../../../actions/Checks/actions";
import { DEFECT_POSITION } from "../../../../actions/Checks/constants";
import { formatCheck2Frontend } from "../../../../actions/Checks/util";
import { getLayerChecks } from "../../../../actions/Layers/actions";
import { Check, Fruit, isArtLocation, optionsFlagged, UNIT_OF_ACCOUNT } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { path_manual_check } from "../../../../actions/Tenants/config/constants";
import { getFilledArrayOrDefault, isFilledArray } from "../../../../utils";
import { callAvosApi } from "../../../../utils/useAvosApiHook";
import MetaForm from "../../../Forms/MetaForm";
import { TenantDefect, useDefectsHook } from "../../../Forms/useManagedOptionsHook";
import { Loading } from "../../../Helper/Loading";
import { toPercentage } from "../../Layers/fields/formatters";
import BoxedFruitTable, { BoxItem } from "../components/Summary/Boxes";
import { AdvanceRipeningLayout } from "./Layout";
import CheckImages from "./Summary/CheckImages";
import InvalidCheckModal from "./Summary/InvalidCheckModel";
import { DefectFrequencyForm } from "./VisualCheck";
import useAuthorization from "../../../../utils/authorization";
import { UserRole } from "../../../../actions/Tenants/config/constantsTyped";


interface DayInfo {
    day: number;
    check: boolean;
    test_id?: number
}


export default function AdvanceRipening() {
    const isLoading = useSelector((state: any) => state?.checks?.isLoading);
    const checks = useSelector((state: any) => state?.layers?.checks);
    const check = useSelector((state: any) => state?.checks?.current);
    const layer = useSelector((state: any) => state?.layers?.current);
    const checkIsLoading = useSelector((state: any) => state?.checks?.isLoading);
    const layerIsLoading = useSelector((state: any) => state?.layers?.isLoading);
    const config = useConfig();
    const layer_config = config.get_layer_config(layer);
    const location = config.get_location(layer, check);
    const checkLocationUnit = location?.unit_of_account || UNIT_OF_ACCOUNT.FRUIT;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const box_size = check.box_size || layer.box_size;
    if (!checks || !check || !layer.type) {
        return <Loading />;
    }

    const addFruit = () => dispatch(createManualAvocado({ test_id: check.test_id, n: 1, unit_of_account: checkLocationUnit }) as any).then((response) => {
        if (!response.error) {
            dispatch(resetAvocadoState());
            navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${path_manual_check}/${response.payload.data.avocado_id}`);
        }
    });
    const buttons = <AdvanceRipeningNavButtons addFruit={addFruit} />;
    const ripeningDays = <AdvanceRipeningChecks highlight_current={true} />;

    return (
        <div>
            <AdvanceRipeningLayout buttons={buttons} hideButtons={isLoading} ripeningDays={ripeningDays} >
                <AdvanceRipeningForm />
                {!checkIsLoading && !layerIsLoading && check?.avocados?.length > 0 && <div>
                    {!box_size && <BoxItem avocados={check.avocados} check={check} layer={layer} title={`Check ${check.test_id}`} />}
                    {box_size > 0 && <BoxedFruitTable check={check} layer={layer} />}
                </div>}
                {check.test_id > 0 && <div>
                    <CheckImages test_id={check.test_id} required_images={layer_config.show_images || []} />
                </div>}
            </AdvanceRipeningLayout>

        </div>
    );
}


export function AdvanceRipeningNavButtons({ addFruit }) {
    const check = useSelector((state: any) => state?.checks?.current);
    const isUpdating = useSelector((state: any) => state?.avocados?.isUpdating);
    const user = useSelector((state: any) => state.auth.user);
    const auth = useAuthorization();

    return (
        <div className="py-4 d-md-flex justify-content-between align-items-center">
            <div className="me-2 d-inline-flex d-md-block my-1">
                {(user.is_admin || auth.userHasRole(UserRole.TENANT_ADMIN)) && <InvalidCheckModal check={check} />}
            </div>
            <div className=" me-2 d-inline-flex d-md-block">
                <Button color={check.do_quality_check !== "yes" ? "light" : "primary"} className="text-nowrap my-1" disabled={isUpdating || check.do_quality_check !== "yes"} onClick={() => addFruit()}>Add Fruit</Button>
            </div>

        </div>
    );
}

export function AdvanceRipeningChecks({ highlight_current = false }: { highlight_current?: boolean }) {
    const layer = useSelector((state: any) => state?.layers?.current);
    const checks = useSelector((state: any) => state?.layers?.checks);

    const dispatch = useDispatch();
    const config = useConfig();
    const check = useSelector((state: any) => state?.checks?.current);
    const location = config.get_location(layer, check);

    const all_defects = useDefectsHook(layer.fruit_type);

    useEffect(() => {
        if (layer.id && isArtLocation(check?.location)) {
            dispatch(getLayerChecks(layer.id, { check_location: check.location }) as any);
        }
    }, [layer.id, check.location]);

    if (!checks) {
        return <Loading />;
    }

    if (!location?.summary_fruit_fields) {
        return <Loading />;
    }

    // * min width of table cell is 1320/7 = ~188px
    const filteredChecks = checks.filter((i) => i.location === check.location).sort((a, b) => a.test_id - b.test_id);
    const days: DayInfo[] = [1, 2, 3, 4, 5, 6, 7].map((i) => (filteredChecks[i - 1] ? { test_id: filteredChecks[i - 1].test_id, day: i, check: true } : { day: i, check: false }));
    return (
        <Container>
            <div className="mx-n2">
                <Table responsive borderless>
                    <tbody>
                        <tr>
                            {days.map((day) => <td key={day.day} className="p-0 px-2" css={css`min-width: 184px;`}>
                                <RipeningDay highlight_current={highlight_current} day={day.day} test_id={day?.test_id || false} all_defects={all_defects as TenantDefect[]} />
                            </td>)}
                        </tr>
                    </tbody>
                </Table>
            </div>

        </Container>
    );
}


function RipeningDay({ day, test_id, highlight_current = false, all_defects }: { day: number; test_id: number | false, highlight_current?: boolean, all_defects: TenantDefect[] }) {
    const navigate = useNavigate();
    const layer = useSelector((state: any) => state?.layers?.current);
    const currentCheck = useSelector((state: any) => state?.checks?.current);
    const [localCheck, setCheck] = useState<Check | null>(null);
    const dispatch = useDispatch();
    const config = useConfig();
    const timerRef = useRef(null as any);

    const isCurrentCheck = test_id === currentCheck.test_id;
    const location = config.get_location(layer, currentCheck);


    // *  fetch all check data for this check
    // * if we navigate to another check we will have to fetch the data again
    useEffect(() => {
        if (test_id) {
            callAvosApi(`/tests/${test_id}/`).then((response) => {
                setCheck(formatCheck2Frontend(response.data));
            });
        }
    }, [test_id, isCurrentCheck]);

    const check = isCurrentCheck ? currentCheck : localCheck;

    // if total_ripening_classes is not equal to sample_size, update sample_size
    const use_advance_ripening_classes_as_sample_size = isFilledArray(location?.advance_ripening_classes_form);
    const total_ripening_classes = sumRipeningClasses(location?.advance_ripening_classes_form, check);

    useEffect(() => {
        if (check && use_advance_ripening_classes_as_sample_size && total_ripening_classes > 0) {
            if (String(check.sample_size) !== String(total_ripening_classes)) {
                clearTimeout(timerRef.current as any);
                timerRef.current = setTimeout(() => {
                    dispatch(patchCheck(check.test_id, "sample_size", total_ripening_classes) as any);
                }, 200);
            }
        }
    }, [use_advance_ripening_classes_as_sample_size, total_ripening_classes]);


    const dispatchCreateCheck = () => dispatch(createCheck({ layer_id: layer.id, location: currentCheck.location }) as any).then((response) => {
        if (!response.error) {
            dispatch(getLayerChecks(layer.id, { check_location: currentCheck.location }) as any);
            navigate(`/layer/${layer.id}/add-check/${response.payload.data.test_id}/advance-ripening`);
        }
    });

    const onClick = () => {
        if (test_id === false) {
            dispatchCreateCheck();
            return;
        }
        navigate(`/layer/${layer.id}/add-check/${test_id}/advance-ripening`);

    };

    // check how many fruit have at least one internal defect
    let status = "-";
    let internal_defected_fruit = 0;
    if (test_id && check && check.avocados.length > 0) {
        const internal_defects = all_defects.filter((defect) => defect.position === DEFECT_POSITION.INTERNAL).map((i) => i.defect_id);
        internal_defected_fruit = check.avocados.filter((i: Fruit) => i.defects.filter((x) => internal_defects.includes(x.defect_id) && x.frequency > 0).length > 0).length;
        status = check.avocados.length > 0 ? "clean" : "not checked";
        if (internal_defected_fruit > 0) {
            status = `${internal_defected_fruit}/${check.avocados.length}`;
        }
    }
    const highlight = isCurrentCheck && highlight_current;
    const glowingEffect = highlight ? "box-shadow: 0px 0px 8px 8px rgba(19, 165, 95, 0.1);" : "";

    let titleBackgroundClass = "bg-light";
    if (highlight) {
        titleBackgroundClass = "bg-active text-white";
    } else if (test_id) {
        titleBackgroundClass = "bg-secondary text-white";
    }

    const external_defects = all_defects.filter((defect) => defect.position === DEFECT_POSITION.EXTERNAL && check?.defects[defect.defect_id] > 0);
    const has_external_defects = external_defects.length > 0;

    return (
        <>
            <div className="cursor-pointer" title={`Check ${test_id || "new"}`} css={css` ${glowingEffect}`} onClick={() => onClick()}>
                <div className={`mb-1 p-2 text-center ${titleBackgroundClass}`} >
                    <span>Day {day}</span>
                </div>
                <div className="mb-1 font-weight-bold p-2 position-relative bg-white" css={css`min-height: 250px; font-size: 12px;`}>
                    {test_id && check && <div>

                        {location.advance_ripening_classes_form.map((i) => <div key={i.name} className="d-flex justify-content-between">
                            <span>{i.label}:</span>
                            <span><b>{toPercentage(check?.[i.name], check.sample_size, 2)}</b></span>
                        </div>)}

                        {check.temperature
                            && <div className="d-flex justify-content-between">
                                <span>Temp:</span>
                                <span>{check.temperature}°</span>
                            </div>
                        }
                        {check.sample_size
                            && <div className="d-flex justify-content-between">
                                <span>Sample size</span>
                                <span><b>{check.sample_size}</b></span>
                            </div>
                        }
                        {location?.advance_ripening_show_external_defects && !has_external_defects
                            && <div className="d-flex justify-content-between">
                                <span>External</span>
                                <span><b>0%</b></span>
                            </div>}
                        {location?.advance_ripening_show_external_defects && external_defects.map((defect, index) => <div key={index}>
                            <div className="d-flex justify-content-between">
                                <span>{defect.label}</span>
                                <span><b>{toPercentage(check.defects[defect.defect_id], check.sample_size)}</b></span>
                            </div>
                        </div>)}
                    </div>}
                </div>
                {test_id && check && <div css={css`font-size: 12px;`} className={`${(check.avocados.length > 0 && !(internal_defected_fruit > 0) ? "bg-success text-white" : "bg-light")}  d-flex justify-content-between p-2`}>
                    <span>Internal</span>
                    <span>{status}</span>
                </div>}
                {!test_id && <div className="bg-light d-flex justify-content-center align-items-center p-2">
                    <div css={css`opacity: 0;font-size: 12px;`}>
                        <span>Place</span>
                        <span>Holder</span>
                    </div>
                </div>
                }

            </div>
        </>
    );
}


const sumRipeningClasses = (advance_ripening_classes_form, check: Check) => (advance_ripening_classes_form || []).reduce((acc: number, i) => acc + (Number(check?.[i.name]) || 0), 0);

function AdvanceRipeningForm() {
    const config = useConfig();
    const check = useSelector((state: any) => state?.checks?.current);
    const layer = useSelector((state: any) => state?.layers?.current);
    const location = config.get_location(layer, check);
    // const effectTimer = useRef(null);


    // count total defectes in this check
    // const total_defects = all_defects.reduce((total, defect) => total + (Number(check.defects[defect.defect_id]]) || 0), 0);

    // const handleCheckUpdate = () => {
    // dispatch(patchCheck(check.test_id, "advance_ripening_total_external_defects", total_defects) as any);
    // };

    // useEffect(() => {
    //     clearTimeout(effectTimer.current as any);
    //     if (check && check.test_id) {
    //         effectTimer.current = setTimeout(handleCheckUpdate, 550) as any;
    //     }

    //     return () => clearTimeout(effectTimer.current as any);
    // }, [total_defects]);


    const default_meta_form = [
        {
            label: "Notes",
            name: "feedback",
            type: "textarea",
            lg: 12,
        },
        {
            label: "",
            name: "flag",
            type: "radio",
            options: optionsFlagged,
            lg: 12
        }
    ];
    const first_column_width = getFilledArrayOrDefault(location?.visual_check_defect_form, [])[0]?.lg || 6;
    const appendend_style = css`width: 4.5rem;`;

    return (
        <div>
            {isFilledArray(location?.advance_ripening_start_form) && <Row className="pt-5 pb-5 border-bottom">
                <Col lg={first_column_width} >
                    <CheckForm
                        key={check.test_id}
                        grid={false}
                        meta={(location?.advance_ripening_start_form || [])}
                        dispatchPercentage={false}
                    />
                </Col>
            </Row>
            }


            {isFilledArray(location?.advance_ripening_classes_form) && <Row className="pt-5">
                <Col lg={first_column_width} >
                    <CheckForm
                        key={check.test_id}
                        meta={[{ type: "header", label: "Firmness classes" }, ...(location?.advance_ripening_classes_form || []).map((i) => ({ ...i, appendend_style, type: "number", unity: toPercentage(check[i.name], check.sample_size, 1), }))]}
                        dispatchPercentage={true}
                    />
                </Col>
            </Row>
            }
            {location?.advance_ripening_show_external_defects && <div >
                <div className=""><DefectFrequencyForm /></div>
            </div>}

            <Row className="pt-5 border-top mt-5">
                <Col lg={first_column_width}>
                    <CheckForm
                        key={check.test_id}
                        grid={true}
                        meta={[...location?.meta_form_advance_ripening || [], ...default_meta_form]}
                        dispatchPercentage={false} />
                </Col>
            </Row>

        </div>
    );
}

function CheckForm(props) {
    const dispatch = useDispatch();
    const config = useConfig();
    const check = useSelector((state: any) => state?.checks?.current);
    return <MetaForm
        {...props}
        config={config}
        object={check}
        setValue={(field, value) => {
            if (props.dispatchPercentage) {
                const field_percentage = toPercentage(check[field], check.sample_size, 1, "");
                dispatch(patchCheck(check.test_id, `advance_ripening_${field}_percentage`, field_percentage, false) as any);
            }
            dispatch(patchCheck(check.test_id, field, value, false) as any);
        }}
        onDebounce={(field, value) => {
            if (props.dispatchPercentage) {
                const field_percentage = toPercentage(check[field], check.sample_size, 1, "");
                dispatch(patchCheck(check.test_id, `advance_ripening_${field}_percentage`, field_percentage) as any);
            }
            dispatch(patchCheck(check.test_id, field, value) as any);
        }}
    />;
}
