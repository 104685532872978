import { PATCH } from "./actionsTypes";

export default (state, action) => {
    switch (action.type) {
    case PATCH.LOADING:
        return { ...state, isLoading: true };
    case PATCH.ERROR:
        return { ...state, isLoading: false };
    case PATCH.SUCCESS:
        return {
            ...state,
            ...action.payload.data,
            isLoading: false,
        };
    default:
        return state;
    }
};
