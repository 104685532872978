import { toast } from "../../utils/toast";
import {
    GET_RELABELING_SESSIONS, DEFECTS_STAT, CREATE_SESSION, GET_RELABELING_SESSION_DETAIL, DEFECT_UPDATE, GET_NEW_BATCH, RESET_RELABELING,
} from "./actionsTypes";

const relabelingInitialState = {
    recentSessions: [],
    defects_stats: [],
    currentSession: null,
    isLoading: false,
    isUpdating: false,
    created: null,
    noMoreBatch: false,
    newBatchAdded: false,
    filter: { search: "", limit: 50, ordering: "" },
};

export default (state = relabelingInitialState, action) => {
    switch (action.type) {
    case RESET_RELABELING.SUCCESS:
        return { ...relabelingInitialState };
    case GET_RELABELING_SESSIONS.LOADING:
        return { ...state, isLoading: true };
    case GET_RELABELING_SESSIONS.SUCCESS:
        return { ...state, isLoading: false, recentSessions: action.payload.data };
    case GET_RELABELING_SESSIONS.ERROR:
        toast.error("Error getting recent relabeling sessions ...", {
            autoClose: 1500,
        });
        return { ...state, isLoading: false };

    case GET_RELABELING_SESSION_DETAIL.LOADING:
        return { ...state, isLoading: true };
    case GET_RELABELING_SESSION_DETAIL.SUCCESS:
        return { ...state, isLoading: false, currentSession: action.payload.data };
    case GET_RELABELING_SESSION_DETAIL.ERROR:
        toast.error("Error getting recent relabeling sessions ...", {
            autoClose: 1500,
        });
        return { ...state, isLoading: false };

    case DEFECTS_STAT.LOADING:
        return { ...state, isLoading: true };

    case DEFECTS_STAT.SUCCESS:
        return { ...state, isLoading: false, defects_stats: action.payload.data };

    case DEFECTS_STAT.ERROR:
        toast.warning("Error getting list of defects", {
            autoClose: 1500,
        });
        return { ...state, isLoading: false };

    case DEFECT_UPDATE.LOADING:
        return { ...state, isLoading: true };

    case DEFECT_UPDATE.SUCCESS:
        return { ...state, isLoading: false };

    case DEFECT_UPDATE.ERROR:
        toast.warning("Error updating the defect", {
            autoClose: 1500,
        });
        return { ...state, isLoading: false };

    case CREATE_SESSION.LOADING:
        return { ...state, isLoading: true };

    case CREATE_SESSION.SUCCESS:
        return { ...state, isLoading: false, created: action.payload.data };

    case CREATE_SESSION.ERROR:
        toast.warning("Error getting list of defects", {
            autoClose: 1500,
        });
        return { ...state, isLoading: false };

    case GET_NEW_BATCH.LOADING:
        return { ...state, isLoading: true, newBatchAdded: false };

    case GET_NEW_BATCH.SUCCESS:
        return getNewBatchSuccess(action, state);
    case GET_NEW_BATCH.ERROR:
        toast.warning("Error getting list of defects", {
            autoClose: 1500,
        });
        return { ...state, isLoading: false };

    default:
        return state;
    }
};

function getNewBatchSuccess(action, state) {
    let noMoreBatch = false;
    if (action.payload.data.length === 0) {
        noMoreBatch = true;
    }

    const temp = state.currentSession;
    if (temp != null) {
        temp.defects = temp.defects.concat(action.payload.data);
    }
    console.log(temp); // eslint-disable-line no-console
    return {
        ...state, isLoading: false, noMoreBatch, newBatchAdded: (noMoreBatch !== true), currentSession: temp,
    };
}
