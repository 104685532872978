/** @jsxImportSource @emotion/react */

import { LAYER_TYPE, VIEW } from "../../../../actions/Layers/constants";
import FilterableConfigList from "./FilterableConfigList";

export const strategyForm = [
    {
        label: "Title",
        type: "text",
        name: "title",

    },
    {
        label: "View strategy",
        name: "view_strategy",
        type: "single-select",
        options: Object.keys(VIEW).map((i) => ({ value: VIEW[i], label: i }))
    },
    {
        label: "Layer type",
        description: "Layer type in this view",
        name: "layer_type",
        type: "single-select",
        options: Object.keys(LAYER_TYPE).map((i) => ({ value: LAYER_TYPE[i], label: i }))
    },
];

const defaultField = {
    field: {
        title: "View data collected on pallets",
        view_strategy: VIEW.SELF,
        layer_type: LAYER_TYPE.PALLET
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
    }
};
export default function ViewStrategyConfigForm({ name, title, fields, description, setArray }) {
    const getFieldTitle = (object) => <span>{object.title} [{object.view_strategy} on {object.layer_type}] </span>;
    const getFieldForm = () => strategyForm;
    return <FilterableConfigList
        useDisplayFilter={false}
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle} />;

}


