/** @jsxImportSource @emotion/react */
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { listFacilityLocations } from "../../../actions/FacilityLocations/actions";
import {
    addLayerLocationHistory,
    getLayer,
    patchLayer,
    resetLayerState
} from "../../../actions/Layers/actions";
import { LAYER_STAGE } from "../../../actions/Layers/constants";
import { applyDisplayFilterFields } from "../../../actions/Tenants/config/applyDisplayFilter";
import useConfig from "../../../actions/Tenants/config/configHook";
import { isFilledArray } from "../../../utils";
import MetaForm, { metaFormIsValid } from "../../Forms/MetaForm";
import { Loading } from "../../Helper/Loading";
import { useSwitchTabContext } from "./fields/useSwitchTab";

// * This component is used to display the CTA form for a single layer
const LayerListCta = () => {
    const config = useConfig();

    const [isOpen, toggle] = useState(false);
    const params = useParams();
    const dispatch = useDispatch();
    const { normalizeUrl, activeTab } = useSwitchTabContext();
    const locations = useSelector((state:any) => state.facilityLocations.locations);
    const [allocateFormState, setAllocateFormState] = useState({} as any);


    const layer = useSelector((state: any) => state.layers.current);
    const layer_config = config.get_layer_config(layer);
    const meta_form = applyDisplayFilterFields(activeTab?.cta_form, {});

    useEffect(() => {
        if (params.cta_layer_id && params.cta_layer_id !== "bulk") {
            toggle(true);
            dispatch(listFacilityLocations({ offset: 0, limit: 200 }) as any);
            dispatch(resetLayerState());
            setAllocateFormState({});
            dispatch(getLayer(params.cta_layer_id, false) as any).then((data) => {
                if (!data.error) {
                    setAllocateFormState(data.payload.latest_location || {});
                }
            });

        } else {
            dispatch(resetLayerState());
            setAllocateFormState({});
            toggle(false);
        }
    }, [params.cta_layer_id]);

    const setValue = (field, value) => dispatch(patchLayer(layer.id, field, value, false) as any);

    const onDebounce = (field, value) => {
        const field_config = meta_form.find((i) => i.name === field);
        if (field_config && metaFormIsValid([field_config], { [field]: value })) {
            return dispatch(patchLayer(layer.id, field, value, true) as any);
        }
        return Promise.resolve();
    };

    const onDebounceLocation = (field, value) => {
        if (field === "location_id") {
            const location = locations.find((i) => i.location_id === value);
            if (location) {
                const { stage, location_id } = location;
                return dispatch(addLayerLocationHistory({
                    layer_id: layer.id,
                    stage,
                    location_id,
                    include_children: false }) as any);
            }

        }
        if (field === "stage") {
            return dispatch(addLayerLocationHistory({
                layer_id: layer.id,
                [field]: value,
                include_children: false }) as any);
        }
        return Promise.resolve();
    };

    const onSubmit = () => {
        if (meta_form && !metaFormIsValid(meta_form, layer)) return;
        // dispatch(triggerLayerBusinessRules(layer.id) as any);
        normalizeUrl();
    };

    const onClose = () => normalizeUrl();

    const loading = !layer_config || !layer;
    const allocate_form = [
        {
            name: "stage",
            label: "Stage",
            type: "radio",
            options: Object.keys(LAYER_STAGE).map((i) => ({ value: LAYER_STAGE[i], label: i })),
            onchange_callback: ({ field, value, setValue }) => {
                const location = locations.find((i) => i.location_id === allocateFormState.location_id);
                // unset location, if selected stage is different from the current location
                if (location && location.stage !== value) {
                    setAllocateFormState({ stage: value });
                }
                setValue(field, value);
            }
        },
        // {
        //     name: "location_id",
        //     label: "Location",
        //     type: locations.length > 6 ? "single-select" : "radio",
        //     options: locations.map((i) => ({ ...i, value: i.location_id })),
        //     onchange_callback: ({ field, value, setValue }) => {
        //         const location = locations.find((i) => i.location_id === value);
        //         if (location) {
        //             setAllocateFormState(location);
        //         } else {
        //             setAllocateFormState({});
        //         }
        //     }
        // }
    ];
    return (
        <Modal isOpen={isOpen} size="md">
            { loading && <Loading /> }
            { !loading && <>
                <ModalHeader toggle={() => () => onClose()} onClick={() => onClose()}>{activeTab?.cta_form_title} {layer_config.text} {layer.label} </ModalHeader>
                <ModalBody>
                    <MetaForm
                        meta={allocate_form}
                        object={allocateFormState}
                        config={config}
                        setValue={(field, value) => setAllocateFormState({ ...allocateFormState, [field]: value })}
                        onDebounce={onDebounceLocation}
                    />
                    <MetaForm
                        meta={meta_form}
                        object={layer}
                        config={config}
                        setValue={setValue}
                        onDebounce={onDebounce}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => onClose()}>Cancel</Button>
                    <Button color="success" onClick={() => onSubmit()}>Save</Button>
                </ModalFooter>
            </>}
        </Modal>
    );
};

export default LayerListCta;


// * Move pallets out of this cell based on the selected
export function BulkLayerListCTA({ selectedLayers, deselectAll }: { selectedLayers: string[], deselectAll: any }) {
    const config = useConfig();

    const [isOpen, toggle] = useState(false);
    const params = useParams();
    const dispatch = useDispatch();
    const { normalizeUrl, activeTab } = useSwitchTabContext();
    const locations = useSelector((state:any) => state.facilityLocations.locations);
    const [allocateFormState, setAllocateFormState] = useState({} as any);
    const [patchFormState, setPatchFormState] = useState({} as any);


    const meta_form = applyDisplayFilterFields(activeTab?.cta_form, {});
    const onClose = () => normalizeUrl();

    useEffect(() => {
        if (params.cta_layer_id === "bulk") {
            toggle(true);
            dispatch(listFacilityLocations({ offset: 0, limit: 200 }) as any);
            setAllocateFormState({});
        } else {
            setAllocateFormState({});
            toggle(false);
        }
    }, [params.cta_layer_id]);

    const allocate_form = [
        {
            name: "stage",
            label: "Stage",
            type: "radio",
            options: Object.keys(LAYER_STAGE).map((i) => ({ value: LAYER_STAGE[i], label: i })),
            onchange_callback: ({ field, value, setValue }) => {
                const location = locations.find((i) => i.location_id === allocateFormState.location_id);
                // unset location, if selected stage is different from the current location
                if (location && location.stage !== value) {
                    setAllocateFormState({ stage: value });
                }
                setValue(field, value);
            }
        },
        // {
        //     name: "location_id",
        //     label: "Location",
        //     type: locations.length > 6 ? "single-select" : "radio",
        //     options: locations.map((i) => ({ ...i, value: i.location_id })),
        //     onchange_callback: ({ field, value, setValue }) => {
        //         const location = locations.find((i) => i.location_id === value);
        //         if (location) {
        //             setAllocateFormState(location);
        //         } else {
        //             setAllocateFormState({});
        //         }
        //     }
        // }
    ];


    const submit = () => {


        const { stage, location_id } = allocateFormState;

        Promise.all(selectedLayers.flatMap((layer_id) => [
            stage ? dispatch(addLayerLocationHistory({ layer_id, stage, location_id, include_children: false }) as any) : Promise.resolve(),
            ...meta_form.map((field) => {
                if (patchFormState[field.name] || patchFormState[field.name] === "") {
                    return dispatch(patchLayer(layer_id, field.name, patchFormState[field.name], true) as any);
                }
                return Promise.resolve();
            })
        ])).then(() => {
            deselectAll();
            normalizeUrl();
        });

    };

    return <Modal isOpen={isOpen} size="md">
        <ModalHeader toggle={() => () => onClose()} onClick={() => onClose()}>{activeTab?.cta_form_title} {selectedLayers.length} items </ModalHeader>
        <ModalBody>
            <MetaForm
                meta={allocate_form}
                object={allocateFormState}
                config={config}
                setValue={(field, value) => setAllocateFormState((prev) => ({ ...prev, [field]: value }))}
            />
            <MetaForm
                meta={meta_form}
                object={patchFormState}
                config={config}
                setValue={(field, value) => setPatchFormState((prev) => ({ ...prev, [field]: value }))}
            />
        </ModalBody>
        <ModalFooter>
            <Button color="primary" disabled={!isFilledArray(selectedLayers)} onClick={() => submit() }>Update {selectedLayers.length} items</Button>
            <Button color="light" onClick={() => onClose()}>Cancel</Button>
        </ModalFooter>
    </Modal>;

}

// * Button to open the bulk CTA form from TabActions
export const BulkLayerListCTAButton = () => {
    const navigate = useNavigate();
    const { selectedLayersArray, activeTab, tab_category } = useSwitchTabContext();
    if (!activeTab?.cta_form_title) {
        return null;
    }
    return <div className="pb-2 pb-md-0 pe-2"><Button
        className="text-nowrap"
        color={selectedLayersArray.length > 0 ? "primary" : "secondary"}
        disabled={selectedLayersArray.length === 0}
        onClick={() => navigate(`/layer/tab/${tab_category}/${activeTab?.value}/cta/bulk`) }><FontAwesomeIcon icon={faListCheck} /> {activeTab?.cta_form_title} {selectedLayersArray.length} items</Button>
    </div>;
};
