import { Button } from "reactstrap";
import { FormImageRadioButtonsField, ImageOption } from "../../actions/Tenants/config/constantsTyped";

export interface ImageButtonProps {
    selected: boolean,
    className: string,
    onClick: () => void,
    disabled?: boolean,
    image: {
        displaySize: [number, number];
        nativeSize: [number, number];
        backgroundPosition: [number, number];
        path: string;
        scale: number;
    }
}

export function ImageButton({ selected, className, onClick, disabled, image }: ImageButtonProps) {
    const [backgroundPositionX, backgroundPositionY] = image.backgroundPosition;
    const [displayWidth, displayHeight] = image.displaySize;
    const [nativeWidth, nativeHeight] = image.nativeSize;
    return (
        <Button outline={!selected} disabled={disabled} className={["btn-radio", className || ""].join(" ")} onClick={onClick}>
            <div style={{
                display: "inline-block",
                background: `url(${image.path}) ${backgroundPositionX * image.scale}px ${backgroundPositionY * image.scale}px no-repeat`,
                backgroundSize: `${nativeWidth * image.scale}px ${nativeHeight * image.scale}px`,
                width: displayWidth,
                height: displayHeight,
            }} />
        </Button>
    );
}


export interface ImageRadioButtonsOption extends Omit<ImageOption, "get_image_position"> {
    imagePosition: [number, number]
}

export interface ImageRadioButtonsProps {
    options: ImageRadioButtonsOption[],
    value: string,
    setValue: (value: number | undefined) => void,
    className?: string;
    image: FormImageRadioButtonsField["image"]
}

export function ImageRadioButtons({
    options, value, setValue, className = "me-1 mb-1", image,
}: ImageRadioButtonsProps) {
    return <div>
        {
            options.map(({ imagePosition, value: optionValue, disabled, className: optionClassName }, index) => <ImageButton
                key={index}
                image={{
                    displaySize: image.optionDisplaySize,
                    nativeSize: image.nativeSize,
                    path: image.path,
                    backgroundPosition: imagePosition,
                    scale: image.scale,
                }}
                selected={value === optionValue}
                disabled={disabled}
                className={`${className} ${optionClassName}`}
                onClick={() => {
                    const newValue:number | undefined = value === optionValue ? undefined : optionValue as number;
                    if (!newValue) (document.activeElement as any).blur();
                    setValue(newValue);
                }} />)
        }
    </div>;
}
