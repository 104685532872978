import C1_264930 from "../../img/dummy-images/264930/C1.webp";
import C2_264930 from "../../img/dummy-images/264930/C2.webp";
import C3_264930 from "../../img/dummy-images/264930/C3.webp";
import C4_264930 from "../../img/dummy-images/264930/C4.webp";
import NIR1_264930 from "../../img/dummy-images/264930/NIR1.webp";
import NIR2_264930 from "../../img/dummy-images/264930/NIR2.webp";
import NIR3_264930 from "../../img/dummy-images/264930/NIR3.webp";
import NIR4_264930 from "../../img/dummy-images/264930/NIR4.webp";
import C1_265700 from "../../img/dummy-images/265700/C1.webp";
import C2_265700 from "../../img/dummy-images/265700/C2.webp";
import C3_265700 from "../../img/dummy-images/265700/C3.webp";
import C4_265700 from "../../img/dummy-images/265700/C4.webp";
import NIR1_265700 from "../../img/dummy-images/265700/NIR1.webp";
import NIR2_265700 from "../../img/dummy-images/265700/NIR2.webp";
import NIR3_265700 from "../../img/dummy-images/265700/NIR3.webp";
import NIR4_265700 from "../../img/dummy-images/265700/NIR4.webp";
import C1_265775 from "../../img/dummy-images/265775/C1.webp";
import C2_265775 from "../../img/dummy-images/265775/C2.webp";
import C3_265775 from "../../img/dummy-images/265775/C3.webp";
import C4_265775 from "../../img/dummy-images/265775/C4.webp";
import NIR1_265775 from "../../img/dummy-images/265775/NIR1.webp";
import NIR2_265775 from "../../img/dummy-images/265775/NIR2.webp";
import NIR3_265775 from "../../img/dummy-images/265775/NIR3.webp";
import NIR4_265775 from "../../img/dummy-images/265775/NIR4.webp";
import C1_265777 from "../../img/dummy-images/265777/C1.webp";
import C2_265777 from "../../img/dummy-images/265777/C2.webp";
import C3_265777 from "../../img/dummy-images/265777/C3.webp";
import C4_265777 from "../../img/dummy-images/265777/C4.webp";
import NIR1_265777 from "../../img/dummy-images/265777/NIR1.webp";
import NIR2_265777 from "../../img/dummy-images/265777/NIR2.webp";
import NIR3_265777 from "../../img/dummy-images/265777/NIR3.webp";
import NIR4_265777 from "../../img/dummy-images/265777/NIR4.webp";
import C1_266557 from "../../img/dummy-images/266557/C1.webp";
import C2_266557 from "../../img/dummy-images/266557/C2.webp";
import C3_266557 from "../../img/dummy-images/266557/C3.webp";
import C4_266557 from "../../img/dummy-images/266557/C4.webp";
import NIR1_266557 from "../../img/dummy-images/266557/NIR1.webp";
import NIR2_266557 from "../../img/dummy-images/266557/NIR2.webp";
import NIR3_266557 from "../../img/dummy-images/266557/NIR3.webp";
import NIR4_266557 from "../../img/dummy-images/266557/NIR4.webp";

import C1_mango1 from "../../img/dummy-images/mango1/C1.webp";
import C2_mango1 from "../../img/dummy-images/mango1/C2.webp";
import C3_mango1 from "../../img/dummy-images/mango1/C3.webp";
import C4_mango1 from "../../img/dummy-images/mango1/C4.webp";
import NIR1_mango1 from "../../img/dummy-images/mango1/NIR1.webp";
import NIR2_mango1 from "../../img/dummy-images/mango1/NIR2.webp";
import NIR3_mango1 from "../../img/dummy-images/mango1/NIR3.webp";
import NIR4_mango1 from "../../img/dummy-images/mango1/NIR4.webp";
import YNIR1_mango1 from "../../img/dummy-images/mango1/YNIR1.webp";
import YNIR2_mango1 from "../../img/dummy-images/mango1/YNIR2.webp";
import YNIR3_mango1 from "../../img/dummy-images/mango1/YNIR3.webp";
import YNIR4_mango1 from "../../img/dummy-images/mango1/YNIR4.webp";

import C1_mango2 from "../../img/dummy-images/mango2/C1.webp";
import C2_mango2 from "../../img/dummy-images/mango2/C2.webp";
import C3_mango2 from "../../img/dummy-images/mango2/C3.webp";
import C4_mango2 from "../../img/dummy-images/mango2/C4.webp";
import NIR1_mango2 from "../../img/dummy-images/mango2/NIR1.webp";
import NIR2_mango2 from "../../img/dummy-images/mango2/NIR2.webp";
import NIR3_mango2 from "../../img/dummy-images/mango2/NIR3.webp";
import NIR4_mango2 from "../../img/dummy-images/mango2/NIR4.webp";
import YNIR1_mango2 from "../../img/dummy-images/mango2/YNIR1.webp";
import YNIR2_mango2 from "../../img/dummy-images/mango2/YNIR2.webp";
import YNIR3_mango2 from "../../img/dummy-images/mango2/YNIR3.webp";
import YNIR4_mango2 from "../../img/dummy-images/mango2/YNIR4.webp";
import { getFilledArrayOrDefault } from "../../utils";
import { AVOCADO_FRUIT } from "../Tenants/config/fruitNames";

const avocado_dummy_images = [
    [
        { url_webp: C1_264930, type: "C1", image_id: "C1_264930" },
        { url_webp: C2_264930, type: "C2", image_id: "C2_264930" },
        { url_webp: C3_264930, type: "C3", image_id: "C3_264930" },
        { url_webp: C4_264930, type: "C4", image_id: "C4_264930" },
        { url_webp: NIR1_264930, type: "NIR1", image_id: "NIR1_264930" },
        { url_webp: NIR2_264930, type: "NIR2", image_id: "NIR2_264930" },
        { url_webp: NIR3_264930, type: "NIR3", image_id: "NIR3_264930" },
        { url_webp: NIR4_264930, type: "NIR4", image_id: "NIR4_264930" }],
    [
        { url_webp: C1_265700, type: "C1", image_id: "C1_265700" },
        { url_webp: C2_265700, type: "C2", image_id: "C2_265700" },
        { url_webp: C3_265700, type: "C3", image_id: "C3_265700" },
        { url_webp: C4_265700, type: "C4", image_id: "C4_265700" },
        { url_webp: NIR1_265700, type: "NIR1", image_id: "NIR1_265700" },
        { url_webp: NIR2_265700, type: "NIR2", image_id: "NIR2_265700" },
        { url_webp: NIR3_265700, type: "NIR3", image_id: "NIR3_265700" },
        { url_webp: NIR4_265700, type: "NIR4", image_id: "NIR4_265700" }],
    [
        { url_webp: C1_265775, type: "C1", image_id: "C1_265775" },
        { url_webp: C2_265775, type: "C2", image_id: "C2_265775" },
        { url_webp: C3_265775, type: "C3", image_id: "C3_265775" },
        { url_webp: C4_265775, type: "C4", image_id: "C4_265775" },
        { url_webp: NIR1_265775, type: "NIR1", image_id: "NIR1_265775" },
        { url_webp: NIR2_265775, type: "NIR2", image_id: "NIR2_265775" },
        { url_webp: NIR3_265775, type: "NIR3", image_id: "NIR3_265775" },
        { url_webp: NIR4_265775, type: "NIR4", image_id: "NIR4_265775" }],
    [
        { url_webp: C1_265777, type: "C1", image_id: "C1_265777" },
        { url_webp: C2_265777, type: "C2", image_id: "C2_265777" },
        { url_webp: C3_265777, type: "C3", image_id: "C3_265777" },
        { url_webp: C4_265777, type: "C4", image_id: "C4_265777" },
        { url_webp: NIR1_265777, type: "NIR1", image_id: "NIR1_265777" },
        { url_webp: NIR2_265777, type: "NIR2", image_id: "NIR2_265777" },
        { url_webp: NIR3_265777, type: "NIR3", image_id: "NIR3_265777" },
        { url_webp: NIR4_265777, type: "NIR4", image_id: "NIR4_265777" }],
    [
        { url_webp: C1_266557, type: "C1", image_id: "C1_266557" },
        { url_webp: C2_266557, type: "C2", image_id: "C2_266557" },
        { url_webp: C3_266557, type: "C3", image_id: "C3_266557" },
        { url_webp: C4_266557, type: "C4", image_id: "C4_266557" },
        { url_webp: NIR1_266557, type: "NIR1", image_id: "NIR1_266557" },
        { url_webp: NIR2_266557, type: "NIR2", image_id: "NIR2_266557" },
        { url_webp: NIR3_266557, type: "NIR3", image_id: "NIR3_266557" },
        { url_webp: NIR4_266557, type: "NIR4", image_id: "NIR4_266557" },
    ]];

const mango_dummy_images = [
    [
        { url_webp: C1_mango1, type: "C1", image_id: "C1_mango1" },
        { url_webp: C2_mango1, type: "C2", image_id: "C2_mango1" },
        { url_webp: C3_mango1, type: "C3", image_id: "C3_mango1" },
        { url_webp: C4_mango1, type: "C4", image_id: "C4_mango1" },
        { url_webp: NIR1_mango1, type: "NIR1", image_id: "NIR1_mango1" },
        { url_webp: NIR2_mango1, type: "NIR2", image_id: "NIR2_mango1" },
        { url_webp: NIR3_mango1, type: "NIR3", image_id: "NIR3_mango1" },
        { url_webp: NIR4_mango1, type: "NIR4", image_id: "NIR4_mango1" },
        { url_webp: YNIR1_mango1, type: "YNIR1", image_id: "YNIR1_mango1" },
        { url_webp: YNIR2_mango1, type: "YNIR2", image_id: "YNIR2_mango1" },
        { url_webp: YNIR3_mango1, type: "YNIR3", image_id: "YNIR3_mango1" },
        { url_webp: YNIR4_mango1, type: "YNIR4", image_id: "YNIR4_mango1" },
    ],
    [
        { url_webp: C1_mango2, type: "C1", image_id: "C1_mango2" },
        { url_webp: C2_mango2, type: "C2", image_id: "C2_mango2" },
        { url_webp: C3_mango2, type: "C3", image_id: "C3_mango2" },
        { url_webp: C4_mango2, type: "C4", image_id: "C4_mango2" },
        { url_webp: NIR1_mango2, type: "NIR1", image_id: "NIR1_mango2" },
        { url_webp: NIR2_mango2, type: "NIR2", image_id: "NIR2_mango2" },
        { url_webp: NIR3_mango2, type: "NIR3", image_id: "NIR3_mango2" },
        { url_webp: NIR4_mango2, type: "NIR4", image_id: "NIR4_mango2" },
        { url_webp: YNIR1_mango2, type: "YNIR1", image_id: "YNIR1_mango2" },
        { url_webp: YNIR2_mango2, type: "YNIR2", image_id: "YNIR2_mango2" },
        { url_webp: YNIR3_mango2, type: "YNIR3", image_id: "YNIR3_mango2" },
        { url_webp: YNIR4_mango2, type: "YNIR4", image_id: "YNIR4_mango2" },
    ],
];

export const populate_dummy_images = (avocado) => {
    if (avocado && ["ATRON_DEMO", "dummy_avocado", "dummy_mango"].includes(avocado.device_uid)) {
        const deviceTypes = ["C1", "C2", "C3", "C4", "NIR1", "NIR2", "NIR3", "NIR4", "YNIR1", "YNIR2", "YNIR3", "YNIR4"];

        const images = avocado.fruit_type !== AVOCADO_FRUIT
            ? avocado_dummy_images[avocado.avocado_id % 5]
            : mango_dummy_images[avocado.avocado_id % 2];

        // Get the manual images
        const manualImages = getFilledArrayOrDefault(avocado.images, []).filter((image) => !deviceTypes.includes(image.type));

        const combinedImages = [...images, ...manualImages];

        return { ...avocado, images: combinedImages };
    }
    return avocado;
};
