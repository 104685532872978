/** @jsxImportSource @emotion/react */
import CategoryConfigForm from "./CategoryConfigForm";
import FilterableConfigList from "./FilterableConfigList";

const defaultField = {
    field: {
        field: "pressure",
        label: "Pressure",
        total_label: "Ripening class",
        speed: false,
        categories: [],
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
    // exclude_layer_types: [],
    }
};
export default function CheckSummaryMinMaxBreakdownConfigForm({ name, title, fields, description, setArray }) {
    const getFieldTitle = (object) => <span> <span className="fw-bold">{object.label}</span> [{object.field}]</span>;
    const getFieldForm = (object, setObject) => [
        {
            label: "Field",
            type: "text",
            name: "field",
            description: "From which field do you want show the miniumum and maximum values. `atron_pressures_median` for example"
        },
        {
            label: "Label",
            type: "text",
            description: <span>Will display as: Maximum{object.label ? ` ${object.label}` : "" }</span>,
            name: "label"
        },
        {
            label: "Total label",
            type: "text",
            name: "total_label",
            description: "For example `Average Ripening Class` or `Dry matter class`"
        },
        {
            label: "Unity",
            type: "text",
            name: "unity",
            description: "For example `kg` or `%`"
        },
        {
            type: "element",
            el: <CategoryConfigForm
                fields={object.categories || []}
                description={<span>Configure categories for this field. The average value will be compared to these categories.<br></br>When using `atron_pressures_median` as field the firmness categories from the fruit config will be used.</span>}
                name="categories"
                useDisplayFilter={false}
                title="Custom categories"
                setArray={(categories) => setObject({ ...object, categories })} />
        },
    ];
    return <FilterableConfigList name={name} title={title} description={description} fields={fields} setArray={setArray} defaultField={defaultField} getFieldForm={getFieldForm} getFieldTitle={getFieldTitle} />;

}


