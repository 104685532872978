/** @jsxImportSource @emotion/react */
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Button,
    ButtonGroup
} from "reactstrap";
import { useSwitchTabContext } from "./fields/useSwitchTab";

export function ActiveFilterBadges() {
    const { submitTabQuery } = useSwitchTabContext();
    const filter = useSelector<any, any>((state) => state.layers.filter);
    return <div>{ Object.keys(filter.meta_filter).map((i: any, index) => <ActiveSearchBadge
        key={index}
        item={filter.meta_filter[i]}
        removeFilter={() => (filter.meta_filter[i].optional ? submitTabQuery({ [i]: null }) : null)}
    />)}</div>;
}
interface ActiveSearchBadgeProps {
    item: {label: string, optional: boolean, editable?: boolean, field: string;};
    removeFilter: () => void;
}

function ActiveSearchBadge({ item, removeFilter }: ActiveSearchBadgeProps) {
    const navigate = useNavigate();
    const { activeTab, tab_category } = useSwitchTabContext();
    if (!item) {
        return null;
    }
    const onClick = () => {
        if (item?.editable === false) {
            return;
        }
        navigate(`/layer/tab/${tab_category}/${activeTab?.value}/edit-filter/${item.field}`);
    };
    const { label } = item;
    return <ButtonGroup className="ms-2">
        <Button onClick={onClick} disabled={item?.editable === false} className="py-1 px-2" color="secondary" >{label}</Button>
        {item.optional && <Button onClick={removeFilter} className="py-1 px-2" color="danger" ><FontAwesomeIcon icon={faTimes} /></Button>}
    </ButtonGroup>;
}
