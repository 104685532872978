/** @jsxImportSource @emotion/react */
import useConfig from "../../../../actions/Tenants/config/configHook";
import MetaForm from "../../../Forms/MetaForm";
import ColorCodeMetricForm from "./ColorCodeMetricForm";
import ColorCodeTriggerForm from "./ColorCodeTriggerForm";
import CopyMetaConfigForm from "./CopyMetaConfigForm";

export const defaultColorCodeScheme = {
    text: "Pressure",
    value: "pressure",
    trigger: {}
};

export default function ColorCodeConfigForm({ config_data, setConfigData }) {
    const config = useConfig();

    const form = [
        {
            label: "Label",
            description: "What is the label of this color coding scheme? e.g. Intake status, ripening status, etc.",
            name: "text",
            type: "text",
            // onchange_callback: ({ field, value }) => {
            //     setConfigData({ ...config_data, [field]: value, value: value.trim().replaceAll(" ", "-").toLowerCase() });
            // },
        },
        {
            label: "Value",
            name: "value",
            description: "What is the slug or identifier of this scheme. Used in the database and in urls and for versioning.",
            type: "text",
            onchange_callback: ({ field, value }) => setConfigData({ ...config_data, [field]: value.trim().replaceAll(" ", "_").toLowerCase() })
        },
        {
            type: "element",
            name: "trigger",
            el: <ColorCodeTriggerForm
                description={"When should this color coding scheme be applied? Disable to always trigger."}
                object={config_data.trigger || {}}
                setObject={(trigger) => setConfigData({ ...config_data, trigger })} />
        },
        {
            type: "element",
            name: "metrics",
            el: <ColorCodeMetricForm
                name="metrics"
                title="Metrics and Flag methods"
                fields={config_data.metrics || []}
                description="Metrics to color code."
                setArray={(metrics) => setConfigData({ ...config_data, metrics })} />
        },
        {
            type: "element",
            name: "copy_check_to_layers_meta",
            el: <CopyMetaConfigForm
                name="copy_check_to_layers_meta"
                title="Copy check to layers meta"
                fields={config_data.copy_check_to_layers_meta || []}
                description="Copy check to layers meta."
                setArray={(copy_check_to_layers_meta) => setConfigData({ ...config_data, copy_check_to_layers_meta })} />
        },
    ];

    return <MetaForm meta={form} setValue={(field, value) => setConfigData({ ...config_data, [field]: value })} object={config_data} config={config} />;
}
