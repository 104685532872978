import { formatCheck2Frontend } from "../Checks/util";
import { format2Frontend } from "../Layers/util";
import { CREATE, GET, GET_CHECKS, GET_CONDITIONS, GET_DATE_CONDITION, GET_LAYERS, LIST } from "./actionsTypes";
import { formatCondition2Frontend, formatMeta2Frontend } from "./utils";

const initialState = {
    isLoading: false,
    locations: [],
    current: null,
    filter: { search: "", limit: 50, ordering: "" },
    conditions: [],
    date_condition: [],
    layers: [],
    checks: [],
    allocatedLayersPerDay: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case CREATE.SUCCESS:
        return { ...state, isLoading: false };
    case GET.SUCCESS:
        return { ...state, isLoading: false, current: action.payload.data };
    case GET_DATE_CONDITION.SUCCESS:
        return { ...state, isLoading: false, date_condition: formatCondition2Frontend(action.payload.data) };
    case GET_CONDITIONS.SUCCESS:
        return { ...state, isLoading: false, conditions: action.payload.data };
    case LIST.LOADING:
    case GET_DATE_CONDITION.LOADING:
        return { ...state, isLoading: true };
    case LIST.ERROR:
    case GET_DATE_CONDITION.ERROR:
        return { ...state, isLoading: false };
    case LIST.SUCCESS:
        return { ...state, isLoading: true, locations: action.payload.data.map(formatMeta2Frontend) };
    case LIST.FILTER:
        return { ...state, filter: action.payload };
    case GET_LAYERS.SUCCESS:
        return { ...state, isLoading: false, layers: action.payload.data.layers.map(format2Frontend), allocatedLayersPerDay: action.payload.data.days };
    case GET_CHECKS.SUCCESS:
        return { ...state, checksLoading: false, checks: action.payload.data.map(formatCheck2Frontend) };

    default:
        return state;
    }
};
