import { defineAction } from "redux-define";
import {
    ERROR,
    FILTER,
    LOADING,
    SUCCESS,
    TheApp,
} from "../../../constants/stateConstants";

export const grower = defineAction("AdminUser", [LOADING, ERROR, SUCCESS], TheApp);
export const LIST = defineAction("LIST", [FILTER, LOADING, ERROR, SUCCESS], grower);
export const GET = defineAction("GET", [LOADING, ERROR, SUCCESS], grower);
export const CREATE = defineAction("CREATE", [LOADING, ERROR, SUCCESS], grower);
export const UPDATE = defineAction("UPDATE", [LOADING, ERROR, SUCCESS], grower);
export const DELETE = defineAction("DELETE", [LOADING, ERROR, SUCCESS], grower);
