import { AvosAction } from "../../types";
import { Filter } from "../reducer";
import { CREATE, DELETE, LIST, UPDATE } from "./actionTypes";
import { User } from "./reducer";


const endpoint = "/tenant_users";

export const addTenantUser = (data: {email:string}) => {
    return {
        types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}/`,
                data,
            },
        },
    } as unknown as AvosAction;
};


export const getUsers = (filter: Filter) => {
    filter = { ...filter } as any;
    return {
        types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
        payload: {
            request: {
                method: "GET",
                url: endpoint,
                params: filter,
            },
        },
    } as unknown as AvosAction;
};

export const deleteUser = (id: string) => {
    return {
        types: [DELETE.LOADING, DELETE.SUCCESS, DELETE.ERROR],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint}/${id}`,
            },
        },
    } as unknown as AvosAction;
};

export const saveUser = (data: User) => {
    data = { ...data } as any;
    if (data.id) {
        return {
            types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
            payload: {
                request: {
                    method: "PUT",
                    url: `${endpoint}/${data.id}`,
                    data,
                },
            },
        } as unknown as AvosAction;
    }
    return {
        type: CREATE.ERROR,
        payload: null
    };
    // return {
    //     types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
    //     payload: {
    //         request: {
    //             method: "POST",
    //             url: endpoint,
    //             data,
    //         },
    //     },
    // } as unknown as AvosAction;
};

export const updateUserFilter = (filter: Filter) => ({
    type: LIST.FILTER,
    payload: filter,
}) as AvosAction;
