/** @jsxImportSource @emotion/react */

import { faUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { getFilledArrayOrDefault } from "../../../../utils";
import { TriggerMetaForm } from "./ColorCodeTriggerForm";
import FilterableConfigList from "./FilterableConfigList";
/**
 * Represents the configuration form for flags.
 * This form allows users to set different conditions and flags for a specific category.
 */
export const flagOptions = [
    {
        value: "danger",
        label: "Danger / Red",
    },
    {
        value: "warning",
        label: "Warning / Yellow",
    },
    {
        value: "success",
        label: "Success / Green",
    },
    {
        value: "light",
        label: "Light / White",
    },
    {
        value: "info",
        label: "Info / Blue",
    },
];


const defaultField = {
    field: {
        gte: false,
        lt: 16,
        flag: "danger",
        trigger_meta: []
    }
};
export const attributeIsSet = (attribute) => attribute !== null && attribute !== undefined && attribute !== "" && attribute !== false && !Number.isNaN(attribute);

export default function FlagConfigForm({ name, title, fields, description, setArray, useDisplayFilter = false }) {
    const { tenant_id } = useParams();

    const getMetaTriggerTitle = (object) => {
        const link = <Link to={`/tenants/${tenant_id}/form-field-options/${object.options_name}`} target="_blank" rel="noopener noreferrer" >{object.meta_location} [{object.options_name}]<FontAwesomeIcon icon={faUpRightFromSquare} /> </Link>;
        // Generate string for included values
        const includeValuesString = object.include_values && object.include_values.length > 0
            ? `incl: ${object.include_values.join(", ")}`
            : "";
        // Generate string for excluded values
        const excludeValuesString = object.exclude_values && object.exclude_values.length > 0
            ? `excl: ${object.exclude_values.join(", ")}`
            : "";
        // Combine the options name with include and exclude values strings, separating with a space when necessary
        const titleParts = [includeValuesString, excludeValuesString].filter((part) => part !== "");
        return <span><span className="ms-3 me-2">{link}</span>{titleParts.join(" - ")}</span>;
    };

    const getFieldTitle = (object) => {
        const conditions: string[] = [];

        // Check and add the conditions based on the object's properties
        if (attributeIsSet(object.gte)) {
            conditions.push(`>= ${object.gte}`);
        }
        if (attributeIsSet(object.gt)) {
            conditions.push(`> ${object.gt}`);
        }
        if (attributeIsSet(object.lte)) {
            conditions.push(`<= ${object.lte}`);
        }
        if (attributeIsSet(object.lt)) {
            conditions.push(`< ${object.lt}`);
        }

        if (attributeIsSet(object.eq)) {
            conditions.push(`= ${object.eq}`);
        }

        const triggers = getFilledArrayOrDefault(object?.trigger_meta, []).map(getMetaTriggerTitle);


        // Join the conditions with a comma and space for readability
        const conditionsString = conditions.join(", ");

        // Return the formatted string
        return <span><b>{object.flag}</b> {conditionsString} {triggers}</span>;
    };
    const getFieldForm = (object, setObject) => [
        {
            label: "Greater then or equal to",
            name: "gte",
            type: "number",
        },
        {
            label: "Less then",
            name: "lt",
            type: "number",
        },
        {
            label: "Greater then",
            name: "gt",
            type: "number",
        },
        {
            label: "Less then or equal to",
            name: "lte",
            type: "number",
        },
        {
            label: "Equals",
            name: "eq",
            type: "text",
            description: "Use this field to compare string values"
        },
        {
            label: "Flag",
            type: "single-select",
            name: "flag",
            options: flagOptions
        },
        {
            type: "header",
            label: "Triggers conditions",
        },
        {
            type: "element",
            name: "trigger_meta",
            el: <TriggerMetaForm name="tigger_meta" title="Trigger meta" description="Define meta data to trigger on" fields={object?.trigger_meta || []} setArray={(trigger_meta) => setObject({ ...object, trigger_meta })} />
        }
    ];
    return <FilterableConfigList
        useDisplayFilter={useDisplayFilter as false | string[]}
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle} />;

}
