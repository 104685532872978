/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { AVOCADO_FRUIT, MANGO_FRUIT } from "../../../../actions/Tenants/config/fruitNames";
import { formatCheck2Frontend } from "../../../../actions/Checks/util";
import { CHECK_LOCATION, Check, Layer } from "../../../../actions/Layers/constants";
import { callAvosApi } from "../../../../utils/useAvosApiHook";
import { Loading } from "../../../Helper/Loading";
import { BoxItem } from "../../AddItem/components/Summary/Boxes";

export default function BoxWeightCheck({ title }) {
    const layer = useSelector<any, Layer>((state) => state.layers.current);
    const navigate = useNavigate();
    const checks = useSelector<any, Check[]>((state) => state.layers.checks);
    const isLoading = useSelector<any>((state) => state.layers.isLoading);
    const [localCheck, setCheck] = useState<Check | null>(null);
    const boxWeightChecks = checks.filter((i) => i.location === CHECK_LOCATION.BOX_WEIGHT).sort((a, b) => b.test_id - a.test_id);
    const fruitTypeCheck = layer.fruit_type === AVOCADO_FRUIT || layer.fruit_type === MANGO_FRUIT; // Only display for Avocado or Mango

    const boxWeightCheckId = boxWeightChecks[0];
    useEffect(() => {
        if (!boxWeightCheckId) {
            return;
        }
        callAvosApi(`/tests/${boxWeightCheckId.test_id}/`).then((response) => {
            setCheck(formatCheck2Frontend(response.data));
        });
    }, [boxWeightCheckId]);

    if (isLoading) {
        return <Loading />;
    }

    if (!layer || !checks) {
        return null;
    }

    const formatAvocado2Frontend = (avocados) => {
        if (Array.isArray(avocados)) {
            return avocados.map((avocado) => {
                const { meta, ...rest } = avocado;
                const metaObject = meta.reduce((acc, item) => ({
                    ...acc,
                    [item.key]: item.value
                }), {});

                return { ...rest, ...metaObject };
            });
        }
        return [];
    };

    return <>
        {fruitTypeCheck && <div className="w-100 pb-5">
            <div css={css`border-radius:8px;`} className="bg-body shadow p-5 " >
                <div className="d-flex">
                    <div>
                        <h3 className=" mb-0">{title}</h3>
                    </div>
                    <div className="ms-auto">
                        {localCheck && <Button color="secondary"
                            title={title}
                            outline size="xs"
                            onClick={() => navigate(`/layer/${layer.id}/add-check/${boxWeightCheckId.test_id}/summary`)}
                        >View Check</Button>}
                    </div>
                </div>
                <div className="pt-3">
                    {!localCheck && <div className="text-warning">No {title} available.</div>}
                    {localCheck?.avocados && <BoxItem avocados={formatAvocado2Frontend(localCheck.avocados)} check={localCheck} layer={layer} title={`Check ${localCheck.test_id}`} />}
                </div>
            </div>
        </div>}
    </>;
}

BoxWeightCheck.propTypes = {
    title: PropTypes.string
};
