import axios from "axios";
import dayjs from "dayjs";
import { askReauthentication } from "../actions/Auth/actions";
import { scopes, tokenType } from "../authConfig";
import { msalInstance } from "../msal-instance";
import { toast } from "../utils/toast";
import { IS_DEMO } from "./urls";

const TOAST_DEBOUNCE_SECONDS = 30;
let lastToastTime = dayjs().subtract(TOAST_DEBOUNCE_SECONDS + 1, "seconds"); // Initialize to ensure the first error can show


const accessTokenRequest = {
    scopes,
    redirectUri: "/blank.html",
};

export function processServerError(error) {
    if (Array.isArray(error)) {
        // * Handle the Pydantic RequestValidationError
        error.forEach((err) => processServerError(err));
    } else if (typeof error === "object") {
        // * Check if 'detail', 'message', or 'msg' properties are present
        if ("errors" in error && Array.isArray(error.errors)) {
            error.errors.forEach((err) => processServerError(err));
        } else if ("detail" in error) {
            toast.warning(error.detail);
        } else if ("message" in error) {
            toast.warning(error.message);
        } else if ("msg" in error) {
            toast.warning(error.msg);
        }
    } else if (typeof error === "string") {
        toast.warning(error);
    }
}


export interface APIClientOptions {
    onSuccess?: {
        showMessage: boolean;
        message: string;
    };
}

const apiClient = ({ onSuccess }: APIClientOptions = {}) => {
    const defaultOptions = {
        method: "get",
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Create instance
    const instance = axios.create(defaultOptions);
    // // Set the AUTH token for any request
    if (!IS_DEMO) {
        instance.interceptors.request.use(async (config) => {
            // const token = localStorage.getItem("avos_id_token");
            const token = await msalInstance.acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => accessTokenResponse)
                .catch((error) => {
                    // Acquire token silent failure
                    console.log("acquire token failed", error); // eslint-disable-line no-console
                    askReauthentication();
                });
            config.headers.Authorization = token ? `Bearer ${token[tokenType]}` : "";
            return config;
        });
    }

    // Use the same publicClientApplication instance provided to MsalProvider

    instance.interceptors.response.use(
        (data) => {
            if (onSuccess && onSuccess.showMessage) {
                toast.success(onSuccess.message);
            }
            return Promise.resolve(data);
        },
        (error) => {
            console.log("theError", error, error.response); // eslint-disable-line no-console
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("data", error.response.data); // eslint-disable-line no-console
                console.log("status", error.response.status); // eslint-disable-line no-console
                console.log("headers", error.response.headers); // eslint-disable-line no-console
                if (error.response.status === 400) {
                    processServerError(error.response.data);
                } else if (!error.config.no_toast && error.response.status === 401) {
                    askReauthentication();
                    processServerError(error.response.data);
                } else if (error.response.status === 422) {
                    processServerError(error.response.data);
                } else if (!error.config.no_toast && error.response.status === 403) {
                    toast.warning("Sorry, you have insufficient rights for this action.");
                    // askReauthentication()
                } else if (!error.config.no_toast && error.response.status === 404 && error.response.data.type && error.response.data.type === "model_not_found") {
                    processServerError(error.response.data);
                } else if (!error.config.no_toast && error.response.status === 404) {
                    toast.error("Sorry, this api endpoint cannot be reached.");
                } else if (error.response.status === 500) {
                    toast.warning(error.response.data?.message || error.response.statusText);
                } else if (error.response.status === 0) {
                    // On appservice restart a status code of 0 is returned 9/10 times this due to a new release
                    // On auth/me this error is supressed

                    // NO_NETWORK ERRROR
                    axios.get("https://dns.google/resolve", {
                        params: {
                            name: "google.com",
                            type: "A",
                        },
                        timeout: 1000,
                    })
                        .then(() => {
                            if (!error.config.no_toast) {
                                toast.info(`We are restarting our service due to an update! Please retry in a minute.`, {
                                    pauseOnFocusLoss: false
                                });
                            }
                        })
                        .catch(() => {
                            console.log("User lost internet access"); // eslint-disable-line no-console
                            if (!error.config.no_toast) {
                                toast.error(<span>Your internet connection is <b> disrupted </b>. Please retry when you are back online.</span >, {
                                    pauseOnFocusLoss: false,
                                });
                            }

                        });
                } else if (!error.config.no_toast) {
                    processServerError(error.response.data);
                    // toast.warning(`${error.message}. View console for more info.`);
                }
                return Promise.reject(error);
            } if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request); // eslint-disable-line no-console
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message); // eslint-disable-line no-console
            }

            // Something happened in setting up the request that triggered an Error
            console.log("Error", error); // eslint-disable-line no-console
            if (dayjs().diff(lastToastTime, "seconds") > TOAST_DEBOUNCE_SECONDS) { // More than 30 seconds since the last toast
                toast.warning(`${error.message}. View console for more info.`);
                lastToastTime = dayjs(); // Update the time of the last shown toast
            }

            return Promise.reject(error);
        }
    );

    return instance;
};

export default apiClient();

export const createAPIClient = (options?: APIClientOptions) => apiClient(options);
