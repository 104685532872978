import { defineAction } from "redux-define";

export const TheApp = defineAction("the-app");

export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const STATE = "STATE";
export const FILTER = "FILTER";
export const SELECT = "SELECT";
export const UNSELECT = "UNSELECT";
export const CREATE = "CREATE";
export const RESET = "RESET";
