import { Row, Col } from "reactstrap";
import Pagination from "../Pagination";


export interface PaginationRowProps {
    results: any[];
    totalCount: number;
    filter: any;
    onPageChanged: (page: number) => void;
    updateLimit: (limit: number) => void;
}

export const PaginationRow = ({ results, totalCount, filter, onPageChanged, updateLimit }: PaginationRowProps) => {
    return <Row className={`align-items-center py-0 mt-auto ${!Array.isArray(results) || results.length <= 0 ? "invisible" : ""}`}>
        <Col md="4" className="py-5">
            <Pagination totalRecords={totalCount} pageLimit={filter.limit} pageNeighbours={1} onPageChanged={onPageChanged} />
        </Col>
        <Col md="4" className="py-5 text-center">
            <small>
                {results.length} of {totalCount}
            </small>
        </Col>
        <Col md="4" className="py-5 text-md-end text-center col-sm">
            <div className="btn-group-sm " role="group" aria-label="limit">
                {[12, 25, 50, 100].map((i) => (<button key={i} type="button" className={`ms-1 btn btn-outline-dark ${i === filter.limit ? "active" : ""}`} onClick={() => updateLimit(i)}>{i}</button>))}
            </div>
        </Col>
    </Row>;
};
