
import { DEFAULT_FRUIT, } from "../fruitNames";
import {
    baseConfig,
    headerLinks,
    ripening_stages_firmness
} from "../constants";
import { RootConfigType } from "../constantsTyped";


export const GlobalPacificConfig: RootConfigType = {
    ...baseConfig,
    default_fruit_type: DEFAULT_FRUIT,
    indicate_quality_at_defects: false,
    strict_check_size: false,
    show_atron_pressure_range_kg: true,
    indicate_soft_avocado_at_internal: false,
    suggest_color_stage: true,
    ripening_stages_firmness,
    headerMainLinks: [headerLinks.home, headerLinks.packinglist],
    headerSecondaryLinks: [headerLinks.packinglist, headerLinks.settings, headerLinks.logout],
};
