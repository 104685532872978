/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export default function SanitizeUnexpectedFields({ form, object, setObject } : any) {
    const expected_fields = form.filter((i) => i.name).map((i) => i.name);
    const [debugModal, setDebugModal] = useState(false as false | string);

    const unexpected_fields = Object.keys(object).filter((i) => !expected_fields.includes(i));

    if (unexpected_fields.length === 0) {
        return null;
    }

    const cleanUp = (key) => {
        delete object[key];
        setObject(object);
        setDebugModal(false);
    };


    return <>
        <Alert color="warning" >
        There are unexpected/deprecated fields on this object.<br></br>
            <div className="py-2">
                {unexpected_fields.map((i) => <div key={i} className="fw-bold me-1" onClick={() => setDebugModal(i)}>{i}, click to inspect.</div>)}
            </div>
        Inspect each item to resolve or ask a developer to double check it.</Alert>
        <Modal size="lg" isOpen={debugModal as boolean} toggle={() => setDebugModal(false)} >
            <ModalHeader toggle={() => setDebugModal(false)} >Possible obsolete/deprecated data</ModalHeader>
            <ModalBody>
                <p>Please inspect the value of {debugModal}. When in doubt check in with a developer.</p>
                <div css={css`max-height: 70vh;overflow-y:scroll;`}>
                    <pre>
                        { debugModal && JSON.stringify(object[debugModal], null, 2)}
                    </pre>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={() => cleanUp(debugModal)}>Clean up</Button>
                <Button color="light" onClick={() => setDebugModal(false)}>Cancel</Button>
            </ModalFooter>
        </Modal >
    </>;

}
