import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

export default function DatePickerInput(props) {
    const [state, setstate] = useState("");

    useEffect(() => {
        if (props.value) setstate(new Date(props.value));
        else setstate("");
    }, [props.value]);

    return (
        <div>
            <style>
                {`
                .react-datepicker__input-container input {
                    display: block;
                    width: 100%;
                    padding: 6px;
                    border-radius: 4px;
                    border: 2px solid #ccc;
                    font-size: 16px;
                    outline: none;
                  }
                  
                  .react-datepicker__input-container input:focus {
                    border-color: #007bff;
                  }
            `}
            </style>

            <DatePicker
                dateFormat="dd-MM-yyyy"
                selected={state}
                onChange={(date) => props.onChange(date)}
                placeholderText="dd-mm-yyyy"
            />
        </div>
    );
}

DatePickerInput.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
};
