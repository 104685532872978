/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useState } from "react";
import { DeviceEventType } from "../../actions/Devices/constants";
import useConfig from "../../actions/Tenants/config/configHook";

const DeviceQueueStatus = () => {
    const config = useConfig();
    const queue = useSelector((state) => state.devices.queue);
    // Assumption...if there is a TRIGGER event queued then the device is busy
    const busy = queue.some((i) => i.command === DeviceEventType.TRIGGER);
    const selected_mini = useSelector((state) => state.devices.selected_mini);
    const layer = useSelector((state) => state.layers.current);

    const [clickCount, setClickCount] = useState(1);

    const handleClick = () => setClickCount((prevCount) => prevCount + 1);

    const showDebugQueue = config.is_admin || clickCount % 4 === 0;

    const Content = () => {
        if (!selected_mini) {
            return (<div className="text-center text-warning">Please go to <Link to="/settings">Settings</Link> and select a device first.</div>);
        }
        // if (!fruit_lang[selected_mini.fruit_type]) {
        //     return (<div className="text-center text-warning">This device is configured for an unknown fruit type. Please contact support.</div>);
        // }
        if (busy) {
            return (<div className="text-center text-muted"><FontAwesomeIcon className="h2 fa-spin" icon={["far", "circle-notch"]} /></div>);
        }
        return (<div className="text-center text-muted">Avos is ready! <br></br>Place {layer.fruit_type} in Avos and press the scan-button to continue.</div>);
    };

    return (<><div css={css`min-height:120px;`} onClick={handleClick} className="d-flex align-items-center justify-content-center">
        <Content />
    </div>
    {showDebugQueue
    && <div className="text-start">
        <h6>Debug queue:</h6>
        <div className="w-100 text-start p-3 " css={css`background: #f9f9f9;`} >
            <pre>{JSON.stringify(queue, null, 2)}</pre>
        </div>
    </div>
    }

    </>);
};

export default DeviceQueueStatus;
