import { createContext, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Loading } from "../../../components/Helper/Loading";
import { getAllActiveTenantConfigs, getAllTenantDefects, getAllTenantFieldOptions, listTenantFacilityLocations } from "../actions";
import {
    CONFIG_TYPES,
    tenantIds
} from "./constants";
import { AgrovisionConfig } from "./tenants/Agrovision"; // !warning: active
import { BakkerConfig } from "./tenants/Bakker"; // !warning: active
import { ColruytConfig } from "./tenants/Colruyt"; // !warning: active
import { DeLaatConfig } from "./tenants/DeLaat"; // !warning: activeimport { useEffect, useRef } from "react";
import { DefaultConfig } from "./tenants/Default"; // !warning: active
import { ExperienceDataConfig } from "./tenants/ExperienceData"; // !warning: active
import { FruitifyExpertsConfig } from "./tenants/FruitifyExperts"; // !warning: active
import { GlobalPacificConfig } from "./tenants/GlobalPacific"; // !warning: active
import { MasterTenantConfig } from "./tenants/MasterTenant"; // !warning: active
import { MehadrinConfig } from "./tenants/Mehadrin"; // !warning: active
import { MehadrinNLConfig } from "./tenants/MehadrinNL"; // !warning: active
import { MissionBredaConfig } from "./tenants/MissionBreda"; // !warning: active
import { SHPTropicalConfig } from "./tenants/SHPTropical";

const ConfigContext = createContext(DefaultConfig);
export const rootConfigs: Record<string, any> = {
    [tenantIds.ExperienceData]: ExperienceDataConfig,
    [tenantIds.Bakker]: BakkerConfig,
    [tenantIds.BakkerBelgium]: BakkerConfig,
    [tenantIds.SHPTropical]: SHPTropicalConfig,
    [tenantIds.Agrovision]: AgrovisionConfig,
    [tenantIds.EDMissionBreda]: MissionBredaConfig, // This the tenant we created for them
    [tenantIds.ActualMission]: MissionBredaConfig, // This the tenant they own
    [tenantIds.MasterTenant]: MasterTenantConfig,
    [tenantIds.FruitifyExperts]: FruitifyExpertsConfig,
    [tenantIds.DeLaat]: DeLaatConfig,
    [tenantIds.colruyt]: ColruytConfig,
    [tenantIds.GlobalPacific]: GlobalPacificConfig,
    [tenantIds.Mehadrin_mtexukcom]: MehadrinConfig,
    [tenantIds.Mehadrin_mtexnl]: MehadrinNLConfig,
};


// * This component is a provider used MainContent -> RequireAuth -> ConfigProver to set a global config state
export function ConfigProvider({ tenant_id, refreshInterval = 6 * 60 * 1000, children }: { tenant_id?: string, refreshInterval?: false | number, children: JSX.Element }) {
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state?.auth?.user);
    const params = useParams();
    // * Get tenant_id from the url params or the user object, use that as an proxy to determine if we are on a tenant edit page
    const param_tenant_id = params?.tenant_id;
    tenant_id = (tenant_id || param_tenant_id || user.tenant.tenant_id) as string;


    let configs = useSelector((state: any) => state?.tenants?.configs)?.[tenant_id] || [];


    // * timer to refresh the active configs and form options list every 5 min
    const timer = useRef<any>(null);

    const getOptions = (tenant_id: string) => {
        if (tenant_id) {
            dispatch(getAllTenantFieldOptions(tenant_id) as any);
        }
    };

    const getConfigs = (tenant_id: string) => {
        if (tenant_id) {
            dispatch(getAllActiveTenantConfigs(tenant_id) as any);
        }
    };
    const getDefects = (tenant_id: string) => {
        if (tenant_id) {
            dispatch(getAllTenantDefects(tenant_id) as any);
        }
    };
    const getFacilityLocations = (tenant_id: string) => {
        if (tenant_id) {
            dispatch(listTenantFacilityLocations(tenant_id) as any);
        }
    };
    // Fetch the options & configs on load
    useEffect(() => {
        if (tenant_id) {
            getOptions(tenant_id);
            getConfigs(tenant_id);
            getDefects(tenant_id);
            getFacilityLocations(tenant_id);
        }
    }, [tenant_id]);

    // Setup an interval on non-config pages
    useEffect(() => {
        if (!param_tenant_id && tenant_id && typeof refreshInterval === "number" && refreshInterval > 0) {
            timer.current = setInterval(() => {
                if (tenant_id !== undefined) { // fuck u typescript
                    getOptions(tenant_id);
                    getConfigs(tenant_id);
                    getDefects(tenant_id);
                    getFacilityLocations(tenant_id);
                }
            }, refreshInterval as number);
        }

        return () => {
            if (timer.current) {
                clearInterval(timer.current);
                timer.current = null;
            }
        };
    }, [tenant_id, param_tenant_id, refreshInterval]);


    if (!configs) {
        return <Loading />;
    }

    // * Get hard coded config
    const rootConfig = rootConfigs[tenant_id] || DefaultConfig;

    // We are only intrested in the actual configs of these types
    // But for management purposes we store an raw array of configs in `configs`
    configs = configs.sort((a, b) => a.position - b.position);
    const root_config = configs.find((i) => i.type === CONFIG_TYPES.ROOT && i.value === "root")?.config || {};
    const layer_types = configs.filter((i) => i.type === CONFIG_TYPES.LAYER_TYPE).map((i) => i.config);
    const stages = configs.filter((i) => i.type === CONFIG_TYPES.STAGE).map((i) => i.config);
    const check_locations = configs.filter((i) => i.type === CONFIG_TYPES.CHECK_LOCATION).map((i) => ({ ...i.config, position: i.position }));
    const pdf_reports = configs.filter((i) => i.type === CONFIG_TYPES.PDF_REPORT).map((i) => i.config);
    const fruit_types = configs.filter((i) => i.type === CONFIG_TYPES.FRUIT_TYPE).map((i) => i.config);
    const color_codes = configs.filter((i) => i.type === CONFIG_TYPES.COLOR_CODE).map((i) => i.config);


    // use in .next() and .prev() function of conifg. Better to pass params from the call
    rootConfig.currentPage = params.action;

    const value = { ...rootConfig, layer_types, stages, check_locations, pdf_reports, fruit_types, root_config, color_codes, configs, tenant_id };
    return <ConfigContext.Provider value={value as any}>
        {children}
    </ConfigContext.Provider>;
}

// hook to get the config provided by the context above
// TODO: remove 'any' type here
export default function useConfig(): any {
    const config = useContext(ConfigContext);
    return config;
}


export function useTenantFormOptions() {
    const user = useSelector((state: any) => state?.auth?.user);
    const options_by_field = useSelector((state: any) => state?.tenants?.options_by_field);
    return options_by_field?.[user.tenant.tenant_id] || {};
}
