import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { chunk } from "../../../../../utils/chunk";

import { PDFPage } from "./PDFLayout";


export const PDFImages = ({ images }) => {
    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            justifyContent: "space-between",
            height: "42vh",
            marginTop: "10px",
        },
        titleFlex: {
            flexDirection: "row",
            justifyContent: "space-between",
        },
        cell: {
            width: "32.5%", // Adjust based on your layout
            marginBottom: "8px",
        },
        cellBorder: {
            border: "1px solid #e3e3e6",
            borderRadius: "5px",
            padding: "4px",
        },
        title: {
            color: "black",
            fontFamily: "Poppins-Medium",
            fontSize: "8px",
            marginBottom: "2px",
            textAlign: "left",
        },
        imageType: {
            opacity: 0.2
        },
        image: {
            objectFit: "contain",
        }
    });
    if (images.length === 0) {
        return null;
    }
    const chunkedPages = chunk(images, 6).map((pageImages) => {
        const filledPageImages = pageImages.concat(Array(6 - pageImages.length).fill({ emptySlot: true }));
        return chunk(filledPageImages, 3);
    });
    return chunkedPages.map((pageImages, pageIndex) => (
        <PDFPage footer key={pageIndex} >
            {pageImages.map((row, rowIndex) => <View style={styles.row} key={rowIndex}>
                {row.map((image, index) => {
                    if (image.emptySlot) {
                        return <View key={index} style={styles.cell} />;
                    }
                    return <View key={index} style={[styles.cell, styles.cellBorder]}>
                        <View>
                            <View style={styles.titleFlex}>
                                <Text style={styles.title}>{image.label}</Text>
                                <Text style={[styles.title, styles.imageType]}>{image.type.replace("-", " ").replace("_", " ")}</Text>
                            </View>
                            <View>
                                <Image
                                    style={styles.image}
                                    src={image.url}
                                />
                            </View>
                        </View>
                    </View>;
                })}
            </View>)}

        </PDFPage>
    ));
};

PDFImages.propTypes = {
    images: PropTypes.array.isRequired
};
