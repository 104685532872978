import { toast } from "../../utils/toast";
import { GET as USER_GET } from "../Auth/actionsTypes";
import {
    ADMIN_POLL_EVENT,
    CREATE_DEVICE,
    DELETE_DEVICE,
    GET_DEVICE,
    LIST_DEVICE,
    LIST_EVENT,
    LIST_STATUS,
    POLL_CHECK_EVENT,
    SELECT_DEVICE,
    SOFTWARE_STATUS,
    UPDATE_DEVICE
} from "./actionsTypes";
import { DeviceEventStatus, defaultFilter } from "./constants";

const deviceManagment = {
    statusHistory: false,
    eventHistory: false,
    isLoading: false,
    isUpdating: false,
    filter: defaultFilter,
    devices: false,
    current: false,
    selected_mini: false,
    queue: [],
    processed: [],
    failed: [],
    softwareStatus: false,
};

const removeDuplicates = (items) => {
    const ids = [];
    return items.filter((obj) => {
        const isDuplicate = ids.includes(obj.id);
        if (!isDuplicate) {
            ids.push(obj.id);
            return true;
        }
        return false;
    });
};

export default (state = deviceManagment, action) => {
    switch (action.type) {

    case LIST_EVENT.SUCCESS:
        return { ...state, isUpdating: false, eventHistory: action.payload.data };


    case LIST_STATUS.LOADING:
        return { ...state, isLoading: true };
    case LIST_STATUS.SUCCESS:
        return {
            ...state,
            isLoading: false,
            statusHistory: action.payload.data,
        };
    case LIST_STATUS.RESET:
        return {
            ...state,
            statusHistory: false,
        };
    case LIST_STATUS.ERROR:
        toast.info("Loading device status failed!", { render: "request sent!", type: "success", autoClose: 3500 });
        return { ...state, isLoading: false };

    case SOFTWARE_STATUS.LOADING:
        return { ...state, isLoading: true };
    case SOFTWARE_STATUS.SUCCESS:
        return {
            ...state,
            isLoading: false,
            softwareStatus: action.payload.data,
        };
    case SOFTWARE_STATUS.ERROR:
        toast.info("Loading software status failed!", { render: "request sent!", type: "success", autoClose: 3500 });
        return { ...state, isLoading: false };

    case LIST_DEVICE.LOADING:
        return { ...state, isLoading: true };

    case GET_DEVICE.LOADING:
        return { ...state, isLoading: true };


    case SELECT_DEVICE.LOADING:
        if (action.payload.request.data.type === "mini") {
            return { ...state, isUpdating: true, selected_mini: action.payload.request.data };
        }
        if (action.payload.request.data.type === "maxi") {
            return { ...state, isUpdating: true, atron_maxi_location: action.payload.request.data };
        }
        break;

    case SELECT_DEVICE.SUCCESS:
        return { ...state, isUpdating: false };

    case SELECT_DEVICE.ERROR:
        return {
            ...state, isUpdating: false, atron_maxi_location: null, selected_mini: null,
        };

    case CREATE_DEVICE.LOADING:
        toast.info("Creating device...", {
            toastId: "create-device",
            autoClose: 1500,
        });
        return { ...state, isUpdating: true };

    case UPDATE_DEVICE.LOADING:
        toast.info(`Updating ${action.payload.request.data.name}...`, {
            toastId: `update-device-${action.payload.request.data.device_id}`,
            autoClose: 1500,
        });

        if (state.devices && action.payload.request.data.device_id > 0) {
            return {
                ...state,
                isUpdating: true,
                isLoading: true,
                devices: state.devices.map((x) => (x.device_id === action.payload.request.data.device_id ? action.payload.request.data : x))
            };
        }
        return { ...state, isLoading: true };

    case DELETE_DEVICE.LOADING:
        toast.warning("Deleting device...", {
            toastId: "delete-device",
            autoClose: 1500,
        });
        return { ...state, isUpdating: true, devices: state.devices.filter((x) => x.UID !== action.payload.request.device_uid) };


    case LIST_DEVICE.ERROR:
    case GET_DEVICE.ERROR:
    case CREATE_DEVICE.ERROR:
    case UPDATE_DEVICE.ERROR:
    case DELETE_DEVICE.ERROR:
        return { ...state, isLoading: false, isUpdating: false };

    case LIST_DEVICE.SUCCESS:
        return { ...state, isLoading: false, devices: action.payload.data };

    case CREATE_DEVICE.SUCCESS:
        toast.update("create-device", { render: `${action.payload.data.name} created!`, type: "success", autoClose: 3500 });
        return {
            ...state, isLoading: false, isUpdating: false, current: action.payload.data,
        };

    case GET_DEVICE.SUCCESS:
        return { ...state, isLoading: false, current: action.payload.data };

    case UPDATE_DEVICE.SUCCESS:
        return {
            ...state, isLoading: false, isUpdating: false, current: action.payload.data
        };

    case DELETE_DEVICE.SUCCESS:
        toast.update("delete-device", { render: "Device deleted!", type: "info", autoClose: 3500 });
        return { ...state, isLoading: false, isUpdating: false };

    case USER_GET.SUCCESS:
        if (action.payload.data.selected_mini) {
            state = { ...state, selected_mini: action.payload.data.selected_mini };
        }
        if (action.payload.data.atron_maxi_location) {
            state = { ...state, atron_maxi_location: action.payload.data.atron_maxi_location };
        }

        return state;

    case POLL_CHECK_EVENT.SUCCESS: {
        const { queue, finished } = action.payload.data;

        // be easy on the renders here
        if (finished.length === 0 && state.queue.length === queue.length) {
            return state;
        }

        const processed = finished.filter((i) => i.status === DeviceEventStatus.PROCESSED);
        const failed = finished.filter((i) => i.status === DeviceEventStatus.FAILED);
        const timeout = finished.filter((i) => i.status === DeviceEventStatus.TIMEOUT);

        // * Show toast for new errrors
        failed.forEach((i) => {
            if (!state.failed.some((x) => x.id === i.id)) {
                toast.warning(<span><b>{i.data?.message || "Device could not handle job."}</b></span>);
            }
        });
        // * Show toast for timeout events
        timeout.forEach((i) => {
            if (!state.failed.some((x) => x.id === i.id)) {
                toast.warning(<span>Device could not handle job in time: <b>timeout.</b></span>);
            }
        });
        // * keep track of the failed events, so we do not have to show the errros again
        return { ...state, queue, processed, failed: removeDuplicates([...state.failed, ...failed, ...timeout]) };
    }

    case ADMIN_POLL_EVENT.SUCCESS: {
        const { queue, processed } = action.payload.data;
        return { ...state, admin_queue: queue, admin_processed: processed };
    }
    default:
        return state;
    }
    return state;
};
