/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable indent */
/** @jsxImportSource @emotion/react */
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import {
    addLayerChild, addLayerParent, createAndAddLayerChild, getLayer, getLayerChecks, removeLayerChild
} from "../../../../actions/Layers/actions";
import { LAYER_TYPE, VIEW } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { LayerTypeConfig } from "../../../../actions/Tenants/config/constantsTyped";
import { findChanges } from "../../../../utils/findChanges";
import { toast } from "../../../../utils/toast";
import LayerAutoComplete from "../../../Forms/LayerAutoComplete";
// import QRCodeScanner from "../../../Forms/QRCodeScanner";
import { getFilledArrayOrDefault } from "../../../../utils";
import QRCodeScan from "../../../Forms/QRCodeScan";
import { searchLayers } from "../../../Forms/utils";


interface LayerItem {
    id?: string;
    type?: string;
    label?: string;
    fruit_type?: string;
}

interface CreateLayerRelationModalState {
    isOpen: boolean;
    linking_layer: any;
    set_child: boolean;
    layer_type: LAYER_TYPE;
    parent?: any;
    children?: LayerItem[];
}

export default function CreateLayerRelationModal() {
    const config = useConfig();
    const layer = useSelector((state: any) => state.layers.current);
    const layer_children = useSelector((state: any) => state.layers.current.children);
    const dispatch = useDispatch<any>();
    const params = useParams();
    const navigate = useNavigate();
    const [scannerOpen, setScannerOpen] = useState(true);
    const [state, setState] = useState<CreateLayerRelationModalState>({
        isOpen: false,
        linking_layer: false,
        set_child: false,
        layer_type: LAYER_TYPE.PALLET,
        parent: undefined,
        children: layer_children
    });

    useEffect(() => {
        if (params.action === "link-parent") {
            openParentModal();
        }
        if (params.action === "link-children") {
            openChildrenModal();
        }
    }, [params.action]);

    const layer_type = config.get_layer_config(layer) as LayerTypeConfig;
    const parent_layer_type = layer_type.parent_layer_type ? config.get_raw_layer_config(layer_type.parent_layer_type as string) : undefined;
    const children_layer_type = layer_type.children_layer_type ? config.get_raw_layer_config(layer_type.children_layer_type as string) : undefined;
    const show_link_layer_button = (parent_layer_type || children_layer_type) && layer_type.show_link_layer_button;

    const openParentModal = () => {
        setState({
            isOpen: true,
            linking_layer: parent_layer_type,
            set_child: false,
            layer_type: parent_layer_type!.value,
            parent: undefined
        });
    };

    const openChildrenModal = () => {
        setState({
            isOpen: true,
            linking_layer: children_layer_type,
            set_child: true,
            layer_type: children_layer_type!.value,
            children: layer_children
        });
    };

    const refreshIndex = () => {
        const request = { view_strategy: layer_type?.show_checks?.view || VIEW.SELF };
        dispatch(getLayer(layer.id));
        dispatch(getLayerChecks(layer.id, request));
    };

    const _startLinking = () => {
        if (state.set_child) {
            if (Array.isArray(state.children)) {
                const { toBeAdded, toBeRemoved } = findChanges(layer_children, state.children, (x, y) => x.label === y.label);
                for (const { id, label, type } of toBeAdded) {
                    if (id) {
                        dispatch(addLayerChild(layer.id, id)).then(refreshIndex);
                    } else if (label) {
                        dispatch(createAndAddLayerChild(layer.id, label, type || state.layer_type)).then(refreshIndex);
                    }
                }
                for (const child of toBeRemoved) {
                    dispatch(removeLayerChild(layer.id, child.id) as any);
                }
            } else {
                // eslint-disable-next-line no-console
                console.error("No children selected");
            }
        } else if (state.parent && state.parent.id) {
            dispatch(addLayerParent(layer.id, state.parent.id)).then(refreshIndex);
        } else {
            // eslint-disable-next-line no-console
            console.error("No parent selected");
        }
        setState({ ...state, isOpen: false });
        if (layer_type?.show_link_layer_button && layer_type?.show_link_layer_button !== true) {
            navigate(`/layer/${layer.id}/action/${layer_type.show_link_layer_button}`);
        }
    };

    const setParent = (parent) => setState((state) => ({ ...state, parent }));
    const setChildren = (children) => setState((state) => ({ ...state, children }));

    const handleScan = (result: string) => {
        // * result is already filtered
        // const filteredResult = config.barcode_filter(result);
        const filteredResult = result;
        /**
         * TODO: @caution we only handle scan for parent also
         * This handle scan only for children
         */
        const emptyResult = (filteredResult: string) => {
            if (filteredResult && state.set_child) {
                // * If the result is not found in the children list, we add it
                if (!getFilledArrayOrDefault(state.children).find((i) => i.label === filteredResult)) {
                    setChildren([...state.children!, { label: filteredResult }]);
                }
            }
        };

        const searchResult = (data: LayerItem[]) => {
            if (data.length > 0 && state.set_child) {
                // !warning: Add all search results here
                setChildren([...state.children!, ...data]);
            } else {
                // if not exsite we create a new layer
                emptyResult(filteredResult);
            }
        };

        const handleError = (error: Error) => {
            toast.error(error.message);
        };
        searchLayers(filteredResult, state.layer_type)
            .then(searchResult)
            .catch(handleError);

    };

    return <>
        {
            show_link_layer_button && <div key={0} className="pe-2">
                {parent_layer_type && <Button
                    size="sm"
                    className="text-nowrap mb-2"
                    color="secondary" outline
                    onClick={() => openParentModal()}>Link {parent_layer_type.text} <FontAwesomeIcon icon={faLink} /></Button>}
                {children_layer_type && <Button
                    size="sm"
                    className="text-nowrap mb-2"
                    color="secondary" outline
                    onClick={() => openChildrenModal()}>Link {children_layer_type.text} <FontAwesomeIcon icon={faLink} /></Button>}
            </div>
        }
        <Modal key={1} isOpen={state.isOpen} size="md">
            <ModalHeader >Link {state.linking_layer.text}</ModalHeader>
            <ModalBody>
                <div>
                    <Row className="align-items-center justify-content-center pb-2">
                        <Col >
                            <Label>{state.linking_layer.text}</Label>
                            {state.set_child && scannerOpen && <div className="pb-2">
                                <QRCodeScan
                                    onScan={handleScan}
                                     // TODO: enable before going to production and discuss if the same filter should be used
                                    // barcode_filter={config.barcode_filter}
                                    />
                            </div>}
                            <LayerAutoComplete type={state.layer_type} creatable={true} multi={state.set_child}
                                layer={state.set_child ? state.children : state.parent}
                                setLayer={state.set_child ? setChildren : setParent} />
                        </Col>
                    </Row>
                </div>
            </ModalBody>
            <ModalFooter>
                {state.set_child && <Button color="light" className="me-auto" onClick={() => setScannerOpen(!scannerOpen)}>{scannerOpen ? "Stop Scanner" : "Start Scanner"}</Button> }
                <Button color="light" onClick={() => setState({ ...state, isOpen: false })}>Cancel</Button>
                <Button color="success" onClick={() => _startLinking()}>Link <FontAwesomeIcon icon={faLink} /></Button>
            </ModalFooter>
        </Modal>
    </>;
}
