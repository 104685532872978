/** @jsxImportSource @emotion/react */

import { path_advance_ripening, path_atron_results, path_manual_check, path_manual_or_with_mini, path_results_with_form, path_summary, path_visual_check } from "../../../../actions/Tenants/config/constants";
import FilterableConfigList from "./FilterableConfigList";


export const flowForm = [
    {
        label: "Path",
        type: "single-select",
        name: "path",
        options: [
            { label: "Device Results", value: path_atron_results },
            { label: "Device Results with Form", value: path_results_with_form },
            { label: "Device Results or Manual Form (hybrid)", value: path_manual_or_with_mini },
            { label: "Manual Form", value: path_manual_check },
            { label: "Visual Check", value: path_visual_check },
            { label: "Advance Ripening", value: path_advance_ripening },
            { label: "Summary", value: path_summary },
        ]
    }];

const defaultField = {
    field: {
        path: path_manual_or_with_mini
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
    // exclude_layer_types: [],
    }
};
export default function FlowConfigForm({ name, title, fields, description, setArray }) {
    const getFieldTitle = (object) => <span>{object.path}</span>;
    const getFieldForm = () => flowForm;
    return <FilterableConfigList
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle} />;

}


