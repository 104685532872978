
/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import ParentLabelField from "./ParentLabelField";

class ParentLabelLinkField extends ParentLabelField {
    field_type = "parent_label_link_field";

    backend_table = "parents";

    get_backend_fieldname(): string[] {
        return [`${this.backend_table}.label`, `${this.backend_table}.id`];
    }

    parent_layer_type: string;

    constructor(key, label, layer_type, options = {}) {
        super(key, label, layer_type, options);
        this.parent_layer_type = layer_type;
    }

    display_component(): JSX.Element {
        if (this.parent) {
            return <Link to={`/layer/${this.parent.id}`}>{this.parent.label}</Link>;
        }
        return <span>-</span>;
    }

}

export default ParentLabelLinkField;
