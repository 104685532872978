/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import Plotly from "plotly.js-basic-dist";
import PropTypes from "prop-types";
import createPlotlyComponent from "react-plotly.js/factory";
import { useSelector } from "react-redux";
import { AVOS_GRAPH_COLORS, VIEW } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { Loading } from "../../../Helper/Loading";

const Plot = createPlotlyComponent(Plotly);

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
const isValidNumber = (number) => (number > 0 || parseFloat(number) === 0) && number !== "" && number !== null && number !== undefined;

export default function FirmnessGraph({ subconfig, title }) {
    const checks = useSelector((state) => state.layers.checks);
    const config = useConfig();
    const layer = useSelector((state) => state.layers.current);
    const isLoading = useSelector((state) => state.layers.isLoading);
    const { filter_field, filter_location, view } = subconfig;
    if (isLoading || !checks) {
        return <Loading />;
    }
    const fruit_type = config.get_fruit_type(layer.fruit_type);

    // Did we explicityl defined the field to use on this layer?
    // if not... use the default for the fruit type
    const the_field = subconfig?.field || fruit_type?.ripening_field;

    if (!the_field?.fieldname_getter) {
        return null;
    }

    const filtered_checks = checks
        .filter((i) => (filter_field && layer.id !== i.layer_id ? i[filter_field] === layer[filter_field] : true))
        .filter((i) => (filter_location && layer.id !== i.layer_id ? i.location === filter_location : true));

    if (filtered_checks.length === 0) {
        return null;
    }

    const ordered = filtered_checks.sort((a, b) => a.test_id - b.test_id).filter((i) => isValidNumber(i[the_field.fieldname_getter]));

    if (ordered.length === 0) {
        return null;
    }
    const avos_green = AVOS_GRAPH_COLORS.AVOS_SUCCESS;
    let graphs = [];
    if (view === VIEW.CHILDREN) {
        // Show multiple lines...for each children
        const layer_ids = filtered_checks.map((i) => i.layer_id).filter(onlyUnique);
        graphs = layer_ids.map((layer_id) => ({
            type: "scatter",
            x: ordered.filter((i) => i.layer_id === layer_id).map((i) => dayjs(i.created).toISOString()),
            // x: ordered.filter((i) => i.layer_id === layer_id).map((i) => dayjs(i.created).diff(first_day, "day", true)),
            y: ordered.filter((i) => i.layer_id === layer_id).map((i) => i[the_field.fieldname_getter]),
            mode: "lines+points+markers",
            name: the_field.label,
            marker: {
                color: avos_green,
                size: 10,
            },
            line: {
                color: avos_green,
                width: 3,
            },
        }));
    } else {
        // just one line
        graphs = [{
            type: "scatter",
            x: ordered.map((i) => dayjs(i.created).toISOString()),
            // x: ordered.map((i) => dayjs(i.created).diff(first_day, "day", true)),
            y: ordered.map((i) => i[the_field.fieldname_getter]),
            mode: "lines+points+markers",
            name: the_field.label,
            marker: {
                color: avos_green,
                size: 10,
            },
            line: {
                color: avos_green,
                width: 3,
            },
        }
        ];
    }

    const range = [0, Math.ceil(Math.max(...ordered.map((i) => i[the_field.fieldname_getter])) * 1.2)];


    const layout = {
        autosize: true,
        showlegend: false,
        margin: {
            l: 50,
            r: 10,
            t: 0,
            b: 30,
            pad: 0
        },
        yaxis: {
            fixedrange: true,
            title: the_field.label,
            range
        },
        xaxis: { fixedrange: true }
    };

    return <div className="w-100 pb-5">
        <div className="bg-body rounded shadow p-5 " >
            <div className="d-flex">
                <div>
                    <h3 className=" mb-0">{title}</h3>
                </div>
            </div>
            <div className="pt-3" css={css`min-height: 20rem;`}>
                <Plot
                    data={graphs}
                    useResizeHandler={true}
                    style={{ width: "100%", height: "100%" }}
                    layout={layout}
                    config={{
                        displayModeBar: false,
                        displaylogo: false,
                        staticPlot: true,
                    }}
                />
            </div>
        </div>
    </div >;
}
FirmnessGraph.defaultProps = {
    title: "Progress External Firmness",
};

FirmnessGraph.propTypes = {
    title: PropTypes.string,
    subconfig: PropTypes.shape({
        view: PropTypes.string,
        field: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        filter_field: PropTypes.string,
        filter_location: PropTypes.string,
    }),
};
