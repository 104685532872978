import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import DatePickerInput from "./DatePickerInput";

interface DatePickerProps {
    value: Date | string | null; // SQL-friendly format (yyyy-mm-dd)
    format?: string;
    onChange: (value: Date | string | null) => void;
}

export default function DatePicker({
    value = null,
    format = "YYYY-MM-DD",
    onChange,
}: DatePickerProps) {
    const [localState, setLocalState] = useState<Dayjs | null>(value ? dayjs(value) : null);

    useEffect(() => {
        setLocalState(value ? dayjs(value) : null);
    }, [value]);

    const toFormatted = (date: Dayjs | null) => {
        if (date && date.isValid()) {
            return date.format(format);
        }
        return null;
    };

    const handleChange = (newValue: string) => {
        const newDate = dayjs(newValue);
        setLocalState(newDate.isValid() ? newDate : null);

        // Ensure the value passed to `onChange` is in SQL format (yyyy-mm-dd)
        const formattedSql = newDate.isValid() ? newDate.format("YYYY-MM-DD") : null;
        onChange(formattedSql);
    };

    return (
        <DatePickerInput
            onChange={handleChange}
            value={toFormatted(localState) || ""}
        />
    );
}
