/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";


// StyledBox component for a styled container with flex layout
const StyledBox = ({ children, title }: {children: React.ReactNode, title?: string}) => {
    return (
        <div className="w-100 py-4">
            <div css={css`border-radius: 8px;`} className="bg-body shadow p-4">
                {title && <h3 className="mb-4">{title}</h3>}
                <div >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default StyledBox;
