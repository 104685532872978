import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";
import DeviceLastSeen, { getDeviceLastSeen } from "./DeviceLastSeen";


export default function DeviceStatus({ only_errors, link_settings }) {
    const selected_mini = useSelector((state) => state.devices.selected_mini);
    const [deviceState, offline, ,] = getDeviceLastSeen(selected_mini, true);

    const { host } = selected_mini;

    if (typeof host === "undefined") {
        return (
            <div>
                <Alert color="warning">Avos Mini is not configured yet.</Alert>
                {link_settings && <p><Link to="/settings">Configure Avos in your settings.</Link></p>}
            </div>
        );
    }

    if (offline) {
        return (<Alert color="warning">{deviceState.name} is probably not connected. <DeviceLastSeen device={deviceState} refresh={true} ></DeviceLastSeen> </Alert>);
    }

    if (only_errors) {
        return null;
    }

    return (
        <div>
            <Alert color="success">{deviceState.name} is online. <DeviceLastSeen device={deviceState} refresh={true} ></DeviceLastSeen></Alert>
        </div>
    );
}

DeviceStatus.defaultProps = {
    only_errors: false,
    link_settings: false,
};
DeviceStatus.propTypes = {
    only_errors: PropTypes.bool,
    link_settings: PropTypes.bool,
};
