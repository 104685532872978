/** @jsxImportSource @emotion/react */
import { Badge } from "reactstrap";
import Field, { DataSource } from "./Field";

class CheckLocationField extends Field {
    field_type = "check_location_field";

    backend_table = "check";

    is_filterable(): boolean {
        return true;
    }

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        this.value = this.value_filter("-", datasource);
        return this;

    }

    display_component(): JSX.Element {
        if (this.value === "-") {
            return <span>-</span>;
        }
        return <span >
            <Badge color="light" className="text-black">{this.value}</Badge>
        </span>;
    }

}

export default CheckLocationField;
