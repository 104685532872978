/** @jsxImportSource @emotion/react */
import { faCaretLeft, faCaretRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
    Button, FormGroup,
    FormText,
    Label
} from "reactstrap";
import { getDayOptions } from "../Helper/DatePicker";
import { RadioButtons } from "./RadioButton";

export interface DateRadioButtonsProps {
    setValue: (value: string) => void;
    value: string;
    label: string;
    forward: number;
    backward: number;
    description: string;
}

export default function DateRadioButtons({ setValue, value, label, forward = 1, backward = 7, description = "" }) {

    const [days, setDays] = useState([backward, forward]);

    const options = getDayOptions(days[0], days[1]);


    const goBackward = () => {
        setDays([days[0] + 7, days[1] - 7]);
    };

    const goForward = () => {
        setDays([days[0] - 7, days[1] + 7]);
    };

    return (<FormGroup>
        {label && <Label>{label}</Label>}
        {description && <FormText className="d-block">{description}</FormText>}
        <div>
            <RadioButtons name="" color="secondary" className="mb-1 me-1" current={value} options={options} onChange={(_, new_value) => setValue(new_value)}></RadioButtons>
        </div>
        <div>
            <Button color="link" size="sm" onClick={() => goBackward()}><FontAwesomeIcon icon={faCaretLeft} /> Earlier</Button>
            <Button color="link" size="sm" onClick={() => goForward()}>Later <FontAwesomeIcon icon={faCaretRight} /></Button>
        </div>
    </FormGroup>);
}

