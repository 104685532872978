import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { isOffline } from "../../actions/Devices/util";
import { callAvosApi } from "../../utils/useAvosApiHook";


dayjs.extend(relativeTime);


//
// Custom hook for re-evaluating the time since last heartbeat
//
export function getDeviceLastSeen(device, refresh) {
    const [now, setNow] = useState(Date.now());
    const [deviceState, setDevice] = useState(device);


    // Do not be confused. This effect is not needed to actually check if the mini is online.
    // This effect is needed to refresh the last seen in the DOM every 1s
    useEffect(() => {
        setDevice(device);
        const interval = setInterval(() => {
            setNow(Date.now());
        }, 1000);

        return () => clearInterval(interval);
    }, [device.device_id, device.last_seen]);

    useEffect(() => {
        let poll_interval;
        if (refresh) {
            poll_interval = setInterval(() => {
                callAvosApi(`${`/device/management/${device.UID}`}/`).then((response) => setDevice(response.data));
            }, 30000);
        }

        return () => clearInterval(poll_interval);
    }, [refresh, device.UID]);

    if (!device || !deviceState.last_seen) {
        return [deviceState, true, false, now];
    }

    const offline = isOffline(deviceState);

    return [deviceState, offline, dayjs.utc(deviceState.last_seen).fromNow(true), now];
}

//
// Component for show the relative time since device has been seen
//
export default function DeviceLastSeen({ device, prefix, refresh }) {

    const [deviceState, offline, last_seen_relative,] = getDeviceLastSeen(device, refresh);

    if (!deviceState.last_seen) {
        return <span>-</span>;
    }

    return <span className={!offline ? "text-success" : "" }>
        {prefix ? `${prefix} ` : ""}{last_seen_relative} ago
    </span>;

}

DeviceLastSeen.defaultProps = {
    prefix: "Last activity",
    refresh: false
};

DeviceLastSeen.propTypes = {
    device: PropTypes.object,
    prefix: PropTypes.string,
    refresh: PropTypes.bool
};
