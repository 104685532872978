/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mrAvos from "../../../img/avos-eye.jpg";

export default function NoMatch404() {
    const naviagte = useNavigate();
    return (
        <Container>
            <Row className="justify-content-center align-items-center" css={css`min-height: 82vh`} >
                <Col className="text-center">
                    <img src={mrAvos} css={css`max-width: 11rem;width: 50%;`} />
                    <h1 className="mb-0 pt-4">Ooopss</h1>
                    <p className="mb-0 pt-2 pb-4">This requested page does not exist.</p>
                    <div>
                        <button className="btn btn-primary mx-2 text-nowrap" onClick={() => naviagte("/admin")}>Back to admin landing page</button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
