import dayjs from "dayjs";
import "dayjs/locale/en-gb";

import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.locale("en-gb");

dayjs.extend(customParseFormat);

export const FULL_DATE_FORMAT = "DD-MM-YYYY HH:mm";
export const DATE_FORMAT_WITHOUT_CLOCK = "DD-MM-YYYY";

const firstDate = dayjs(new Date());
export const format = (date, format = FULL_DATE_FORMAT) => {
    const dayjsDate = dayjs(date);
    return firstDate.isSame(dayjsDate) ? "-" : dayjsDate.format(format);
};
export const isAfter = (dateFirst, dateSecond) => dayjs(dateFirst).isAfter(dateSecond);
