/** @jsxImportSource @emotion/react */
import { Badge } from "reactstrap";
import { View } from "@react-pdf/renderer";
import Field, { DataSource } from "./Field";

class CheckColorBadgeField extends Field {
    field_type = "check_color_code_field";

    backend_table = "check";

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        let value = "success";
        if (datasource.check && datasource.check[this.fieldname_getter]) {
            value = datasource.check[this.fieldname_getter];
        }
        this.value = this.value_filter(value, datasource);
        return this;
    }

    display_component(): JSX.Element {
        return <span >
            <Badge style={{ padding: "10px" }} color={this.value}> </Badge>
        </span>;
    }


    display_vector(): JSX.Element {
        const colors = {
            light: "#f8f9fa",
            success: "#198754",
            danger: "#dc3545",
            warning: "#ffc107",
            info: "#0dcaf0",
            primary: "#0d6efd",
            secondary: "#6c757d",
        };
        return <View style={{ width: "11px", height: "11px", borderRadius: "50%", backgroundColor: colors[this.value] }} >
        </View>;
    }

}

export default CheckColorBadgeField;
