/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import useConfig from "../../../../actions/Tenants/config/configHook";
import NumericInputField from "../../../Forms/NumericInputField";
import { Loading } from "../../../Helper/Loading";
import { AvocadoIndex } from "./Images";
import Box from "./Layout";
import usePatchAvocado from "../usePatchAvocado";

export default function TemperatureInput() {
    const config = useConfig();
    const check = useSelector((state) => state.checks.current);
    const isLoading = useSelector((state) => state.checks.isLoading);
    const layer = useSelector((state) => state.layers.current);
    const patchAvocado = usePatchAvocado();
    return (
        <Box headerTitle={config.title()} hideButtons={isLoading}>
            {isLoading && <Loading></Loading>}
            {!isLoading
                && <div css={check.location === "validation" ? css`max-width: 64rem;` : css`max-width: 40rem;`}>
                    <Table size="sm" responsive={true} >
                        <thead>
                            <tr className="align-middle">
                                <th className="text-muted text-uppercase text-start ">{layer.fruit_type}</th>
                                <th className="text-muted text-uppercase text-start ">Temperature</th>
                            </tr>
                        </thead>
                        <tbody>
                            {check.avocados.map((i, index) => (
                                <tr key={i.avocado_id} className="align-items-center pb-2">
                                    <td className="text-start text-uppercase align-middle" ><AvocadoIndex avocado={i} key={i.avocado_id} index={index + 1}></AvocadoIndex></td>
                                    <td className="text-start align-middle" css={css`width: 10rem;`}>
                                        <NumericInputField
                                            field="manual_temperature"
                                            db_value={i.manual_temperature}
                                            setValue={(manual_temperature) => patchAvocado(i, "manual_temperature", manual_temperature)}
                                            min={-100}
                                            max={100}
                                            unity="°C"
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            }
        </Box>

    );
}
