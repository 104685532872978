/** @jsxImportSource @emotion/react */
import { faFlagSwallowtail } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import LayerMetaColorBadgeField from "./LayerMetaColorBadgeField";

// * Use this component to display manual flag set on check
// * IDP will copy and convert it to layer_meta...
class LayerFlagField extends LayerMetaColorBadgeField {
    field_type = "check_flag_field";

    backend_table = "layer_meta";

    convert_flag_state_color(state: string | null | undefined) {
        if (state === "-") {
            return "light";
        }
        if (state) {
            // lets be 100% that `flag-` is removed
            const output = state.split("flag-").pop();
            return output;
        }
        return "light";
    }

    // Manual flags are displayed with the FontAwesome flag
    display_component(): JSX.Element {
        return <span className={`text-${this.convert_flag_state_color(this.value)}`}>
            <FontAwesomeIcon icon={faFlagSwallowtail} />
        </span>;
    }

}

export default LayerFlagField;
