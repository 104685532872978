const useAvosMini = {
    form_field_id: "use_avos_mini",
    label: "Use AVOS mini",
    name: "is_manual",
    type: "radio",
    options: [
        { label: "Yes", value: false },
        { label: "No", value: true }
    ],
    mandatory: true,
};

const useBulkEdit = {
    form_field_id: "use_bulk_edit",
    label: "Use bulk edit",
    name: "use_bulk_edit",
    type: "radio",
    options: [
        { label: "No", value: 1 },
        { label: "Yes, 10 fruit", value: 10 },
        { label: "Yes, 12 fruit", value: 12 },
        { label: "Yes, 20 fruit", value: 20 },
    ],
    mandatory: true,
};

const doQualityCheck = {
    form_field_id: "do_quality_check",
    label: "Destructive check?",
    name: "do_quality_check",
    type: "radio",
    options: [
        { label: "No", value: "no" },
        { label: "Yes", value: "yes" }
    ],
};

export const checkFormFields = [
    useAvosMini,
    useBulkEdit,
    doQualityCheck
];
