import { Middleware, Store, applyMiddleware, compose, createStore } from "redux";
import axiosMiddleware from "redux-axios-middleware";
import { createLogger } from "redux-logger";
import { GET as AUTH_GET, BACKEND_VERSION } from "../actions/Auth/actionsTypes";
import { POLL_CHECK_EVENT } from "../actions/Devices/actionsTypes";
import { ROOT_URL } from "../constants/urls";
import rootReducer from "../reducers";
import { AvosAction } from "../actions/types";

export interface AxiosAction {
    payload: {
        request: {
            url: string;
            method: string;
            data?: any;
            headers?: any;
            params?: any;
            no_toast?: boolean;
        }
    }
    types?: [string, string, string];
}

export const configureStore = (
    apiClient: any,
    initialState?: any
): Store<any, AvosAction> => {
    apiClient.defaults.baseURL = `${ROOT_URL}api`;

    const logger = createLogger<any>({
        // Options for the logger
    });

    const hideEventTypes: string[] = [
        POLL_CHECK_EVENT.LOADING,
        POLL_CHECK_EVENT.SUCCESS,
        AUTH_GET.LOADING,
        AUTH_GET.SUCCESS,
        BACKEND_VERSION.LOADING,
        BACKEND_VERSION.SUCCESS
    ]; // Add the event types you want to hide

    const middleware: Middleware<any>[] = [
        axiosMiddleware(apiClient) as Middleware<any>,
        (store) => (next) => (action: any) => {
            if (!hideEventTypes.includes(action.type)) {
                logger(store)(next)(action);
            } else {
                next(action);
            }
        }
    ];

    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middleware))
    );
};

let store: Store<any, AvosAction>;

export const initStore = (
    apiClient: any,
    initialState?: any
) => {
    store = configureStore(apiClient, initialState);
};

export const getStore = () => store;
