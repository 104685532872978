/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Col, Container, Row } from "reactstrap";


export default function Landing() {
    return (
        <Container>
            <Row className="justify-content-center align-items-center" css={css`min-height: 82vh`} >
                <Col className="text-center">
                    <h1 className="mb-0 pt-4">Welcome to Admin Page</h1>
                    <p className="mb-0 pt-2 pb-4">This is the landing page for admin.</p>
                </Col>
            </Row>
        </Container>
    );
}
