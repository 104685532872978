/* eslint-disable no-case-declarations */
import { DELETE, LIST, UPDATE, CREATE } from "./actionTypes";
import { createEntityReducer } from "../entityReducer";
import { Entity, EmailRecipients, EntityState } from "../reducer";


export interface SupplierInfo extends Entity{
    label: string;
    supplier_id: string;
    fruit_type: string | null;
    email_recipients: EmailRecipients;
}

export interface SupplierGrowerRelation extends Entity{
    supplier_id: string;
    grower_name: string;
    fruit_type: string;
}

export interface Supplier extends Entity{
    country: string;
    fruit_types: string[];
    label: string;
    name: string;
    infos:{[fruit_type:string]: SupplierInfo}
    growers:{[fruit_type:string]: SupplierGrowerRelation[]}
}

export type SupplierState = EntityState<Supplier>;

export default createEntityReducer<SupplierState>({ LIST, CREATE, UPDATE, DELETE });
