/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faCamera, faCircleNotch, faUpload } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";
import ImageUploading from "react-images-uploading";
import { Button } from "reactstrap";

export function UploadImageFiles({ saveImage, images, deleteImage, isLoading, setActionType }) {

    const _images = images.map((image) => ({ data_url: image.url, id: image.image_id || image.id }));
    const [localIsLoading, setIsLoading] = useState(false);


    const maxNumber = 25;
    const capture = (image) => image.data_url;
    const onChange = (imageList) => {
        setIsLoading(true);
        const newImages = imageList.filter((i) => !i.id);
        // also keep a local loading state to prevnet flickering of the loading state
        const timeBetweenUploads = 1000;
        setTimeout(() => setIsLoading(false), newImages.length * timeBetweenUploads + 100);

        newImages.forEach((img, index) => {
            const capturedImage = capture(img);
            setTimeout(() => {
                saveImage(capturedImage, false);
            }, index * timeBetweenUploads);

        });

    };

    const _deleteImage = (image) => {
        if (image.id) {
            deleteImage({ id: image.id, image_id: image.id });
        }
    };

    const getRemoveButtonStyle = () => {
        return {
            position: "absolute",
            top: "0",
            right: "-20%",
            transform: "translate(-50%, -50%)",
            width: "30px",
            height: "30px",
            backgroundColor: "red",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            color: "white",
        };
    };

    const _isLoading = isLoading || localIsLoading;

    return (
        <div>
            <ImageUploading
                multiple
                value={_images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemove,
                    isDragging,
                    dragProps,
                }) => (
                    <div className="upload__image-wrapper">
                        <div
                            css={css`width: 100%; padding: 5rem 2rem; border: 2px dashed ${(isDragging ? "var(--bs-success)" : "#ddd")};`}
                            className="text-center"

                            {...dragProps}
                        >
                            <Button className="mb-3 mx-2" color="primary" disabled={_isLoading} onClick={onImageUpload} >
                                <FontAwesomeIcon spin={_isLoading} icon={_isLoading ? faCircleNotch : faUpload} /> Upload images
                            </Button>
                            <Button className="mb-3 mx-2" color="primary" disabled={_isLoading} onClick={() => setActionType("camera")} >
                                <FontAwesomeIcon spin={_isLoading} icon={_isLoading ? faCircleNotch : faCamera} /> Use camera
                            </Button>
                        </div>
                            &nbsp;
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                            }}
                        >
                            {imageList.map((image, index) => (
                                <div
                                    key={index}
                                    className="image-item m-3 position-relative" // Increase margin here
                                    style={{
                                        textAlign: "center",
                                        position: "relative",
                                        flex: "0 0 140px", // flex basis
                                    }}
                                >
                                    <div
                                        className="border border-1 p-3 rounded position-relative"
                                        style={{
                                            height: "140px",
                                            display: "inline-block",
                                            width: "100%",
                                        }}
                                    >
                                        <img
                                            src={image.data_url}
                                            alt=""
                                            height="100%"
                                            width="100%"
                                            style={{ objectFit: "cover" }}
                                        />
                                    </div>
                                    <div
                                        style={getRemoveButtonStyle()}
                                        onClick={() => {
                                            onImageRemove(index);
                                            _deleteImage(image);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </ImageUploading>
        </div>
    );
}

UploadImageFiles.propTypes = {
    saveImage: PropTypes.func.isRequired,
    images: PropTypes.array,
    deleteImage: PropTypes.func,
    isLoading: PropTypes.bool,
    setActionType: PropTypes.func,
};
