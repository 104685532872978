import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { getUser } from "./actions/Auth/actions";
import { getStore } from "./store";

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.handleRedirectPromise().then((response) => {
    console.info("handle Redirect Promise"); // eslint-disable-line no-console
    if (response) {
        msalInstance.setActiveAccount(response.account);
        getStore().dispatch(getUser());
    }
}).catch((error) => console.error("AAD redirect promise Error", error)); // eslint-disable-line no-console

msalInstance.addEventCallback((event) => {
    // set active account after redirect
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const { account } = event.payload;
        msalInstance.setActiveAccount(account);
    }
}, (error) => console.log("error after AAD login", error)); // eslint-disable-line no-console

export {
    msalInstance,
};
