import { AnyAction } from "redux";
import { CREATE, GET, GET_CHECKS, GET_CONDITIONS, GET_LAYERS, LIST } from "./actionsTypes";

const endpoint = "facility-locations/";

export const listFacilityLocations = (filter: { offset: number, limit: number, type?: string, stage?: string }): AnyAction => {
    const params = {
        ...filter
    };
    return {
        types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
        payload: {
            request: {
                params,
                method: "GET",
                url: `${endpoint}`,
            },
        },
    } as unknown as AnyAction;
};


export const addLocationCondition = (data): AnyAction => {
    return {
        types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint + data.location_id}/condition`,
                data
            },
        },
    } as unknown as AnyAction;
};
export const getFacilityLocation = (location_id): AnyAction => {
    return {
        types: [GET.LOADING, GET.SUCCESS, GET.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint + location_id}`,
            },
        },
    } as unknown as AnyAction;
};

export const listLocationConditions = (location_id, from_date, to_date): AnyAction => {
    return {
        types: [GET_CONDITIONS.LOADING, GET_CONDITIONS.SUCCESS, GET_CONDITIONS.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint + location_id}/condition/${from_date}/${to_date}`,
            },
        },
    } as unknown as AnyAction;
};

// export const getDateCondition = (location_id: string, edit_date: string): AnyAction => {
//     return {
//         types: [GET_DATE_CONDITION.LOADING, GET_DATE_CONDITION.SUCCESS, GET_DATE_CONDITION.ERROR],
//         payload: {
//             request: {
//                 method: "GET",
//                 url: `${endpoint + location_id}/condition/date/${edit_date}`
//             },
//         },
//     } as unknown as AnyAction;
// };

export const listAllocatedLayers = (location_id: string, date_from: string, date_to: string): AnyAction => {
    return {
        types: [GET_LAYERS.LOADING, GET_LAYERS.SUCCESS, GET_LAYERS.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint + location_id}/allocated_layers/${date_from}/${date_to}`,
            },
        },
    } as unknown as AnyAction;
};

export const listLocationChecks = (location_id: string, date_from: string, date_to: string): AnyAction => {
    return {
        types: [GET_CHECKS.LOADING, GET_CHECKS.SUCCESS, GET_CHECKS.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint + location_id}/checks/${date_from}/${date_to}`,
            },
        },
    } as unknown as AnyAction;
};
