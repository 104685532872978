/** @jsxImportSource @emotion/react */
import { faExchange, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button, Col, Container,
    FormGroup, FormText,
    Input,
    Label,
    Modal, ModalBody, ModalFooter, ModalHeader, Row, Table
} from "reactstrap";
import { createTenantFieldOption, deleteAllTenantFieldOption, deleteTenantFieldOption, getTenant, getTenantFieldOptions, importTenantFieldOption, updateTenantFieldOption } from "../../../actions/Tenants/actions";
import useConfig from "../../../actions/Tenants/config/configHook";
import MetaForm from "../../Forms/MetaForm";
import { useManagedOptionsHook } from "../../Forms/useManagedOptionsHook";
import { Loading } from "../../Helper/Loading";
import TenantPageWrappedInConfigProvider from "./TenantPageWrappedInConfigProvider";

const defaultValues = {
    country: null,
    supplier: null,
    global_gap_number: null,
    label: "",
    value: ""

};

const defaultCsvOption = {
    country: null,
    supplier: null,
    fruit_type: null,
    global_gap_number: null,
    label: null,
    value: null
};

// * Wrap this page in the config of the tenant we are editing
export default function WrappedInConfigProviderTenantEditForm() {
    return <TenantPageWrappedInConfigProvider>
        <TenantOptionsEditForm/>
    </TenantPageWrappedInConfigProvider>;
}

function TenantOptionsEditForm() {
    const current = useSelector((state) => state.tenants.current);
    const options = useSelector((state) => state.tenants.options);
    const isLoading = useSelector((state) => state.tenants.isLoading);
    const [isOpen, setModal] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const params = useParams();
    const dispatch = useDispatch();
    const [query, setQuery] = useState(defaultValues);
    const [showAllToBeImportedItems, setShowAllToBeImportedItems] = useState(false);
    const navigate = useNavigate();
    const config = useConfig();

    const { tenant_name } = current;
    const { field, tenant_id } = params;


    const countries = useManagedOptionsHook({
        object: {}, post_value: "", name: "country"
    });
    const fruit_types = useManagedOptionsHook({
        object: {}, post_value: "", name: "fruit_type"
    });
    const suppliers = useManagedOptionsHook({
        object: {}, post_value: "", name: "supplier"
    });
    const ggns = useManagedOptionsHook({
        object: {}, post_value: "", name: "global_gap_number"
    });
    const [importData, setImportData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(false);

    // * refresh the tenant object if needed
    // * fetch field options we use for filtering like country, fruit_type and supplier
    useEffect(() => {
        if (tenant_id) {
            dispatch(getTenant(tenant_id));
        }
    }, [tenant_id]);

    // * fetch the field options that we are currently editing
    useEffect(() => {
        if (tenant_id && field) {
            dispatch(getTenantFieldOptions(tenant_id, field));
        }
    }, [field, tenant_id]);

    const fileChangeHandler = (e) => {
        const file = e.target.files[0];

        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete(results) {
                    const options = results.data.map((i) => ({ ...defaultCsvOption,
                        country: i.country ? i.country.trim() : null,
                        supplier: i.supplier ? i.supplier.trim() : null,
                        fruit_type: i.fruit_type ? i.fruit_type.trim() : null,
                        global_gap_number: i.global_gap_number ? i.global_gap_number.trim() : null,
                        label: i.label ? i.label.trim() : null,
                        value: i.value || i.label.trim().replaceAll(" ", "-").toLowerCase() }));
                    setImportData(options);
                    setSelectedFile(file);
                },
            });
        }
    };

    const handleImport = () => {
        dispatch(importTenantFieldOption(params.tenant_id, params.field, filteredImportData));
    };

    const fruitOptions = config.fruit_types.map((i) => ({ value: i.value, label: i.text }));
    const form = [
        field !== "country" && {
            label: "Country",
            name: "country",
            type: "select-managed",
        },
        field !== "supplier" && {
            label: "Supplier",
            name: "supplier",
            type: "select-managed",
        },
        field !== "global_gap_number" && {
            label: "GGN",
            name: "global_gap_number",
            type: "select-managed",
        },
        field !== "fruit_type" && {
            label: "Fruit type",
            name: "fruit_type",
            type: "single-select",
            options: fruitOptions
        },
        field !== "fruit_type" && {
            label: "Label",
            name: "label",
            type: "text",
            onchange_callback: ({ setValue, field, value }) => {
                // set the label
                setValue(field, value);
                // preset the option value
                setValue("value", value.replaceAll(" ", "-").toLowerCase());
            },
        },
        field !== "fruit_type" && {
            label: "Value",
            name: "value",
            type: "text",
        },
        field === "fruit_type" && {
            label: "Value",
            name: "value",
            type: "single-select",
            options: fruitOptions,
            onchange_callback: ({ setValue, field, value }) => {
                // set the value
                setValue(field, value);
                const option = fruitOptions.find((i) => i.value === value);
                // set the option label
                if (option) {
                    setValue("label", option.label);
                }
            },
        },
        field === "supplier" && {
            label: "Recipients",
            name: "recipients",
            type: "text"
        },
    ];

    const deleteOption = (option) => dispatch(deleteTenantFieldOption(tenant_id, field, option.id));

    const deleteAll = () => dispatch(deleteAllTenantFieldOption(tenant_id, field));


    const cancel = () => {
        setModal(false);
        setQuery(defaultValues);
    };

    const newItem = () => {
        setModal(true);
        setQuery(defaultValues);
    };
    const onSubmit = () => {
        if (query.id) {
            dispatch(updateTenantFieldOption(tenant_id, field, query));
        } else {
            dispatch(createTenantFieldOption(tenant_id, field, query));
        }
        setModal(true);
        setQuery(defaultValues);
    };


    const exportData = (data, fileName, type) => {
        // Create a link and download the file
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const downloadTemplate = () => {
        // Download file with all available fruits, countries and suppliers
        const exampleFile = [
            defaultCsvOption,
            ...fruit_types.map((i) => ({ ...defaultCsvOption, fruit_type: i.value })),
            ...countries.map((i) => ({ ...defaultCsvOption, country: i.value })),
            ...suppliers.map((i) => ({ ...defaultCsvOption, supplier: i.value })),
            ...ggns.map((i) => ({ ...defaultCsvOption, global_gap_number: i.value })),
        ];
        const csvData = Papa.unparse(exampleFile);
        exportData(csvData, `template-${tenant_name}-${params.field}-options.csv`, "text/csv;charset=utf-8;");
    };

    const exportOptions = () => {
        const csvData = Papa.unparse(options);
        exportData(csvData, `export-${tenant_name}-${dayjs().format("DD-MMM-YYYY-HHmm")}-${params.field}-options.csv`, "text/csv;charset=utf-8;");
    };

    const filteredImportData = importData.filter((i) => i.label && i.value);


    if (!options && isLoading) return <Loading></Loading>;
    return (
        <Container className="py-5">
            <Row className="justify-content-center">
                <Col lg="12">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2 className="">Form options</h2>
                            <p className="lead">Edit tenant {tenant_name} options for {field}</p>
                        </div>
                        <div>
                            <Button className="text-nowrap me-3" color="primary" onClick={() => newItem()}><FontAwesomeIcon icon={faPlus} /> Add option</Button>
                            <Button className="text-nowrap me-3" color="primary" onClick={() => setImportModal(true)}><FontAwesomeIcon icon={faExchange} /> Import</Button>
                            <Button className="btn-close my-1" size="lg" onClick={() => navigate(`/tenants/${tenant_id}`)} ></Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-center py-5">
                <Col lg="12">
                    <Table size="sm">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Country</th>
                                <th>Supplier</th>
                                <th>GGN</th>
                                <th>Fruit type</th>
                                <th>Value</th>
                                <th>Label</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(options || []).map((i, index) => (
                                <tr key={index} >
                                    <td className="align-middle">{i.id}</td>
                                    <td className="align-middle ">{i.country}</td>
                                    <td className="align-middle">{i.supplier}</td>
                                    <td className="align-middle">{i.global_gap_number}</td>
                                    <td className="align-middle">{i.fruit_type}</td>
                                    <td className="align-middle">{i.value}</td>
                                    <td className="align-middle">{i.label}</td>
                                    <td className="text-end">
                                        <Button color="primary" className="me-1 mb-1" outline onClick={() => {
                                            setQuery(i);
                                            setModal(true);
                                        }}>Edit</Button>
                                        <Button color="danger" outline className="me-1 mb-1" onClick={() => deleteOption(i)}>Delete</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Modal size="md" isOpen={isOpen} toggle={() => cancel()} >
                <ModalHeader toggle={() => cancel()} >{query.id ? "Edit" : "New"} {field} option</ModalHeader>
                <ModalBody>
                    <MetaForm meta={form} setValue={(field, value) => setQuery((query) => ({ ...query, [field]: value }))} object={query} config={config} />
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => cancel()}>Cancel</Button>
                    <Button color="primary" disabled={!query.label || !query.value || isLoading} className="me-1 mb-1" onClick={() => onSubmit()}>Submit</Button>
                </ModalFooter>
            </Modal >

            <Modal size="lg" isOpen={importModal} toggle={() => setImportModal(false)} >
                <ModalHeader toggle={() => setImportModal(false)} >Import / Delete all</ModalHeader>
                <ModalBody>
                    <p>Download the template. The template contains available fruit_types, countries and suppliers to enable the option.</p>
                    <ul>
                        <li><b>label*</b></li>
                        <li><em>value</em></li> leave empty to use slugified label as value. Fill in to override slugified version.
                        <li><em>country:</em> value representation of country. Use if you want to make the option only available for this country.</li>
                        <li><em>supplier:</em> value representation of supplier. Use if you want to make the option only available for this supplier.</li>
                        <li><em>global_gap_number:</em> value representation of global_gap_number. Use if you want to make the option only available for this global_gap_number.</li>
                        <li><em>fruit_type:</em> value representation of fruit_type. Use if you want to make the option only available for this fruit_type.</li>
                    </ul>
                    <FormGroup>
                        <Label for="exampleFile">CSV</Label>
                        <Input
                            id="exampleFile"
                            name="file"
                            type="file"
                            onChange={fileChangeHandler}
                        />

                        {selectedFile ? (
                            <div className="py-3">
                                <FormText >
                                    <div>Filename: {selectedFile.name}</div>
                                    <div>Filetype: {selectedFile.type}</div>
                                    <div>Size in bytes: {selectedFile.size}</div>
                                    <div>Items: {importData && importData.length}</div>
                                    <div>Valid items: {importData && filteredImportData.length}</div>
                                </FormText>
                            </div>

                        ) : (<FormText>Upload csv file without headers here.</FormText>)}
                    </FormGroup>

                    {importData.length > 0 && <Table size="sm">
                        <thead>
                            <tr>
                                <th>Country</th>
                                <th>Supplier</th>
                                <th>GGN</th>
                                <th>Fruit type</th>
                                <th>Label</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {importData.slice(0, showAllToBeImportedItems ? importData.length : 10).map((i, index) => (
                                <tr key={index} >
                                    <td className="align-middle">{i.country}</td>
                                    <td className="align-middle">{i.supplier}</td>
                                    <td className="align-middle">{i.global_gap_number}</td>
                                    <td className="align-middle">{i.fruit_type}</td>
                                    <td className={`align-middle ${i.label || "bg-danger"}`}>{i.label}</td>
                                    <td className={`align-middle ${i.value || "bg-danger"}`}>{i.value}</td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>}
                    {importData.length > 10 && !showAllToBeImportedItems
                        && <div className="text-center py-3">
                            <span onClick={() => setShowAllToBeImportedItems(true)} className="btn-link">Show all</span>
                        </div>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => setImportModal(false)}>Cancel</Button>
                    <Button color="light" className="me-1 mb-1" onClick={() => downloadTemplate()}>Download template</Button>
                    <Button color="light" className="me-1 mb-1" onClick={() => exportOptions()}>Export</Button>
                    <Button color="primary" disabled={isLoading || !importData || filteredImportData.length === 0} className="me-1 mb-1" onClick={() => handleImport()}>Import</Button>
                    <Button color="danger" disabled={isLoading} className="me-1 mb-1" onClick={() => deleteAll()}>Delete All</Button>
                </ModalFooter>
            </Modal >

        </Container>
    );
}
