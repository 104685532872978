/** @jsxImportSource @emotion/react */


import Field, { DataSource } from "./Field";


class LayerMetaField extends Field {
    field_type = "layer_meta_field";

    backend_table = "layer_meta";

    is_filterable(): boolean {
        // * fruit_type has its own way of doing things for now
        if (this.fieldname_getter === "fruit_type") {
            return false;
        }
        return true;
    }

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        this.value = null;
        if (datasource.layer[this.fieldname_getter]) {
            this.value = datasource.layer[this.fieldname_getter];
        // * Not needed anymore since we use LayerMetaInherited model in het backend
        // ! nice try, but doesn't work, so enable below line again.
        } else if (datasource.layer.parent_meta && datasource.layer.parent_meta[this.fieldname_getter]) {
            this.value = datasource.layer.parent_meta[this.fieldname_getter];
        }

        this.value = this.get_form_option_label(datasource.form_options, this.value_filter(this.value, datasource));
        this.set_color_code(datasource.layer);
        return this;

    }
}

export default LayerMetaField;


