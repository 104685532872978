import { StyleSheet, View, Text, Image } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { loadImage } from "./utils";


export function PDFHeader({ logo_name, title }) {

    const styles = StyleSheet.create({
        header: {
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
        },
        logo: {
            width: 112,
        },
        title: {
            fontSize: 24,
            fontFamily: "Poppins-Bold",
            fontWeight: "bold",
            color: "#4f4f4f"
        },
    });

    return (
        <>
            <View style={styles.header}>
                {logo_name && <Image style={styles.logo} src={loadImage(logo_name)} />}
                <Text style={styles.title}>{title}</Text>
                <Image style={styles.logo} src={loadImage("logo-experience-fruit-quality.png")} />
            </View>
        </>
    );
}

PDFHeader.propTypes = {
    logo_name: PropTypes.string,
    title: PropTypes.string,
};
