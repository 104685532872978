/** @jsxImportSource @emotion/react */
import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button } from "reactstrap";
import {
    summary_field_atron_weight, summary_field_weight
} from "../../../../../actions/Tenants/config/constants";

import useConfig from "../../../../../actions/Tenants/config/configHook";
import FruitTable from "./FruitTable";

import { chunk } from "../../../../../utils/chunk";

export default function BoxedFruitTable({ check, layer, do_print }) {
    // take box_size from check, if unavaiable take it from layer
    const box_size = check.box_size || layer.box_size;

    const boxxes = box_size > 0 ? chunk(check.avocados, box_size) : [];


    return boxxes.map((i, index) => <BoxItem avocados={i} check={check} layer={layer} title={`Box${index + 1}`} key={index} do_print={do_print} />);
}

BoxedFruitTable.defaultProps = {
    do_print: false
};
BoxedFruitTable.propTypes = {
    check: PropTypes.object,
    layer: PropTypes.object,
    do_print: PropTypes.bool
};


const parse_weight = (weight) => {
    if (weight === 0 || weight > 0) {
        return `${Math.round(weight)} GRAM`;
    }
    return "-";
};

export function BoxItem({ avocados, check, layer, title, do_print }) {
    const config = useConfig();
    const location = config.get_location(layer, check);

    const should_show_atron_weight = location.summary_fruit_fields.some((summary_field) => summary_field === summary_field_atron_weight);
    const should_show_manual_weight = location.summary_fruit_fields.some((summary_field) => summary_field === summary_field_weight);
    const total_atron_weight = avocados.map((avocado) => avocado.atron_weight_gram).reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0,
    );
    const total_manual_weight = avocados.map((avocado) => avocado.weight).reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0,
    );
    const weightSelect = () => {
        if (should_show_atron_weight && total_atron_weight > 0) {
            return total_atron_weight;
        }
        if (should_show_manual_weight && total_manual_weight > 0) {
            return total_manual_weight;
        }

        return -1;
    };
    const [open, setOpen] = useState(!config.summary_collapsed || do_print);
    return <div className="mb-3 px-3 border" >
        <div className="d-flex justify-content-between align-items-center py-3">
            <h6 className="mb-0">{title}</h6>
            <div className="ms-auto"></div>
            <h6 className="mb-0 me-3">{avocados.length} items</h6>
            {(should_show_atron_weight || should_show_manual_weight) && <h6 className="mb-0 me-3">{parse_weight(weightSelect())} </h6>}
            {!do_print && <Button color="light" outline={open} onClick={() => setOpen(!open)}>
                <span>
                    <FontAwesomeIcon icon={open ? faMinus : faPlus} />
                </span>
            </Button>}
        </div>
        {open && <div className={`pb-3`}><FruitTable fruits={avocados} check={check} layer={layer} do_print={do_print} /></div>}
    </div>;
}
BoxItem.defaultProps = {
    do_print: false
};
BoxItem.propTypes = {
    title: PropTypes.string,
    check: PropTypes.object,
    layer: PropTypes.object,
    avocados: PropTypes.array,
    do_print: PropTypes.bool
};
