/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import PropTypes from "prop-types";
import Footer from "./Footer";
import Header from "./Header";

export default function DefaultLayout({ children, blueBackground = false }: { children: any, blueBackground?: boolean }) {
    const backgroundColor = blueBackground ? "#f4f9ff" : "white";


    return <div className="d-flex flex-column" css={css`background-color:${backgroundColor};min-height: 100vh;`} >
        <Header blueBackground={blueBackground} />
        <div >{children}</div>
        <div className="mt-auto" css={css`background-color:${backgroundColor}`} >
            <Footer />
        </div>
    </div>;
}

DefaultLayout.propTypes = {
    children: PropTypes.any,
};
