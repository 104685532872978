import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "./PDFLayerMeta";
import { tableStyles } from "./PDFTable";

interface Field {
    label: string;
    display_vector: (size: string) => JSX.Element;
}

interface PDFArrivalCheckProps {
    fields: Field[];
}

const PDFArrivalCheck: React.FC<PDFArrivalCheckProps> = ({ fields }) => {
    return (
        <View style={{ height: "40vh", }}>
            <Text style={tableStyles.pageTitle}>Arrival</Text>
            <View style={styles.container}>
                {fields.map((i, index) => (
                    <View key={index} style={styles.metaItemsIntakeReport}>
                        <View>
                            <Text>{i.label}:</Text>
                        </View>
                        <View>
                            <Text style={styles.metaTextValueIntakeReport}>
                                {i.display_vector("10px")}
                            </Text>
                        </View>
                    </View>
                ))}
            </View>
        </View>
    );
};

export default PDFArrivalCheck;

