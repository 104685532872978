/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Select from "react-select";
import { FormGroup, FormText, Label } from "reactstrap";


// * use return_objects if you want to return the array with objects
// * use return_array if you want to return the array with values
// * use none of the above if you want to return a string (with comma separated values if multi)
// * Use string values for everything you want to store in meta tables
// TODO: return_objects is not used, please remove it
export default function SingleSelect({
    setValue, options, post_value, field, label, disabled, formatOptionLabel, multi, return_objects, return_array, description
}) {
    let selectedValue = null;

    const _onChange = (option) => {
        if (option) {
            if (return_objects) {
                setValue(field, option);
            } else if (return_array) {
                setValue(field, option.map((i) => i.value));
            } else {
                setValue(field, multi ? option.map((i) => i.value).join(",") : option.value);
            }
        } else {
            setValue(field, multi ? [] : null);
        }
    };
    if (return_objects && post_value) {
        selectedValue = post_value;
    } else if (multi) {
        let selectedOptions = [];
        if (return_array && post_value) {
            selectedOptions = post_value;
        } else {
            selectedOptions = post_value && post_value.length > 0 ? String(post_value).split(",") : [];
        }
        selectedValue = options.filter((i) => selectedOptions.includes(i.value));

    } else {
        selectedValue = options.find((i) => String(i.value) === String(post_value)) || {};
    }

    return (
        <FormGroup>
            <Label>{label}</Label>
            <Select id={field} options={options} isMulti={multi} isClearable={true} formatOptionLabel={formatOptionLabel} value={selectedValue} onChange={_onChange} isDisabled={disabled} />
            {description && <FormText className="d-block">{description}</FormText>}
        </FormGroup>
    );
}

SingleSelect.defaultProps = {
    multi: false,
    return_objects: false,
    description: "",
};
SingleSelect.propTypes = {
    setValue: PropTypes.func,
    options: PropTypes.array,
    post_value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]),
    description: PropTypes.string,
    field: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    formatOptionLabel: PropTypes.func,
    multi: PropTypes.bool,
    return_objects: PropTypes.bool,
    return_array: PropTypes.bool,
};
