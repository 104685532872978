/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import {
    Button, Carousel, CarouselControl,
    CarouselIndicators, CarouselItem, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AvocadoIcon from "../../../../img/avocado-icon.svg";
import { Loading } from "../../../Helper/Loading";

export const device_image_types = ["C1", "C2", "C3", "C4", "NIR1", "NIR2", "NIR3", "NIR4", "YNIR1", "YNIR2", "YNIR3", "YNIR4"];
const ImagesModal = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const {
        isOpen, toggleModal, avocado, index,
    } = props;
    const { images } = avocado;

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = !images ? null : images.map((item) => (

        <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.image_id}
        >
            <div className="mx-auto w-100">
                <AvocadoGrayscaleImg item={item} />

            </div>
        </CarouselItem>

    ));

    return (
        <Modal isOpen={isOpen} className="modal-lg" css={css`max-width: 40rem;`} toggle={() => toggleModal(!isOpen)}>
            <ModalHeader toggle={() => toggleModal(!isOpen)} >Fruit {index} <span className="text-muted">(ID: {avocado.avocado_id})</span></ModalHeader>
            <ModalBody>
                <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    interval={false}
                >
                    <CarouselIndicators items={images} activeIndex={activeIndex} onClickHandler={goToIndex} />
                    {slides}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                </Carousel>
            </ModalBody>
        </Modal>
    );
    /* eslint-enable */
};

ImagesModal.defaultProps = {
    index: "",
};

ImagesModal.propTypes = {
    isOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
    avocado: PropTypes.object,
    index: PropTypes.number,
};

export default ImagesModal;

export const DefectImage = ({ image, toggleModal }) => (
    <Modal isOpen={image !== false} className="modal-lg" onClick={() => toggleModal(false)}>
        <ModalHeader >Defect Images</ModalHeader>
        <ModalBody>
            <img className="w-100" src={image.url_webp || image.url} alt={image.blob_name} />
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={() => toggleModal(false)}>Close</Button>
        </ModalFooter>
    </Modal>
);

DefectImage.propTypes = {
    image: PropTypes.object,
    toggleModal: PropTypes.func,
};

export const is_nir_image = (image_type) => image_type && image_type.toString().startsWith("NIR");
export const is_ynir_image = (image_type) => image_type && image_type.toString().startsWith("YNIR");

export function AvocadoGrayscaleImg(props) {
    const { item, className, placeholder } = props;
    const canvasRef = useRef();
    const timer = useRef(null);
    const [isLoaded, setLoaded] = useState(false);
    const [padding, setPadding] = useState((1440 / 1080) * 100); // default to padding from device
    const [the_time_out, set_time_out] = useState(250);

    const modifyColors = (data) => {
        for (let i = 0; i < data.length; i += 4) {
            //   data[i] = data[i] ;
            data[i + 1] = data[i];
            data[i + 2] = data[i];
        }
    };

    const get_time_out = () => {
        const new_time_out = the_time_out + 250;
        set_time_out(new_time_out);
        return new_time_out;
    };

    const loadImage = () => {
        const canvas = canvasRef.current;
        if (!canvas) {
            timer.current = setTimeout(() => loadImage(), 250);
            return;
        }
        const context = canvas.getContext("2d");

        const image = new Image();
        image.crossOrigin = "";
        if (item) {
            if (item.url_webp) {
                image.src = item.url_webp;
            } else {
                image.src = item.url;
            }
        }
        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            setPadding((image.height / image.width) * 100);
            context.drawImage(image, 0, 0);
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            if (is_nir_image(item.type) || is_ynir_image(item.type)) {
                modifyColors(imageData.data);
            }
            context.putImageData(imageData, 0, 0);
            setLoaded(true);
        };
        image.onerror = () => {
            timer.current = setTimeout(() => loadImage(), get_time_out());
        };
    };
    // clear on component unmount
    useEffect(() => () => clearTimeout(timer.current), []);

    //! warning: canvas must be rendered for this to work, hence the ugly .d-none hack
    useEffect(() => {
        if (placeholder) {
            return;
        }
        loadImage();
    }, [placeholder]);

    // !warning: please note that everything is absolute positioned inside the relative div. So we do not have ugly jumps between avocado loads.
    return <div css={css`background: #f1f1f1; position: relative; padding-bottom: ${padding}%;`}>
        <div css={css`position:absolute; top: 50%; left: 50%; transform: translate(-50%,-50%)`}>
            {!placeholder && !isLoaded && <Loading />}
            {placeholder && <img css={css`width: 2rem;`} src={AvocadoIcon} alt="" />}
        </div>
        <canvas css={css`position:absolute; top: 0; bottom: 0; left: 0; right: 0; `} ref={canvasRef} className={`w-100 ${!placeholder && isLoaded ? "" : "d-none"} ${className}`} ></canvas>
    </div>;
}

AvocadoGrayscaleImg.defaultProps = {
    placeholder: false,
};

AvocadoGrayscaleImg.propTypes = {
    placeholder: PropTypes.bool,
    item: PropTypes.object,
    className: PropTypes.string,
};
export const AvocadoIndex = ({ avocado, index, fruit_path }) => {
    const [isOpen, toggleModal] = useState(false);
    const navigate = useNavigate();
    const check = useSelector((state) => state.checks.current);

    const has_images = avocado.images && avocado.images.length > 0;

    const NIR1 = avocado.images ? avocado.images.find((i) => i.type === "NIR1") : "";
    const C1 = avocado.images ? avocado.images.find((i) => i.type === "C1") : "";
    const non_device_images = avocado.images ? avocado.images.filter((i) => !device_image_types.includes(i.type)) : "";

    return <div className="d-flex align-items-center" >

        {!fruit_path && <span css={css`width: 2rem;`}><b>{index}</b></span>}
        {fruit_path && <span ><Button css={css`width: 2.1rem;`} color="secondary" outline size="sm" className="me-2 my-1 d-inline-block" onClick={() => navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${fruit_path}/${avocado.avocado_id}`)} >{index}</Button></span>}
        {has_images && NIR1 && <div className="d-print-none me-1" css={css`width: 2rem;`} role="button" onClick={() => toggleModal(!isOpen)}>
            <AvocadoGrayscaleImg className="me-1" item={NIR1} />
        </div>}
        {has_images && C1 && <div className="d-print-none" css={css`width: 2rem;`} role="button" onClick={() => toggleModal(!isOpen)}>
            <AvocadoGrayscaleImg item={C1} />
        </div>}
        {non_device_images && non_device_images.map((i, index) => <div key={index} className="d-print-none ms-1" css={css`width: 2rem;`} role="button" onClick={() => toggleModal(!isOpen)}>
            <AvocadoGrayscaleImg item={i} />
        </div>)}
        <ImagesModal isOpen={isOpen} toggleModal={toggleModal} avocado={avocado} index={index}></ImagesModal>
    </div>;
};

AvocadoIndex.defaultProps = {
    fruit_path: "",
};
AvocadoIndex.propTypes = {
    avocado: PropTypes.object,
    index: PropTypes.number,
    fruit_path: PropTypes.string,
};
