import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";

import { resetPreviewPackingList, reviewPackingList, updatePackingListStep } from "../../../actions/PackingList/actions";

import { STEPS } from "./PackingListUploadModal";
import useEnrichPreviewHook from "./useEnrichPreviewHook";
import WrappedPreviewPackingList from "./PreviewPackingList";


interface PackingListReviewModalProps {
    list_id: string | boolean;
    isOpen: boolean;
    toggle: () => void;
}
export default function PackingListReviewModal({ list_id, isOpen, toggle }: PackingListReviewModalProps) {
    const dispatch = useDispatch();
    const step = useSelector((state: any) => state.packingLists.step);
    const import_data = useSelector((state: any) => state.packingLists.import_data);
    const isLoading = useSelector((state: any) => state.packingLists.isLoading);
    const isUpating = useSelector((state: any) => state.packingLists.isUpdating);


    useEnrichPreviewHook();


    const cancel = () => {
        dispatch(resetPreviewPackingList() as any);
        toggle();
    };

    useEffect(() => {
        dispatch(resetPreviewPackingList() as any);
        if (list_id) {
            dispatch(reviewPackingList(list_id)as any);
        }
    }, [list_id]);


    return (
        <Modal fullscreen={true} isOpen={isOpen} toggle={() => cancel()} >
            <ModalHeader toggle={() => cancel()} >View packing list</ModalHeader>
            <ModalBody>
                <WrappedPreviewPackingList />
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => cancel()}>Cancel</Button>
                {step === STEPS.PICK_FILE_AND_REVIEW_CONTEXT && import_data && <Button color="primary" disabled={ isLoading || isUpating || !import_data} onClick={() => dispatch(updatePackingListStep(STEPS.REVIEW_CHILDS_AND_IMPORT))}>Next</Button>}
                {step === STEPS.REVIEW_CHILDS_AND_IMPORT && <Button color="light" onClick={() => dispatch(updatePackingListStep(STEPS.PICK_FILE_AND_REVIEW_CONTEXT))}>Back</Button>}
            </ModalFooter>
        </Modal>


    );
}
