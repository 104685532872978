import { ROOT_URL } from "../../constants/urls";
import {
    GET_RELABELING_SESSIONS, GET_RELABELING_SESSION_DETAIL, CREATE_SESSION, DEFECTS_STAT, DEFECT_UPDATE, GET_NEW_BATCH, RESET_RELABELING,
} from "./actionsTypes";

export function resetLabeling() {
    return {
        type: RESET_RELABELING.SUCCESS,
    };
}
export function createRelabellingSession(data) {
    return {
        types: [CREATE_SESSION.LOADING, CREATE_SESSION.SUCCESS, CREATE_SESSION.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${ROOT_URL}api/relabeling/session/`,
                data,
            },
        },
    };
}

export function getRelabelingSesions() {
    return {
        types: [GET_RELABELING_SESSIONS.LOADING, GET_RELABELING_SESSIONS.SUCCESS, GET_RELABELING_SESSIONS.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${ROOT_URL}api/relabeling/session/`,
            },
        },
    };
}

export function getRelabellingSessionDetail(session_id) {
    return {
        types: [GET_RELABELING_SESSION_DETAIL.LOADING, GET_RELABELING_SESSION_DETAIL.SUCCESS, GET_RELABELING_SESSION_DETAIL.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${ROOT_URL}api/relabeling/session/${session_id}/`,
            },
        },
    };
}

export function getDefectsStats(data) {
    return {
        types: [DEFECTS_STAT.LOADING, DEFECTS_STAT.SUCCESS, DEFECTS_STAT.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${ROOT_URL}api/relabeling/session/stat/`,
                data,
            },
        },
    };
}

export function getNewBatch(session_id, count) {
    return {
        types: [GET_NEW_BATCH.LOADING, GET_NEW_BATCH.SUCCESS, GET_NEW_BATCH.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${ROOT_URL}api/relabeling/session/${session_id}/batch/`,
                data: { count },
            },
        },
    };
}

export function updateDefect(data) {
    return {
        types: [DEFECT_UPDATE.LOADING, DEFECT_UPDATE.SUCCESS, DEFECT_UPDATE.ERROR],
        payload: {
            request: {
                method: "PATCH",
                url: `${ROOT_URL}api/relabeling/session/${data.session_id}/defects/`,
                data,
            },
        },
    };
}
