/** @jsxImportSource @emotion/react */
import Plotly from "plotly.js-basic-dist";
import PropTypes from "prop-types";
import createPlotlyComponent from "react-plotly.js/factory";
import { AVOS_GRAPH_COLORS } from "../../../../actions/Layers/constants";


const Plot = createPlotlyComponent(Plotly);

export default function BarChartMaturityHorizontal({ fruits, initialMaturity, style }) {

    if (initialMaturity === undefined) {
        return <div></div>;
    }

    // Create initial object
    const initialMaturityObj = initialMaturity.reduce((acc, i) => {
        const value = Number(i.value);
        acc[value] = 0;
        return acc;
    }, {});

    const maturity = fruits
        .map((i) => i.manual_maturity)
        .filter((i) => i !== undefined).reduce((acc, item) => {
            acc[item] = (acc[item] || 0) + 1;
            return acc;
        }, { ...initialMaturityObj });

    // Sort the keys of initialMaturity based on the order of x-axis values
    const sortedKeys = Object.keys(maturity).sort((a, b) => parseFloat(a) - parseFloat(b));

    const serie: Plotly.Data = {
        type: "bar",
        x: sortedKeys,
        y: sortedKeys.map((key) => maturity[key]),
        marker: {
            color: AVOS_GRAPH_COLORS.AVOS_SUCCESS,
        },
    };

    return (
        <div>
            <Plot
                data={[serie]}
                useResizeHandler={true}
                config={{
                    displayModeBar: false,
                    displaylogo: false,
                    staticPlot: true,
                }}
                style={style}
                layout={{
                    autosize: true,
                    margin: {
                        l: 30,
                        r: 30,
                        t: 10,
                        b: 30,
                        pad: 0,
                    },
                    bargap: 0.05,
                    xaxis: {
                        tickangle: 0,
                        type: "category",
                        title: "Maturity",
                    },
                    yaxis: {
                        zeroline: false,
                        gridwidth: 2,
                        dtick: 1,
                        title: "Count"
                    },
                }}
            />
        </div>
    );
}


BarChartMaturityHorizontal.defaultProps = {
    style: { width: "100%", height: "100%" }
};

BarChartMaturityHorizontal.propTypes = {
    fruits: PropTypes.array,
    initialMaturity: PropTypes.array,
    style: PropTypes.object
};
