/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container } from "reactstrap";
import { useSelector } from "react-redux";
import { getFilledArrayOrDefault } from "../../../../utils";
import { toPercentage } from "../../Layers/fields/formatters";
import { countAllocatedLayersHook, formatDate } from "./hooks";


export default function RipeningCellDays({ dayNavigator, conditionsMap }) {
    const { lastWeekDay, setLastWeekDay, weekDates, openCellConditionsForm, today } = dayNavigator;
    const isToday = lastWeekDay.isSame(today, "day");


    return (
        <Container>
            <div className="mx-n2" >

                <div className="d-flex justify-content-between pt-0">
                    <div className="d-none d-md-flex align-items-center">
                        <Button
                            onClick={() => setLastWeekDay(lastWeekDay.subtract(1, "day"))}
                            className="mx-2"
                            style={{ height: "40%" }}
                            color="primary"
                        >
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </Button>
                    </div>
                    <div className="d-flex flex-nowrap py-3" css={css`overflow-x: auto;`}>
                        {weekDates.map((date, index) => (
                            <div key={index} className="p-0 px-2">
                                <CellDay
                                    selected={date.isSame(today, "day")}
                                    index={index}
                                    date={date}
                                    conditions={conditionsMap?.[formatDate(date)]}
                                    onClick={() => openCellConditionsForm(date)}
                                />
                            </div>
                        ))}
                    </div>

                    <div className={`d-none d-md-flex align-items-center ${(isToday ? "opacity-50" : "")}`}>
                        <Button
                            onClick={() => setLastWeekDay(lastWeekDay.add(1, "day"))}
                            className="mx-2"
                            disabled={isToday}
                            style={{ height: "40%" }}
                            color="primary"
                        >
                            <FontAwesomeIcon icon={faAngleRight} />
                        </Button>
                    </div>

                </div>
            </div>
        </Container>
    );
}


function CellDay({ selected, index, date, onClick, conditions }) {
    const glowingEffect = selected ? "box-shadow: 0px 0px 8px 8px rgba(19, 165, 95, 0.1);" : "";
    const countALlocatedLayers = countAllocatedLayersHook();
    const loaded = countALlocatedLayers(date);
    const currentLocation = useSelector((state: any) => state.facilityLocations.current);


    let titleBackgroundClass = "bg-light";
    if (selected) {
        titleBackgroundClass = "text-white";
    }
    return (
        <div
            className="cursor-pointer rounded pt-8"
            key={index}
            css={[css`${glowingEffect}`, css`width: 290px;`]}
            onClick={onClick}
        >
            <div
                className={`mb-1 p-2 text-center ${titleBackgroundClass}`}
                css={css`border-radius: 10px 10px 0 0; background-color: ${selected ? "#13A55F" : "#f8f9fa"};`}
            >
                <span>{formatDate(date)}</span>
            </div>
            <div
                className="mb-1 font-weight-bold p-2 position-relative bg-white rounded"
                css={css`min-height: 136px; font-size: 14px; border-radius: 0 0 10px 10px; box-shadow: 0px 0px 5px rgba(19, 165, 95, 0.2);`}
            >
                {conditions?.hasValues ? (
                    <div>
                        {getFilledArrayOrDefault(conditions?.keyLabelMap).map((item) => {
                            return (
                                <div className="d-flex justify-content-between" key={item.key}>
                                    <span>{item.label}:</span>
                                    <span className={item.class}>{item.value || "-"}</span>
                                </div>
                            );
                        })}
                        <div className="d-flex justify-content-between" >
                            <span>Occupancy:</span>
                            <span>
                                <span className=""><strong>{toPercentage(loaded, (currentLocation?.capacity || 20))}</strong></span> </span>
                        </div>
                    </div>
                ) : (
                    <div className="text-center">-</div>
                )}
            </div>
        </div>
    );
}
