/** @jsxImportSource @emotion/react */
import Field, { DataSource } from "./Field";

class CheckField extends Field {
    field_type = "check_field";

    backend_table = "check";

    extrapolate_from_layer = true;

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        let value = "-";
        if (datasource.check && datasource.check[this.fieldname_getter]) {
            value = datasource.check[this.fieldname_getter];
        } else if (this.extrapolate_from_layer && datasource.layer && datasource.layer[this.fieldname_getter]) {
            // * some values can be extracted from the layer
            value = datasource.layer[this.fieldname_getter];
        }
        this.value = this.value_filter(value, datasource);
        this.set_color_code(datasource.check);
        return this;
    }

}

export default CheckField;
