/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { getStore } from "../../store";
import { AvosAction } from "../types";
import { convert_to_comma_seperated_list } from "../util";
import {
    CHECKS, CHILDREN, CREATE, DELETE,
    DELETE_LOCATION_HISTORY,
    DELETE_REPORT,
    GET,
    GET_LOCATION_HISTORY, LIST,
    PARENTS,
    PATCH,
    POST_LOCATION_HISTORY,
    SUB_CREATE, UPDATE,
    UPLOAD_REPORT
} from "./actionsTypes";
import { LayerFilter, defaultLayer } from "./constants";
import { format2Backend } from "./util";

const endpoint = "layers/";


// * Method types compatible with backend
export const FILTER_METHODS = {
    IN: "in", // * Meta text with options defined or color fields
    NOT_IN: "not_in", // * Meta text with options defined
    STARTS_WITH: "starts_with", // * Meta text no options, search first characters (becauseo of index)
    DATE_FROM: "date_from", // * Meta date from
    DATE_TO: "date_to", // * Meta date to
    NUMBER_RANGE_MIN: "number_range_min", // * Meta number range min (metrics)
    NUMBER_RANGE_MAX: "number_range_max", // * Meta number range max (metrics)
    IN_ANY_KEY: "in_any_key", // * Any of meta keys is equal to value e.g. advance_ripening_qc_status == "draft" or intake_qc_status == "draft"
    IS_SET: "is_set", // * Meta key is not set or empty

};

// * Method types compatible with frontend
export const FILTER_METHOD_TYPES = {
    TEXT: "text",
    DATE_RANGE: "date_range",
    NUMBER_RANGE: "number_range",
    COLOR_CODE: "color_code",
};


export const updateFilter = (filter: LayerFilter, extendFilter = true) => ({
    type: LIST.FILTER,
    payload: { filter, extendFilter },
});

export function prepareParams(filter: LayerFilter = {}) {
    let { meta_filter, ...params } = filter;
    // * Convert frontend object to array, keep only fields needer for backend, filter empty filters, and stringify
    meta_filter = JSON.stringify(Object.values(meta_filter || {}).filter((i) => i).map(({ method, field, value }: any) => ({ method, field, value })));

    params = {
        ...params,
        meta_filter,
        stage: convert_to_comma_seperated_list(filter.stage),
        select_fields: convert_to_comma_seperated_list(filter.select_fields),
        fruit_type: convert_to_comma_seperated_list(filter.fruit_type)
    } as any;
    return params;
}

export function listLayers(filter: LayerFilter = {}) {
    const state = getStore()!.getState();
    const latestFilter = { ...state.layers.filter, ...filter };
    const params = prepareParams(latestFilter);
    return {
        types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
        payload: {
            request: {
                filter_object: latestFilter,
                params,
                method: "GET",
                url: endpoint,
            },
        },
    } as unknown as AvosAction;
}

export function resetLayerList() {
    return {
        type: LIST.RESET,
        payload: false,
    };
}

export function getLayer(layer_id: string, showLoading = true) {
    return {
        types: [showLoading ? GET.LOADING : `${GET.LOADING}~DoNotShowLoading`, GET.SUCCESS, GET.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint + layer_id}`,
            },
        },
    };
}

export function triggerLayerBusinessRules(layer_id: string) {
    return {
        types: [`${GET.LOADING}~DoNotShowLoading`, GET.SUCCESS, GET.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint + layer_id}/business-rules`,
            },
        },
    };

}

export function createLayer(data) {
    data = format2Backend(data);
    return {
        types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: endpoint,
                data
            },
        },
    } as unknown as AvosAction;
}

export function createSubLayer(data) {
    data = format2Backend(data);
    return {
        types: [SUB_CREATE.LOADING, SUB_CREATE.SUCCESS, SUB_CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: endpoint,
                data
            },
        },
    };
}


export function updateLayerState(data) {
    data = format2Backend(data);
    return {
        type: GET.SUCCESS,
        payload: { data },
    };
}
export function resetLayerState() {
    const data = { ...defaultLayer, meta: [] }; // * dummy empty layer from backend
    return {
        type: GET.SUCCESS,
        payload: { data },
    };
}
export function updateLayer(data) {
    data = format2Backend(data);
    return {
        types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
        payload: {
            request: {
                method: "PUT",
                url: `${endpoint + data.id}`,
                data,
            },
        },
    };
}


// TODO: after testing with QC draft report, we could consider to set keep_history by default to true
// TODO: Discuss with Thijs and Martijn and others
export function patchLayer(layer_id, field, value, callApi = true, keep_history = false) {
    // only update the field in the store
    if (!callApi) {
        return {
            type: PATCH.STATE,
            payload: { layer_id, field, value },
        };
    }

    const data = {
        field,
        state_value: value,
        value: convert_to_comma_seperated_list(value),
        keep_history
    };

    // run through the axios-redux middleware loop
    return {
        types: [PATCH.LOADING, PATCH.SUCCESS, PATCH.ERROR],
        payload: {
            request: {
                method: "PATCH",
                url: `${endpoint + layer_id}`,
                layer_id,
                data,
            },
        },
    };
}
export function deleteLayer(id) {
    return {
        types: [DELETE.LOADING, DELETE.SUCCESS, DELETE.ERROR],
        payload: {
            request: {
                method: "DELETE",
                layer_id: id,
                url: `${endpoint + id}/`,
            },
        },
    };
}

export function getLayerParents(layer_id) {
    return {
        types: [PARENTS.LOADING, PARENTS.SUCCESS, PARENTS.ERROR],
        payload: {
            request: {
                method: "GET",
                no_toast: true,
                url: `${endpoint + layer_id}/parents`,
            },
        },
    };
}

export function getLayerChildren(layer_id) {
    return {
        types: [CHILDREN.LOADING, CHILDREN.SUCCESS, CHILDREN.ERROR],
        payload: {
            request: {
                method: "GET",
                no_toast: true,
                url: `${endpoint + layer_id}/children`,
            },
        },
    };
}

export function getLayerChecks(layer_id, params) {
    return {
        types: [CHECKS.LOADING, CHECKS.SUCCESS, CHECKS.ERROR],
        payload: {
            request: {
                method: "GET",
                no_toast: true,
                url: `${endpoint + layer_id}/checks`,
                params
            },
        },
    };
}

// current display layer is parent_layer_id
export function addLayerChild(parent_layer_id: string, layer_id: string) {
    return {
        types: [CHILDREN.LOADING, CHILDREN.SUCCESS, CHILDREN.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint + parent_layer_id}/children`,
                data: {
                    parent_layer_id, layer_id
                }
            },
        },
    };
}

export function createAndAddLayerChild(parent_layer_id: string, layer_label: string, layer_type: string) {
    return {
        types: [CHILDREN.LOADING, CHILDREN.SUCCESS, CHILDREN.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint + parent_layer_id}/children?create_child=${true}`,
                data: {
                    parent_layer_id, layer_label, layer_type
                }
            },
        },
    };
}

export function removeLayerChild(parent_layer_id, layer_id) {
    return {
        types: [CHILDREN.LOADING, CHILDREN.SUCCESS, CHILDREN.ERROR],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint + parent_layer_id}/children/${layer_id}`,
            },
        },
    };
}

// current display layer is layer_id
export function addLayerParent(layer_id, parent_layer_id) {
    return {
        types: [PARENTS.LOADING, PARENTS.SUCCESS, PARENTS.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint + layer_id}/parents`,
                data: {
                    parent_layer_id, layer_id
                }
            },
        },
    };
}

export function removeLayerParent(layer_id, parent_layer_id) {
    return {
        types: [PARENTS.LOADING, PARENTS.SUCCESS, PARENTS.ERROR],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint + layer_id}/parents/${parent_layer_id}`,
            },
        },
    };
}

export function getLayerLocationHistory(layer_id) {
    return {
        types: [GET_LOCATION_HISTORY.LOADING, GET_LOCATION_HISTORY.SUCCESS, GET_LOCATION_HISTORY.ERROR],
        payload: {
            request: {
                method: "GET",
                no_toast: true,
                url: `${endpoint + layer_id}/locations`,
            },
        },
    };
}

export function addLayerLocationHistory(data) {
    return {
        types: [POST_LOCATION_HISTORY.LOADING, POST_LOCATION_HISTORY.SUCCESS, POST_LOCATION_HISTORY.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint + data.layer_id}/locations`,
                data
            },
        },
    };
}

export function deleteLayerLocationHistory(data) {
    return {
        types: [DELETE_LOCATION_HISTORY.LOADING, DELETE_LOCATION_HISTORY.SUCCESS, DELETE_LOCATION_HISTORY.ERROR],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint + data.layer_id}/locations/${data.id}`,
                data
            },
        },
    };
}

export function addLayerImage(data) {
    return {
        types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `/images/layer/${data.layer_id}/add_image/`,
                data,
            },
        },
    };
}

export function deleteLayerImage(data) {
    return {
        types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
        payload: {
            request: {
                method: "DELETE",
                url: `/images/layer/${data.layer_id}/delete_image/`,
                data,
            },
        },
    };
}


export function addReport(type: string, file: { content: string; name: string, type: string }, layer_id: string, folder: string) {
    return {
        types: [UPLOAD_REPORT.LOADING, UPLOAD_REPORT.SUCCESS, UPLOAD_REPORT.ERROR],
        payload: {
            request: {
                method: "POST",
                url: "/report/",
                data: {
                    type,
                    file,
                    entity: { id: layer_id, type: "layer" },
                    folder
                },
            },
        },
    } as unknown as AvosAction;
}

export function deleteReport(meta_key: string, blob_name: string, layer_id: string) {
    return {
        types: [DELETE_REPORT.LOADING, DELETE_REPORT.SUCCESS, DELETE_REPORT.ERROR],
        payload: {
            request: {
                method: "DELETE",
                url: "/report/",
                data: {
                    meta_key,
                    blob_name,
                    entity: { id: layer_id, type: "layer" },
                },
            },
        },
    } as unknown as AvosAction;
}
