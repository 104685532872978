/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { DeviceResultActions } from "../../../../actions/Tenants/config/constants";
import { getFilledArrayOrDefault } from "../../../../utils";
import DeviceQueueStatus from "../../../Devices/DeviceQueueStatus";
import DeviceStatus from "../../../Devices/DeviceStatus";
import DeviceToggle from "../../../Devices/DeviceToggle";
import { listenForTriggerDeviceEvents } from "../../../Devices/TriggerDeviceButton";
import { Loading } from "../../../Helper/Loading";
import { DeviceResultsNavButtons } from "./DeviceResultsNavButtons";
import ImagesModal, { AvocadoGrayscaleImg, is_nir_image } from "./Images";
import Box from "./Layout";
import { ManualForm } from "./ManualFruitForm";
import { Scale, atronPressureScale } from "./Scales";
import { SetupPressureCalibration, SetupPressureCalibrationAfterNFruit, SetupPressureCalibrationWithForm } from "./SetupPressureCalibration";

export const placeholder_avocado = {
    user: "-",
    id: "-",
    time: "00:00:00",
    blob_base: "-",
    device_uid: "-",
    atron_color_stage: "",
    atron_quality: "",
    atron_pressure: null,
    atron_ripening_stage: null,
    atron_pressures_average: "-",
    atron_pressures_median: false,
    atron_weight_gram: null,
    images: [1, 2, 3, 4].map((i) => ({ url: true, type: `NIR${i}`, image_id: i })),
};


export default function DeviceResults() {
    const isLoading = useSelector((state) => state.checks.isLoading);
    const params = useParams();
    const check = listenForTriggerDeviceEvents(params.action);
    const config = useConfig();
    const layer = useSelector((state) => state.layers.current);
    const location = config.get_location(layer, check);

    const showForm = [DeviceResultActions.inline_form, DeviceResultActions.setup_pressure_calibration_with_form].includes(location.device_result_action);
    const expertMode = showForm; // check with Agis if we can remove this feature/toggle
    let avocado = placeholder_avocado;

    if (params.avocado_id > 0) {
        avocado = check.avocados.find((i) => i.avocado_id === parseInt(params.avocado_id, 10)) || placeholder_avocado;
    } else if (check?.avocados?.length > 0) {
        avocado = check.avocados[check.avocados.length - 1];
    }

    const buttons = <DeviceResultsNavButtons avocado={avocado} action={params.action} />;

    if (isLoading) {
        return <Loading></Loading>;
    }

    return (
        <Box buttons={buttons} devicesModalButton={config.show_device_toggle ? <DeviceToggle /> : null}>
            <Row className="">
                <Col md="6">
                    {expertMode && <h2 className=" mb-4">AVOS</h2>}
                    <DeviceStatus only_errors={true} link_settings={true} />
                    <DeviceResult avocado={avocado} />
                </Col>
                <Col md={6} className="ps-0 ps-md-5">
                    {expertMode && <h2 className=" mb-4" >Expert</h2>}
                    <DeviceResultSideEffect avocado={avocado} />
                    {!isLoading && showForm && <ManualForm avocado={avocado} />}
                    <div className="border-top pt-5 mt-5" >
                        <DeviceQueueStatus/>
                    </div>
                </Col>
            </Row>
        </Box>
    );
}


const DeviceResult = ({ avocado }) => {
    const config = useConfig();
    const check = useSelector((state) => state.checks.current);
    const layer = useSelector((state) => state.layers.current);
    const external_colors = config.get_fruit_type_external_colors(layer);
    const location = config.get_location(layer, check);
    const [isOpen, toggleModal] = useState(false);
    const placeholder = !(avocado.avocado_id > 0);
    const opacity = placeholder ? 0.3 : 1;

    if (!(avocado.avocado_id > 0)) {
        avocado = placeholder_avocado;
    }
    const parse_atron_weight = () => {
        if (avocado.atron_weight_gram === 0 || avocado.atron_weight_gram > 0) {
            return avocado.atron_weight_gram.toFixed(0);
        }
        return "-";
    };

    const images = getFilledArrayOrDefault(avocado.images, []).filter((i) => (i.url || i.url_webp) && is_nir_image(i.type)).map((i) => <Col key={i.image_id} xs="6" md="3" className="p-1 px-md-2 py-md-0" >
        <AvocadoGrayscaleImg key={i.image_id} className={(placeholder ? "opacity-20" : "")} item={i} placeholder={placeholder} />
    </Col>);

    return (
        <div css={css`opacity: ${opacity};`}>
            <ImagesModal avocado={avocado} isOpen={isOpen} toggleModal={toggleModal} index={check.avocados.findIndex((i) => avocado.avocado_id === i.avocado_id) + 1} />
            <Row className="justify-content-between pb-2 mx-n2" role="button" onClick={() => toggleModal(!isOpen)}>
                {images}
            </Row>
            {location.show_atron_color
                && <Row className="align-items-center pt-5">
                    <Col><Scale blocks={external_colors} value={avocado.atron_color_stage} external_colors={external_colors} /></Col>
                </Row>
            }

            <Row className="align-items-center pt-5">
                <Col>
                    <Scale fluid={true} legend={["Hard", "Soft"]} blocks={atronPressureScale} external_colors={external_colors} value={avocado.atron_pressures_median ? Math.max(10, Math.min(100, avocado.atron_pressures_median)) : 0} />
                </Col>
            </Row>

            {location.show_atron_results_bottom_summary && (
                <div>
                    <Row className="align-items-center pt-5">
                        <Col>
                            {config.research_mode
                                && <div className="d-flex justify-content-end align-items-center pt-2">
                                    <div>Median:</div>
                                    <span className="bg-light px-3 py-1 text-bold ms-1">{config.firmness_to_display_value(avocado.atron_pressures_median) || "-"}</span>
                                    <div className="ps-3">Average:</div>
                                    <span className="bg-light px-3 py-1 text-bold ms-1">{config.firmness_to_display_value(avocado.atron_pressures_average)}</span>
                                </div>
                            }
                        </Col>
                    </Row>
                    {location.show_atron_weight
                        && <Row className="align-items-center pt-5">
                            <Col className="text-end">
                                Weight <span className="bg-light px-3 py-1 text-bold">{parse_atron_weight()} GRAM</span>
                            </Col>
                        </Row>
                    }
                </div>
            )}

            {/* {user.tenant.has_trained_model
                && <Row className="align-items-center pt-3">
                    <Col>
                        <div className="d-flex justify-content-start">
                            <div className="text-center me-2">
                                A
                                <div className="p-3 bg-success" css={css` opacity: ${avocado.atron_quality === "a" ? 1 : 0.3};`}>
                                    <img css={css`height: 30px;`} src={avocadoIcon} className="w-100" alt="" />
                                </div>
                            </div>
                            <div className="text-center">
                                B
                                <div className="p-3 bg-danger" css={css` opacity: ${avocado.atron_quality === "b" ? 1 : 0.3};`}>
                                    <img css={css`height: 30px;`} src={avocadoIcon} className="w-100" alt="" />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            } */}
        </div>
    );
};
DeviceResult.propTypes = {
    avocado: PropTypes.object,
};

const DeviceResultSideEffect = (props) => {
    const { avocado } = props;
    const check = useSelector((state) => state.checks.current);
    const layer = useSelector((state) => state.layers.current);
    const config = useConfig();
    const location = config.get_location(layer, check);

    let action = false;
    switch (true) {
    case DeviceResultActions.setup_pressure_calibration_with_form === location.device_result_action:
        action = <SetupPressureCalibrationWithForm avocado={avocado} />;
        break;
    // first fill in the pressure of 2 fruit, then scan any arbritrary number of fruit
    case [DeviceResultActions.setup_pressure_calibration, DeviceResultActions.pressure_advice].includes(location.device_result_action):
        action = <SetupPressureCalibration avocado={avocado} />;
        break;

        // first scan 10 fruit, then fill in the pressure of x (2) fruit
    case DeviceResultActions.calibrate_after_n_fruits === location.device_result_action:
        action = <SetupPressureCalibrationAfterNFruit avocado={avocado} />;
        break;

    default:
        action = false;
    }
    return action || null;

};
DeviceResultSideEffect.propTypes = {
    avocado: PropTypes.object,
};

