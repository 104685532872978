import { createFlatObjectFromLayerWithMetaObject, createLayerWithMetaObjectFromFlatObject } from "../util";

export const formatFruit2Backend = (data) => {
    const fields = [
        "avocado_id",
        "manual_ripening_stage",
        "manual_color_stage",
        "atron_ripening_stage",
        "atron_color_stage",
        "atron_quality",
        "atron_raw_pressure_1",
        "atron_raw_pressure_2",
        "atron_raw_pressure_3",
        "atron_raw_pressure_4",
        "user_action",
        "pressure",
        "created",
        "test_id",
        "user_id",
        "manual_quality",
        "tenant_id",
        "device_uid",
        "atron_pressure_kg",
        "atron_pressure_range",
        "atron_pressures_median",
        "model_name",
        "legacy_eurowest_id",
        "atron_pressures_average",
        "pressure_2",
        "pressure_1",
        "atron_tapperspeed_1",
        "atron_tapperspeed_2",
        "atron_tapperspeed_3",
        "atron_tapperspeed_4",
        "atron_pressure_kg_1",
        "atron_pressure_kg_2",
        "weight",
        "submitted_pressure",
        "submitted_pressure_1",
        "submitted_pressure_2",
        "atron_weight_gram",
        "manual_brix",
        "manual_internal_color_stage",
        "is_invalid",
        "manual_dry_matter",
        "is_shiny",
        "manual_temperature",
        "manual_maturity",
        "submitted_as_kg",
        "manual_taste",
        "durometer_pressure_1",
        "durometer_pressure_2",
        "plu",
        "pit_a",
        "pit_b",
        "pit_c",
        "n",
        "unit_of_account"
    ];
    return createLayerWithMetaObjectFromFlatObject(data, fields);
};
export const formatFruit2Frontend = createFlatObjectFromLayerWithMetaObject;
