/** @jsxImportSource @emotion/react */
// import PropTypes from "prop-types";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import { deleteCheck } from "../../../../../actions/Checks/actions";

interface Check {
    test_id: string;
    layer_id: string;
}

interface InvalidCheckModalProps {
    check?: Check;
}

const InvalidCheckModal: React.FC<InvalidCheckModalProps> = ({ check }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [isInvalidModalOpen, toggleInvalidModal] = useState(false);

    const { layer_id } = params;

    const markInvalid = () => {
        if (check) {
            dispatch(deleteCheck(check.test_id) as any).then((response: any) => {
                if (!response.error) {
                    toggleInvalidModal(false);
                    navigate(`/layer/${layer_id}`);
                }
            });
        }
    };

    return (
        <>
            <div className="pe-2">
                <Button
                    className="text-nowrap"
                    color="danger"
                    outline
                    onClick={() => toggleInvalidModal(true)}
                >
                    Delete <FontAwesomeIcon icon={faTrash} />
                </Button>
            </div>
            <Modal
                isOpen={isInvalidModalOpen}
                size="md"
                toggle={() => toggleInvalidModal(!isInvalidModalOpen)}
            >
                <ModalHeader toggle={() => toggleInvalidModal(!isInvalidModalOpen)}>
                    {`Check ${check?.test_id}`}
                </ModalHeader>
                <ModalBody>
                    <span>Please mark the check {check?.test_id} as invalid if you think something went wrong.</span>
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => toggleInvalidModal(!isInvalidModalOpen)}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={markInvalid}>
                        Mark Invalid
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default InvalidCheckModal;

