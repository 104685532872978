import PropTypes from "prop-types";
import {
    Modal, ModalBody, ModalHeader
} from "reactstrap";


import { DeviceList } from "./DeviceList";

export function DeviceListModal(props) {
    const {
        toggleModal, isOpen
    } = props;


    return (
        <Modal isOpen={isOpen} size="xl" toggle={() => toggleModal(!props)}>
            <ModalHeader toggle={() => toggleModal(!props)} ><span className="">Select Device</span></ModalHeader>
            <ModalBody>
                <DeviceList/>
            </ModalBody>
        </Modal>
    );
}

DeviceListModal.propTypes = {
    toggleModal: PropTypes.func,
    isOpen: PropTypes.bool,
};
