import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Select from "react-select";

export default function DeviceTenantDropdown({ device, onChange }) {
    const tenants = useSelector((state) => state.tenants);
    const { items, isLoading } = tenants;

    if (isLoading || !items || items.length === 0) {
        return null;
    }

    const options = items.map((i) => ({ label: i.tenant_name, value: i.tenant_id }));

    return <Select options={options} value={options.find((i) => i.value === device.tenant_id)} is_multi={false} onChange={(selected_option) => onChange(selected_option.value)}></Select>;
}

DeviceTenantDropdown.defaultProps = {
    device: undefined,
    onChange: undefined,
};

DeviceTenantDropdown.propTypes = {
    device: PropTypes.object,
    onChange: PropTypes.func,
};
