import { Input, InputGroup } from "reactstrap";
import { toast } from "react-toastify";
import { MimeType } from "../../constants/misc";
import { FormFileSelectFieldReadFormat } from "../../actions/Tenants/config/constantsTyped";

export interface FileSelectProps {
    onSelect: (fileName: string, fileContent: string) => void;
    accept: MimeType[];
    readFormat?: FormFileSelectFieldReadFormat;
}

export function FileSelect({ onSelect, accept = [], readFormat = FormFileSelectFieldReadFormat.Base64 }: FileSelectProps) {
    const isValidFileType = (fileType: MimeType, accept: MimeType[]) => {
        return accept.includes(fileType);
    };
    const fileChangeHandler = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (isValidFileType(file.type as MimeType, accept)) {
                const reader = new FileReader();
                reader.onload = async (event) => {
                    try {
                        const content = event?.target?.result as string;
                        await onSelect(file.name, content);
                    } catch (error: any) {
                        toast.error(error.message, {
                            autoClose: 3000,
                        });
                    }
                };
                if (readFormat === FormFileSelectFieldReadFormat.Base64) {
                    reader.readAsDataURL(file); // Use readAsDataURL to get base64-encoded string
                } else if (readFormat === FormFileSelectFieldReadFormat.Raw) {
                    reader.readAsArrayBuffer(file); // Use readAsArrayBuffer to get raw binary data
                }
            } else {
                toast.error(`Invalid file type. Please select a file of type ${accept.join(",")}`, {
                    autoClose: 3000,
                });
            }
        }
    };
    return (
        <InputGroup className="mt-3">
            <Input
                type="file"
                accept={accept.join(",")}
                onChange={fileChangeHandler}
            />
        </InputGroup>
    );
}
