/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import AsyncCreatableSelect from "react-select/async-creatable";

export default function MetaSelectCreatable({
    setValue, post_value, field, disabled, creatable
}) {

    const _onLayerSelect = (values) => setValue(field, values.map((i) => i.value).join(","));
    const value = String(post_value || "").split(",").filter((i) => i).map((value) => ({ value, label: value }));
    if (creatable) {
        return (
            <AsyncCreatableSelect
                defaultOptions={[]}
                isMulti={true}
                isClearable={true}
                onChange={_onLayerSelect}
                isDisabled={disabled}
                value={value}
                placeholder={""}
            />
        );
    }
    return <></>;
}
MetaSelectCreatable.defaultProps = {
    overwrite: true,
    creatable: true,
    post_value: "",
    type: null,
};
MetaSelectCreatable.propTypes = {
    setValue: PropTypes.func,
    field: PropTypes.string,
    disabled: PropTypes.bool,
    creatable: PropTypes.bool,
    post_value: PropTypes.string,
};
