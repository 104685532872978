/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "reactstrap";
import { Filter } from "../../../actions/Admin/reducer";
import { addTenantUser, deleteUser, getUsers, saveUser, updateUserFilter } from "../../../actions/Admin/tenantUser/actions";
import { User, UserState } from "../../../actions/Admin/tenantUser/reducer";
import useConfig from "../../../actions/Tenants/config/configHook";
import { FormField, UserRole } from "../../../actions/Tenants/config/constantsTyped";
import { RootState } from "../../../reducers";
import { getFilledArrayOrDefault } from "../../../utils";
import { Form, PageBase, PageBaseTableRowField } from "./PageBase";

interface UserFormObject extends Omit<User, "name" | "fruit_types"> {
    roles: string[];
    teams: string[];
}

const StatusBadges = {
    Impersonated: <Badge color="indigo" css={css`background-color: var(--bs-indigo);`}>Impersonated</Badge>,
    Active: <Badge color="primary">Active</Badge>,
    Deactivated: <Badge color="danger">Deactivated</Badge>,
    Invited: <Badge color="info">Invited</Badge>,
    "Contact Support": <Badge color="danger">Contact Support</Badge>
};

const defineUserFormUpdate = (onSaveUser: (user:User) => void, onDeleteUser: (id:string) => any) => () => {
    const userForm: FormField[] = [
        {
            label: "Roles",
            description: "Which roles this user has",
            type: "single-select",
            name: "roles",
            multi: true,
            return_array: true,
            options: [
                { value: UserRole.TENANT_ADMIN, label: "TenantAdmin" },
            ]
        },
        {
            label: "Teams",
            description: "To which teams this user belongs",
            type: "select-managed",
            name: "teams",
            multi: true,
            return_array: true,
        },
        {
            label: "Status",
            description: "Do you want to change the status?",
            name: "new_status",
            type: "radio",
            //  TODO: change options bases on current status... userForm should be a callback function
            options: [
                // {
                //     value: "delete",
                //     color: "danger",
                //     text: "Delete Invite"
                // },
                {
                    value: "Deactivated",
                    color: "danger",
                    text: "Deactivate"
                },
                {
                    value: "Active",
                    color: "primary",
                    text: "Activate"
                }
            ]

        }
    ];
    const onSave = (formObject: UserFormObject, entity: Partial<User>) => {
        const user: any = {
            ...entity,
            ...formObject,
        };
        onSaveUser(user as User);
    };

    const onDelete = (formObject: UserFormObject, entity: Partial<User>) => {
        const user: any = {
            ...entity,
            ...formObject,
        };
        onDeleteUser(user.id);
    };

    const getFormObjectFromEntity = (entity: Partial<User>): Partial<UserFormObject> => {
        return { ...entity };
    };
    const userModal: Form<UserFormObject, User> = {
        title: "User",
        fields: userForm,
        modalButtonLabel: "Edit",
        onSave,
        onDelete,
        getFormObjectFromEntity
    };
    return userModal;
};
const defineUserFormCreate = (onAddTenantUser: (user:User) => void) => () => {
    const userForm: FormField[] = [
        {
            label: "Email address",
            description: "Email address of the user",
            type: "email",
            name: "email",
        },
        {
            label: "Roles",
            description: "Which roles this user has",
            type: "single-select",
            name: "roles",
            multi: true,
            return_array: true,
            options: [
                { value: UserRole.TENANT_ADMIN, label: "TenantAdmin" },
            ]
        },
        {
            label: "Teams",
            description: "To which teams this user belongs",
            type: "select-managed",
            name: "teams",
            multi: true,
            return_array: true,
        },
    ];
    const onSave = (formObject: UserFormObject, entity: Partial<User>) => {
        const user: any = {
            ...entity,
            ...formObject,
        };
        onAddTenantUser(user as User);
    };

    const getFormObjectFromEntity = (entity: Partial<User>): Partial<UserFormObject> => {
        return { ...entity };
    };
    const userModal: Form<UserFormObject, User> = {
        title: "User",
        fields: userForm,
        modalButtonLabel: "Add",
        onSave,
        getFormObjectFromEntity,
        modalButtonHidden: true
    };
    return userModal;
};


const UserPage = () => {
    const dispatch = useDispatch();
    const state = useSelector<RootState, UserState>((state) => state.admin.user);
    const config = useConfig();
    const allOptions = useSelector((state: any) => state?.tenants?.options_by_field);
    const getOptionLabel = (optionType:string, value: any): string => {
        const options = allOptions?.[config.tenant_id]?.[optionType] || [];
        const option = options.find((option) => option.value === value);
        return option?.label || "";
    };
    const onSaveUser = async (entity: User) => {
        await dispatch(saveUser(entity));
    };

    const onDeleteUser = async (id: string) => {
        await dispatch(deleteUser(id));
        await dispatch(getUsers(state.filter));
    };

    const onAddTenantUser = async (entity: User) => {
        await dispatch(addTenantUser(entity));
        await dispatch(getUsers(state.filter));
    };
    const onUpdateFilter = (filter: Filter) => {
        dispatch(updateUserFilter(filter));
    };
    const onListEntities = (filter: Filter) => {
        dispatch(getUsers(filter));
    };
    const tableRow: PageBaseTableRowField<User>[] = [
        {
            label: "Email",
            name: "email",
            lg: 3,
        },
        {
            label: "Status",
            name: "status",
            lg: 1,
            display_component(entity:User) {
                return StatusBadges[entity.status] || <Badge color="warning">{entity.status}</Badge>;

            }
        },
        {
            label: "UserName",
            name: "username",
            lg: 3,
        },
        {
            label: "Name",
            name: "first_name",
            lg: 2,
        },
        {
            label: "Roles",
            name: "roles",
            lg: 1,
            display_component(entity:User) {
                return <div>{ getFilledArrayOrDefault(entity.roles).map((role, index) => <Badge key={index} className="me-2" color="secondary">{role}</Badge>)}</div>;
            },
        },
        {
            label: "Teams",
            name: "teams",
            lg: 1,
            display_component(entity:User) {
                return <div>{ getFilledArrayOrDefault(entity.teams).map((team, index) => <Badge key={index} className="me-2" color="secondary">{getOptionLabel("teams", team)}</Badge>)}</div>;
            },
        },
        // TODO: add created to avos_user model
        // {
        //     label: "Created",
        //     name: "created",
        //     lg: 1,
        //     display_component(entity:User) {
        //         if (entity?.created) {
        //             return "-";
        //         }
        //         return dayjs(entity.created).format("DD MMM YYYY");
        //     }
        // }
    ];
    const updateUserForm = defineUserFormUpdate(onSaveUser, onDeleteUser);
    const createUserForm = defineUserFormCreate(onAddTenantUser);
    const createForms = [createUserForm, updateUserForm];

    return <PageBase<User>
        title="User"
        tableRow={tableRow}
        actionsColumnWidth={1}
        createForms = {createForms as any}
        state={state}
        events={{ onUpdateFilter, onListEntities }}
    />;
};


export default UserPage;
