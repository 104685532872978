import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { chunk } from "../../../../../utils/chunk";
import { styles } from "./PDFCheckFruitTables";
import { PDFPage } from "./PDFLayout";
import { PDFTable, tableStyles } from "./PDFTable";

export function PDFPallets({ fields, printChildren, config, children_title, form_options, layer_fields }) {

    // stack the columns, starting with the headers, followed by content and finally the footer
    const headers = fields.map((i, index) => <View key={index} style={[tableStyles.tableCell, tableStyles.tableHeader]} >
        <Text style={tableStyles.tableHeaderText}>{i.label || "\u00A0" }</Text>
    </View>);
    const allColumns = fields.map((i, index) => printChildren.map((p) => <View style={[tableStyles.tableCellHeight, tableStyles.tableCell]} key={index + 1}>{i.set_value({
        config,
        layer: p,
        check: p.latest_check, // * To be used in check tables and layer overview
        fruit: null, // * Fruit list is not available on overview screen
        children: null, // * children is only available for layer index your are viewing
        form_options // * used to translate form values to labels

    }).display_vector("6px", 50, 48)}</View>));


    // make chunks of all columns with 25 rows per column
    const chunked = fields.map((_, index) => chunk(allColumns[index], 25));

    return chunked[0].map((i, chunkIndex) => <PDFPage footer key={chunkIndex}>
        <View>
            <Text style={tableStyles.pageTitle}>{children_title}</Text>
        </View>
        {layer_fields && <View style={styles.container}>
            {layer_fields.map((i, index) => (
                <View key={index} style={styles.metaItems}>
                    <View>
                        <Text>{i.label}:</Text>
                    </View>
                    <View style={styles.metaTextValueArtReport}>
                        {i.display_vector("10px")}
                    </View>
                </View>
            ))}
        </View>}

        <PDFTable columns={chunked.map((i, colIndex) => [headers[colIndex], ...i[chunkIndex]])} />

    </PDFPage>);

}

PDFPallets.propTypes = {
    fields: PropTypes.array,
    printChildren: PropTypes.array,
    config: PropTypes.any,
    children_title: PropTypes.string,
    form_options: PropTypes.object,
    layer_fields: PropTypes.array
};


