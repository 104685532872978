/* eslint-disable no-confusing-arrow */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { faCaretDown, faCaretUp, faCopy, faMinus, faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button, FormText
} from "reactstrap";
import useConfig from "../../actions/Tenants/config/configHook";
import { FormField } from "../../actions/Tenants/config/constantsTyped";
import { moveItemUp, moveItemDown } from "../../utils/array";
import MetaForm from "./MetaForm";

enum Display {
    PREVIEW = "preview",
    EDIT = "edit",
    ADVANCED = "advanced"
}

function ListItem({ item, form, title, onChange, moveUp, moveDown, deleteItem, copyItem, defaultDisplay = Display.PREVIEW, invalid }) {
    const [display, setDisplay] = useState(defaultDisplay);
    const config = useConfig();

    const updateItem = (field_name, value) => onChange({ ...item, [field_name]: value });


    const closeEditAndMoveUp = () => {
        setDisplay(Display.PREVIEW);
        moveUp();
    };

    const closeEditAndMoveDown = () => {
        setDisplay(Display.PREVIEW);
        moveDown();
    };

    return (
        <div className="p-3 mt-3 border bg-white">
            <div className="d-flex justify-content-start align-items-center">
                <div className="me-auto mb-1">{title}</div>
                {display === Display.PREVIEW && <div className="me-1 my-1"><Button size="sm" color="light" onClick={() => setDisplay(Display.EDIT)} ><FontAwesomeIcon icon={faPencil}/></Button></div>}
                {display !== Display.PREVIEW && <div className="me-1 my-1"><Button size="sm" color="light" onClick={() => setDisplay(Display.PREVIEW)} ><FontAwesomeIcon icon={faMinus}/></Button></div>}
                <div className="me-1 my-1"><Button size="sm" color="danger" outline onClick={() => deleteItem()} ><FontAwesomeIcon icon={faTrash}/> </Button></div>
                <div className="me-1 my-1"><Button size="sm" color="light" onClick={() => copyItem()} ><FontAwesomeIcon icon={faCopy} /> </Button></div>
                <div className="me-1 my-1"><Button size="sm" disabled={moveUp === false} color="light" onClick={() => closeEditAndMoveUp()}><FontAwesomeIcon icon={faCaretUp}/> </Button></div>
                <div className="me-1 my-1"><Button size="sm" disabled={moveDown === false} color="light" onClick={() => closeEditAndMoveDown()}><FontAwesomeIcon icon={faCaretDown}/> </Button></div>
            </div>
            {display !== Display.PREVIEW
            && <div>
                <MetaForm
                    meta={form}
                    setValue={updateItem}
                    object={item}
                    config={config}
                />
            </div>}
            {
                invalid && <div>
                    <FormText color="danger">Please make sure that all the required fields are filled in correctly.</FormText>
                </div>
            }
        </div>
    );
}


export type ObjectListProps<T extends object> = {
    name: string;
    title: string;
    description: string | JSX.Element;
    items: T[];
    form: FormField[];
    onChange: (arr: T[]) => void;
    getItemTitle: (item: T) => string;
    validate?: (value: T) => boolean;
    disabled?: boolean;
}

export function ObjectList<T extends object>({
    name,
    title,
    description,
    items = [],
    form,
    onChange,
    getItemTitle,
    validate,
    disabled
}: ObjectListProps<T>) {
    const [display, setDisplay] = useState(Display.PREVIEW);

    return (<div className="p-3 mb-3" css={css`background: #fcfcfc;`}>

        <div className="d-flex align-items-center">
            <div className="mb-0">
                <div className="mb-0 fw-bold">{title} [{name}] </div>
                <div className="text-muted">{description}</div>
            </div>
            <div className="mb-0 ms-auto text-muted">{items && `${items.length} items`}</div>
            { display === Display.PREVIEW && <div className="ms-2"><Button disabled={disabled} size="sm" onClick={() => setDisplay(Display.EDIT)}>Edit</Button></div>}
            { display === Display.EDIT && <div className="ms-2"><Button size="sm" onClick={() => onChange([...items, {} as T])}>Add Item</Button></div>}
            { display === Display.EDIT && <div className="ms-2"><Button size="sm" onClick={() => setDisplay(Display.PREVIEW)}><FontAwesomeIcon icon={faMinus}/></Button></div>}
        </div>
        { display === Display.EDIT && <div>
            { items && items.map((item, index) => (
                <div key={index} className="pb-2">
                    <ListItem
                        item={item}
                        title={getItemTitle(item)}
                        form={form}
                        onChange={(newItem) => onChange(items.map((i, iIndex) => iIndex === index ? newItem : i))}
                        deleteItem={() => onChange(items.filter((_, i) => i !== index))}
                        copyItem={() => onChange([...items, JSON.parse(JSON.stringify(item))])}
                        moveUp={() => onChange(moveItemUp(items, index))}
                        moveDown={() => onChange(moveItemDown(items, index))}
                        invalid={validate && !validate(item)}
                    />

                </div>
            ))}
        </div>
        }
    </div>
    );


}
