/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-restricted-syntax */
/**
 * Compares two arrays and identifies elements to be removed and added.
 *
 * @param {Array<T>} oldArray - The original array.
 * @param {Array<T>} newArray - The updated array.
 * @param {(a: T, b: T) => boolean} [compareFn] - A function used to compare elements for equality.
 *                                                Should return true if elements are considered equal.
 *                                                Defaults to a strict equality check (===).
 * @returns {{ toBeRemoved: Array<T>, toBeAdded: Array<T> }} An object containing arrays of elements to be removed and added.
 *
 * @template T
 *
 * @example
 * // Example usage with a custom comparison function
 * let oldArray = [{ id: 1, value: 'A' }, { id: 2, value: 'B' }, { id: 3, value: 'C' }];
 * let newArray = [{ id: 2, value: 'B' }, { id: 3, value: 'C' }, { id: 4, value: 'D' }];
 *
 * let changes = findChanges(oldArray, newArray, (a, b) => a.id === b.id);
 * console.log('To be removed:', changes.toBeRemoved); // Output: [{ id: 1, value: 'A' }]
 * console.log('To be added:', changes.toBeAdded);    // Output: [{ id: 4, value: 'D' }]
 */
export function findChanges<T>(oldArray: Array<T>, newArray: Array<T>, compareFn?: (a: T, b: T) => boolean): { toBeRemoved: Array<T>, toBeAdded: Array<T> } {
    // Initialize arrays to store elements to be removed and added
    const toBeRemoved: Array<T> = [];
    const toBeAdded: Array<T> = [];

    // Default comparison function if none is provided
    compareFn = compareFn || ((a, b) => a === b);

    // Check elements in oldArray but not in newArray
    for (const element of oldArray) {
        if (!newArray.some((newElement) => compareFn!(element, newElement))) {
            toBeRemoved.push(element);
        }
    }

    // Check elements in newArray but not in oldArray
    for (const element of newArray) {
        if (!oldArray.some((oldElement) => compareFn!(element, oldElement))) {
            toBeAdded.push(element);
        }
    }

    // Return the results
    return {
        toBeRemoved,
        toBeAdded
    };
}
