/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Col,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row,
    Table,
} from "reactstrap";

import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { adminGetDeviceEvents } from "../../../../actions/Devices/actions";
import DeviceEventDump from "../../../Devices/DeviceEventDump";
import DeviceEventStatusBadge from "../../../Devices/DeviceEventStatusBadge";
import { Loading } from "../../../Helper/Loading";
import { PaginationRow } from "../../../Helper/PaginationRow";

const defaultEvent = { status: "", id: "" };
export default function DeviceEventHistory() {
    const eventHistory = useSelector((state) => state.devices.eventHistory);
    const isLoading = useSelector((state) => state.devices.isLoading);
    const device = useSelector((state) => state.devices.current);
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(25);
    const params = useParams();

    const isDataUnavailable = isLoading || !eventHistory || params.device_uid !== device?.UID;

    useEffect(() => {
        const offset = 0;
        dispatch(adminGetDeviceEvents(params.device_uid, { limit, offset }));
    }, [limit, params.device_uid]);


    const [currentEvent, setCurrentEvent] = useState(defaultEvent);
    const closeModal = () => setCurrentEvent(defaultEvent);


    const onPageChanged = (data) => {
        const { currentPage, pageLimit } = data;
        const offset = Math.max((currentPage - 1) * pageLimit, 0);
        dispatch(adminGetDeviceEvents(device.UID, { limit, offset }));
    };

    if (isDataUnavailable) {
        return <Loading />;
    }

    return (
        <div>
            <Row>
                <Col>
                    <div css={css`min-height: 30vh;`}>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Event ID</th>
                                    <th>Command</th>
                                    <th>Status</th>
                                    <th>Created</th>
                                    <th>Updated</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {eventHistory.results.map((i) => <tr key={i.id}>
                                    <td>{i.id}</td>
                                    <td>{i.command}</td>
                                    <td><DeviceEventStatusBadge event={i}/></td>
                                    <td>{dayjs(i.date_created).format("HH:mm:ss D MMM YYYY")}</td>
                                    <td>{dayjs(i.date_updated).format("HH:mm:ss D MMM YYYY")}</td>
                                    <td ><Button size="sm" onClick={() => setCurrentEvent(i)}>Details</Button></td>
                                </tr>)}
                            </tbody>
                        </Table>
                        <EventDetailModal event={currentEvent} toggle={closeModal}></EventDetailModal>
                    </div>
                </Col>
            </Row>
            <PaginationRow results={eventHistory.results || []} totalCount={eventHistory?.count || 0} onPageChanged={onPageChanged} filter={{ limit }} updateLimit={setLimit} />
        </div>
    );
}


function EventDetailModal({ event, toggle }) {

    return (
        <div>
            <Modal isOpen={event.id} toggle={toggle} size="lg" >
                <ModalHeader className="bg-white py-3" toggle={toggle}>
                    <div className="py-3"></div>
                </ModalHeader>
                <ModalBody className="bg-white">
                    <DeviceEventDump event={event} />
                </ModalBody>
                <ModalFooter className="bg-white">
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

EventDetailModal.propTypes = {
    event: PropTypes.object,
    toggle: PropTypes.func
};

