export const isPositiveInteger = (num: number): boolean => Number.isInteger(num) && num > 0;

export const isDigitCountValid = (str: string, expectedDigitCount: number): boolean => {

    const digitCount: number = str.length;
    return digitCount === expectedDigitCount;
};

export const matchesPattern = (str: string, pattern: string): boolean => {
    // first check if pattern is defined
    if (!pattern) return true;

    // Test if string matches the pattern
    const isMatch = (new RegExp(pattern)).test(str);

    return isMatch;
};
