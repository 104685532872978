/** @jsxImportSource @emotion/react */

import { CHECK_LOCATION, LAYER_STAGE, LAYER_TYPE } from "../../../../actions/Layers/constants";
import { SPECIAL_ACTIONS } from "../../Layers/Layer/SpecialActions";
import FilterableConfigList from "./FilterableConfigList";

export const actionForm = [
    {
        label: "Type",
        type: "single-select",
        name: "type",
        options: Object.keys(SPECIAL_ACTIONS).map((i) => ({ value: SPECIAL_ACTIONS[i], label: i }))
    },
    {
        label: "Child layer type",
        name: "child_type",
        type: "single-select",
        options: Object.keys(LAYER_TYPE).map((i) => ({ value: LAYER_TYPE[i], label: i }))
    },
    {
        label: "Allocate stage",
        name: "allocate_stage",
        type: "single-select",
        options: Object.keys(LAYER_STAGE).map((i) => ({ value: LAYER_STAGE[i], label: i }))
    },
    {
        label: "Check location",
        name: "check_location",
        type: "single-select",
        options: Object.keys(CHECK_LOCATION).map((i) => ({ value: CHECK_LOCATION[i], label: i }))
    },
    {
        label: "Position",
        name: "position",
        type: "number",
        description: "The position of the button.",
    },
];

const defaultField = {
    field: {
        type: SPECIAL_ACTIONS.CREATE_CHILD_AND_START_CHECK,
        child_type: LAYER_TYPE.PALLET,
        allocate_stage: LAYER_STAGE.INTAKE,
        check_location: LAYER_STAGE.INTAKE
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
    }
};
export default function SpecialActionsConfigForm({ name, title, fields, description, setArray }) {
    const getFieldTitle = (object) => <span>{object.check_location} on {object.child_type} </span>;
    const getFieldForm = () => actionForm;
    return <FilterableConfigList
        name={name}
        title={title}
        useDisplayFilter={ ["fruit_types", "exclude_fruit_types"]}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle} />;

}


