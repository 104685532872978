import { StyleSheet, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

export const tableStyles = StyleSheet.create({
    table: {
        display: "table",
        width: "100%",
        marginBottom: "20px",
        flexDirection: "row",
        justifyContent: "space-between",
        borderTop: "0.8px solid #d8d8d8",
        borderLeft: "0.8px solid #d8d8d8"
    },
    pageTitle: {
        color: "black",
        fontFamily: "Poppins-Medium",
        fontSize: "14px",
        marginBottom: "10px"
    },
    tableHeader: {
        backgroundColor: "#f0f0f0",
        width: "100%",
        height: "19px"
    },
    tableHeaderText: {
        fontFamily: "Poppins-Medium",
        fontSize: "7px",
        textAlign: "center",
        flexWrap: "nowrap", // Prevent text wrapping
        whiteSpace: "nowrap", // Additional property for certain cases
    },
    tableCell: {
        padding: "2px 8px 2px 4px", // the 20px is hack to be still able to set a background color on the row
        textAlign: "center",
        flexGrow: 1,
        fontSize: "9px",
        verticalAlign: "middle",
        borderBottom: "0.8px solid #d8d8d8",
        borderRight: "0.8px solid #d8d8d8"
    },
    tableCellHeight: {
        height: "19px",
        display: "flex",
        justifyContent: "center",
        alignItems: "left"
    },
    note: {
        marginTop: "10px",
        fontSize: "8px",
        lineHeight: 1.1,
        backgroundColor: "#f0f0f0",
        padding: "8px"
    }
});
export function PDFTable({ columns }) {
    return <View style={tableStyles.table} >
        {columns.map((i, index) => <View key={index} style={{ flexGrow: 1 }} >{i}</View>)}
    </View>;
}

PDFTable.propTypes = {
    columns: PropTypes.array,

};


