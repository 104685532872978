/** @jsxImportSource @emotion/react */
import { faSend } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
    Button,
    FormGroup, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import { Layer } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { getEnumKeyByValue } from "../../../../utils/enum";
import AttachmentLink from "../../../Helper/AttachmentLink";
import { getBlobNameFromUrl } from "../../Layers/Layer/LayerReportsView";
import { truncateText } from "../../Layers/Layer/Report/utils";
import { getReports } from "./LayerReportsView";
import { ReportType } from "./Report/utils";


const MAX_PDF_SIZE_IN_MB = 8;


export interface AttachedUrls {
    [key: string]: string;
}
interface SendReportModalProps {
    onSend: (attachedUrls?: AttachedUrls) => void;
    reportType: string;
    pdfSizeInMB: number;
    checkPDFSize?: boolean;
    attachedReportsType?: string;
}

export const SendReportModal = ({ onSend, reportType, attachedReportsType, pdfSizeInMB, checkPDFSize }: SendReportModalProps) => {
    const config = useConfig();
    const layer = useSelector<any, Layer>((state) => state.layers.current);
    const [attachedUrls, setAttachedUrls] = useState({});
    const reports = getReports(layer);
    const layer_config = config.get_layer_config(layer);

    const [isOpen, setIsOpen] = useState(false);
    const onSendButtonClick = () => {
        setIsOpen(false);
        onSend(attachedUrls);
    };
    const handleCheckboxChange = (key, index, url) => {
        const blob_name = getBlobNameFromUrl(url);
        setAttachedUrls((prevState) => ({
            ...prevState,
            [`${key}_${index}`]: !prevState[`${key}_${index}`] ? blob_name : undefined
        }));
    };

    const exceedsThePdfSizeLimit = pdfSizeInMB > MAX_PDF_SIZE_IN_MB;
    const isPdfEmpty = pdfSizeInMB === 0;
    const sendReportButtonDisabled = checkPDFSize && (isPdfEmpty || exceedsThePdfSizeLimit);
    const attachedReports = Object.entries(reports).filter(([reportType]) => reportType === attachedReportsType);
    return <>
        <div>{
            checkPDFSize && exceedsThePdfSizeLimit ? <span className="text-warning p-2">The report size exceeds the limit of 8MB</span> : <></>
        }</div>
        <div className="pe-4">
            <Button color="primary" outline className="text-nowrap" disabled={sendReportButtonDisabled || pdfSizeInMB === 0} size="sm" onClick={() => setIsOpen(true)}> Send {getEnumKeyByValue(ReportType, reportType)} report <FontAwesomeIcon icon={faSend} /></Button>
        </div>
        <Modal key={1} isOpen={isOpen} size="md">
            <ModalHeader >Send {reportType} report to the supplier</ModalHeader>
            <ModalBody>
                <Row>{layer_config?.show_upload_reports_button && attachedReports.length > 0 && <p>Which {attachedReportsType} files you want to sent?</p>}</Row>
                <Row>{layer_config?.show_upload_reports_button && attachedReports.length === 0 && <p>No attached files founded</p>}</Row>
                {attachedReports.map(([reportType, reports]) => {
                    return <div key={reportType}>
                        <div>
                            {reports.map(([key, url], index) => {
                                const target = `${reportType}_${index}`;
                                return (<div key={index} style={{ display: "flex", alignItems: "center" }}>
                                    <FormGroup check style={{ marginRight: "8px" }}>
                                        <Input
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange(key.replace(`report_${reportType}_`, ""), index + 1, url)}
                                        />
                                    </FormGroup>
                                    <div style={{ flex: 1 }}>
                                        <AttachmentLink
                                            url={url}
                                            label={truncateText(key.replace(`report_${reportType}_`, ""), 35, 40)}
                                            id={target}
                                        />
                                    </div>
                                </div>);
                            })}

                        </div>
                    </div>;
                })}
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => setIsOpen(false)}>Cancel</Button>
                <Button color="success" onClick={onSendButtonClick}>Send <FontAwesomeIcon icon={faSend} /></Button>
            </ModalFooter>
        </Modal>
    </>;
};
