/** @jsxImportSource @emotion/react */
import dayjs from "dayjs";
import Field, { DataSource } from "./Field";

class LayerFruitArrivalDelayField extends Field {
    field_type = "layer_fruit_arrival_delay_field";

    backend_table = "layer_meta";

    arrival_date: any | null = null;

    estimated_date_of_arrival: any | null = null;

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        this.value = null;

        // estimated_date_of_arrival field for the current layer, hard coded for now
        this.estimated_date_of_arrival = datasource.layer.estimated_date_of_arrival;

        if (datasource.layer[this.fieldname_getter]) {
            this.value = datasource.layer[this.fieldname_getter];
        }
        if (datasource.layer.parent_meta && datasource.layer.parent_meta[this.fieldname_getter]) {
            this.value = datasource.layer.parent_meta[this.fieldname_getter];
        }

        this.value = this.value_filter(this.value, datasource);

        this.arrival_date = dayjs(this.value);
        this.estimated_date_of_arrival = dayjs(this.estimated_date_of_arrival);

        if (this.value && this.arrival_date.isValid() && this.estimated_date_of_arrival.isValid()) {
            this.value = dayjs(this.arrival_date).diff(this.estimated_date_of_arrival, "day");
        } else {
            this.value = "-";
        }

        return this;
    }

    display_component(): JSX.Element {
        let className = "";
        let displayValue = "";

        if (this.value === "-") {
            displayValue = "-";
        } else if (this.value === 1) {
            displayValue = "1 day";
            className = "text-success";
        } else if (this.value <= 3) {
            displayValue = `${this.value} days`;
            className = "text-success";
        } else if (this.value < 7) {
            displayValue = `${this.value} days`;
            className = "text-warning";
        } else {
            displayValue = `${this.value} days`;
            className = "text-danger";
        }

        return <span className={className}>{displayValue}</span>;
    }


}

export default LayerFruitArrivalDelayField;
