/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";

/**
 *
 * Use this scale to visualize sensor values
 * FLUID: means the indicator doesn't align with the legenda and can be on the seperators line
 */

export const ripeningStageCategoryScale = [
    {
        value: "hard",
        text: "Hard",
    },
    {
        value: "triggered",
        text: "Triggered",
    },
    {
        value: "ready_to_eat",
        text: "RTE",
    },
];

export const kilogramPressureScale = [
    {
        value: 13,
        text: "13",
        seperator_right: true,
    },
    {
        value: 12,
        text: 12,
    },
    {
        value: 11,
        text: 11,
    },
    {
        value: 10,
        text: 10,
        seperator_right: true,
    },
    {
        value: 9,
        text: 9,
    },
    {
        value: 8,
        text: 8,
    },
    {
        value: 7,
        text: 7,
        seperator_right: true,
    },
    {
        value: 6,
        text: 6,
    },
    {
        value: 5,
        text: 5,
    },
    {
        value: 4,
        text: 4,
        seperator_right: true,
    },
    {
        value: 3,
        text: 3,
    },
    {
        value: 2,
        text: 2,
    },
    {
        value: 1,
        text: 1,
        seperator_right: true,
    },
    {
        value: 0,
        text: "0",
    },
];

function set_atronPressureScale() {
    const atronPressureScale = [];
    const range = (start, stop, step = 1) => Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step);
    range(10, 101, 1).reverse().forEach((i) => {
        atronPressureScale.push({
            value: i,
            seperator_right: i < 100 && i % 10 === 0 && i !== 10,
        });
    });
    return atronPressureScale;
}
export const atronPressureScale = set_atronPressureScale();

const pointerFluidCss = css`
    position:relative;
    ::after{
      content: "";
      position: absolute;
      top: -20px;
      left: 50%;
      width: 0;
      height: 0;
      border-top: solid 15px #757575;
      border-left: solid 15px transparent;
      border-right: solid 15px transparent;
      transform: translateX(-50%);
  }`;

const pointerCss = css`
    position:relative;
    ::after{
      content: "";
      position: absolute;
      top: -38px;
      left: 50%;
      width: 0;
      height: 0;
      border-top: solid 15px #757575;
      border-left: solid 15px transparent;
      border-right: solid 15px transparent;
      transform: translateX(-50%);
  }`;
export const Scale = ({
    legend, blocks, value, fluid, external_colors,
}) => {
    const _legend = legend || blocks;

    const firstColor = external_colors?.[0]?.color || "#057430";
    const lastColor = external_colors?.[external_colors.length - 1]?.color || "#612F0A";

    const ScaleCss = css`
      position: relative;
  .gradient {
    height: 15px;
      border-radius: 3px;
      background: ${firstColor}; /* fallback for old browsers */
      background: -webkit-linear-gradient(to right, ${firstColor}, ${lastColor}); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
          to right,
          ${firstColor},
          ${lastColor}
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }`;

    return (
        <div css={ScaleCss} className="" >
            <div className="gradient d-flex justify-content-between" >

                {fluid && blocks.map((i, index) => <div key={index} id={i.value} css={[css`width: ${100 / blocks.length}%;position:relative; height: 15px; border-right: 3px solid ${i.seperator_right ? "rgba(255,255,255,0.8)" : "transparent"}; box-sizing: border-box;`, i.value === value && fluid ? pointerFluidCss : ""] } > </div>)}

            </div>
            <div className="d-flex justify-content-between">
                <Legend legend={_legend} fluid={fluid} value={value}></Legend>
            </div>
        </div>
    );
};

Scale.defaultProps = {
    blocks: false,
    fluid: false,
};
Scale.propTypes = {
    legend: PropTypes.any,
    blocks: PropTypes.any,
    value: PropTypes.any,
    fluid: PropTypes.any,
    external_colors: PropTypes.array,
};

const Legend = ({ legend, fluid, value }) => {
    const items = [];
    legend.forEach((i, index) => {
        items.push(<span key={`sep${index}`} className="px-2" css={ i.value === value && !fluid ? pointerCss : ""} >{i.text || ((typeof i === "string") && i) }</span>);
        if (i.seperator_right) {
            items.push(<span key={index} css={css`background: rgba(255,255,255,0.8);margin-top: -20px; width: 3px;`} ></span>);
        }
    });
    return items;
};
