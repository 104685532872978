import {
    CREATE_FIELD_OPTION,
    DELETE_FIELD_OPTION,
    LIST_ALL_FIELD_OPTIONS,
    LIST_FIELD_OPTIONS,
    UPDATE_FIELD_OPTION,
} from "./actionsTypes";

const endpoint = "tenants/";

export function getTenantFieldOptions(tenant_id, fieldName) {
    return {
        types: [
            LIST_FIELD_OPTIONS.LOADING,
            LIST_FIELD_OPTIONS.SUCCESS,
            LIST_FIELD_OPTIONS.ERROR,
        ],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint}${tenant_id}/form-field-options/${fieldName}/`,
            }
        }
    };
}

export function getAllTenantFieldOptions(tenant_id) {
    return {
        types: [
            LIST_ALL_FIELD_OPTIONS.LOADING,
            LIST_ALL_FIELD_OPTIONS.SUCCESS,
            LIST_ALL_FIELD_OPTIONS.ERROR,
        ],
        payload: {
            request: {
                method: "GET",
                tenant_id,
                url: `${endpoint}${tenant_id}/form-field-options/all`,
            }
        }
    };
}

export function createTenantFieldOption(tenant_id, fieldName, data) {
    return {
        types: [
            CREATE_FIELD_OPTION.LOADING,
            CREATE_FIELD_OPTION.SUCCESS,
            CREATE_FIELD_OPTION.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/form-field-options/${fieldName}/`,
                data,
            }
        }
    };
}

export function deleteTenantFieldOption(tenant_id, fieldName, option_id) {
    return {
        types: [
            DELETE_FIELD_OPTION.LOADING,
            DELETE_FIELD_OPTION.SUCCESS,
            DELETE_FIELD_OPTION.ERROR,
        ],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint}${tenant_id}/form-field-options/${fieldName}/${option_id}`,
            }
        }
    };
}

export function deleteAllTenantFieldOption(tenant_id, fieldName) {
    return {
        types: [
            DELETE_FIELD_OPTION.LOADING,
            DELETE_FIELD_OPTION.SUCCESS,
            DELETE_FIELD_OPTION.ERROR,
        ],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint}${tenant_id}/form-field-options/${fieldName}/all`,
            }
        }
    };
}

export function updateTenantFieldOption(tenant_id, fieldName, data) {
    return {
        types: [
            UPDATE_FIELD_OPTION.LOADING,
            UPDATE_FIELD_OPTION.SUCCESS,
            UPDATE_FIELD_OPTION.ERROR,
        ],
        payload: {
            request: {
                method: "PUT",
                url: `${endpoint}${tenant_id}/form-field-options/${fieldName}/${data.id}`,
                data,
            }
        }
    };
}

export function importTenantFieldOption(tenant_id, fieldName, data) {
    return {
        types: [
            UPDATE_FIELD_OPTION.LOADING,
            UPDATE_FIELD_OPTION.SUCCESS,
            UPDATE_FIELD_OPTION.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/form-field-options/${fieldName}/import`,
                data,
            }
        }
    };
}

export function resetAllTenantFieldOption(tenant_id, data) {
    return {
        types: [
            UPDATE_FIELD_OPTION.LOADING,
            LIST_ALL_FIELD_OPTIONS.SUCCESS,
            UPDATE_FIELD_OPTION.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/form-field-options/reset-all`,
                data,
            }
        }
    };
}

