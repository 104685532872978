/** @jsxImportSource @emotion/react */

import FilterableConfigList from "./FilterableConfigList";

const defaultField = {
    field: {
        label: "Packing",
        type: "packing"
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
    }
};
export default function RequiredImageConfigForm({ name, title, fields, description, setArray, useDisplayFilter = ["fruit_types", "exclude_fruit_types"] }) {
    const getFieldTitle = (object) => <span>{object.label}</span>;
    const getFieldForm = (object, setObject) => [
        {
            label: "Label",
            type: "text",
            name: "label",
            onchange_callback: ({ field, value }) => {
                setObject({ ...object, [field]: value, type: value.trim().replaceAll(" ", "_").toLowerCase() });
            },
        },
        {
            label: "Type",
            type: "text",
            name: "type",
        },

    ];
    return <FilterableConfigList
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle}
        useDisplayFilter={useDisplayFilter}
    />;

}


