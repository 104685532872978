import { AvosAction } from "../../types";
import { Filter } from "../reducer";
import { LIST, CREATE, DELETE, UPDATE } from "./actionTypes";
import { Supplier } from "./reducer";

const endpoint = "/supplier/";

export const getSuppliers = (filter: Filter) => {
    return {
        types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
        payload: {
            request: {
                method: "GET",
                url: endpoint,
                params: filter,
            },
        },
    } as unknown as AvosAction;
};

export const deleteSupplier = (id: string) => {
    return {
        types: [DELETE.LOADING, DELETE.SUCCESS, DELETE.ERROR],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint}${id}`,
            },
        },
    } as unknown as AvosAction;
};

export const saveSupplier = (data: Supplier) => {
    if (data.id) {
        return {
            types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
            payload: {
                request: {
                    method: "PUT",
                    url: `${endpoint}${data.id}`,
                    data,
                },
            },
        } as unknown as AvosAction;
    }
    return {
        types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: endpoint,
                data,
            },
        },
    } as unknown as AvosAction;
};

export const updateSupplierFilter = (filter: Filter) => ({
    type: LIST.FILTER,
    payload: filter,
}) as AvosAction;
