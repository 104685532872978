export const hasNoValueToPrint = (value) => value === undefined
    || value === null;

export const dashIfEmpty = (value, callback, check = hasNoValueToPrint) => {
    if (check(value)) return "-";
    return typeof callback === "function" ? callback(value) : value;
};

export function capitalizeFirstLetter(value, check = hasNoValueToPrint) {
    if (typeof value === "string") {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
    if (check(value)) return "-";
    return value;
}

export function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export function getEnumLabel(enumObj, value) {
    return Object.keys(enumObj).find((key) => enumObj[key] === value);
}
