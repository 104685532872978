import { Link } from "react-router-dom";
import Field, { DataSource } from "./Field";

class LayerField extends Field {
    field_type = "layer_field";

    backend_table = "layer";

    layer_id?: string;

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        // always reset the value
        this.value = null;
        this.value = this.value_filter(datasource.layer[this.fieldname_getter], datasource);
        this.layer_id = datasource.layer?.id || undefined;
        return this;
    }

    // * render react component, can be overwritten by special fields
    display_component(): JSX.Element {
        // * Consultant should use LabelLinkField, this is a fallback for backwards compatibility
        if (this.fieldname_getter === "label" && this.layer_id) {
            return <Link to={`/layer/${this.layer_id}`}>{this.value}</Link>;
        }
        return <span className={this.get_classname()}>{this.formatted_value()}</span>;
    }


}

export default LayerField;
