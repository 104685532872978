/** @jsxImportSource @emotion/react */
import { useMsal } from "@azure/msal-react";
import { css } from "@emotion/react";
import { faBars, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Link, NavLink, useLocation, useNavigate
} from "react-router-dom";
import Select from "react-select";
import { Button } from "reactstrap";
import { logoutUser, switchTenant } from "../../../actions/Auth/actions";
import { LAYER_TAB_CATEGORIES } from "../../../actions/Layers/constants";
import useConfig from "../../../actions/Tenants/config/configHook";
import { headerLinks } from "../../../actions/Tenants/config/constants";
import logo from "../../../img/logo-experience-fruit-quality.png";
import { isFilledArray } from "../../../utils";
import useAuthorization from "../../../utils/authorization";
import FindLayerBox from "../Layers/FindLayerBox";
import FruitFilter from "../Layers/FruitFilter";

export default function Header({ blueBackground, showLayerFilters }) {
    const [open, toggleNav] = useState(false);

    let backgroundColor = "white";
    if (blueBackground) {
        backgroundColor = open ? "#f8f8f8" : "#f4f9ff";
    }


    return <div css={css`box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12); transition: color 0.3s ease-in; background-color:${backgroundColor}`}>
        <div className="container py-4">
            {/* MOBILE NAv */}
            <div className="d-lg-none">
                <div className="d-flex align-items-start justify-content-between" css={css`min-height: 3.6rem;`}>
                    <div>
                        <Link to={`/layer/tab/${LAYER_TAB_CATEGORIES.OPERATION}/first`} >
                            <img src={logo} alt="" css={css`height: 48px; padding-bottom: 8px;`} />
                        </Link>
                    </div>
                    <div className="">
                        <NavToggle open={open} toggleNav={toggleNav} ></NavToggle>
                    </div>
                </div>
                {open
                    && <div className="w-100">
                        <div className="navbar">
                            <ul className="navbar-nav w-100">
                                <NavMainLinks />
                                <NavSecondaryLinks />
                            </ul>
                        </div>
                    </div>
                }

            </div>
            {/* TABLET + DESKTOP NAV */}
            <div className="d-none d-lg-flex align-items-center justify-content-start" css={css`min-height: 3.6rem;`}>
                <div>
                    <Link to={`/layer/tab/${LAYER_TAB_CATEGORIES.OPERATION}/first`} >
                        <img src={logo} alt="" css={css`height: 48px; padding-bottom: 8px;`} />
                    </Link>
                </div>
                {!open
                    && <div css={css`padding-left: 2em;`}>
                        <div className="navbar navbar-expand-lg">
                            <ul className="d-flex navbar-nav justify-content-end w-100">
                                <NavMainLinks />
                            </ul>
                        </div>
                    </div>
                }
                {!open && <div className="ms-auto rounded d-flex align-items-center justify-content-center">
                    {showLayerFilters
                        && <>
                            <FruitFilter />
                            <FindLayerBox />
                        </>
                    }
                </div>}
                {open
                    && <div className="ms-auto">
                        <div className="navbar navbar-expand-lg">
                            <ul className="d-flex navbar-nav justify-content-end w-100">
                                <NavSecondaryLinks />
                            </ul>
                        </div>
                    </div>
                }
                <div className="ms-3">
                    <NavToggle open={open} toggleNav={toggleNav} ></NavToggle>
                </div>
            </div>
        </div>
    </div>;
}

Header.defaultProps = {
    blueBackground: false,
    showLayerFilters: false,
};

Header.propTypes = {
    blueBackground: PropTypes.bool,
    showLayerFilters: PropTypes.bool,
};


const NavItemCSS = css`
    color: black;
    min-width: 2.5rem;
`;

function NavToggle({ open, toggleNav }) {
    return (

        <Button color="light" css={NavItemCSS} onClick={() => toggleNav(!open)}>
            {!open && <span><FontAwesomeIcon icon={faBars} /></span>}
            {open && <span><FontAwesomeIcon icon={faTimes} /></span>}
        </Button>
    );
}
NavToggle.propTypes = {
    open: PropTypes.bool,
    toggleNav: PropTypes.func,
};


const GeneralLink = ({ path, label }) => {
    const location = useLocation();
    // remove /first from path to make sure the active class is set correctly for tab Categories
    const cleanedPath = path.endsWith("/first") ? path.slice(0, -"/first".length) : path;
    const isActive = location.pathname.startsWith(cleanedPath);
    return (
        <li className={`nav-item ${isActive ? "active" : ""}`} key={path}>
            <Link className={`nav-link ${isActive ? "active fw-bold" : ""}`} to={path}>{label}</Link>
        </li>
    );
};
GeneralLink.propTypes = {
    path: PropTypes.string,
    label: PropTypes.string,
};


const generalLinks = {
    [headerLinks.home]: (label) => <GeneralLink key={headerLinks.home} path={`/layer/tab/${LAYER_TAB_CATEGORIES.OPERATION}/first`} label={label || "Overview"} />, // Legacy EYE before the planning module
    [headerLinks.tabCategoryOperation]: (label) => <GeneralLink key={headerLinks.tabCategoryOperation} path={`/layer/tab/${LAYER_TAB_CATEGORIES.OPERATION}/first`} label={label || "Operation"} />,
    [headerLinks.tabCategoryStock]: (label) => <GeneralLink key={headerLinks.tabCategoryStock} path={`/layer/tab/${LAYER_TAB_CATEGORIES.STOCK}/first`} label={label || "Stock"} />,
    [headerLinks.tabCategoryQCPlanning]: (label) => <GeneralLink key={headerLinks.QC_PLANNING} path={`/layer/tab/${LAYER_TAB_CATEGORIES.QC_PLANNING}/first`} label={label || "QC"} />,
    [headerLinks.tabCategoryQCReport]: (label) => <GeneralLink key={headerLinks.QC_REPORT} path={`/layer/tab/${LAYER_TAB_CATEGORIES.QC_REPORT}/first`} label={label || "Reports"} />,
    [headerLinks.tabCategoryTransit]: (label) => <GeneralLink key={headerLinks.tabCategoryTransit} path={`/layer/tab/${LAYER_TAB_CATEGORIES.TRANSIT}/first`} label={label || "Transit"} />,
    [headerLinks.mailbox]: (label) => <GeneralLink key={headerLinks.mailbox} path="/mailbox" label={label || "Mailbox"} />, // TODO: migrate this to QC_REPORT
    [headerLinks.insights]: (label) => <GeneralLink key={headerLinks.insights} path="/power-bi" label={label || "Insights"} />,
    [headerLinks.ripeWise]: (label) => <GeneralLink key={headerLinks.ripeWise} path="/ripening-cell" label={label || "RipeWise"} />,
    [headerLinks.waste]: (label) => <GeneralLink key={headerLinks.waste} path="/waste" label={label || "Waste"} />,
    [headerLinks.waste_trops]: (label) => <GeneralLink key={headerLinks.waste_trops} path="/waste-trops" label={label || "Waste"} />,
    [headerLinks.ripening]: (label) => <GeneralLink key={headerLinks.ripening} path="/ripening" label={label || "Ripening"} />, // TODO: remove this link
    [headerLinks.labelling]: (label) => <GeneralLink key={headerLinks.labelling} path="/labelling" label={label || "Labelling"} />,
    [headerLinks.packinglist]: (label) => <GeneralLink key={headerLinks.packinglist} path="/packing-list" label={label || "Packing List"} />, // TODO: migrate to TRANSIT tab
    [headerLinks.deviceManagement]: (label) => <GeneralLink key={headerLinks.deviceManagement} path="/device-managment" label={label || "Device Management"} />,
    [headerLinks.tenants]: (label) => <GeneralLink key={headerLinks.tenants} path="/tenants" label={label || "Tenants"} />,
    [headerLinks.settings]: (label) => <GeneralLink key={headerLinks.settings} path="/settings" label={label || "Settings"} />,
    [headerLinks.admin]: (label) => <GeneralLink key={headerLinks.admin} path="/admin" label={label || "Admin"} />,
};


export function NavSecondaryLinks() {
    const location = useLocation();
    const navigate = useNavigate();
    const { user, authenticated, isLoading } = useSelector((state) => state.auth);
    const auth = useAuthorization();
    const config = useConfig();
    const dispatch = useDispatch();
    const { instance } = useMsal();

    const logoutHandler = () => {
        // make sure to redirect to login so that the redirect_to=/add-check/134/summary param is not set to the currect page
        navigate("/login");
        dispatch(logoutUser());
        instance.logoutRedirect();
    };

    if (!authenticated) {
        // also show login item when loading, this will prevent "flickering" of the header during page load
        return [
            (location.pathname !== "/login" || isLoading) && <li className="nav-item" key="login">
                <NavLink className="nav-link" to="/login" >Login</NavLink>
            </li>,
        ];
    }

    const hasMultiTenants = user.tenants.length > 1;

    const links = { ...generalLinks,
        [headerLinks.logout]: () => <li className="nav-item" key="logout">
            <button className="nav-link link btn btn-link " onClick={() => logoutHandler()} href="#"><span className="text-danger">Logout</span></button>
        </li>,
        [headerLinks.switchTenant]: () => <li className="nav-item" key="switchTenant">
            <Select
                options={user.tenants.map((tenant) => ({ value: tenant.tenant_id, label: tenant.tenant_name }))}
                value={{ label: user.tenant_user.tenant_name, value: user.tenant_id }}
                onChange={(option) => {
                    if (option) {
                        dispatch(switchTenant({ tenant_id: option.value })).then(() => navigate("/"));
                    }
                }}
            />
        </li>,
    };

    let linksToShow = [];


    // use urls configuerd in wizard
    if (isFilledArray(config.root_config.secondary_header_links)) {
        const secondary_header_links_to_show = config.root_config.secondary_header_links.filter((link) => {
            // If link.roles is not defined or empty, show the link
            if (!isFilledArray(link.roles)) return true;

            // Check if user has at least one of the roles required by the link
            return auth.userHasOneOfRoles(link.roles);
        });
        linksToShow = [...secondary_header_links_to_show, ...(hasMultiTenants ? [{ path: headerLinks.switchTenant, label: null }] : [])];
        return linksToShow.filter((item) => links[item.path]).map((item) => links[item.path](item.label));
    }

    // !warning do not use .append() here as it will add a switchTenant button for every render
    linksToShow = [...config.headerSecondaryLinks, ...(hasMultiTenants ? [headerLinks.switchTenant] : [])];

    return linksToShow.filter((item) => links[item.path]).map((path) => links[path](null));
}

export function NavMainLinks() {
    const { user } = useSelector((state) => state.auth);
    const config = useConfig();


    // use urls configuerd in wizard
    if (isFilledArray(config.root_config?.primary_header_links)) {
        return config.root_config?.primary_header_links.filter((item) => generalLinks[item.path]).map((item) => generalLinks[item.path](item.label));
    }

    const getDynamicLinks = () => (user.tenant.show_powerbi_link ? [headerLinks.insights] : []);

    const linksToShow = [...config.headerMainLinks, ...getDynamicLinks()];

    return linksToShow.map((path) => {
        return generalLinks[path](null);
    });
}
