import { toast } from "../../utils/toast";
import { LIST, POST } from "./actionsTypes";

const waste = {
    items: undefined,
    isLoading: false,
    isCurrentLoading: false,
    filter: {
        limit: 12,
        offset: 0
    },
    current: undefined,
    allPallets: {
        numbers: undefined,
        isLoading: false,
    },
};

export const isCurrentPartij = (current) => Array.isArray(current.pallet_nr);

export default (state = waste, action) => {
    switch (action.type) {
    case LIST.LOADING:
        return { ...state, isLoading: true, filter: action.payload.request.filter_object };
    case LIST.SUCCESS:
        return { ...state, isLoading: false, items: action.payload.data };
    case LIST.ERROR:
        return { ...state, isLoading: false };

    case POST.SUCCESS:
        toast.success("Waste log succesfully saved");
        return { ...state, isLoading: false };

    default:
        return state;
    }
};
