/** @jsxImportSource @emotion/react */

import { LAYER_STAGE } from "../../../../actions/Layers/constants";
import FilterableConfigList from "./FilterableConfigList";

export const form = [
    {
        label: "Calibration stage",
        description: "Explicitly set a stage where this calibration is used. If you set this to ripening, all checks done with calibration_stage=ripening will try to use a calibration done on same layer in ripening. Leaving this empty, will only allow to use calibration done on same check.",
        name: "calibration_stage",
        type: "single-select",
        options: Object.keys(LAYER_STAGE).map((i) => ({ value: LAYER_STAGE[i], label: i }))
    },
    {
        label: "Min fruit",
        description: "Minimum fruit to scan before calibrating. If you need 10 fruit, then 3 fruit for calibration, set this to 10.",
        name: "min_fruit",
        type: "number"
    },
    {
        label: "Required sample size",
        description: "The number of fruit needed to get to an calibration sample. IF you need 10 fruit, then 3 fruit for calibration, set this to 3.",
        name: "required_sample_size",
        type: "number"
    },

    // enable later if we actualy want to setup a different action per fruit type
    // {
    //     label: "Device result action",
    //     description: "If we are using a device, what should happen right after we received fruit results from the device?",
    //     name: "device_result_action",
    //     type: "single-select",
    //     options: Object.keys(DeviceResultActions).map((i) => ({ value: i, label: DeviceResultActions[i] }))
    // }
];

const defaultField = {
    field: {},
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
    // exclude_layer_types: [],
    }
};
export default function PressureCalibrationSettingsConfigForm({ name, title, fields, description, setArray }) {
    const getFieldTitle = (object, display_filter) => {
        // Generate string for included values

        const { fruit_types, exclude_fruit_types } = display_filter;
        const includeValuesString = fruit_types && fruit_types.length > 0
            ? `incl: ${fruit_types.join(", ")}`
            : "";
        // Generate string for excluded values
        const excludeValuesString = exclude_fruit_types && exclude_fruit_types.length > 0
            ? `excl: ${exclude_fruit_types.join(", ")}`
            : "";
        return <span><b>{object?.min_fruit || 10} + {object?.required_sample_size || 2}</b> {object?.calibration_stage} {includeValuesString} {excludeValuesString}</span>;
    };
    const getFieldForm = () => form;

    return <FilterableConfigList
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle}
        useDisplayFilter ={["fruit_types", "exclude_fruit_types"]}
    />;

}


