/** @jsxImportSource @emotion/react */
import facepaint from "facepaint";

const breakpoints = [
    // "0", // xs
    "576", // sm
    "768", // md
    "992", // lg
    "1200", // xl
    "1400" // xxl
];

const mq = facepaint(
    breakpoints.map((bp) => `@media (min-width: ${bp}px)`)
);

export default mq;
