import {
    CREATE_CONFIG,
    GET_CONFIG,
    LIST_ALL_CONFIGS,
    LIST_ALL_CONFIG_REVISIONS,
    UPDATE_CONFIG,
    UPDATE_CONFIG_STATE,
} from "./actionsTypes";

const endpoint = "tenants/";

// * config actions
export function getTenantConfig(tenant_id, config_id) {
    return {
        types: [
            GET_CONFIG.LOADING,
            GET_CONFIG.SUCCESS,
            GET_CONFIG.ERROR,
        ],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint}${tenant_id}/configs/${config_id}`,
            }
        }
    };
}


export function getAllActiveTenantConfigs(tenant_id) {
    return {
        types: [
            LIST_ALL_CONFIGS.LOADING,
            LIST_ALL_CONFIGS.SUCCESS,
            LIST_ALL_CONFIGS.ERROR,
        ],
        payload: {
            request: {
                method: "GET",
                tenant_id,
                url: `${endpoint}${tenant_id}/configs/active`,
            }
        }
    };
}

export function getTenantConfigRevisions(tenant_id, config_id) {
    return {
        types: [
            LIST_ALL_CONFIG_REVISIONS.LOADING,
            LIST_ALL_CONFIG_REVISIONS.SUCCESS,
            LIST_ALL_CONFIG_REVISIONS.ERROR,
        ],
        payload: {
            request: {
                method: "GET",
                tenant_id,
                url: `${endpoint}${tenant_id}/configs/${config_id}/revisions`,
            }
        }
    };
}

export function createTenantConfig(tenant_id, data) {
    return {
        types: [
            CREATE_CONFIG.LOADING,
            CREATE_CONFIG.SUCCESS,
            CREATE_CONFIG.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/configs`,
                data,
            }
        }
    };
}

export function patchTenantConfig(tenant_id, config_id, field, value) {
    return {
        types: [
            `${UPDATE_CONFIG.LOADING}~DoNotShowLoading`,
            UPDATE_CONFIG.SUCCESS,
            UPDATE_CONFIG.ERROR,
        ],
        payload: {
            request: {
                method: "PATCH",
                tenant_id,
                url: `${endpoint}${tenant_id}/configs/${config_id}`,
                data: { field, value },
            }
        }
    };
}


export function updateTenantConfigState(data) {
    return {
        type: UPDATE_CONFIG_STATE.SUCCESS,
        payload: { data },
    };
}


export function resetAllTenantConfig(tenant_id, data) {
    return {
        types: [
            LIST_ALL_CONFIGS.LOADING,
            LIST_ALL_CONFIGS.SUCCESS,
            LIST_ALL_CONFIGS.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/configs/reset-all`,
                data,
                tenant_id
            }
        }
    };
}

