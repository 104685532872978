/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";

export enum ModalResult {
    Cancel= "cancel",
    Save= "save",
    Skip= "skip"
}

interface AvosModalProps {
    header?: string;
    size?: string;
    children?: ReactNode;
    onClose: (result: ModalResult) => void;
    isOpen: boolean;
    disableSaveButton?: boolean;
    showSkipButton?: boolean;
    skipButtonText?: string;
    showSaveButton?: boolean;
    saveButtonText?: string;
    showCancelButton?: boolean;
    cancelButtonText?: string;
}

const AvosModal: React.FC<AvosModalProps> = ({
    header,
    children,
    isOpen,
    onClose,
    disableSaveButton,
    size = "xl",
    showSkipButton = false,
    showSaveButton = true,
    showCancelButton = true,
    skipButtonText = "Skip",
    saveButtonText = "Save",
    cancelButtonText = "Cancel"
}) => {
    return (
        <Modal isOpen={isOpen} size={size} toggle={() => onClose(ModalResult.Cancel)}>
            <ModalHeader toggle={() => onClose(ModalResult.Cancel)}>{header}</ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            <ModalFooter>
                {showCancelButton && <Button color="light" onClick={() => onClose(ModalResult.Cancel)}>{cancelButtonText}</Button>}
                {showSkipButton && <Button color="light" onClick={() => onClose(ModalResult.Skip)}>{skipButtonText}</Button>}
                {showSaveButton && <Button disabled={disableSaveButton} color="success" onClick={() => onClose(ModalResult.Save)}>{saveButtonText}</Button>}
            </ModalFooter>
        </Modal>
    );
};

export default AvosModal;

export interface ConfirmationModalProps {
    isOpen: boolean;
    onClose: (result: ModalResult) => void;
    onConfirm: () => void;
    header: string;
    children: ReactNode;
    confirmButtonText?: string;
    cancelButtonText?: string;
    size?: string;
}

export function ConfirmationModal({ isOpen, onClose, onConfirm, header, children, confirmButtonText = "Confirm", cancelButtonText = "Cancel", size = "xl" }:ConfirmationModalProps) {
    const onMOdalClose = (result) => {
        if (result === ModalResult.Save) {
            onConfirm();
        }
        onClose(result);
    };
    return (
        <AvosModal isOpen={isOpen} onClose={onMOdalClose} header={header} showCancelButton={true} showSaveButton={true} saveButtonText={confirmButtonText} cancelButtonText={cancelButtonText} size={size}>
            {children}
        </AvosModal>
    );
}
