/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from "@emotion/react";
import { JsonEditor, JsonEditorProps } from "json-edit-react";
import { useState } from "react";
import { Button, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "../../utils/toast";

const defaultSettings = {
    collapsed: 1,
    theme: "githubDark",
    minWidth: "100%",
    restrictEdit: true,
    restrictDelete: true,
    restrictAdd: true,
    restrictTypeSelection: true,
    restrictDrag: true,
    enableClipboard: () => toast.info("Copied to clipboard!"),
} as unknown as JsonEditorProps;

const VIEWS = {
    EDITOR: "editor",
    TEXTAREA: "textarea",
    PREVIEW: "preview",
};

export default function DebugModal({ title, docProps, isOpen, close, enableClipboardImport = false, onChange = (_:any): void | null => null }) {
    const props = { ...defaultSettings, ...docProps, onChange };
    const [view, setView] = useState(VIEWS.EDITOR);
    const [textAreaData, setTextAreaData] = useState<string>("");
    const [searchText, setSearch] = useState<string>("");
    const [importPreview, setImportPreview] = useState(false);

    const copyToClipboard = async (text) => {
        try {
            const json = JSON.stringify(text, null, 2);
            await navigator.clipboard.writeText(json);
            toast.info("Copied to clipboard!");
        } catch (err) {
            toast.warning("Failed to copy to clipboard");
        }
    };


    // const copyDataFromClipboard = async () => {
    //     try {
    //         // !warning: this wont work due to permission problems
    //         // const text = await navigator.clipboard.readText();

    //         const data = JSON.parse(text);
    //         setImportPreview(data);
    //         toast.info("Data copied from clipboard!");
    //     } catch (err) {
    //         console.error(err);
    //         toast.warning("Failed to copy data from clipboard");
    //     }
    // };

    const confirmPreview = () => {
        onChange(importPreview);
        setImportPreview(false);
        setSearch("");
        setView(VIEWS.EDITOR);
    };

    const readAndParseTextArea = () => {
        try {
            const data = JSON.parse(textAreaData);
            setImportPreview(data);
            setSearch("");
            setView(VIEWS.PREVIEW);
        } catch (err) {
            toast.warning("Failed to parse data");
        }
    };

    const resetPreview = () => {
        setImportPreview(false);
        setTextAreaData("");
        setSearch("");
        setView(VIEWS.EDITOR);
    };

    const previewProps = { ...defaultSettings, data: importPreview || {} };

    return (
        <Modal isOpen={isOpen} toggle={() => close()} size="xl">
            <ModalHeader toggle={() => close()}>{title}</ModalHeader>
            <ModalBody>
                <div css={css`max-height: 70vh; overflow-y: scroll;`}>
                    {view === VIEWS.EDITOR && <InputGroup className="pb-2">
                        <Input type="text" placeholder="Search object..." onChange={(e) => setSearch(e.target.value)} />
                    </InputGroup>}
                    { view === VIEWS.EDITOR && <JsonEditor {...props} searchText={searchText} searchFilter="all" />}
                    {view === VIEWS.TEXTAREA && <InputGroup>
                        <Input css={css`min-height: 50vh!important;`} type="textarea" value={textAreaData} onChange={(e) => setTextAreaData(e.target.value)} />
                    </InputGroup>}
                    { view === VIEWS.PREVIEW && importPreview && <JsonEditor {...previewProps} />}
                </div>
            </ModalBody>
            <ModalFooter>
                {view === VIEWS.EDITOR && enableClipboardImport && <Button color="warning" onClick={() => setView(VIEWS.TEXTAREA)}>Paste from clipboard</Button>}
                {view === VIEWS.EDITOR && <Button color="primary" onClick={() => copyToClipboard(docProps.data)}>Copy</Button>}

                {(view === VIEWS.TEXTAREA || view === VIEWS.PREVIEW) && <Button color="secondary" onClick={() => resetPreview()}>Cancel</Button>}
                {view === VIEWS.TEXTAREA && <Button color="primary" onClick={() => readAndParseTextArea()}>Read & Parse</Button>}
                {view === VIEWS.PREVIEW && importPreview && <Button color="primary" onClick={() => confirmPreview()}>Confirm</Button>}

                <Button color="secondary" onClick={() => close()}>Close</Button>
            </ModalFooter>
        </Modal>
    );
}
