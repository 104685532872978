/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import mrAvos from "../img/avos-eye.jpg";
import DefaultLayout from "./scenes/Layout/DefaultLayout";

export default function NoMatch404() {
    return (
        <DefaultLayout>
            <Container>
                <Row className="justify-content-center align-items-center" css={css`min-height: 82vh`} >
                    <Col className="text-center">
                        <img src={mrAvos} css={css`max-width: 11rem;width: 50%;`}/>
                        <h1 className="mb-0 pt-4">Ooopss</h1>
                        <p className="mb-0 pt-2 pb-4">This requested page does not exist.</p>
                        <div>
                            <Link className="btn btn-primary mx-2 text-nowrap" to="/">Back to landing page</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </DefaultLayout>
    );
}
