export const defaultAvocado = {
    manual_ripening_stage: null,
    manual_color_stage: null,
    defects: [],
    user_action: null,
    pressure: null,
    pressure_1: null,
    pressure_2: null,
    durometer_pressure_1: null,
    durometer_pressure_2: null,
    plu: null,
    image: "",
    unit_of_account: ""
};

export const pressures = [];
// eslint-disable-next-line no-restricted-syntax
for (const kg of [0, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) {
    pressures.push({
        value: kg,
        text: `${kg} kg`,
    });
}
pressures[0] = {
    value: 0,
    text: "< 0.5 kg",
};
pressures.push({
    value: 13,
    text: "> 12 kg",
});

export const pressures_lbs = [];
// eslint-disable-next-line no-restricted-syntax
for (const lbs of [0, 1, 2, 3, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24]) {
    pressures_lbs.push({
        value: lbs,
        text: `${lbs} lbs`,
    });
}
pressures_lbs[0] = {
    value: 0,
    text: "< 1 lbs",
};
pressures_lbs.push({
    value: 25,
    text: "> 24 lbs",
});

export const colors = [
    { value: 1, text: "1" },
    { value: 2, text: "2" },
    { value: 3, text: "3" },
    { value: 4, text: "4" },
    { value: 5, text: "5" },
];
