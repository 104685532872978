/** @jsxImportSource @emotion/react */
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";

export enum ModalResult {
    Cancel = "cancel",
    Save = "save",
    Skip = "skip"
}

export interface AvosModalProps {
    header: string;
    size?: string;
    children: any;
    onClose: (result: ModalResult) => void;
    isOpen: boolean;
    disableSaveButton?: boolean;
    showSkipButton?: boolean;
    skipButtonText?: string;
    showSaveButton?: boolean;
    saveButtonText?: string;
    showCancelButton?: boolean;
    cancelButtonText?: string;
}

export default function AvosModal({
    header, children, isOpen, onClose, disableSaveButton, size = "xl", showSkipButton, showSaveButton = true, showCancelButton = true,
    skipButtonText = "Skip",
    saveButtonText = "Save",
    cancelButtonText = "Cancel"
}: AvosModalProps) {
    return (
        <Modal isOpen={isOpen} size={size} toggle={() => onClose(ModalResult.Cancel)}>
            <ModalHeader toggle={() => onClose(ModalResult.Cancel)} >{ header }</ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            <ModalFooter>
                {showCancelButton && <Button color="light" onClick={() => onClose(ModalResult.Cancel)}>{cancelButtonText}</Button>}
                {showSkipButton && <Button color="light" onClick={() => onClose(ModalResult.Skip)}>{skipButtonText}</Button>}
                {showSaveButton && <Button disabled={disableSaveButton} color="success" onClick={() => onClose(ModalResult.Save)}>{saveButtonText}</Button>}
            </ModalFooter>
        </Modal >
    );
}

