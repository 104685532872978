import { useState } from "react";
import { AxiosRequestConfig } from "axios";
import { createAPIClient, APIClientOptions } from "../constants/apiClient";
import { ROOT_URL } from "../constants/urls";

const base_url = `${ROOT_URL}api`;
const useAvosApi = (endpoint, method = "GET", requestOptions: any = undefined, apiClientOptions: APIClientOptions | undefined = undefined) => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    // configure the request
    const apiClient = createAPIClient(apiClientOptions);
    const callConfig = (data = {}, extra_options: any = {}) => apiClient({
        method,
        url: base_url + (extra_options.endpoint || endpoint),
        params: requestOptions,
        data,
        ...extra_options
    });


    // call the request, update state accordingly
    const call = (data = {}, extra_options = {}) => {
        setLoading(true);
        return callConfig(data, extra_options)
            .then((response) => setResponse(response.data))
            .catch((err) => {
                setError(err);
                throw err;
            })
            .finally(() => setLoading(false));
    };

    return [call, response, loading, error, callConfig];
};


export default useAvosApi;


export const callAvosApi = (endpoint, requestOptions?:AxiosRequestConfig, apiClientOptions?: APIClientOptions) => {
    const apiClient = createAPIClient(apiClientOptions);
    return apiClient({
        method: "GET",
        url: base_url + endpoint,
        ...requestOptions
    });
};

export const createAvosApiCall = (apiClientOptions?: APIClientOptions) => {
    const apiClient = createAPIClient(apiClientOptions);
    return (config: AxiosRequestConfig) => apiClient({
        ...config,
        url: base_url + config.url
    });
};
