import { defineAction } from "redux-define";
import {
    ERROR, LOADING, SUCCESS, TheApp,
} from "../../constants/stateConstants";

export const AUTH = defineAction("AUTH", [LOADING, ERROR, SUCCESS], TheApp);
export const LOGOUT = defineAction("LOGOUT", [LOADING, ERROR, SUCCESS], AUTH);
export const GET = defineAction("GET", [LOADING, ERROR, SUCCESS], AUTH);
export const UPDATE = defineAction("UPDATE", [LOADING, ERROR, SUCCESS], AUTH);

export const POWERBI_AUTH = defineAction("POWERBI_AUTH", [LOADING, ERROR, SUCCESS], AUTH);
export const POWERBI_GET = defineAction("GET", [LOADING, ERROR, SUCCESS], POWERBI_AUTH);
export const POWERBI_LOGOUT = defineAction("LOGOUT", [LOADING, ERROR, SUCCESS], POWERBI_AUTH);

export const BACKEND_VERSION = defineAction("BACKEND_VERSION", [LOADING, ERROR, SUCCESS], AUTH);
