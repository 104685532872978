/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { patchLayer } from "../../../../actions/Layers/actions";

export default function LayerNotes() {
    const dispatch = useDispatch();
    const layer = useSelector((state) => state.layers.current);

    return <div className="w-100 pb-5">
        <div css={css`border-radius:8px;`} className="bg-body shadow p-5 " >
            <div className="d-flex">
                <div>
                    <h3 className="mb-0">NOTES</h3>
                </div>
            </div>
            <div className="pt-3" >
                <Input
                    value={layer.arrival_feedback}
                    type="textarea"
                    id="latest_check_feedback"
                    rows={4}
                    maxLength={255}
                    className="p-4"
                    onChange={(e) => dispatch(patchLayer(layer.id, "arrival_feedback", e.target.value))}
                />
            </div>

        </div>
    </div>;
}
