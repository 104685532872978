/** @jsxImportSource @emotion/react */

import FilterableConfigList from "./FilterableConfigList";

const defaultField = {
    field: {
        label: "Some option", value: "some_option",
    }
};
export default function OptionsConfigForm({ name, title, fields, description, setArray, useDisplayFilter = false }) {
    const getFieldTitle = (object) => <span>{object.label} [{object.value}] </span>;
    const getFieldForm = (object, setObject) => [
        {
            label: "Label",
            name: "label",
            type: "text",
            onchange_callback: ({ field, value }) => {
                setObject({ ...object, [field]: value, value: value.trim().replaceAll(" ", "-").toLowerCase() });
            },
        },
        {
            label: "Value",
            name: "value",
            description: "For true or false, use 1 or 0",
            type: "text"
        },
        {
            label: "Color",
            name: "color",
            description: "A color to give to your button. Use a hex code or a valid css color name.",
            type: "text"
        },
        {
            label: "Class",
            name: "className",
            type: "text",
            description: "A class to give to your button. Use `text-white` if your color is really dark."
        }

    ];


    return <FilterableConfigList
        useDisplayFilter={useDisplayFilter as false | string[]}
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle}
    />;

}

