import { StyleSheet, Text, View } from "@react-pdf/renderer";

export function PDFFooter() {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
    });

    const styles = StyleSheet.create({
        footerContainer: {
            position: "absolute",
            bottom: 21,
            left: 0,
            right: 0,
            height: 40,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
        },
        leftContainer: {
            flexDirection: "row",
            alignItems: "flex-end",
            marginLeft: "24px",
        },
        rightContainer: {
            flexDirection: "row",
            alignItems: "flex-end",
        },
        pageNumberBox: {
            border: "1px solid #f0f0f0",

            borderRadius: 3,
            padding: 4,
            marginRight: "24px",
        },
        pageText: {
            fontSize: 8,
            color: "#444",
        },
        generatedByText: {
            fontSize: 8,
            color: "#198754",
            marginRight: 10,
        },
        dateTimeText: {
            fontSize: 8,
            color: "#999",
            marginLeft: 5,
        },
    });

    return (
        <View style={styles.footerContainer} fixed>
            <View style={styles.leftContainer}>
                <Text style={styles.generatedByText}>https://frontend.experiencedata.app</Text>
                <Text style={styles.dateTimeText}>{formattedDate}</Text>
            </View>
            <View style={styles.rightContainer}>
                <View style={styles.pageNumberBox}>
                    <Text
                        style={styles.pageText}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </View>
        </View>
    );
}
