import { AvosAction } from "../../types";
import { Filter } from "../reducer";
import { SupplierInfo } from "../supplier/reducer";
import { LIST, CREATE, UPDATE, DELETE } from "./actionTypes";

const endpoint = "/supplier/email_recipients";

export const getInternalEmails = (filter: Filter) => {
    return {
        types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
        payload: {
            request: {
                method: "GET",
                url: endpoint,
                params: filter,
            },
        },
    } as unknown as AvosAction;
};


export const saveInternalEmails = (data: SupplierInfo) => {
    if (data.id) {
        return {
            types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
            payload: {
                request: {
                    method: "PUT",
                    url: `${endpoint}/${data.id}`,
                    data,
                },
            },
        } as unknown as AvosAction;
    }
    return {
        types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: endpoint,
                data,
            },
        },
    } as unknown as AvosAction;
};

export const deleteInternalEmails = (id: string) => ({
    types: [DELETE.LOADING, DELETE.SUCCESS, DELETE.ERROR],
    payload: {
        request: {
            method: "DELETE",
            url: `${endpoint}/${id}`,
        },
    },
}) as AvosAction;

export const updateInternalEmailsFilter = (filter: Filter) => ({
    type: LIST.FILTER,
    payload: filter,
}) as AvosAction;
