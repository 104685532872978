/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import { Related } from "../../../../actions/Layers/constants";
import Field, { DataSource } from "./Field";

class ListChildrenLabelLinkField extends Field {
    field_type = "list_children_label_link_field";

    backend_table = "children";

    get_backend_fieldname(): string[] {
        return [`${this.backend_table}.label`, `${this.backend_table}.id`];
    }

    children: Related[] = [];

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        const { children } = datasource.layer;
        if (!children || children.length === 0) {
            this.children = [];
            this.value = "-";
            return this;
        }
        this.children = children.filter((i) => i[this.fieldname_getter]);
        this.value = this.children.map((i) => i[this.fieldname_getter]).join(", ");
        return this;
    }

    display_component(): JSX.Element {
        if (!this.children || this.children.length === 0) {
            return <span>-</span>;
        }
        return (
            <span>
                {this.children.map((item, index) => (
                    <span key={item.id}>
                        <Link to={`/layer/${item.id}`}>{item.label}</Link>
                        {index < this.children.length - 1 && <span>, </span>}
                    </span>
                ))}
            </span>
        );

    }


}

export default ListChildrenLabelLinkField;
