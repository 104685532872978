/** @jsxImportSource @emotion/react */
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Button,
    Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { patchLayer, triggerLayerBusinessRules } from "../../../../actions/Layers/actions";
import MetaForm, { metaFormIsValid } from "../../../Forms/MetaForm";


import useConfig from "../../../../actions/Tenants/config/configHook";


export function EditLayerButton({ layer_type }) {
    const params = useParams();
    const [layerModalOpen, setLayerModal] = useState(params.action === "edit");
    // random comment
    useEffect(() => {
        if (params.action === "edit") {
            setLayerModal(true);
        }
    }, [params.action]);

    const { show_edit_button } = layer_type;

    if (!show_edit_button) return null;

    return <>
        <div className="pe-2" key={0}>
            <Button onClick={() => setLayerModal(true)} size="sm" color="secondary" className="text-nowrap mb-2" outline > <span className="pe-2">Edit</span> <FontAwesomeIcon icon={faPencil} /></Button>
        </div>
        <EditLayerModal
            key={1}
            isOpen={layerModalOpen}
            setLayerModal={setLayerModal}
        />
    </>;
}

interface EditLayerModalProps {
    setLayerModal: (boolean) => void;
    isOpen: boolean;
}

function EditLayerModal({ isOpen, setLayerModal }: EditLayerModalProps) {
    const config = useConfig();
    const layer = useSelector((state: any) => state.layers.current);
    const dispatch = useDispatch();

    const layer_config = config.get_layer_config(layer);
    const meta_form = layer_config?.meta_form || [];

    const setValue = (field, value) => dispatch(patchLayer(layer.id, field, value, false) as any);
    const onDebounce = (field, value) => {
        const field_config = meta_form.find((i) => i.name === field);
        if (metaFormIsValid([field_config], { [field]: value })) {
            return dispatch(patchLayer(layer.id, field, value, true) as any);
        }
        return Promise.resolve();
    };

    const onSubmit = () => {
        if (meta_form && !metaFormIsValid(meta_form, layer)) return;
        dispatch(triggerLayerBusinessRules(layer.id) as any);
        setLayerModal(false);
    };

    const onClose = () => {
        setLayerModal(false);
    };

    return (
        <Modal isOpen={isOpen} size="md">
            <ModalHeader toggle={() => () => onClose()} onClick={() => onClose()}>Edit {layer_config.text} {layer.label} </ModalHeader>
            <ModalBody>
                <MetaForm
                    meta={meta_form}
                    object={layer}
                    config={config}
                    setValue={setValue}
                    onDebounce={onDebounce}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => onClose()}>Cancel</Button>
                <Button color="success" onClick={() => onSubmit()}>Save</Button>
            </ModalFooter>
        </Modal>
    );
}
