/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";

import DeviceEventStatusBadge from "./DeviceEventStatusBadge";

const Plot = createPlotlyComponent(Plotly);

export default function DeviceEventDump({ event }) {

    const graph_data = event?.data?.graph_data;
    const datadump = JSON.stringify(event || {}, null, "\t");

    const downloadData = () => {
        const json = JSON.stringify(graph_data);
        const blob = new Blob([json], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `data_${new Date().getTime()}.json`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return (
        <div >
            <div className="d-flex justify-content-between ">
                <h5>Event: {event.id}
                    <div><Button color="secondary" className="mt-2" onClick={() => downloadData()}>Download</Button></div>
                </h5>
                <div>
                    <DeviceEventStatusBadge event={event} />
                </div>
            </div>
            {graph_data && <div >
                <Plot
                    data={graph_data.data}
                    useResizeHandler={true}
                    style={{ width: "100%", height: "100%" }}
                    layout={{
                        title: graph_data.title,
                        autosize: true,
                    }}
                    config={{
                        displayModeBar: false,
                        displaylogo: false,
                        staticPlot: true,
                    }}
                />
            </div>
            }

            <div className="p-3" css={css`background: #f9f9f9;`}>
                <pre>
                    {datadump}
                </pre>
            </div>

        </div>
    );
}

DeviceEventDump.propTypes = {
    event: PropTypes.object,
};
