import { Provider } from "react-redux";
import PropTypes from "prop-types";
import { getStore } from "../../store";

function StoreProvider({ children }) {
    return <Provider store={getStore()}>{children}</Provider>;
}

StoreProvider.propTypes = {
    children: PropTypes.element,
};

export {
    StoreProvider,
};
