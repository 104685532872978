import { ActiveFilterBadges } from "./ActiveFilterBadges";
import DownloadExcelModal from "./DownloadExcelModal";
import { BulkLayerListCTAButton } from "./LayerListCta";
import TabSettingsModal from "./TabSettingsModal";

export default function TabActions() {
    return <div className="d-flex align-items-center">
        <BulkLayerListCTAButton/>
        <TabSettingsModal />
        <DownloadExcelModal />
        {/* <LayerFilter/> */}
        <ActiveFilterBadges />
    </div>;
}
