/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPowerBIEmbedInfo } from "../../../actions/Auth/actions";

function PowerBI() {
    const dispatch = useDispatch();
    const powerbiEmbedInfo = useSelector((state) => state.auth.powerbiEmbedInfo);
    useEffect(() => {
        if (!powerbiEmbedInfo) {
            dispatch(getPowerBIEmbedInfo());
        }
    });
    return powerbiEmbedInfo
        ? <>
            <div css={css`width:100%;height:2.6em;background-color:white;position:relative;top:2.6em`}></div>
            <PowerBIEmbed
                embedConfig = {{
                    type: "report",
                    id: powerbiEmbedInfo.reportConfig[0].reportId,
                    embedUrl: powerbiEmbedInfo.reportConfig[0].embedUrl,
                    accessToken: powerbiEmbedInfo.accessToken,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        layoutType: models.LayoutType.Custom,
                        customLayout: {
                            displayOption: models.DisplayOption.FitToPage,
                        },
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            },
                        },
                        background: models.BackgroundType.Transparent,
                    },
                }}
                getEmbeddedComponent = {(embed) => {
                    embed.iframe.style = "height:55em; width:100%";
                }}
            /></>
        : <></>;
}

export default PowerBI;
