import { LogLevel } from "@azure/msal-browser";

// https://frontend is both the develop as the production subdomain.
const subdomain = !window.location.origin.endsWith("local") && window.location.host.split(".")[1] ? window.location.host.split(".")[0] : false;

const clientsList = {
    // frontend: { // production
    //     backend_client: "30bb5e28-280f-4e1f-95fe-63c37ba246b8",
    //     frontend_client: "30bb5e28-280f-4e1f-95fe-63c37ba246b8",
    //     scopes: ["openid", "profile", "email"],
    //     tokenType: "idToken" // leave prod unchanged
    // },
    frontend: { // production
        backend_client: "30bb5e28-280f-4e1f-95fe-63c37ba246b8",
        frontend_client: "30bb5e28-280f-4e1f-95fe-63c37ba246b8",
        scopes: ["openid", "profile", "email", "api://30bb5e28-280f-4e1f-95fe-63c37ba246b8/user_impersonation"],
        tokenType: "accessToken" // leave prod unchanged
    },
    "test-avos": { // develop & test
        backend_client: "12ef3e6c-6e1c-4ebd-a6ab-9fd3097389e2",
        frontend_client: "12ef3e6c-6e1c-4ebd-a6ab-9fd3097389e2",
        // frontend_client: "e432166b-24da-41c4-ab36-83f6715f679b", /// sererate app registration for frontend
        scopes: ["openid", "profile", "email", "api://12ef3e6c-6e1c-4ebd-a6ab-9fd3097389e2/user_impersonation"], // which will require scopes to access backend client
        tokenType: "accessToken" // lets test this on the test environment
    },
    "staging-avos": { // staging
        backend_client: "12ef3e6c-6e1c-4ebd-a6ab-9fd3097389e2",
        frontend_client: "12ef3e6c-6e1c-4ebd-a6ab-9fd3097389e2",
        // frontend_client: "e432166b-24da-41c4-ab36-83f6715f679b", /// sererate app registration for frontend
        scopes: ["openid", "profile", "email", "api://12ef3e6c-6e1c-4ebd-a6ab-9fd3097389e2/user_impersonation"], // which will require scopes to access backend client
        tokenType: "accessToken" // lets test this on the test environment
    },
};
const clients = clientsList[subdomain] || clientsList["test-avos"];
const clientId = clients.frontend_client;
// export const scopes = ["openid", "profile", "email", `api://${clients.backend_client}/user_impersonation`];
export const { scopes, tokenType } = clients;
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId,
        // authority: "https://login.windows-ppe.net/common",
        // redirectUri: window.location.protocol + "://" + window.location.host +"/auth",
        postLogoutRedirectUri: "/login",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                case LogLevel.Error:
                    console.error(message); // eslint-disable-line no-console
                    break;
                default:
                }
            },
        },
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes,
    prompt: "select_account",
};
