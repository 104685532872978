import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { getFlagColor } from "./utils";
import { LAYER_TYPE } from "../../../../../actions/Layers/constants";


// * First page for intake report (PO) and check report

export const styles = StyleSheet.create({
    container: {
        border: "1px solid #e3e3e6",
        borderRadius: "5px",
        padding: "10px",
        marginBottom: "10px",
        height: "100%",
    },
    title: {
        color: "black",
        fontFamily: "Poppins-Medium",
        fontSize: "14",
        fontWeight: "bold",
        marginBottom: "10px"
    },
    metaItemContainer: {
    },
    metaItems: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "top",
        color: "#939496",
        // fontSize: "8px",
    },
    metaItemsIntakeReport: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "top",
        color: "#939496",
        fontSize: "10px",
    },
    metaTextValue: {
        color: "#4f4f4f",
        fontWeight: "bold",
        fontSize: "12px",
        textAlign: "right",
        maxWidth: "220px",
    },
    metaTextValueIntakeReport: {
        color: "#4f4f4f",
        fontWeight: "bold",
        marginBottom: "8px",
        maxWidth: "210px",
    },
    image: {
        width: "110px",
    },
    metaContainer: {
        width: "100%",
    },
    notes: {
        marginTop: "10px",
        fontSize: "8px",
        lineHeight: 1.1,
        backgroundColor: "#f0f0f0",
        padding: "8px"
    },
    items: {
        flexDirection: "row",
        display: "flex",
        marginTop: "10px",
        justifyContent: "space-between",
        fontSize: "14px",
    },
    itemOveralDecision: {
        color: "black",
        flexDirection: "row",
        fontFamily: "Poppins-Medium",
        display: "flex",
        marginTop: "20px",
        marginBottom: "20px",
        justifyContent: "space-between",
        fontSize: "14px",
    },
});


export default function PDFLayerMeta({ layer, fields, status }) {
    return (
        <View style={styles.container}>
            <View style={styles.metaContainer}>
                <View style={styles.metaItemsIntakeReport}>
                    <Text>
                        {layer.type === LAYER_TYPE.PURCHASE_ORDER && "Purchase order:" }
                        {layer.type === LAYER_TYPE.PALLET && "Pallet:"}
                    </Text>
                    <Text style={styles.title}>
                        {layer.label}
                    </Text>
                </View>

            </View>
            <View style={styles.metaItemContainer}>
                {fields.filter((i) => !["label", "arrival_notes"].includes(i.fieldname_getter)).map((i, index) => (
                    <View key={index} style={styles.metaItemsIntakeReport}>
                        <View>
                            <Text>{i.label}:</Text>
                        </View>
                        <View>
                            <Text style={status ? styles.metaTextValueIntakeReport : styles.metaTextValue}>{i.display_vector("10px")}</Text>
                        </View>
                    </View>
                ))}
            </View>

            {status && <View style={styles.itemOveralDecision}>
                <Text>Overall decision:</Text>
                <View> <Text style={{
                    width: "80px",
                    backgroundColor: getFlagColor(status),
                    padding: "8px",
                }} ></Text> </View>
            </View>}
        </View>
    );
}

PDFLayerMeta.defaultProps = {
    status: null,
};
PDFLayerMeta.propTypes = {
    layer: PropTypes.object,
    fields: PropTypes.array,
    status: PropTypes.string,
};

