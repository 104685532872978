import { defineAction } from "redux-define";
import {
    ERROR,
    FILTER,
    LOADING,
    STATE,
    SUCCESS,
    TheApp,
} from "../../constants/stateConstants";

export const avocados = defineAction("avocados", [LOADING, ERROR, SUCCESS], TheApp);
export const LIST = defineAction("LIST", [FILTER, LOADING, ERROR, SUCCESS], avocados);
export const GET = defineAction("GET", [LOADING, ERROR, SUCCESS], avocados);
export const RESET = defineAction("RESET", [SUCCESS], avocados);
export const CREATE = defineAction("CREATE", [LOADING, ERROR, SUCCESS], avocados);
export const UPDATE = defineAction("UPDATE", [LOADING, ERROR, SUCCESS], avocados);
export const PATCH = defineAction("UPDATE", [LOADING, ERROR, SUCCESS, STATE], avocados);
export const DELETE = defineAction("DELETE", [LOADING, ERROR, SUCCESS], avocados);
