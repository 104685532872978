/* eslint-disable @typescript-eslint/no-non-null-assertion */

export const resizeBase64Image = (base64: string, width: number, height: number): Promise<string> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d")!;
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result as string);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob!);
            }, "image/webp");
        };
        img.onerror = reject;
        img.src = base64;
    });
};

export const MAX_IMAGE_WIDTH = 640;
export const MAX_IMAGE_HEIGHT = 480;
export const MAX_IMAGE_AREA = MAX_IMAGE_WIDTH * MAX_IMAGE_HEIGHT;

export const checkImageSizeAndShrinkIfNeeded = async (base64: string, width: number, height: number): Promise<string> => {
    const imageArea = width * height;
    if (imageArea > MAX_IMAGE_AREA) {
        const scale = Math.sqrt(MAX_IMAGE_AREA / imageArea);
        const newWidth = Math.round(width * scale);
        const newHeight = Math.round(height * scale);
        return resizeBase64Image(base64, newWidth, newHeight);
    }
    return base64;
};

export const fetchBlobFromUrl = async (url: string): Promise<Blob> => {
    const response = await fetch(url);
    return response.blob();
};

export const createImageFromBlob = (blob: Blob): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const objectURL = URL.createObjectURL(blob);
        img.onload = () => {
            URL.revokeObjectURL(objectURL);
            resolve(img);
        };
        img.onerror = () => {
            URL.revokeObjectURL(objectURL);
            reject(new Error("Failed to load the image."));
        };
        img.src = objectURL;
    });
};

export const createImageFromBase64 = (base64: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = base64;
    });
};

export const drawImageOnCanvas = (img: HTMLImageElement): HTMLCanvasElement => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d")!;
    canvas.width = img.width;
    canvas.height = img.height;
    context.drawImage(img, 0, 0);
    return canvas;
};

export const convertCanvasToBase64 = (canvas: HTMLCanvasElement, format = "image/webp"): string => {
    return canvas.toDataURL(format);
};

export const convertCanvasToJpeg = (canvas: HTMLCanvasElement): string => {
    return convertCanvasToBase64(canvas, "image/jpeg");
};

export async function webpToJpgBase64(url: string): Promise<string> {
    if (!url) return "";
    try {
        const blob = await fetchBlobFromUrl(url);
        const img = await createImageFromBlob(blob);
        let canvas = drawImageOnCanvas(img);

        const base64String = convertCanvasToBase64(canvas);
        const shrunkBase64 = await checkImageSizeAndShrinkIfNeeded(base64String, img.width, img.height);

        const shrunkImg = await createImageFromBase64(shrunkBase64);
        canvas = drawImageOnCanvas(shrunkImg);

        return convertCanvasToJpeg(canvas);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error converting webp to jpg", error);
        return "";
    }
}

export default webpToJpgBase64;
