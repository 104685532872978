/* eslint-disable no-case-declarations */
import { Reducer } from "redux";
import { toast } from "react-toastify";
import { AvosAction } from "../types";
import { EntityState } from "./reducer";

export const defaultFilter = { offset: 0, limit: 25 };

export const initialState: EntityState = {
    items: {
        results: [],
        count: 0,
    },
    isLoading: false,
    filter: defaultFilter,
    localFiltering: true,
    reload: false,
};

export interface EntityReducerActions {
    LIST: {
        LOADING: string;
        SUCCESS: string;
        ERROR: string;
        FILTER: string;
    };
    CREATE: {
        LOADING: string;
        SUCCESS: string;
        ERROR: string;
    };
    UPDATE: {
        LOADING: string;
        SUCCESS: string;
        ERROR: string;
    };
    DELETE: {
        LOADING: string;
        SUCCESS: string;
        ERROR: string;
    };
}

export const createEntityReducer = <T extends EntityState>({ LIST, CREATE, UPDATE, DELETE }:EntityReducerActions) => ((state: T = initialState as T, action: AvosAction): T => {
    switch (action.type) {
    case LIST.LOADING:
        return {
            ...state,
            isLoading: true,
            reload: false,
        };
    case LIST.SUCCESS:
        return {
            ...state,
            items: action.payload.data as any,
            isLoading: false,
        };
    case LIST.ERROR:
        return {
            ...state,
            isLoading: false,
        };
    case CREATE.LOADING:
        return {
            ...state,
            isLoading: true,
        };
    case CREATE.SUCCESS:
        toast.success("Entity created successfully");
        return {
            ...state,
            isLoading: false,
            reload: true, // Addition alters the ordering so we need to reload the list
        };
    case CREATE.ERROR:
        return {
            ...state,
            isLoading: false,
        };
    case UPDATE.LOADING:
        return {
            ...state,
            isLoading: true,
        };
    case UPDATE.SUCCESS:
        toast.success("Entity updated successfully");
        const currentSupplier = action.payload.data as any;
        const currentSupplierIndex = state.items.results.findIndex((s) => s.id === currentSupplier.id);
        return {
            ...state,
            items: {
                ...state.items,
                results: [
                    ...state.items.results.slice(0, currentSupplierIndex),
                    currentSupplier,
                    ...state.items.results.slice(currentSupplierIndex + 1),
                ],
            },
            isLoading: false,
        };
    case UPDATE.ERROR:
        return {
            ...state,
            isLoading: false,
        };
    case DELETE.LOADING:
        return {
            ...state,
            isLoading: true,
        };
    case DELETE.SUCCESS:
        toast.success("Entity deleted successfully");
        return {
            ...state,
            items: {
                ...state.items,
                results: state.items.results.filter((s) => s.id !== action.payload.data.id),
            },
            isLoading: false,
        };
    case DELETE.ERROR:
        return {
            ...state,
            isLoading: false,
        };
    case LIST.FILTER:
        return {
            ...state,
            ...state,
            filter: action.payload as any,
        };
    default: return state;
    }
}) as Reducer<T>;
