
/** @jsxImportSource @emotion/react */
import Field, { DataSource } from "./Field";

class CountChildrenField extends Field {
    field_type = "count_children_field";

    backend_table = "children";

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        const { children } = datasource.layer;
        if (!children || children.length === 0) {
            this.value = "-";
            return this;
        }
        this.value = children.length;
        return this;
    }

}

export default CountChildrenField;
