/** @jsxImportSource @emotion/react */
import CategoryConfigForm from "./CategoryConfigForm";
import FilterableConfigList from "./FilterableConfigList";

const defaultField = {
    field: {
        field: "atron_pressures_median",
        title: "Firmness",
        type: "pie",
        categories: [],
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
    // exclude_layer_types: [],
    }
};
export default function CheckSummaryCategoryChartsConfigForm({ name, title, fields, description, setArray }) {
    const getFieldTitle = (object) => <span> <span className="fw-bold">{object.title}</span> [{object.field}]</span>;
    const getFieldForm = (object, setObject) => [
        {
            label: "Field",
            type: "text",
            name: "field",
            description: "From which field do you want show the miniumum and maximum values. `atron_pressures_median` for example"
        },
        {
            label: "Title",
            type: "text",
            description: "Title for the chart. For example `Firmness` or `Dry matter`",
            name: "title"
        },

        {
            label: "Type",
            type: "single-select",
            name: "type",
            options: ["pie"].map((i) => ({ label: i, value: i })),
        },
        {
            type: "element",
            el: <CategoryConfigForm
                fields={object.categories || []}
                description="Configure categories for this field. When using `atron_pressures_median` as the firmness categories from the fruit config will be used."
                name="categories"
                useDisplayFilter={false}
                title="Custom categories"
                setArray={(categories) => setObject({ ...object, categories })} />
        },
    ];
    return <FilterableConfigList name={name} title={title} description={description} fields={fields} setArray={setArray} defaultField={defaultField} getFieldForm={getFieldForm} getFieldTitle={getFieldTitle} />;

}


