/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import stripesPicture from "../../../img/footer_bg_Eye.svg";

export interface FooterEyePlatformStripesProps {
    top?: string;
}

const getStripeStyle = (top = "0") => css`
    height: 10rem;
    width: 100%;
    background-size: contain;
    background-image: url(${stripesPicture});
    background-repeat: repeat-x;
    top: ${top};
`;

export default function FooterEyePlatformStripes({ top }: FooterEyePlatformStripesProps) {
    return <div css={getStripeStyle(top)} />;
}
