import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import { Button } from "reactstrap";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = { currentPage: 1 };
    }

    gotoPage = (page) => {
        const { onPageChanged = (f) => f } = this.props;

        const currentPage = Math.max(0, Math.min(page, this.totalPages));

        const paginationData = {
            currentPage,
            totalPages: this.totalPages,
            pageLimit: this.pageLimit,
            totalRecords: this.totalRecords,
        };

        this.setState({ currentPage }, () => onPageChanged(paginationData));
    };

    handleClick = (page, evt) => {
        evt.preventDefault();
        this.gotoPage(page);
    };

    handleMoveLeft = (evt) => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage - this.pageNeighbours * 2 - 1);
    };

    handleMoveRight = (evt) => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage + this.pageNeighbours * 2 + 1);
    };

    fetchPageNumbers = () => {
        const { totalPages } = this;
        const { currentPage } = this.state;
        const { pageNeighbours } = this;

        const totalNumbers = this.pageNeighbours * 2 + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            let pages = [];

            const leftBound = currentPage - pageNeighbours;
            const rightBound = currentPage + pageNeighbours;
            const beforeLastPage = totalPages - 1;

            const startPage = leftBound > 2 ? leftBound : 2;
            const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

            pages = range(startPage, endPage);

            const pagesCount = pages.length;
            const singleSpillOffset = totalNumbers - pagesCount - 1;

            const leftSpill = startPage > 2;
            const rightSpill = endPage < beforeLastPage;

            const leftSpillPage = LEFT_PAGE;
            const rightSpillPage = RIGHT_PAGE;

            if (leftSpill && !rightSpill) {
                const extraPages = range(startPage - singleSpillOffset, startPage - 1);
                pages = [leftSpillPage, ...extraPages, ...pages];
            } else if (!leftSpill && rightSpill) {
                const extraPages = range(endPage + 1, endPage + singleSpillOffset);
                pages = [...pages, ...extraPages, rightSpillPage];
            } else if (leftSpill && rightSpill) {
                pages = [leftSpillPage, ...pages, rightSpillPage];
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    };

    shouldComponentUpdate(nextProps) {
        if (this.props.totalRecords !== nextProps.totalRecords) {
            this.setState({ currentPage: 1 });
        }

        return true;
    }

    render() {
        this.pageLimit = typeof this.props.pageLimit === "number" ? this.props.pageLimit : 30;
        this.totalRecords = typeof this.props.totalRecords === "number" ? this.props.totalRecords : 0;

        this.pageNeighbours = typeof this.props.pageNeighbours === "number"
            ? Math.max(0, Math.min(this.props.pageNeighbours, 2))
            : 0;

        this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);

        if (!this.totalRecords) return null;

        if (this.totalPages === 1) return null;

        const { currentPage } = this.state;
        const pages = this.fetchPageNumbers();

        return (
            <Fragment>
                <nav aria-label="Countries Pagination">
                    <ul className="pagination pagination-sm mb-0">
                        {pages.map((page, index) => {
                            if (page === LEFT_PAGE) {
                                return (
                                    <li key={index} className="page-item pe-2">
                                        <Button
                                            className="page-link"
                                            aria-label="Previous"
                                            onClick={this.handleMoveLeft}
                                        >
                                            <span aria-hidden="true">&laquo;</span>
                                            <span className="visually-hidden">Previous</span>
                                        </Button>
                                    </li>
                                );
                            }

                            if (page === RIGHT_PAGE) {
                                return (
                                    <li key={index} className="page-item pe-2">
                                        <Button
                                            className="page-link"
                                            aria-label="Next"
                                            onClick={this.handleMoveRight}
                                        >
                                            <span aria-hidden="true">&raquo;</span>
                                            <span className="visually-hidden">Next</span>
                                        </Button>
                                    </li>
                                );
                            }

                            return (
                                <li
                                    key={index}
                                    className={`page-item pe-2 ${
                                        currentPage === page ? " active" : ""
                                    }`}
                                >
                                    <Button
                                        className="page-link"
                                        onClick={(e) => this.handleClick(page, e)}
                                    >
                                        {page}
                                    </Button>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </Fragment>
        );
    }
}

Pagination.propTypes = {
    totalRecords: PropTypes.number.isRequired,
    pageLimit: PropTypes.number,
    pageNeighbours: PropTypes.number,
    onPageChanged: PropTypes.func,
};

export default Pagination;
