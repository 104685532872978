import { useEffect, useRef, useState } from "react";

type ClickHandler = () => void;

const useClickHandler = (
    onSingleClick: ClickHandler,
    onDoubleClick: ClickHandler,
    delay = 250
) => {
    const [clicks, setClicks] = useState(0);
    const timer = useRef<number | undefined>();

    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        timer.current = setTimeout(() => {
            if (clicks === 1) {
                onSingleClick();
            } else if (clicks > 1) {
                onDoubleClick();
            }
            setClicks(0);
        }, delay) as unknown as number;

        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };

    }, [clicks]);

    const handleClick = () => setClicks((prev) => prev + 1);

    return handleClick;
};

export default useClickHandler;
