/** @jsxImportSource @emotion/react */
import { useSelector } from "react-redux";
import { Layer } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { getFilledArrayOrDefault, isFilledArray } from "../../../../utils";
import { MetricCardComponents } from "./Components/MetricCards";


export default function LayerMetricCardsGroup() {
    const config = useConfig();
    const layer = useSelector<any, Layer>((state) => state.layers.current);
    const layerTypeConfig = config.get_layer_config(layer);
    const layerMetricList = layerTypeConfig?.show_metric_cards;

    if (!isFilledArray(layerMetricList)) {
        return null;
    }

    return (
        <div className="pb-5">
            <div className="d-flex align-items-start pt-3 flex-nowrap">
                {getFilledArrayOrDefault(layerMetricList, []).map((i, index) => {
                    const MetricComponent = MetricCardComponents[i.key];
                    if (!MetricComponent) {
                        // eslint-disable-next-line no-console
                        console.log(`MetricCardComponent not found for key: ${i.key}`);
                        return null;
                    }
                    return <MetricComponent key={index} metric={i} />;
                })}
            </div>
        </div>
    );
}
