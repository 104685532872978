import { Location } from "./constants";
import { BROWSE_HISTORY } from "./actionTypes";


export function addBrowseHistory(location: Location) {
    return {
        type: BROWSE_HISTORY.SUCCESS,
        payload: {
            pathname: location.pathname,
            id: location.id,
            label: location.label,
            text: location.text,
            level: location.level
        }
    };
}
