/** @jsxImportSource @emotion/react */
import { useRef, useState } from "react";
import { OptionsOrGroups } from "react-select";
import AsyncSelect from "react-select/async";
import { useSelector } from "react-redux";
import { Badge } from "reactstrap";
import { LAYER_TYPE, Layer } from "../../actions/Layers/constants";
import { toast } from "../../utils/toast";
import apiClient from "../../constants/apiClient";
import { ROOT_URL } from "../../constants/urls";


type MetaItem = {
    key: string;
    value: string;
};

type Option = {
    label: string;
    value: string;
    meta: MetaItem[];
};

export interface LayerState {
    layers: {
        current: Layer;
    };
}

type SingleLayerChildrenAutoCompleteProps = {
    type: LAYER_TYPE | null,
    setValue?: (field: string, value: string) => void,
    meta_options?: string[],
    field: string,
    post_value?: string,
};

type LayerChildrenAutoCompleteProps = {
    type?: LAYER_TYPE | null,
    setValue?: (field: string, value: string) => void,
    meta_options?: string[],
    field: string,
    post_value?: string,
};

export interface LayerChildrenAutoComplete {
    (props: SingleLayerChildrenAutoCompleteProps): JSX.Element,
    (props: LayerChildrenAutoCompleteProps): JSX.Element,
}

export const formatOptionLabel = (props: Option) => {
    const { label, meta } = props;
    return (
        <div className="py-1">
            <div><b>{label}</b></div>
            {meta.map((item, index) => (
                <div key={index}>
                    <Badge size="sm" className="me-1">{item.key}</Badge>
                    <Badge size="sm" className="me-1"> [ {item.value} ] </Badge>
                </div>
            ))}
        </div>
    );
};

export const LayerChildrenAutoComplete: LayerChildrenAutoComplete = (({
    setValue, field, type, meta_options, post_value
}: LayerChildrenAutoCompleteProps) => {
    const timer = useRef<NodeJS.Timeout | null>(null);
    const layer = useSelector((state: LayerState) => state.layers.current);
    const [layer_meta, setLayerMeta] = useState([]);

    const searchLayers = (
        value: string,
        callback: (options: OptionsOrGroups<Option, never>) => void,
    ) => {
        if (timer.current !== null) {
            clearTimeout(timer.current);
        }

        timer.current = setTimeout(() => {
            apiClient
                .get(`${ROOT_URL}api/layers/search/`, {
                    params: {
                        text: value,
                        layer_type: type,
                        parent_layer_id: layer.id,
                    }
                })
                .then((response) => {
                    const options = response.data.map((i: any) => ({
                        value: i.label,
                        label: i.label,
                        meta: (meta_options
                            ? i.meta.filter((item: MetaItem) => meta_options.some((key) => key === item.key))
                            : []
                        ).map((j: MetaItem) => ({ key: j.key, value: j.value })),
                    }));
                    callback(options);
                })
                .catch((error) => toast.error(`Could not fetch layers ${error}`));
        }, 350);
    };

    const _onLayerSelect = (values) => {
        const selectedValue = values ? values.value : "";
        if (setValue) {
            setValue(field, selectedValue);
        }
        setLayerMeta(values.meta);
    };

    const value = post_value ? { value: post_value, label: post_value, meta: layer_meta } : null;

    return <AsyncSelect
        formatOptionLabel={formatOptionLabel}
        loadOptions={searchLayers}
        onChange={_onLayerSelect}
        value={value}
    />;
}) as LayerChildrenAutoComplete;

export default LayerChildrenAutoComplete;
