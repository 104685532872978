/** @jsxImportSource @emotion/react */
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { addLocationCondition } from "../../../../actions/FacilityLocations/actions";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { getFilledArrayOrDefault } from "../../../../utils";
import MetaForm from "../../../Forms/MetaForm";
import { formatDate, getDayConditions } from "./hooks";
// RipeningCell component
export default function RipeningCellConditionsForm({ refreshConditions, editDate, closeCellConditionsForm }) {
    const params = useParams();

    const dispatch = useDispatch();
    const config = useConfig();
    const [query, setQuery] = useState({});

    const { location_id } = params;


    const default_meta_form = [
        {
            label: "Ripening program",
            name: "ripening_program",
            type: "single-select",
            disabled: !editDate?.isValid(),
            options: [
                { label: "Program 1", value: "program_1" },
                { label: "Program 2", value: "program_2" },
                { label: "Program 3", value: "program_3" },
            ]
        },
        {
            label: "Ripening target",
            name: "ripening_target",
            type: "single-select",
            disabled: !editDate?.isValid(),
            options: [
                { label: "RTE", value: "rte" },
                { label: "Triggered", value: "triggered" },
            ]
        },
        {
            label: "Temperature",
            name: "temperature",
            type: "single-select",
            disabled: !editDate?.isValid(),
            options: [
                { label: "4", value: "4" },
                { label: "6", value: "6" },
                { label: "8", value: "8" },
            ]
        },
        {
            label: "Ethylene",
            name: "ethylene",
            type: "single-select",
            disabled: !editDate?.isValid(),
            options: [
                { label: "100", value: "100" },
                { label: "150", value: "150" },
                { label: "200", value: "200" },
                { label: "250", value: "250" },
            ]
        },
    ];

    const addCondition = (field, value) => {
        dispatch(addLocationCondition({
            location_id,
            key: field,
            value,
            active_since: editDate.format("YYYY-MM-DDTHH:mm:ss"),
            created: dayjs().toISOString(),
        })).then(() => {
            // Refresh conditions for given timeframe
            refreshConditions();
        });
    };

    const { keyLabelMap } = getDayConditions(editDate);
    useEffect(() => {
        keyLabelMap.map((item) => setQuery((prev) => ({ ...prev, [item.key]: item.value })));
    }, [getFilledArrayOrDefault(keyLabelMap).map((i) => i.value).join(",")]);

    return <Modal isOpen={editDate?.isValid()} toggle={() => closeCellConditionsForm()}>
        <ModalHeader toggle={() => closeCellConditionsForm()}>Add conditions</ModalHeader>
        <ModalBody>
            <Alert color="info">You are changing the cell conditions for <b>{formatDate(editDate)}</b></Alert>
            <MetaForm
                config={config}
                object={query}
                grid={false}
                meta={default_meta_form}
                setValue={(field, value) => setQuery((prev) => ({ ...prev, [field]: value }))}
                onDebounce={(field, value) => addCondition(field, value)}
                debounce_time={200}
            />
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={() => closeCellConditionsForm()}>Save</Button>
        </ModalFooter>
    </Modal>;
}
