/* eslint-disable no-nested-ternary */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Table } from "reactstrap";
import { CHECK_LOCATION } from "../../../../actions/Layers/constants";
import { LayersState } from "../../../../actions/Layers/reducer";
import useConfig, { useTenantFormOptions } from "../../../../actions/Tenants/config/configHook";
import { RootState } from "../../../../reducers";
import { Loading } from "../../../Helper/Loading";
import { getLatestCheck } from "./LayerMeta";

export default function SubLayers({ subconfig, show_art }) {
    const children = useSelector<RootState, any>((state) => state.layers.children);
    const checks = useSelector<RootState, any[]>((state) => state.layers.checks);

    const [ARTChildren, SetARTChildren] = useState<any>(false);
    const childrenLoading = useSelector<RootState, boolean>((state) => state.layers.childrenLoading);

    // TODO: instead of "show_art", create "check_location", so that is can be re-used for other locations
    useEffect(() => {
        if (show_art && checks && children) {
            // we filter the children to only show the ones that have a check in the advance ripening location
            const filteredLayerIds = checks
                .filter((check) => check.location === CHECK_LOCATION.ADVANCE_RIPENING)
                .map((check) => check.layer_id);
            // TODO:  basically checking if the checks belong to  one of the children. but that is inheritenly always true. No-op.
            const filteredChecks = children.results.filter((child) => filteredLayerIds.includes(child.id));
            SetARTChildren(
                {
                    count: filteredChecks.length,
                    results: filteredChecks
                }
            );
        }
    }, [checks, show_art]);


    if (childrenLoading || !children) {
        return <Loading />;
    }

    if (children.count === 0) {
        return null;
    }

    return <div className="w-100 pb-5">
        <div css={css`border-radius:8px;`} className="bg-body shadow p-5" >
            <h3 className=" mb-0">{subconfig.title}</h3>
            <div className="pt-3">
                <SubLayerTable fields={subconfig.fields} layerChildren={ARTChildren || children} />
            </div>
        </div>
    </div >;
}

enum SortDirection {
    ASC = "ASC",
    DESC = "DESC",
    NONE = 0
}
interface SortingValues {
    index: number;
    direction: SortDirection;
}
interface THProps {
    children: any;
    onClick: (index: number) => void;
    columnIndex: number;
    sortDirection: SortDirection;
}

// TODO: Refactor to use the TableHeaderCell component
function TH({ children, onClick, columnIndex, sortDirection }: THProps) {
    return <td
        className="text-muted text-start text-nowrap fw-bold py-1 px-2"
        css={css`color: black !important;background: #e5e8e5!important; border-right: 1px solid white; cursor:pointer`}
        onClick={() => onClick(columnIndex)}>
        {children}
        {sortDirection === SortDirection.ASC && (
            <span className="p-2">
                <FontAwesomeIcon icon={faCaretUp} />
            </span>
        )}
        {sortDirection === SortDirection.DESC && (
            <span className="p-2">
                <FontAwesomeIcon icon={faCaretDown} />
            </span>
        )}
    </td>;
}

interface TDProps {
    children: any[];
}
function TD({ children }: TDProps) {
    return <td className="text-start text-nowrap px-2 text-capitalize">{children}</td>;
}

export interface SubLayerTableProps {
    fields: any[];
    layerChildren: any;
}

export function SubLayerTable({ fields, layerChildren }: SubLayerTableProps) {
    const childrenLoading = useSelector<RootState, boolean>((state) => state.layers.childrenLoading);
    const form_options = useTenantFormOptions();
    const checks = useSelector<RootState, LayersState["checks"]>((state) => state.layers.checks);
    const config = useConfig();
    const navigate = useNavigate();
    const defaultSortIndex = fields.findIndex((i) => i.fieldname_getter === "label");
    const [sortingValues, setSortingValues] = useState<SortingValues>({ index: defaultSortIndex || 0, direction: SortDirection.ASC });

    if (!layerChildren) {
        return null;
    }

    // sometimes needed when switching tenant
    if (!fields) {
        return null;
    }

    const getPopulatedFields = (p) => fields.map((i) => i.clone().set_value({
        config,
        layer: p,
        check: getLatestCheck(checks, null, p.id), // * Make sure to copy the check fields you need
        fruit: null, // * Fruit list is not available on overview screen
        children: null, // * children is only available for layer index your are viewing
        form_options // * used to translate form values to labels
    }));

    const layers = layerChildren.results;

    const cellValueMap = layers.reduce((acc, layer) => {
        return { ...acc, [layer.id]: getPopulatedFields(layer) };
    }, {});

    if (sortingValues !== undefined) {
        layers.sort((a, b) => {
            let valueA = cellValueMap[a.id][sortingValues.index]?.value;
            let valueB = cellValueMap[b.id][sortingValues.index]?.value;
            const valueAAsNumber = Number(valueA);
            const valueBAsNumber = Number(valueB);
            if (!Number.isNaN(valueAAsNumber) && !Number.isNaN(valueBAsNumber)) {
                valueA = valueAAsNumber;
                valueB = valueBAsNumber;
            }
            if (sortingValues.direction === SortDirection.ASC) {
                return valueA < valueB ? -1 : 1;
            }
            return valueA > valueB ? -1 : 1;
        });
    }

    const header = () => fields.map((i, index) => <TH key={index} onClick={() => {
        const direction = sortingValues.index === index ? (sortingValues.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC) : SortDirection.ASC;
        setSortingValues({ index, direction });
    }} columnIndex={index} sortDirection={index === sortingValues.index ? sortingValues.direction : SortDirection.NONE}>{i.label}</TH>);
    return <div >
        {childrenLoading && <Loading />}
        {!childrenLoading && <div className="w-100" css={css`overflow:hidden;`}>
            <Table size="sm" responsive borderless={true}>
                <thead>
                    <tr>{header()}</tr>
                </thead>
                <tbody>
                    {layers.map((i, index) => <tr key={index} className="clickable" title={i.id} onClick={() => navigate(`/layer/${i.id}`)}>
                        {cellValueMap[i.id].map((field, indexx) => <TD key={indexx}>{field.display_component()}</TD>)}
                    </tr>)}
                </tbody>

            </Table >
        </div>}
    </div>;
}
