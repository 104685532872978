import {
    AUTH,
    BACKEND_VERSION,
    GET,
    LOGOUT,
    POWERBI_GET,
    POWERBI_LOGOUT,
    UPDATE,
} from "./actionsTypes";
import userReducer from "../Users/reducer";

const defaultState = { isLoading: false, user: { tenant: {}, authenticated: false }, backend_version: false };
export default (state = defaultState, action) => {
    switch (action.type) {
    case AUTH.LOADING:
        return { ...state, isLoading: action.data };

    case LOGOUT.SUCCESS:
        return { ...state, authenticated: false, user: { tenant: {} } };

    case UPDATE.SUCCESS:
        return {
            ...state, user: action.payload.data, isLoading: false, authenticated: true, powerbiEmbedInfo: undefined,
        };

    case GET.LOADING:
        return { ...state, isLoading: true };

    case GET.SUCCESS:
        return {
            ...state, user: action.payload.data, isLoading: false, authenticated: true,
        };

    case GET.ERROR:
        return {
            ...state, isLoading: false, authenticated: false, user: { tenant: {} },
        };

    case BACKEND_VERSION.SUCCESS:
        return {
            ...state, backend_version: action.payload.data.version
        };

    case POWERBI_GET.LOADING:
        return { ...state, isPowerbiLoading: true };

    case POWERBI_GET.SUCCESS:
        return { ...state, powerbiEmbedInfo: action.payload.data, isPowerbiLoading: false };

    case POWERBI_GET.ERROR:
        return { ...state, isPowerbiLoading: false };

    case POWERBI_LOGOUT.SUCCESS:
        return { ...state, powerbiEmbedInfo: undefined };

    default:
        return {
            ...state,
            user: userReducer(state.user, action),
        };
    }
};
