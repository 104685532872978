export const AVOCADO_FRUIT = "avocado";
export const MANGO_FRUIT = "mango";
export const KIWI_FRUIT = "kiwi";
export const PEACH_FRUIT = "peach";
export const NECTARINE_FRUIT = "nectarine";
export const PLUM_FRUIT = "plum";
export const PEAR_FRUIT = "pear";
export const DEFAULT_FRUIT = "fruit";
export const APPLE_FRUIT = "apple";
export const GRAPE_FRUIT = "grape";
export const ALL_FRUIT = [DEFAULT_FRUIT, AVOCADO_FRUIT, MANGO_FRUIT, KIWI_FRUIT, PEACH_FRUIT, NECTARINE_FRUIT, PLUM_FRUIT, PEAR_FRUIT, APPLE_FRUIT, GRAPE_FRUIT];
