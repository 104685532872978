import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import dayOfYear from "dayjs/plugin/dayOfYear";

dayjs.locale("en-gb");
dayjs.extend(dayOfYear);

export const getDayOptions = (daysBack = 7, daysForward = 1) => {
    const options = [];
    const endDay = dayjs().add(daysForward, "day");
    let iteratorDay = dayjs().subtract(daysBack, "day");
    while (iteratorDay.isBefore(endDay)) {
        options.push({
            text: iteratorDay.format("DD MMMM"),
            value: iteratorDay.format("YYYY-MM-DD"),
        });
        iteratorDay = iteratorDay.add(1, "day");
    }
    return options;
};

export const julianCode2Dayjs = (julianCode) => {
    const dayOfYear = dayjs(julianCode).dayOfYear(); // Calculate the day of the year for the julianCode date
    const julianDate = dayjs().dayOfYear(dayOfYear);

    // if this date is in the future... then it is probably from last year
    if (julianDate.isAfter(dayjs())) {
        return dayjs().subtract(1, "year").dayOfYear(julianCode);
    }
    return julianDate;

};

export const julianCode2packingDate = (julianCode) => {
    const julianDate = julianCode2Dayjs(julianCode);
    return julianDate.format("YYYY-MM-DD");
};

export const packingDate2JulianCode = (packingDate) => {
    const julianDate = dayjs(packingDate);
    return julianDate.dayOfYear();
};

export const fruitAge2Date = (fruitAge) => {
    return dayjs().subtract(fruitAge, "day").format("YYYY-MM-DD");
};

export const date2FruitAge = (date) => {
    return dayjs().diff(dayjs(date), "day");
};
