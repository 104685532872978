import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader } from "reactstrap";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { faHistory } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";


const LocationTracker: React.FC = () => {
    const navigate = useNavigate();
    const browse_history = useSelector((state: any) => state.navigation.browse_history);
    const layer = useSelector((state: any) => state.layers.current);

    const [isOpen, setModal] = useState(false);

    return (
        <>
            {browse_history.length > 0 && <div>
                <div>
                    <Breadcrumb className="mt-3 mb-5 d-none d-md-block">
                        <BreadcrumbItem onClick={() => setModal(true)}>
                            <span className="text-muted cursor-pointer">
                                <FontAwesomeIcon icon={faHistory} /> Viewed items
                            </span>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Modal size="md" isOpen={isOpen} toggle={() => setModal(false)}>
                    <ModalHeader toggle={() => setModal(false)}>Previous viewed items</ModalHeader>
                    <ModalBody>
                        <ListGroup>
                            {removeDuplicates(browse_history).map((loc, index) => (
                                <ListGroupItem key={index} href="#" tag="a" onClick={() => navigate(`${loc.pathname}`)}>
                                    <div className="d-flex">
                                        <span>{loc?.text} {loc?.label}</span>
                                        {loc.id !== layer.id && (
                                            <span className="ms-auto">
                                                <FontAwesomeIcon icon={faArrowRight} />
                                            </span>
                                        )}
                                    </div>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </ModalBody>

                </Modal>
            </div>}
        </>
    );
};

export default LocationTracker;

function removeDuplicates(arrOfObjects: any[]) {
    const uniqueLabels: string[] = [];
    return arrOfObjects.reverse().filter((obj) => {
        if (!obj.label) {
            return false;
        }
        const isDuplicate = uniqueLabels.includes(obj.label);
        if (!isDuplicate) {
            uniqueLabels.push(obj.label);
            return true;
        }
        return false;
    }).reverse();
}
