/** @jsxImportSource @emotion/react */
import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { triggerCheckBusinessRules } from "../../../../../actions/Checks/actions";
import useConfig from "../../../../../actions/Tenants/config/configHook";
import { path_manual_check } from "../../../../../actions/Tenants/config/constants";
import { metaFormIsValid } from "../../../../Forms/MetaForm";
import { Loading } from "../../../../Helper/Loading";
import Box from "../Layout";
import BoxedFruitTable, { BoxItem } from "./Boxes";
import CheckImages from "./CheckImages";
import CheckSummaryForm from "./CheckSummaryForm";
import InvalidCheckModal from "./InvalidCheckModel";
import LayerCtaCheckSummary from "./LayerCtaCheckSummary";
import PalletSummary from "./PalletSummary";
import useAuthorization from "../../../../../utils/authorization";
import { UserRole } from "../../../../../actions/Tenants/config/constantsTyped";


export default function CheckSummary() {
    const check = useSelector((state) => state.checks.current);
    const layer = useSelector((state) => state.layers.current);
    const browse_history = useSelector((state) => state.navigation.browse_history);
    const config = useConfig();
    const location = config.get_location(layer, check);
    const params = useParams();
    const layer_config = config.get_layer_config(layer);
    const lastBrowse = browse_history[browse_history.length - 1];
    const previousPath = lastBrowse?.pathname || `/layer/${params.layer_id}`;

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const submit = () => {
        dispatch(triggerCheckBusinessRules(check.test_id));
        const has_valid_meta_form_summary = location.meta_form_summary && metaFormIsValid(location.meta_form_summary, check);

        if (has_valid_meta_form_summary || !location.meta_form_summary) {

            if (location?.cta_form_title) {
                navigate(`/layer/${layer_id}/add-check/${check.test_id}/${params.action}/modal/cta`);
                return;
            }

            navigate(previousPath);
        }
    };

    // take box_size from check, if unavaiable take it from layer
    const box_size = check.box_size || layer.box_size;
    const multiple_boxes = box_size > 0 && location.show_fruit_per_box;

    // when you navigate from a parent layer to a child layer check summary...
    // we need to give it some time to reinstate the config (e.g. when the parent doesn't have a location)
    if (!layer || !check || !location || !location.summary_fruit_fields) {
        return <Loading />;
    }
    const buttons = <SummaryBottomButtons submit={() => submit()} />;
    return (
        <div >
            <Box headerTitle={"Summary"} buttons={buttons} >
                <PalletSummary />
                {/* {location.flow.includes(path_visual_check) && <VisualCheckForm show_images={false} is_check_summary={true}/>} */}
                {!multiple_boxes && <BoxItem avocados={check.avocados} check={check} layer={layer} title={`Check ${check.test_id}`} />}
                {multiple_boxes && <BoxedFruitTable check={check} layer={layer} />}
                <CheckSummaryForm check={check} layer={layer} />
                <CheckImages test_id={check.test_id} required_images={location.required_images || layer_config.show_images} />
                <LayerCtaCheckSummary />
            </Box>
        </div>
    );
}


export function SummaryBottomButtons(props) {
    const check = useSelector((state) => state.checks.current);
    const layer = useSelector((state) => state.layers.current);
    const user = useSelector((state) => state.auth.user);
    const auth = useAuthorization();
    const config = useConfig();
    const location = config.get_location(layer, check);
    const navigate = useNavigate();
    const isUpdating = useSelector((state) => state.avocados.isUpdating);
    const checkIsUpdating = useSelector((state) => state.checks.isUpdating);
    const disabled = isUpdating || checkIsUpdating;
    const { submit } = props;
    if (!check.location) {
        return null;
    }
    const prev = !check.is_manual && config.prev(layer, check);

    return (
        <div >
            <div className="py-4 d-md-flex justify-content-between align-items-center">
                <div className="me-2 d-inline-flex d-md-block">
                    {(user.is_admin || auth.userHasRole(UserRole.TENANT_ADMIN)) && <InvalidCheckModal check={check} />}
                </div>
                <div className="d-md-flex justify-content-end align-items-center">
                    {prev && <div className="me-2 d-inline-flex d-md-block">
                        <Button color="secondary" disabled={disabled} outline={true} block className="my-1" onClick={() => navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${prev.path}`)}>Edit {location?.unit_of_account || "Fruit"}</Button>
                    </div>}
                    {check.is_manual && <div className="me-2 d-inline-flex d-md-block">
                        <Button color="secondary" disabled={disabled} outline={true} block className="my-1" onClick={() => navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/${path_manual_check}`)}>Edit {location?.unit_of_account || "Fruit"}</Button>
                    </div>}
                    <div className="me-2 d-inline-flex d-md-block">
                        <Button color="secondary" disabled={disabled} outline={true} block className="my-1" onClick={() => navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/report`)}><FontAwesomeIcon icon={faPrint} /> Print</Button>
                    </div>
                    <div className="me-2 d-inline-flex d-md-block">
                        <Button color="primary" disabled={disabled} block className="my-1" onClick={() => { submit(); }}>Submit</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
SummaryBottomButtons.propTypes = {
    submit: PropTypes.func,
};
