/** @jsxImportSource @emotion/react */
import { Container } from "reactstrap";
import WasteFilter from "./Filter";
import WasteList from "./WasteList";

export default function WasteIndex() {

    return <Container>
        <div className="pt-5 pb-3">
            <h3 className="">Logs Waste</h3>
        </div>
        <div>
            <WasteFilter/>
        </div>
        <div>
            <WasteList/>
        </div>
    </Container>;
}
