/**
 * Moves an item in the array up by one index.
 * @param {T[]} array - The array containing the items.
 * @param {number} index - The index of the item to move up.
 * @returns {T[]} - The updated array with the item moved up.
 * If the index is out of bounds or already at the beginning, returns the original array.
 * @template T
 */
export const moveItemUp = <T>(array: T[], index: number): T[] => {
    if (index <= 0 || index >= array.length) {
        return array; // If index is out of bounds or already at the beginning, return the original array
    }

    const itemToMove = array.splice(index, 1)[0]; // Remove item at index
    array.splice(index - 1, 0, itemToMove); // Insert item at index - 1

    return array;
};

/**
 * Moves an item in the array down by one index.
 * @param {T[]} array - The array containing the items.
 * @param {number} index - The index of the item to move down.
 * @returns {T[]} - The updated array with the item moved down.
 * If the index is out of bounds or already at the end, returns the original array.
 * @template T
 */
export const moveItemDown = <T>(array: T[], index: number): T[] => {
    if (index < 0 || index >= array.length - 1) {
        return array; // If index is out of bounds or already at the end, return the original array
    }

    const itemToMove = array.splice(index, 1)[0]; // Remove item at index
    array.splice(index + 1, 0, itemToMove); // Insert item at index + 1

    return array;
};
