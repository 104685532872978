/** @jsxImportSource @emotion/react */
import { LAYER_TYPE } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import MetaForm from "../../../Forms/MetaForm";
import { ReportType } from "../../Layers/Layer/Report/utils";
import { DisplayFilterForm } from "./DisplayFilterForm";
import FieldConfigForm from "./FieldConfigForm";
import SanitizeUnexpectedFields from "./SanitizeUnexpectedFields";
import { yerOrNoRadio } from "./util";

export const defaultPdfReport = {
    text: "Intake",
    value: "intake",
    layer_type: "purchase_order",
    logo_name: "",
    internal_defects_title: "",
    external_defects_title: "",
    children_table_fields: [],
    children_check_summary_meta_fields: [],
    show_graphs: true, // by default is true for all tenants
    check_meta_fields: []

};

export default function PDFReportConfigForm({ config_data, setConfigData }) {
    const config = useConfig();

    const form = [
        {
            label: "Label",
            description: `What is the label of this pdf report. Used in the button (Print ${config_data.text}).`,
            name: "text",
            type: "text"
        },
        {
            label: "Value",
            description: `What is the value/id of this pdf report. If not ART, it will be used to fetch checks and check config of this type.`,
            name: "value",
            type: "single-select",
            options: Object.entries(ReportType).map(([label, value]) => ({ label, value }))
        },
        {
            label: "Layer type",
            description: "On which layer type do you want to generate this report?",
            name: "layer_type",
            type: "single-select",
            options: Object.keys(LAYER_TYPE).map((i) => ({ value: LAYER_TYPE[i], label: i }))
        },
        {
            label: "Display filter",
            type: "element",
            name: "display_filter",
            el: <DisplayFilterForm
                object={config_data.display_filter}
                setValue={(field, value) => setConfigData({ ...config_data, display_filter: { ...config_data.display_filter, [field]: value } })}
                attributes={["fruit_types", "exclude_fruit_types"]} />
        },
        {
            label: "Logo name",
            name: "logo_name",
            description: "Show tenant logo on the pdf report. use extension like .png, .jpg, .jpeg...",
            type: "text"
        },
        {
            type: "element",
            name: "children_table_fields",
            el: <FieldConfigForm
                fields={config_data.children_table_fields || []}
                description="What fields to display in the children (pallet) table. Only for purchase order layer type."
                name="children_table_fields"
                title="Children table fields"
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                setArray={(children_table_fields) => setConfigData({ ...config_data, children_table_fields })} />
        },
        {
            type: "element",
            name: "pallet_meta_fields",
            el: <FieldConfigForm
                fields={config_data.pallet_meta_fields || []}
                description="What contextual data to display for pallet in ART report. "
                name="pallet_meta_fields"
                title="Pallet meta fields"
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                setArray={(pallet_meta_fields) => setConfigData({ ...config_data, pallet_meta_fields })} />
        },
        {
            type: "element",
            name: "children_check_summary_meta_fields",
            el: <FieldConfigForm
                fields={config_data.children_check_summary_meta_fields || []}
                description="What fields to show on the check summary"
                name="children_check_summary_meta_fields"
                title="Check summary fields"
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                setArray={(children_check_summary_meta_fields) => setConfigData({ ...config_data, children_check_summary_meta_fields })} />
        },

        {
            type: "element",
            name: "check_meta_fields",
            el: <FieldConfigForm
                fields={config_data.check_meta_fields || []}
                description="Check meta fields to display Intake report. Like Unapproved grower, Has contaminated fruit"
                name="check_meta_fields"
                title="Check meta fields"
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                setArray={(check_meta_fields) => setConfigData({ ...config_data, check_meta_fields })} />
        },
        {
            label: "Show graphs?",
            name: "show_graphs",
            description: "Show defect bar charts",
            ...yerOrNoRadio
        },
        {
            label: "Show send email button?",
            name: "show_send_email_button",
            description: "[Deprecated] Show send email button on the pdf report. Use teams_that_can_send instead.",
            ...yerOrNoRadio
        },
        {
            label: "Teams that can send the report",
            name: "teams_that_can_send",
            description: "Teams that can send the report. Leave empty to hide button. Button not showing? Set \"specific_pdf_layout\" to \"yes\".",
            type: "select-managed",
            multi: true,
            return_array: true,
            options_name: "teams",
        },
        {
            label: "Teams that can manage QC status of the report",
            name: "teams_that_can_manage_qc_status",
            description: "Teams that can manage QC status of the report (Mark ready for review). Leave empty to hide button. Button not showing? Set \"specific_pdf_layout\" to \"yes\".",
            type: "select-managed",
            multi: true,
            return_array: true,
            options_name: "teams",
        },
        {
            label: "Is the pdf report going to use specific layout?",
            name: "use_specific_pdf_layout",
            description: "Use a specific layout for the pdf report. If not set, the default layout will be used (Check summary layout).",
            ...yerOrNoRadio
        },
        {
            label: "Save draft report",
            name: "text_save_draft_report",
            description: "Text to show on the button to save the draft report. By default is 'Save draft report'.",
            type: "text",
        },
        {
            label: "Edit report",
            name: "text_edit_report",
            description: "Text to show on the button to edit report. By default is 'Edit'.",
            type: "text",
        },
        {
            label: "Report sent",
            name: "text_report_sent",
            description: "Text to show on the button to send report. By default is 'Report sent'.",
            type: "text",
        }, {
            label: "Show Dry matter?",
            name: "show_dry_matter",
            description: "Show dry matter in the pdf report.",
            ...yerOrNoRadio
        },
    ];

    return <div>
        <SanitizeUnexpectedFields form={form} object={config_data} setObject={setConfigData} />
        <MetaForm meta={form} setValue={(field, value) => setConfigData({ ...config_data, [field]: value })} object={config_data} config={config} />
    </div>;

}
